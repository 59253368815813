import { Dispatch, SetStateAction, useState } from "react";

import { ProjectActivitiesConstants, ProjectActivityReviewsConstants } from "../../../../constants";
import { transitionActivity } from "../../../../service/activity";
import { Status } from "../../../../service/Shared";
import { getErrorMessageFromCode } from "../../../../service/ValidationErrorFormatter";
import { useIsLoadingWrapper } from "../../../../utils";
import { Toast } from "../../../../widget";
/* eslint-disable unused-imports/no-unused-vars */
interface useReturnToDeveloperModalProps {
  activityUuid: string;
  activityStatus: string;
  activityRowVersion: number;
  onClose: () => void;
  activityAssessmentUuid: string;
  activityAssessmentStatus: string;
  activityAssessmentRowVersion: number;
  refreshActivityReviews?: boolean;
  setRefreshActivityReviews?: Dispatch<SetStateAction<boolean>>;
  exitActivityReviewWizard?: () => void;
}
interface useReturnToDeveloperModalReturnData {
  isLoading: boolean;
  onConfirmClick: () => void;
}

export const useReturnToDeveloperModal = ({
  activityUuid,
  activityStatus,
  activityRowVersion,
  onClose,
  activityAssessmentUuid,
  activityAssessmentStatus,
  activityAssessmentRowVersion,
  refreshActivityReviews,
  setRefreshActivityReviews,
  exitActivityReviewWizard,
}: useReturnToDeveloperModalProps): useReturnToDeveloperModalReturnData => {
  const [isLoading, setIsLoading] = useState(false);

  const onConfirmClick = useIsLoadingWrapper(async (): Promise<void> => {
    const res = await transitionActivity({
      activity: {
        uuid: activityUuid,
        rowVersion: activityRowVersion,
        toStatus: ProjectActivitiesConstants.STATUS_CHANGES_REQUIRED,
        fromStatus: activityStatus,
        publishExternalMessages: true,
        publishInternalMessages: false,
      },
      activityReviews: [
        {
          activityReviewUuid: activityAssessmentUuid,
          rowVersion: activityAssessmentRowVersion,
          fromStatus: activityAssessmentStatus,
          toStatus: ProjectActivityReviewsConstants.STATUS_AWAITING_FEEDBACK,
        },
      ],
    });

    if (res.status === Status.Success) {
      Toast.success({ message: "Activity returned to developer" });

      // If called from dashboard refresh context
      if (refreshActivityReviews !== undefined && setRefreshActivityReviews !== undefined) {
        setRefreshActivityReviews(!refreshActivityReviews);
      }

      // If called from wizard, call this (navigate)
      if (exitActivityReviewWizard !== undefined) exitActivityReviewWizard();

      onClose();
    } else if (res.errors && res.errors.length > 0) {
      Toast.error({ message: getErrorMessageFromCode(res.errors[0].message) });
    }
  }, setIsLoading);

  return {
    isLoading,
    onConfirmClick,
  };
};
