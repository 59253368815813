import { Column, Row } from "../../../../../widget";
import { MarketingSettingsForm } from "./form/MarketingSettingsForm";
import { useMarketingSettings } from "./useMarketingSettings";

const MarketingSettings = (): JSX.Element => {
  const { defaultValues, projectDetails } = useMarketingSettings();
  return (
    <div className="ProjectTabSection">
      <Row spacingV="ll">
        <Column span={12}>
          <h2>Marketing settings</h2>
        </Column>
      </Row>
      {defaultValues && projectDetails && (
        <MarketingSettingsForm defaultValues={defaultValues} projectDetails={projectDetails} />
      )}
    </div>
  );
};

export default MarketingSettings;
