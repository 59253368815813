import "./DiscussionTab.css";

import {
  Button,
  Column,
  DeleteDiscussionModal,
  ProfileMenu,
  ProfileMenuItem,
  Row,
  Select,
} from "../../../../../widget";
import { DiscussionDetails } from "../discussion-details";
import { DiscussionTable } from "../discussion-table";
import { NewDiscussionModal } from "./components";
import { useDiscussionTab } from "./useDiscussionTab";

export const DiscussionTab = (): JSX.Element => {
  const {
    columns,
    showNewDiscussionModal,
    setShowNewDiscussionModal,
    internalDiscussion,
    setInternalDiscussion,
    showMessageDropdown,
    setShowMessageDropdown,
    messageDropdownOptions,
    discussionTableVisible,
    handleShowTableHideDetails,
    selectTableRowAndHideDetails,
    resolveViewOptions,
    selectedResolveViewOption,
    changeSelectedResolveViewOption,
    showDeleteDiscussionModal,
    isConfirmDeleteLoading,
    confirmDelete,
    cancelDelete,
    isExternalActivity,
    canWriteDiscussion,
    canWriteExternal,
  } = useDiscussionTab();

  const header = (): JSX.Element => (
    <Row spacingV="ml">
      <Column span={12}>
        <div className="DiscussionHeader">
          <h2>Discussions</h2>
          {canWriteDiscussion && (
            <div className="NewDiscussionButton">
              <Button
                text={canWriteExternal ? "New discussion..." : "New discussion"}
                onClick={
                  canWriteExternal
                    ? () => setShowMessageDropdown(true)
                    : () => {
                        setInternalDiscussion(true);
                        setShowNewDiscussionModal(true);
                      }
                }
              />
              <ProfileMenu
                show={showMessageDropdown}
                onClose={() => setShowMessageDropdown(false)}
                position={{ top: "2px", right: "unset" }}
              >
                {messageDropdownOptions.map((el) => (
                  <ProfileMenuItem
                    key={el.id}
                    profileMenuItem={el}
                    onClick={() => {
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      el.action!();
                    }}
                  />
                ))}
              </ProfileMenu>
            </div>
          )}
        </div>
      </Column>
    </Row>
  );

  const filters = (): JSX.Element => (
    <div className="DiscussionTabFilters">
      <div>
        <Select
          label="Show"
          data={resolveViewOptions}
          value={selectedResolveViewOption}
          onChange={changeSelectedResolveViewOption}
        />
      </div>
    </div>
  );

  return (
    <div className="ProjectTabSection ActivityDiscussionTab">
      {header()}
      {filters()}
      <DiscussionTable selectRow={selectTableRowAndHideDetails} columns={columns} visible={discussionTableVisible} />
      <DiscussionDetails
        onHideDiscussion={handleShowTableHideDetails}
        visible={!discussionTableVisible}
        canReply={canWriteDiscussion}
        isExternalActivity={isExternalActivity}
      />
      <NewDiscussionModal
        internalDiscussion={internalDiscussion}
        setInternalDiscussion={setInternalDiscussion}
        show={showNewDiscussionModal}
        closeModal={() => setShowNewDiscussionModal(false)}
      />
      {showDeleteDiscussionModal && (
        <DeleteDiscussionModal
          onConfirm={confirmDelete}
          onCancel={cancelDelete}
          isOnConfirmLoading={isConfirmDeleteLoading}
        />
      )}
    </div>
  );
};
