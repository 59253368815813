import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ProjectActivitiesConstants } from "../../../../constants";
import { SelectData } from "../../../../models";
import { transitionActivity } from "../../../../service/activity";
import { getActivityDetails } from "../../../../service/query";
import { ServiceError, Status } from "../../../../service/Shared";
import { getErrorMessageFromCode } from "../../../../service/ValidationErrorFormatter";
import { useAuth } from "../../../../useAuth";
import { useIsLoadingWrapper } from "../../../../utils";
import { getActivitiesRoute, getProjectActivitiesRoute } from "../../../../utils/routes";
import { Toast } from "../../../../widget";
import { ProjectContext } from "../../../shared/projects/project/ProjectContext";
import { isSuccessfulCompleteActivityDraft } from "../../../shared/utils/activities/completeActivity";

interface useCompleteActivityModalProps {
  activityUuid: string;
  activityProjectUuid?: string;
  onClose: () => void;
  activityHistoryUuid: string;
  activityHistoryRowVersion: number | undefined;
  isDraft: boolean;
}

interface useCompleteActivityModalReturnData {
  outcomeSelectData: SelectData;
  outcomeValue: string | null;
  isLoading: boolean;
  errors: ServiceError[];
  onCancel: () => void;
  handleConfirm: () => void;
  handleSaveWithoutCompleting: () => void;
  setOutcomeValue: (value: string) => void;
}

export const useCompleteActivityModal = ({
  activityUuid,
  activityProjectUuid,
  onClose,
  activityHistoryUuid,
  activityHistoryRowVersion,
  isDraft,
}: useCompleteActivityModalProps): useCompleteActivityModalReturnData => {
  const navigate = useNavigate();
  const { currentUserType } = useAuth();
  const { shouldRefreshActivities } = useContext(ProjectContext);

  const [errors, setErrors] = useState<ServiceError[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const outcomeSelectData = [
    { key: ProjectActivitiesConstants.STATUS_APPROVED, value: "Approve" },
    { key: ProjectActivitiesConstants.STATUS_REJECTED, value: "Reject" },
    { key: ProjectActivitiesConstants.STATUS_WITHDRAWN, value: "Withdraw" },
  ];
  const [outcomeValue, setOutcomeValue] = useState<string | null>(null);
  const redirectLink = activityProjectUuid
    ? getProjectActivitiesRoute(activityProjectUuid, currentUserType)
    : getActivitiesRoute(currentUserType);

  const onCancel = (): void => {
    setErrors([]);
    setOutcomeValue(null);
    onClose();
  };

  const potentiallyCompleteDraft = async (): Promise<boolean> => {
    if (isDraft && activityHistoryRowVersion) {
      const successfullyCompletedDraft = await isSuccessfulCompleteActivityDraft(
        activityHistoryUuid,
        activityHistoryRowVersion
      );

      if (!successfullyCompletedDraft) {
        Toast.error({ message: "Activity failed to save" });

        return false;
      }
    }
    return true;
  };

  const onCompleteClick = useIsLoadingWrapper(async (toStatus: string): Promise<void> => {
    const successfullyCompletedDraft = await potentiallyCompleteDraft();
    if (!successfullyCompletedDraft) return;

    // `completeDraft` endpoint also updates the `activity` table, this call is needed to be get latest activity rowVersion
    const activityDetailsRes = await getActivityDetails({ activityUuid });

    if (activityDetailsRes.status === Status.Success && activityDetailsRes.data) {
      const res = await transitionActivity({
        activity: {
          uuid: activityUuid,
          rowVersion: activityDetailsRes.data.rowVersion,
          toStatus,
          fromStatus: ProjectActivitiesConstants.STATUS_IN_PROGRESS,
          publishExternalMessages: false,
          publishInternalMessages: false,
        },
        activityReviews: [],
      });

      if (res.status === Status.Success) {
        if (shouldRefreshActivities) {
          shouldRefreshActivities();
        }

        switch (toStatus) {
          case ProjectActivitiesConstants.STATUS_APPROVED:
            Toast.success({ message: "Activity completed successfully" });
            break;
          case ProjectActivitiesConstants.STATUS_REJECTED:
            Toast.success({ message: "Activity rejected successfully" });
            break;
          case ProjectActivitiesConstants.STATUS_WITHDRAWN:
            Toast.success({ message: "Activity withdrawn successfully" });
            break;
          default:
            break;
        }

        onCancel();

        navigate(redirectLink);
      } else if (res.errors && res.errors.length > 0) {
        Toast.error({ message: getErrorMessageFromCode(res.errors[0].message) });
      }
    }
  }, setIsLoading);

  const handleConfirm = async (): Promise<void> => {
    if (outcomeValue) {
      onCompleteClick(outcomeValue);
    } else {
      setErrors([
        {
          code: "NO_OUTCOME_VALUE",
          message: "An outcome must be selected",
        },
      ]);
    }
  };

  const handleSaveWithoutCompleting = useIsLoadingWrapper(async (): Promise<void> => {
    const successfullyCompletedDraft = await potentiallyCompleteDraft();
    if (successfullyCompletedDraft) {
      Toast.success({ message: "Activity saved successfully" });
      onCancel();
      navigate(redirectLink);
    }
  }, setIsLoading);

  return {
    outcomeSelectData,
    outcomeValue,
    isLoading,
    errors,
    onCancel,
    handleConfirm,
    handleSaveWithoutCompleting,
    setOutcomeValue,
  };
};
