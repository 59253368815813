import { Dispatch, FormEvent, SetStateAction, useCallback, useEffect, useState } from "react";

import {
  ActivityReviewTypeConstants,
  OrganisationRoleConstants,
  ProjectActivitiesConstants,
  ProjectActivityReviewsConstants,
} from "../../../../constants";
import { SelectData } from "../../../../models";
import { transitionActivity, updateActivityReview } from "../../../../service/activity";
import { getActivityReviewDetails, searchOrganisationUsers } from "../../../../service/query";
import { ServiceError, Status } from "../../../../service/Shared";
import { getErrorMessageFromCode } from "../../../../service/ValidationErrorFormatter";
import { useAuth } from "../../../../useAuth";
import { useIsLoadingWrapper } from "../../../../utils";
import { dataGridMapFilterCriteria, Toast } from "../../../../widget";

interface UseAssignActivityModalProps {
  closeModal: () => void;
  refreshActivities?: boolean;
  setRefreshActivities: Dispatch<SetStateAction<boolean>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  activityReview: any;
}

interface ActivityReviewInfo {
  activityUuid: string;
  activityFromStatus: string;
  activityRowVersion: number;
  activityReviewUuid: string;
  activityReviewFromStatus: string;
  activityReviewRowVersion: number;
  activityReviewType: string;
}

export interface SelectedAssigneeItem {
  index: number;
  userUuid?: string;
  userName?: string;
}

interface UseAssignActivityModalReturnData {
  relatedUsers?: SelectData;
  activityReviewType: string;
  showSecondAssigneeDropdown: boolean;
  deadline?: Date;
  setDeadline: Dispatch<SetStateAction<Date | undefined>>;
  setSelectedAssignees: Dispatch<SetStateAction<SelectedAssigneeItem[]>>;
  setShowSecondAssigneeDropdown: Dispatch<SetStateAction<boolean>>;
  handleCancel: () => void;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
  isHandleSubmitLoading: boolean;
  errors: ServiceError[];
  selectedAssignees: SelectedAssigneeItem[];
}

export const useAssignActivityModal = ({
  closeModal,
  refreshActivities,
  setRefreshActivities,
  activityReview,
}: UseAssignActivityModalProps): UseAssignActivityModalReturnData => {
  const { currentOrganisationUuid } = useAuth();

  const [errors, setErrors] = useState<ServiceError[]>([]);
  const [isHandleSubmitLoading, setIsHandleSubmitLoading] = useState(false);

  const [activityReviewType, setActivityReviewType] = useState<string>("Review");
  const [showSecondAssigneeDropdown, setShowSecondAssigneeDropdown] = useState(false);
  const [relatedUsers, setRelatedUsers] = useState<SelectData>();

  const [selectedAssignees, setSelectedAssignees] = useState<SelectedAssigneeItem[]>([]);

  const [deadline, setDeadline] = useState<Date>();

  const [activityReviewInfo, setActivityReviewInfo] = useState<ActivityReviewInfo>();

  const clearStateData = (): void => {
    setErrors([]);
    setShowSecondAssigneeDropdown(false);
    setRelatedUsers(undefined);
    setDeadline(undefined);
    setActivityReviewInfo(undefined);
    setSelectedAssignees([]);
  };

  const handleCancel = (): void => {
    clearStateData();
    closeModal();
  };

  const getAssignActivityDetails = useCallback(async () => {
    clearStateData();
    setActivityReviewType(activityReview?.type);
    if (activityReview?.activityReviewUuid) {
      const filterCriteria = dataGridMapFilterCriteria([]);

      filterCriteria.results = {
        role: {
          operator: "eq",
          value: OrganisationRoleConstants.VVB_AUDITOR,
        },
      };

      await searchOrganisationUsers({
        organisationUuid: currentOrganisationUuid || "",
        paging: {
          afterCursor: null,
          beforeCursor: null,
          limit: 50,
        },
        filter: filterCriteria,
      }).then((res) => {
        setRelatedUsers(res.data?.results.map((r) => ({ key: r.user.uuid, value: r.user.fullName })));
      });
      const resSelectedReview = await getActivityReviewDetails({
        activityReviewUuid: activityReview.activityReviewUuid,
      });
      if (resSelectedReview.status === Status.Success && resSelectedReview.data) {
        setActivityReviewInfo({
          activityUuid: resSelectedReview.data.activityHistoryDetailsResponse.activity.uuid,
          activityFromStatus: resSelectedReview.data.activityHistoryDetailsResponse.activity.status,
          activityRowVersion: resSelectedReview.data.activityHistoryDetailsResponse.activity.rowVersion,
          activityReviewFromStatus: resSelectedReview.data.activityReviewStatus,
          activityReviewRowVersion: resSelectedReview.data.rowVersion,
          activityReviewUuid: resSelectedReview.data.activityReviewUuid,
          activityReviewType: resSelectedReview.data.activityReviewType,
        });
        setDeadline(resSelectedReview.data.deadline || undefined);

        if (resSelectedReview.data.assignedToUserUuids.length > 0) {
          const newSelectedAssignees = resSelectedReview.data.assignedToUserUuids.map((x, i) => {
            return { index: i, userUuid: x, userName: "" };
          });
          setSelectedAssignees(newSelectedAssignees);
        } else {
          setSelectedAssignees([{ index: 0, userUuid: undefined, userName: undefined }]);
        }
      }
    }
  }, [activityReview]);

  const handleSubmit = useIsLoadingWrapper(async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setErrors([]);
    let newErrors: ServiceError[] = [];
    // eslint-disable-next-line no-console
    await updateActivityReview({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      activityReviewUuid: activityReview.activityReviewUuid,
      assignedToUserUuids: selectedAssignees
        .filter((x) => x.userUuid !== undefined)
        .sort((x) => x.index)
        .map((a) => a.userUuid) as Array<string>,
      deadlineDate: deadline || null,
      rowVersion: activityReview.rowVersion,
    }).then(async (response) => {
      if (
        response.status === Status.Success &&
        response.data &&
        activityReviewInfo?.activityReviewFromStatus === ProjectActivityReviewsConstants.STATUS_UNASSIGNED
      ) {
        await transitionActivity({
          activity: {
            uuid: activityReviewInfo?.activityUuid || "",
            rowVersion: activityReviewInfo?.activityRowVersion || 0,
            toStatus:
              activityReviewInfo?.activityReviewType === ActivityReviewTypeConstants.REVIEW
                ? ProjectActivitiesConstants.STATUS_REVIEW_ASSIGNED
                : ProjectActivitiesConstants.STATUS_ASSESSMENT_ASSIGNED,
            fromStatus: activityReviewInfo?.activityFromStatus || "",
            publishExternalMessages: false,
            publishInternalMessages: false,
          },
          activityReviews: [
            {
              activityReviewUuid: activityReviewInfo?.activityReviewUuid || "",
              rowVersion: response.data?.rowVersion || 0,
              fromStatus: activityReviewInfo?.activityReviewFromStatus || "",
              toStatus: ProjectActivityReviewsConstants.STATUS_TO_DO,
            },
          ],
        }).then((response2) => {
          if (response2.status === Status.Error && response2.errors && response2.errors.length) {
            newErrors = response2.errors;
          }
        });
      } else if (response.status === Status.Error && response.errors && response.errors.length) {
        newErrors = response.errors;
      }

      if (newErrors && newErrors.length) {
        setErrors(newErrors);
        Toast.error({ message: getErrorMessageFromCode(newErrors[0].code) });
      } else {
        handleCancel();
        Toast.success({ message: "You have successfully updated the activity review" });
        setRefreshActivities(!refreshActivities);
      }
    });
  }, setIsHandleSubmitLoading);

  useEffect(() => {
    getAssignActivityDetails();
  }, [activityReview, closeModal]);

  return {
    relatedUsers,
    activityReviewType,
    showSecondAssigneeDropdown,
    deadline,
    setDeadline,
    setSelectedAssignees,
    setShowSecondAssigneeDropdown,
    handleCancel,
    handleSubmit,
    isHandleSubmitLoading,
    errors,
    selectedAssignees,
  };
};
