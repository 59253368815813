import { useCallback, useEffect, useState } from "react";

import { FileType } from "../../../../../../models";
import {
  searchActivityDefinitionDocumentTypes,
  searchVerifiers,
} from "../../../../../../service/query/QueryService.full";
import { Status } from "../../../../../../service/Shared";
import { dataGridMapFilterCriteria } from "../../../../../../widget";

interface VerifierDetails {
  displayName: string;
  logoUrl: string;
}

interface UseIntroductionContextProps {
  activityDefinitionUuid?: string;
}

interface UseIntroductionContextReturnData {
  keyDocuments: string[];
  verifierDetails: VerifierDetails[];
}
export const useIntroductionContent = ({
  activityDefinitionUuid,
}: UseIntroductionContextProps): UseIntroductionContextReturnData => {
  const [keyDocuments, setKeyDocuments] = useState<string[]>([]);
  const [verifierDetails, setVerifierDetails] = useState<VerifierDetails[]>([]);

  const getAndSetKeyDocuments = useCallback(
    async (uuid: string) => {
      const filterCriteria = dataGridMapFilterCriteria([]);

      filterCriteria.activityDefinitionUuid = {
        operator: "eq",
        value: uuid,
      };

      filterCriteria.isGenerated = {
        operator: "eq",
        value: false,
      };

      filterCriteria.activityDefinitionVersionDocumentTypes = {
        some: {
          isKey: {
            operator: "eq",
            value: true,
          },
        },
      };

      const searchActivityDefinitionDocumentTypesResponse = await searchActivityDefinitionDocumentTypes({
        paging: {
          limit: 50,
          beforeCursor: null,
          afterCursor: null,
        },
        sort: [
          {
            key: "displayName" as "results.displayName",
            direction: "asc",
          },
        ],
        filter: { results: filterCriteria },
      });

      if (
        searchActivityDefinitionDocumentTypesResponse.status === Status.Success &&
        searchActivityDefinitionDocumentTypesResponse.data
      ) {
        setKeyDocuments(searchActivityDefinitionDocumentTypesResponse.data.results.map((x) => x.displayName));
      }
    },
    [activityDefinitionUuid]
  );

  const getAndSetVerifiers = useCallback(async () => {
    const filterCriteria = dataGridMapFilterCriteria([]);
    filterCriteria.supportsDigitalVerification = {
      operator: "eq",
      value: true,
    };

    const searchVerifiersResponse = await searchVerifiers({
      filter: {
        results: filterCriteria,
      },
      paging: {
        limit: 10,
        beforeCursor: null,
        afterCursor: null,
      },
      sort: [
        {
          key: "displayName" as "results.displayName",
          direction: "asc",
        },
      ],
    });

    if (searchVerifiersResponse.status === Status.Success && searchVerifiersResponse.data?.results) {
      setVerifierDetails(
        searchVerifiersResponse.data.results.map(
          (x) =>
            ({
              displayName: x.displayName,
              logoUrl: x.files.find((f) => f.type === FileType.SmallLogo)?.file.url,
            }) as VerifierDetails
        )
      );
    }
  }, []);

  useEffect(() => {
    if (activityDefinitionUuid) getAndSetKeyDocuments(activityDefinitionUuid);
  }, [activityDefinitionUuid]);

  useEffect(() => {
    getAndSetVerifiers();
  }, []);

  return { keyDocuments, verifierDetails };
};
