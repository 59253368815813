import "./Dashboard.css";

import { OrganisationRoleConstants, OrganisationTypeConstants } from "../../../constants";
import { DeveloperTemplate } from "../../../templates";
import {
  getVerifierQueueManagementAssessmentsRoute,
  getVerifierQueueManagementReviewsRoute,
  getVerifierQueueManagementUnassignedActivitiesRoute,
} from "../../../utils/routes";
import { Column, Row, UserHeader } from "../../../widget";
import { DashboardPieCard } from "../../shared/components";
import { AssignActivityModal, DashboardTableCard } from "./components";
import { useDashboard } from "./useDashboard";

const Dashboard = (): JSX.Element => {
  const {
    activityReviewAggregate,
    tableColumnsQueue,
    tableColumnsUnassignedActivities,
    isLoading,
    selectedActivityReview,
    showAssignActivityModal,
    refreshDashboardTables,
    setRefreshDashboardTables,
    setShowAssignActivityModal,
    onAssessmentQueueDataLoad,
    onReviewQueueDataLoad,
    onUnassignedActivitiesDataLoad,
    isLoadingAssessmentQueue,
    isLoadingReviewQueue,
    isLoadingUnassignedActivities,
    currentUserRole,
    defaultSortingCriteria,
  } = useDashboard();

  return (
    <DeveloperTemplate activeElement="Dashboard">
      <div className="Dashboard">
        <Row spacingV="ml">
          <Column span={12}>
            <UserHeader />
          </Column>
        </Row>
        <Row spacingV="ml">
          <Column span={12}>
            <div className="HeaderContainer">
              <h1>Dashboard</h1>
            </div>
          </Column>
        </Row>
        {!isLoading && (
          <Row spacingV="ml">
            <Column span={6}>
              <DashboardPieCard
                title="Assessment queue"
                data={{
                  "Past deadline": activityReviewAggregate?.assessment?.pastDeadLines ?? 0,
                  "Near deadline": activityReviewAggregate?.assessment?.nearDeadLines ?? 0,
                  Queued: activityReviewAggregate?.assessment?.queued ?? 0,
                }}
                colours="verifierDashboard"
              />
            </Column>
            <Column span={6}>
              <DashboardPieCard
                title="Review queue"
                data={{
                  "Past deadline": activityReviewAggregate?.review?.pastDeadLines ?? 0,
                  "Near deadline": activityReviewAggregate?.review?.nearDeadLines ?? 0,
                  Queued: activityReviewAggregate?.review?.queued ?? 0,
                }}
                colours="verifierDashboard"
              />
            </Column>
          </Row>
        )}
        <Row spacingV="ml">
          <Column span={12}>
            <DashboardTableCard
              title="Assessment activities"
              expandRoute={getVerifierQueueManagementAssessmentsRoute(OrganisationTypeConstants.VERIFIER)}
              onChange={onAssessmentQueueDataLoad}
              columns={tableColumnsQueue}
              sortable
              isLoading={isLoadingAssessmentQueue}
              refresh={refreshDashboardTables}
              defaultSortingCriteria={defaultSortingCriteria}
            />
          </Column>
        </Row>
        <Row spacingV="ml">
          <Column span={12}>
            <DashboardTableCard
              title="Review queue"
              expandRoute={getVerifierQueueManagementReviewsRoute(OrganisationTypeConstants.VERIFIER)}
              onChange={onReviewQueueDataLoad}
              columns={tableColumnsQueue}
              sortable
              isLoading={isLoadingReviewQueue}
              refresh={refreshDashboardTables}
              defaultSortingCriteria={defaultSortingCriteria}
            />
          </Column>
        </Row>
        {currentUserRole === OrganisationRoleConstants.VVB_MANAGER && (
          <Row spacingV="ml">
            <Column span={12}>
              <DashboardTableCard
                title="Unassigned activities"
                expandRoute={getVerifierQueueManagementUnassignedActivitiesRoute(OrganisationTypeConstants.VERIFIER)}
                onChange={onUnassignedActivitiesDataLoad}
                columns={tableColumnsUnassignedActivities}
                sortable
                isLoading={isLoadingUnassignedActivities}
                refresh={refreshDashboardTables}
                defaultSortingCriteria={defaultSortingCriteria}
              />
            </Column>
          </Row>
        )}
      </div>
      {currentUserRole === OrganisationRoleConstants.VVB_MANAGER && (
        <AssignActivityModal
          show={showAssignActivityModal}
          closeModal={() => setShowAssignActivityModal(false)}
          activityReview={selectedActivityReview}
          refreshActivities={refreshDashboardTables}
          setRefreshActivities={setRefreshDashboardTables}
          modalTitle="Assign Activity"
        />
      )}
    </DeveloperTemplate>
  );
};

export default Dashboard;
