/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useState } from "react";
import { SortColumn } from "react-data-grid";

import {
  ActivityReviewTypeConstants,
  OrganisationRoleConstants,
  ProjectActivityReviewsConstants,
} from "../../../../../constants";
import { CursorChangeProps, ResultType } from "../../../../../models";
import { searchActivityReviews } from "../../../../../service/query/QueryService.full";
import { ResultData } from "../../../../../service/Shared";
import { useAuth } from "../../../../../useAuth";
import { flattenObject } from "../../../../../utils";
import { getActivityReviewDashboardTabRoute } from "../../../../../utils/routes";
import {
  DataGridColumnDefinition,
  DataGridColumnDefinitionWithCustomCellFormatter,
  DataGridCursorDataLoadEventHandler,
  DataGridLinkCellFormatterData,
  dataGridMapFilterCriteria,
} from "../../../../../widget";
import {
  AvatarCellFormatter,
  RemainingTimeCellFormatter,
} from "../../../../../widget/data/DataGrid/components/CellFormatters";

interface UseAssessmentsReturnData {
  tableColumnsQueue: DataGridColumnDefinition[];
  isLoading: boolean;
  defaultSortingCriteria: SortColumn[];
  onAssessmentQueueDataLoad: DataGridCursorDataLoadEventHandler;
}

export const useAssessments = (): UseAssessmentsReturnData => {
  const { currentUserRole, user } = useAuth();

  const [isLoading, setIsLoading] = useState(true);

  const defaultSortingCriteria: SortColumn[] = [{ columnKey: "calculatedDaysInQueue", direction: "DESC" }];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tableColumnsQueue: (DataGridColumnDefinition | DataGridColumnDefinitionWithCustomCellFormatter<any>)[] = [
    {
      key: "projectLink",
      name: "Project / Group",
      dataType: "string",
      formatter: "link",
      filterable: false,
      sortable: false,
      minWidth: 120,
    },
    {
      key: "developer.displayName",
      name: "Developer",
      dataType: "string",
      formatter: "stringWithEllipsis",
      minWidth: 90,
    },
    { key: "standard.displayName", name: "Code", dataType: "string", formatter: "stringWithEllipsis" },
    {
      key: "activity.activityDefinition.displayName",
      name: "Activity",
      dataType: "string",
      formatter: "stringWithEllipsis",
    },
    {
      name: "Status",
      key: "status",
      dataType: "string",
      formatter: "projectActivityReviewsStatusPill",
      minWidth: 180,
    },
    {
      key: "avatar",
      name: "Assigned to",
      dataType: "string",
      formatter: "custom",
      alignment: "center",
      customCellFormatter: AvatarCellFormatter,
      filterable: false,
      sortable: true,
    },
    {
      name: "Progress",
      key: "completionPercentage",
      dataType: "number",
      formatter: "progress",
      minWidth: 140,
      filterable: false,
      sortable: false,
    },
    {
      name: "Days in queue",
      key: "calculatedDaysInQueue",
      dataType: "number",
      filterable: false,
      sortable: true,
      minWidth: 140,
    },
    {
      name: "Deadline",
      key: "deadline",
      dataType: "number",
      formatter: "custom",
      customCellFormatter: RemainingTimeCellFormatter,
      minWidth: 160,
      filterable: false,
      sortable: false,
    },
  ];

  // We need to use any here as we extend response to include data for custom table components
  const formatQueueData = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (responseData: any | undefined): ResultData[] =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      responseData?.results?.map((el: any) => {
        const result = flattenObject(el);
        const fullName: string = el.primaryAssignedToUser?.fullName ?? undefined;

        result.projectLink = {
          text: el.group?.displayName ?? el.project?.displayName,
          to: getActivityReviewDashboardTabRoute(
            el.activity.uuid,
            "documents",
            currentUserRole === OrganisationRoleConstants.VVB_AUDITOR ? "type=Assessment" : undefined
          ),
        } as DataGridLinkCellFormatterData;

        return {
          ...result,
          avatar: { fullName },
          deadline: {
            days: el.calculatedDaysToDeadline,
            isComplete: el.status === ProjectActivityReviewsConstants.STATUS_COMPLETE,
          },
        };
      }) || [],
    []
  );

  const onAssessmentQueueDataLoad: DataGridCursorDataLoadEventHandler = async ({
    paging,
    filtering,
    sorting,
  }: CursorChangeProps) => {
    setIsLoading(true);
    let data: ResultType = {
      resultData: [],
      paging: {
        pageSize: 5,
        totalCount: 0,
        startCursor: "",
        endCursor: "",
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };

    const filterCriteria = dataGridMapFilterCriteria(filtering);

    filterCriteria.type = {
      operator: "eq",
      value: ActivityReviewTypeConstants.ASSESSMENT,
    };

    filterCriteria.status = {
      operator: "neq",
      value: ProjectActivityReviewsConstants.STATUS_CANCELLED,
    };

    filterCriteria.assignedToUser = {
      uuid: {
        operator: "neq",
        value: null,
      },
    };

    if (currentUserRole === OrganisationRoleConstants.VVB_AUDITOR) {
      filterCriteria.assignedToUser = {
        uuid: {
          operator: "eq",
          value: user?.userUuid,
        },
      };
    }

    sorting.forEach((s) => {
      switch (s.key) {
        case "avatar":
          // eslint-disable-next-line no-param-reassign
          s.key = "assignedToUser.fullName";
          break;
        default:
          break;
      }
    });

    await searchActivityReviews({
      paging: {
        afterCursor: null,
        beforeCursor: null,
        limit: 10,
      },
      sort: sorting.map((s: { key: any; direction: any }) => ({
        key: s.key as any,
        direction: s.direction,
      })),
      filter: { results: filterCriteria },
    })
      .then((response) => {
        data = {
          resultData: formatQueueData(response.data),
          paging: {
            startCursor: response.data?.paging?.startCursor || "",
            endCursor: response.data?.paging?.endCursor || "",
            hasNextPage: false,
            hasPreviousPage: false,
            pageSize: paging.pageSize || 10,
            totalCount: response.data?.paging?.total || response.data?.results?.length || 0,
          },
        };
      })
      .finally(() => {
        setIsLoading(false);
      });
    return data;
  };

  return {
    tableColumnsQueue,
    defaultSortingCriteria,
    isLoading,
    onAssessmentQueueDataLoad,
  };
};
