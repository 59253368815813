import "./ReturnToDeveloperModal.css";

import { Dispatch, SetStateAction } from "react";

import { Button, Column, Modal, Row } from "../../../../widget";
import { useReturnToDeveloperModal } from "./useReturnToDeveloperModal";

interface ReturnToDeveloperModalProps {
  show: boolean;
  onClose: () => void;
  activityUuid: string;
  activityStatus: string;
  activityRowVersion: number;
  activityAssessmentUuid: string;
  activityAssessmentStatus: string;
  activityAssessmentRowVersion: number;
  developerDisplayName?: string;
  refreshActivityReviews?: boolean;
  setRefreshActivityReviews?: Dispatch<SetStateAction<boolean>>;
  exitActivityReviewWizard?: () => void;
}

export const ReturnToDeveloperModal = ({
  show,
  onClose,
  activityUuid,
  activityStatus,
  activityRowVersion,
  activityAssessmentUuid,
  activityAssessmentStatus,
  activityAssessmentRowVersion,
  refreshActivityReviews,
  setRefreshActivityReviews,
  exitActivityReviewWizard,
  developerDisplayName = "the developer",
}: ReturnToDeveloperModalProps): JSX.Element => {
  const { isLoading, onConfirmClick } = useReturnToDeveloperModal({
    activityUuid,
    activityStatus,
    activityRowVersion,
    onClose,
    activityAssessmentUuid,
    activityAssessmentStatus,
    activityAssessmentRowVersion,
    refreshActivityReviews,
    setRefreshActivityReviews,
    exitActivityReviewWizard,
  });

  return (
    <Modal show={show} title="Return to developer" onClose={onClose}>
      <Row spacingV="ll" justify="center">
        <Column span={10}>
          <span className="body1">
            Your organisation’s comments will be sent back to {developerDisplayName} for their action. Once sent, you
            will no longer be able to edit your review responses until the document is returned.
          </span>
        </Column>
      </Row>
      <Row justify="center">
        <Column span={6}>
          <div className="ReturnToDeveloperModalButtons">
            <Button text="Send" onClick={onConfirmClick} isLoading={isLoading} />
            <Button variant="secondary" text="Cancel" onClick={onClose} />
          </div>
        </Column>
      </Row>
    </Modal>
  );
};
