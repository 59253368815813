import "./ReturnToAssessorModal.css";

import { Dispatch, SetStateAction } from "react";

import { Button, Column, Modal, Row } from "../../../../widget";
import { useReturnToAssessorModal } from "./useReturnToAssessorModal";

interface ReturnToAssessorModalProps {
  show: boolean;
  onClose: () => void;
  activityUuid: string;
  activityStatus: string;
  activityRowVersion: number;
  activityReviewUuid: string;
  activityReviewStatus: string;
  activityReviewRowVersion: number;
  activityAssessmentUuid: string;
  activityAssessmentStatus: string;
  activityAssessmentRowVersion: number;
  refreshActivityReviews?: boolean;
  setRefreshActivityReviews?: Dispatch<SetStateAction<boolean>>;
  exitActivityReviewWizard?: () => void;
}

export const ReturnToAssessorModal = ({
  show,
  onClose,
  activityUuid,
  activityStatus,
  activityRowVersion,
  activityReviewUuid,
  activityReviewStatus,
  activityReviewRowVersion,
  activityAssessmentUuid,
  activityAssessmentStatus,
  activityAssessmentRowVersion,
  refreshActivityReviews,
  setRefreshActivityReviews,
  exitActivityReviewWizard,
}: ReturnToAssessorModalProps): JSX.Element => {
  const { isManager, isLoading, onConfirmClick } = useReturnToAssessorModal({
    activityUuid,
    activityStatus,
    activityRowVersion,
    onClose,
    activityReviewUuid,
    activityReviewStatus,
    activityReviewRowVersion,
    activityAssessmentUuid,
    activityAssessmentStatus,
    activityAssessmentRowVersion,
    refreshActivityReviews,
    setRefreshActivityReviews,
    exitActivityReviewWizard,
  });

  return (
    <Modal show={show} title="Return to assessor" onClose={onClose}>
      <Row spacingV="ll" justify="center">
        <Column span={10}>
          <span className="body1">
            {isManager
              ? "Returning the activity to the assessor will reopen the assessment and review process. Are you sure you wish to proceed?"
              : "Submitting this review will notify the assessor. Once sent, you will no longer be able to edit your review responses until the document is returned."}
          </span>
        </Column>
      </Row>
      <Row justify="center">
        <Column span={6}>
          <div className="ReturnToAssessorModalButtons">
            <Button text="Send" onClick={onConfirmClick} isLoading={isLoading} />
            <Button variant="secondary" text="Cancel" onClick={onClose} />
          </div>
        </Column>
      </Row>
    </Modal>
  );
};
