import { useCallback, useContext, useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { ActivityReviewTypeConstants, OrganisationTypeConstants } from "../../../../../../constants";
import { StepItem } from "../../../../../../models";
import { WriteActivityReviewResponse } from "../../../../../../service/activity";
import { writeActivityReview } from "../../../../../../service/activity/ActivityService";
import { logError } from "../../../../../../service/error";
import {
  GetActivityHistoryDetailsResponse,
  getActivityReviewDetails,
  getDiscussionThreads,
  GetDiscussionThreadsResponse,
  GetGroupDetailsResponse,
  GetProjectDetailsResponse,
} from "../../../../../../service/query";
import { ServiceError, Status } from "../../../../../../service/Shared";
import { useAuth } from "../../../../../../useAuth";
import { deepObjectSearch, useIsLoadingWrapper } from "../../../../../../utils";
import { getActivityReviewDashboardTabRoute, getActivityReviewViewRoute } from "../../../../../../utils/routes";
import { StepProps } from "../../../../../../widget";
import {
  ActivityData,
  ActivityDefinition,
  ActivityDefinitionInfo,
  HasComponents,
  HasData,
  HasDataSteps,
  HasKey,
  HasReviews,
  HasSteps,
  UploadedDocumentEnriched,
} from "../../../../../developer/activities/activity/types";
import {
  checkTargetStepkeysAppended,
  createExpressionContext,
  createReviewComponent,
  enrichUploadedDocuments,
  filterConditionalSteps,
  findCurrentStepName,
  getActivityDefinitionFields,
  getDataPath,
  getFirstStepKeys,
  getLastStepKeys,
  getNextStepKeys,
  getPreviousStepKeys,
  getStep,
  hasNextStep,
  hasPreviousStep,
} from "../../../../../developer/activities/activity/utils";
import { getDiscussionsForComponent } from "../../../../../developer/activities/activity/utils/DiscussionUtils";
import { useFetchActivityReviewHistory } from "../../hooks";
import { createStepReview, getNextUnreviewedStepKeys, mapToReviewStepItem, mapToReviewStepProps } from "../../utils";
import { ActivityReviewWizardContext } from "../context";

interface UseActivityReturnData {
  steps: StepProps[];
  l3Steps: StepItem[] | undefined;
  stepFields: JSX.Element[];
  stepReviews?: (JSX.Element | undefined)[];
  currentStepName: string | null;
  currentStepKeys: string[];
  hasNext: boolean;
  hasPrevious: boolean;
  activityData: GetActivityHistoryDetailsResponse | undefined;
  isLoading: boolean;
  isReviewPage: boolean;
  showReviewPage: boolean;
  activityDefinition: ActivityDefinition;
  activityDefinitionInfo: ActivityDefinitionInfo | undefined;
  activityReviewType: string;
  activityReviewStatus: string;
  activityReviewCompletionPercentage: number;
  isAssignedAuditor: boolean;
  currentUserType: OrganisationTypeConstants;
  errors: ServiceError[] | undefined;

  isMoveNextLoading: boolean;
  isMovePreviousLoading: boolean;
  isMoveToLastStepLoading: boolean;
  isMoveToReviewLoading: boolean;
  isSaveAndCloseLoading: boolean;
  // handlers
  movePrevious: () => void;
  moveNext: () => void;
  moveTo: (targetStepKeys: string[]) => void;
  moveToLastStep: () => void;
  moveToReview: () => void;
  onSaveAndClose: () => void;
}

export const useReview = (): UseActivityReturnData => {
  const { currentUserType } = useAuth();

  const { activityReviewUuid, activityReviewRowVersion, setActivityReviewRowVersion } =
    useContext(ActivityReviewWizardContext);

  const [searchParams] = useSearchParams();

  const [activityReviewCompletionPercentage, setActivityReviewCompletionPercentage] = useState(0);
  const [activityReviewType, setActivityReviewType] = useState("");
  const [activityReviewStatus, setActivityReviewStatus] = useState("");
  const [activityHistoryUuid, setActivityHistoryUuid] = useState<string>();
  const [hasFetchedData, setHasFetchedData] = useState(false);
  const [activityUuid, setActivityUuid] = useState<string>();
  const [currentStepKeys, setCurrentStepKeys] = useState<string[]>([]);
  const [currentStepName, setCurrentStepName] = useState<string | null>(null);
  const [activityData, setActivityData] = useState<GetActivityHistoryDetailsResponse | undefined>(undefined);
  const [activityDocuments, setActivityDocuments] = useState<UploadedDocumentEnriched[]>([]);
  const [activityDefinitionInfo, setActivityDefinitionInfo] = useState<ActivityDefinitionInfo | undefined>(undefined);
  const [steps, setSteps] = useState<StepProps[]>([]);
  const [l3Steps, setL3Steps] = useState<StepItem[] | undefined>([]);
  const [stepFields, setStepFields] = useState<JSX.Element[]>([]);

  const [isValidAssessment, setIsValidAssessment] = useState<boolean>();
  const [isValidReview, setIsValidReview] = useState<boolean>();

  const [stepReviews, setStepReviews] = useState<(JSX.Element | undefined)[]>([]);

  const [hasNext, setHasNext] = useState(true);
  const [hasPrevious, setHasPrevious] = useState(false);
  const [activityDefinition, setActivityDefinition] = useState<ActivityDefinition>({
    components: undefined,
    parent: undefined,
    steps: [],
  });
  const [originalActivityDefinition, setOriginalActivityDefinition] = useState<ActivityDefinition>({
    components: undefined,
    parent: undefined,
    steps: [],
  });
  const [projectDetails, setProjectDetails] = useState<GetProjectDetailsResponse | undefined>(undefined);
  const [groupDetails, setGroupDetails] = useState<GetGroupDetailsResponse | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState<ServiceError[] | undefined>();

  const [showReviewPage, setShowReviewPage] = useState(false);

  // TODO_VVB: To set when we navigate to the latest unreviewed step
  // eslint-disable-next-line unused-imports/no-unused-vars
  const [scrollToElement, setScrollToElement] = useState<string | undefined>(undefined);

  const [isReviewPage, setIsReviewPage] = useState(false);
  const [isAssignedAuditor, setIsAssignedAuditor] = useState(false);

  const [isMoveNextLoading, setIsMoveNextLoading] = useState(false);
  const [isMovePreviousLoading, setIsMovePreviousLoading] = useState(false);
  const [isMoveToLastStepLoading, setIsMoveToLastStepLoading] = useState(false);
  const [isMoveToReviewLoading, setIsMoveToReviewLoading] = useState(false);
  const [isSaveAndCloseLoading, setIsSaveAndCloseLoading] = useState(false);

  const navigate = useNavigate();

  const fetchData = useFetchActivityReviewHistory(
    setActivityReviewCompletionPercentage,
    setActivityReviewType,
    setActivityReviewStatus,
    setIsAssignedAuditor,
    setHasFetchedData,
    setActivityData,
    setOriginalActivityDefinition,
    setActivityDefinitionInfo,
    setProjectDetails,
    setGroupDetails,
    setActivityUuid,
    setActivityHistoryUuid,
    activityReviewUuid
  );

  const filterAndUpdateActivityDefinition = (
    activityData2: ActivityData,
    originalActivityDefinition2?: ActivityDefinition,
    projectDetails2?: GetProjectDetailsResponse
  ): ActivityDefinition => {
    // eslint-disable-next-line no-param-reassign
    originalActivityDefinition2 = originalActivityDefinition2 || originalActivityDefinition;
    const expressionContext = createExpressionContext(activityData2, projectDetails2 || projectDetails, groupDetails);
    const filteredActivityDefinition = filterConditionalSteps(originalActivityDefinition2, expressionContext);
    setActivityDefinition(filteredActivityDefinition);
    return filteredActivityDefinition;
  };

  const updateSteps: (
    discussions: GetDiscussionThreadsResponse[],
    stepKeys: string[],
    activityUuid2: string,
    activityDefinition2: ActivityDefinition,
    activityData2: ActivityData,
    activityDocuments2: UploadedDocumentEnriched[],
    projectDetails2?: GetProjectDetailsResponse,
    groupDetails2?: GetGroupDetailsResponse
  ) => void = async (
    discussions,
    stepKeys,
    activityUuid2,
    activityDefinition2,
    activityData2,
    activityDocuments2,
    projectDetails2 = projectDetails,
    groupDetails2 = groupDetails
  ) => {
    const stepData = getStep(stepKeys, activityData2);
    const stepComponents = getActivityDefinitionFields(stepKeys, activityDefinition2);
    const expressionContext = createExpressionContext(activityData2, projectDetails2, groupDetails2);
    const dataPath = getDataPath(stepKeys);

    setStepFields(
      stepComponents
        .map((c) =>
          createReviewComponent(
            c,
            getDiscussionsForComponent(c.key, c.component, c.children, discussions, stepKeys),
            stepData?.data || {},
            expressionContext,
            activityDocuments2,
            dataPath,
            false
          )
        )
        .filter((c) => c !== null) as JSX.Element[]
    );

    setStepReviews(
      createStepReview(
        isAssignedAuditor,
        { activityReviews: stepData?.activityReviews } as HasReviews,
        dataPath,
        setIsValidAssessment,
        setIsValidReview,
        activityReviewType
      )
    );

    setSteps(mapToReviewStepProps(stepKeys, activityData2, activityReviewType, activityDefinition2.steps));
    setL3Steps(mapToReviewStepItem(stepKeys, activityData2, activityReviewType, activityDefinition2.steps));
    setHasNext(hasNextStep(stepKeys, activityDefinition2));
    setHasPrevious(hasPreviousStep(stepKeys, activityDefinition2));
  };

  useEffect(() => {
    fetchData().then(async (data) => {
      setActivityReviewRowVersion(data.rowVersion); // Set activityReviewRowVersion on init load

      const filteredActivityDefinition = filterAndUpdateActivityDefinition(
        data.activityData,
        data.activityDefinition,
        data.projectDetails
      );
      setIsLoading(false);
      const enrichedActivityDocuments = enrichUploadedDocuments(data.activityData, data.activityDocuments);
      setActivityDocuments(enrichedActivityDocuments);

      // Get furthest completed step
      let stepKeys = getNextUnreviewedStepKeys(
        [],
        filteredActivityDefinition as HasSteps & HasKey & HasComponents,
        activityReviewType,
        data.activityData as HasKey & HasDataSteps & HasData & HasReviews
      );

      // if the activity has been 100% completed hence no more unvalidated steps then move the user to the first activity page
      if (!stepKeys.length) {
        stepKeys = getFirstStepKeys(filteredActivityDefinition);
      }

      const threadUuids = deepObjectSearch(activityData, "discussionThreads").flatMap((value) => value);

      let discussionThreads: GetDiscussionThreadsResponse[] = [];
      let getDiscussionThreadsRes;

      if (activityHistoryUuid)
        getDiscussionThreadsRes = await getDiscussionThreads({
          messageLookBack: true,
          objectType: "Activity History",
          objectUuid: activityHistoryUuid,
          threadUuids,
        });

      if (getDiscussionThreadsRes?.status === Status.Success && getDiscussionThreadsRes.data !== undefined)
        discussionThreads = getDiscussionThreadsRes.data;

      setCurrentStepKeys(stepKeys);
      updateSteps(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        discussionThreads,
        stepKeys,
        data.activityUuid,
        filteredActivityDefinition,
        data.activityData,
        enrichedActivityDocuments
      );

      setShowReviewPage(true);
    });
  }, [activityHistoryUuid]);

  const submit = async (): Promise<WriteActivityReviewResponse | null> => {
    let shouldWrite = true;
    const stepData = getStep(currentStepKeys, activityData?.data);

    // Don't write when we would be over-writing a defined field with null
    if (
      (activityReviewType === ActivityReviewTypeConstants.ASSESSMENT &&
        isValidAssessment === undefined &&
        stepData?.activityReviews?.assessment?.isValid != null) ||
      (activityReviewType === ActivityReviewTypeConstants.REVIEW &&
        isValidReview === undefined &&
        stepData?.activityReviews?.review?.isValid != null)
    ) {
      shouldWrite = false;
    }

    if (shouldWrite) {
      const res = await writeActivityReview({
        activityReviewUuid: activityReviewUuid || "",
        dataPath: getDataPath(currentStepKeys),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        isValid:
          activityReviewType === ActivityReviewTypeConstants.ASSESSMENT
            ? isValidAssessment ?? null
            : isValidReview ?? null,
        rowVersion: activityReviewRowVersion,
      });

      if (res.status === Status.Success) {
        if (res.data) {
          setActivityReviewRowVersion(res.data.rowVersion);
          setErrors([]);
          setIsValidAssessment(undefined);
          setIsValidReview(undefined);
          return res.data;
        }
        logError({ error: "No data received for call to ActivityService.writeActivityReviewResponse" });
      }

      if (res.status === Status.Error) {
        setErrors(res?.errors);
      }
    }

    setIsValidAssessment(undefined);
    setIsValidReview(undefined);

    return null;
  };

  const moveToStep = async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getStepsFn: (data: any, activityDefinition2: ActivityDefinition) => string[]
  ): Promise<void> => {
    let data = activityData?.data;
    await submit();

    // Re-fetch the activity review / history data
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const reviewDetailRes = await getActivityReviewDetails({ activityReviewUuid: activityReviewUuid! });
    if (reviewDetailRes.status === Status.Success && reviewDetailRes.data) {
      data = reviewDetailRes.data.activityHistoryDetailsResponse.data;
      setActivityReviewCompletionPercentage(reviewDetailRes.data.completionPercentage || 0);
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    setActivityData({ ...activityData!, data });

    const filteredActivityDefinition = filterAndUpdateActivityDefinition(data);
    const stepKeys = getStepsFn(currentStepKeys, filteredActivityDefinition);
    setCurrentStepKeys(stepKeys);
    setIsReviewPage(false);

    const threadUuids = deepObjectSearch(data, "discussionThreads").flatMap((value) => value);

    const discussionThreadsRes = await getDiscussionThreads({
      messageLookBack: true,
      objectType: "Activity History",
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      objectUuid: activityHistoryUuid!,
      threadUuids,
    });

    updateSteps(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      discussionThreadsRes.data!,
      stepKeys,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      activityUuid!,
      filteredActivityDefinition,
      data,
      activityDocuments
    );
    window.scrollTo(0, 0);
  };

  const moveNext = useIsLoadingWrapper(async (): Promise<void> => {
    await moveToStep(getNextStepKeys);
  }, setIsMoveNextLoading);

  const movePrevious = useIsLoadingWrapper(async (): Promise<void> => {
    await moveToStep(getPreviousStepKeys);
  }, setIsMovePreviousLoading);

  const moveTo = useCallback(
    async (targetStepKeys: string[]): Promise<void> => {
      await moveToStep((data, activityDefinition2) => checkTargetStepkeysAppended(targetStepKeys, activityDefinition2));
    },
    [activityDefinition, currentStepKeys, isValidAssessment, isValidReview]
  );

  const moveToReview = useIsLoadingWrapper(async (): Promise<void> => {
    let data = activityData?.data;

    await submit();

    // Re-fetch the activity review / history data
    const res = await getActivityReviewDetails({ activityReviewUuid: activityReviewUuid || "" });

    data = res.data?.activityHistoryDetailsResponse.data;
    setActivityReviewCompletionPercentage(res.data?.completionPercentage || 0);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    setActivityData({ ...activityData!, data });

    const filteredActivityDefinition = filterAndUpdateActivityDefinition(data);
    setSteps(mapToReviewStepProps([], data, activityReviewType, filteredActivityDefinition.steps, true));
    setL3Steps(mapToReviewStepItem([], data, activityReviewType, filteredActivityDefinition.steps));

    setIsReviewPage(true);
    setHasNext(false);
    setHasPrevious(false);
  }, setIsMoveToReviewLoading);

  const moveToLastStep = useIsLoadingWrapper(async (): Promise<void> => {
    const lastStepKeys = getLastStepKeys(activityDefinition);
    setCurrentStepKeys(lastStepKeys);
    setIsReviewPage(false);

    const threadUuids = deepObjectSearch(activityData, "discussionThreads").flatMap((value) => value);

    const discussionThreadsRes = await getDiscussionThreads({
      messageLookBack: true,
      objectType: "Activity History",
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      objectUuid: activityHistoryUuid!,
      threadUuids,
    });

    updateSteps(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      discussionThreadsRes.data!,
      lastStepKeys,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      activityUuid!,
      activityDefinition,
      activityData?.data,
      activityDocuments
    );
    setIsReviewPage(false);
    window.scrollTo(0, 0);
  }, setIsMoveToLastStepLoading);

  const onSaveAndClose = useIsLoadingWrapper(async () => {
    await submit();
    navigate(
      getActivityReviewDashboardTabRoute(
        activityData?.activity.uuid || "",
        "history",
        searchParams.get("type") ? `type=${searchParams.get("type")}` : ""
      )
    );
  }, setIsSaveAndCloseLoading);

  const backNavigate = (): void => {
    if (activityHistoryUuid)
      navigate(
        getActivityReviewViewRoute(
          activityHistoryUuid,
          searchParams.get("type") ? `type=${searchParams.get("type")}` : ""
        )
      );
    else {
      navigate(-1);
    }
  };

  useEffect(() => {
    setCurrentStepName(findCurrentStepName(steps));
  }, [steps]);

  useLayoutEffect(() => {
    if (showReviewPage && scrollToElement) {
      setTimeout(() => {
        document.getElementById(scrollToElement)?.scrollIntoView(true);
      }, 50);
    }
  }, [showReviewPage]);

  useLayoutEffect(() => {
    if (activityData?.isUnderReview === false && activityReviewUuid) {
      backNavigate();
    }
  }, [activityData]);

  useLayoutEffect(() => {
    if (hasFetchedData) {
      if (!isAssignedAuditor) {
        backNavigate();
      }
    }
  }, [hasFetchedData]);

  return {
    steps,
    l3Steps,
    stepFields,
    stepReviews,
    currentStepName,
    currentStepKeys,
    hasNext,
    hasPrevious,
    activityData,
    isLoading,
    isReviewPage,
    showReviewPage,
    activityDefinition,
    activityDefinitionInfo,
    activityReviewStatus,
    activityReviewType,
    activityReviewCompletionPercentage,
    isAssignedAuditor,
    currentUserType,
    errors,

    isMoveNextLoading,
    isMovePreviousLoading,
    isMoveToLastStepLoading,
    isMoveToReviewLoading,
    isSaveAndCloseLoading,
    // handlers
    movePrevious,
    moveNext,
    moveTo,
    moveToReview,
    moveToLastStep,
    onSaveAndClose,
  };
};
