import { OrganisationRoleConstants } from "../../../../constants/organisation/OrganisationRoleConstants";
import { Column, CursorDataGrid, Row } from "../../../../widget";
import { AssignActivityModal } from "../../components/AssignActivityModal";
import { useActivityReview } from "./useReviews";

const Reviews = (): JSX.Element => {
  const {
    columns,
    defaultSortingCriteria,
    dataIsLoading,
    currentUserRole,
    showAssignActivityModal,
    selectedActivityReview,
    setShowAssignActivityModal,
    setRefreshActivityReviews,
    refreshActivityReviews,
    onChange,
  } = useActivityReview();

  return (
    <div className="ProjectTabSection">
      <Row spacingV="xl">
        <Column span={9}>
          <Row spacingV="xs">
            <Column span={12}>
              <h2>Reviews</h2>
            </Column>
          </Row>
        </Column>
      </Row>
      <CursorDataGrid
        columns={columns}
        clientSide={false}
        filterable
        onChange={onChange}
        data={[]}
        noDataMessage="No reviews to display"
        dataIsLoading={dataIsLoading}
        defaultSortingCriteria={defaultSortingCriteria}
        refresh={refreshActivityReviews}
      />
      {currentUserRole === OrganisationRoleConstants.VVB_MANAGER && (
        <AssignActivityModal
          show={showAssignActivityModal}
          closeModal={() => setShowAssignActivityModal(false)}
          activityReview={selectedActivityReview}
          refreshActivities={refreshActivityReviews}
          setRefreshActivities={setRefreshActivityReviews}
          modalTitle="Edit Activity"
        />
      )}
    </div>
  );
};

export default Reviews;
