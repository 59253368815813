import { Dispatch, SetStateAction, useEffect, useState } from "react";

import {
  ActivityReviewTypeConstants,
  ProjectActivitiesConstants,
  ProjectActivityReviewsConstants,
} from "../../../../../../../../constants";
import { WorkflowActivityReview } from "../../../../../../../../models/shared/workflowActivityReviewType";
import { downloadActivityDocumentsZip, transitionActivity } from "../../../../../../../../service/activity";
import { Status } from "../../../../../../../../service/Shared";
import { useIsLoadingWrapper } from "../../../../../../../../utils";
import { ProfileMenuItemType } from "../../../../../../../../widget";
import toast from "../../../../../../../../widget/general/Toast/Toast";
import { downloadGeneratedDocument } from "../../../../../../../developer/activities";

interface useActivityReviewWorkflowButtonsProps {
  activityReviews?: WorkflowActivityReview[];
}

interface useActivityReviewWorkflowButtonsReturnData {
  showDownloadDropdown: boolean;
  isDocumentDownloading: boolean;
  setShowDownloadDropdown: Dispatch<SetStateAction<boolean>>;
  dropdownOffsetX: number;
  setDropdownOffsetX: Dispatch<SetStateAction<number>>;
  dropdownOffsetY: number;
  setDropdownOffsetY: Dispatch<SetStateAction<number>>;
  downloadDropdownOptions: ProfileMenuItemType[];
  showDownloadActivityFilesModal: boolean;
  setShowDownloadActivityFilesModal: Dispatch<SetStateAction<boolean>>;

  activityAssessment?: WorkflowActivityReview;
  startAssessment: () => Promise<void>;
  isStartAssessmentLoading: boolean;
  activityReview?: WorkflowActivityReview;
  startReview: () => Promise<void>;
  isStartReviewLoading: boolean;

  showReturnToDeveloperModal: boolean;
  setShowReturnToDeveloperModal: Dispatch<SetStateAction<boolean>>;
  showReturnToAssessorModal: boolean;
  setShowReturnToAssessorModal: Dispatch<SetStateAction<boolean>>;
  showSubmitToReviewerModal: boolean;
  setShowSubmitToReviewerModal: Dispatch<SetStateAction<boolean>>;
  showCompleteReviewModal: boolean;
  setShowCompleteReviewModal: Dispatch<SetStateAction<boolean>>;
  showCompleteAssessmentModal: boolean;
  setShowCompleteAssessmentModal: Dispatch<SetStateAction<boolean>>;
  showSubmitToRegistryModal: boolean;
  setShowSubmitToRegistryModal: Dispatch<SetStateAction<boolean>>;
  showSubmitToCodeModal: boolean;
  setShowSubmitToCodeModal: Dispatch<SetStateAction<boolean>>;
  showApproveModal: boolean;
  setShowApproveModal: Dispatch<SetStateAction<boolean>>;
  showAssignAssessmentModal: boolean;
  setShowAssignAssessmentModal: Dispatch<SetStateAction<boolean>>;
  showAssignReviewModal: boolean;
  setShowAssignReviewModal: Dispatch<SetStateAction<boolean>>;
}

export const useActivityReviewWorkflowButtons = ({
  activityReviews,
}: useActivityReviewWorkflowButtonsProps): useActivityReviewWorkflowButtonsReturnData => {
  const [showDownloadDropdown, setShowDownloadDropdown] = useState(false);
  const [isDocumentDownloading, setIsDocumentDownloading] = useState(false);
  const [downloadDropdownOptions, setDownloadDropdownOptions] = useState<ProfileMenuItemType[]>([]);
  const [showDownloadActivityFilesModal, setShowDownloadActivityFilesModal] = useState(false);

  const [showReturnToDeveloperModal, setShowReturnToDeveloperModal] = useState(false);
  const [showReturnToAssessorModal, setShowReturnToAssessorModal] = useState(false);
  const [showSubmitToReviewerModal, setShowSubmitToReviewerModal] = useState(false);
  const [showCompleteReviewModal, setShowCompleteReviewModal] = useState(false);
  const [showCompleteAssessmentModal, setShowCompleteAssessmentModal] = useState(false);
  const [showSubmitToRegistryModal, setShowSubmitToRegistryModal] = useState(false);
  const [showSubmitToCodeModal, setShowSubmitToCodeModal] = useState(false);
  const [showAssignAssessmentModal, setShowAssignAssessmentModal] = useState(false);
  const [showAssignReviewModal, setShowAssignReviewModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);

  const [activityAssessment, setActivityAssessment] = useState<WorkflowActivityReview>();
  const [activityReview, setActivityReview] = useState<WorkflowActivityReview>();
  const [isStartAssessmentLoading, setIsStartAssessmentLoading] = useState(false);
  const [isStartReviewLoading, setIsStartReviewLoading] = useState(false);

  const [dropdownOffsetX, setDropdownOffsetX] = useState(0);
  const [dropdownOffsetY, setDropdownOffsetY] = useState(0);

  const startAssessment = useIsLoadingWrapper(async (): Promise<void> => {
    if (activityAssessment != null) {
      await transitionActivity({
        activity: {
          uuid: activityAssessment.activityUuid,
          rowVersion: activityAssessment.activityRowVersion,
          toStatus: ProjectActivitiesConstants.STATUS_ASSESSMENT_IN_PROGRESS,
          fromStatus: activityAssessment.activityStatus,
          publishExternalMessages: false,
          publishInternalMessages: false,
        },
        activityReviews: [
          {
            activityReviewUuid: activityAssessment.uuid,
            rowVersion: activityAssessment.rowVersion,
            fromStatus: activityAssessment.status,
            toStatus: ProjectActivityReviewsConstants.STATUS_IN_PROGRESS,
          },
        ],
      });

      await activityAssessment.onEdit();
    }
  }, setIsStartAssessmentLoading);

  const startReview = useIsLoadingWrapper(async (): Promise<void> => {
    if (activityReview != null) {
      await transitionActivity({
        activity: {
          uuid: activityReview.activityUuid,
          rowVersion: activityReview.activityRowVersion,
          toStatus: ProjectActivitiesConstants.STATUS_REVIEW_IN_PROGRESS,
          fromStatus: activityReview.activityStatus,
          publishExternalMessages: false,
          publishInternalMessages: false,
        },
        activityReviews: [
          {
            activityReviewUuid: activityReview.uuid,
            rowVersion: activityReview.rowVersion,
            fromStatus: activityReview.status,
            toStatus: ProjectActivityReviewsConstants.STATUS_IN_PROGRESS,
          },
        ],
      });

      await activityReview.onEdit();
    }
  }, setIsStartReviewLoading);

  useEffect(() => {
    if (activityAssessment && activityAssessment.activityHistoryUuid) {
      const { activityHistoryUuid } = activityAssessment;
      setDownloadDropdownOptions([
        {
          id: 1,
          value: "Output",
          action: async () => {
            setIsDocumentDownloading(true);
            setShowDownloadDropdown(false);
            await downloadGeneratedDocument({
              activityHistoryUuid,
              currentOnly: true,
            });

            setIsDocumentDownloading(false);
          },
        },
        {
          id: 2,
          value: "Output + Key documents",
          action: async () => {
            const res = await downloadActivityDocumentsZip({ activityHistoryUuid, isKey: true });

            if (res.status === Status.Error) {
              toast.error({ message: "Could not download documents for this activity!" });

              return;
            }

            setShowDownloadDropdown(false);
            setShowDownloadActivityFilesModal(true);
          },
        },
        {
          id: 3,
          value: "Output + All documents",
          action: async () => {
            const res = await downloadActivityDocumentsZip({ activityHistoryUuid, isKey: false });

            if (res.status === Status.Error) {
              toast.error({ message: "Could not download documents for this activity!" });

              return;
            }

            setShowDownloadDropdown(false);
            setShowDownloadActivityFilesModal(true);
          },
        },
      ]);
    }
  }, [activityAssessment]);

  useEffect(() => {
    setActivityAssessment(activityReviews?.find((review) => review.type === ActivityReviewTypeConstants.ASSESSMENT));
    setActivityReview(activityReviews?.find((review) => review.type === ActivityReviewTypeConstants.REVIEW));
  }, [activityReviews]);

  return {
    showDownloadDropdown,
    setShowDownloadDropdown,
    dropdownOffsetX,
    setDropdownOffsetX,
    dropdownOffsetY,
    setDropdownOffsetY,
    isDocumentDownloading,
    showDownloadActivityFilesModal,
    setShowDownloadActivityFilesModal,
    downloadDropdownOptions,

    activityAssessment,
    startAssessment,
    isStartAssessmentLoading,
    activityReview,
    startReview,
    isStartReviewLoading,

    showReturnToAssessorModal,
    setShowReturnToAssessorModal,
    showCompleteAssessmentModal,
    setShowCompleteAssessmentModal,
    showCompleteReviewModal,
    setShowCompleteReviewModal,
    showReturnToDeveloperModal,
    setShowReturnToDeveloperModal,
    showSubmitToReviewerModal,
    setShowSubmitToReviewerModal,
    showSubmitToRegistryModal,
    setShowSubmitToRegistryModal,
    showSubmitToCodeModal,
    setShowSubmitToCodeModal,
    showApproveModal,
    setShowApproveModal,
    showAssignAssessmentModal,
    setShowAssignAssessmentModal,
    showAssignReviewModal,
    setShowAssignReviewModal,
  };
};
