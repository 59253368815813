import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { BadgeTypeConstants } from "../../constants";
import { BadgeData, FileType, PublicProjectParams } from "../../models";
import { usePublicContext } from "../../route/public";
import {
  getProjectDetails,
  GetProjectDetailsResponse,
  getPublicGroupDetails,
  searchProjects,
} from "../../service/publicquery";
import { Status } from "../../service/Shared";
import { getPublicPageNotFoundRoute } from "../../utils/routes";

export type DeveloperProject = {
  uuid: string;
  displayName: string;
  image: string | undefined;
  description: string | undefined;
  codeName?: string;
};

export type GroupData = {
  groupUuid: string;
  groupName: string;
  groupCount: number;
  projects: DeveloperProject[] | undefined;
};

interface usePublicProjectTemplateReturnData {
  project: GetProjectDetailsResponse | undefined;
  images: string[] | undefined;
  otherDeveloperProjects: DeveloperProject[] | undefined;
  groupData: GroupData | undefined;
  developerIcon: string | undefined;
  projectBadges: BadgeData[];
  isLoading: boolean;
  searchParams?: string;
  showRegisterInterestModal: boolean;
  onBackToSearchResults: () => void;
  onRegisterInterest: () => void;
  onCloseRegisterInterestModal: () => void;
}

export const usePublicProjectTemplate = (): usePublicProjectTemplateReturnData => {
  const navigate = useNavigate();
  const { uuid } = useParams<PublicProjectParams>();
  const { setProjectDetails } = usePublicContext();
  const [searchParams] = useSearchParams();
  const [project, setProject] = useState<GetProjectDetailsResponse>();
  const [developerIcon, setDeveloperIcon] = useState<string>();
  const [otherDeveloperProjects, setOtherDeveloperProjects] = useState<DeveloperProject[] | undefined>();
  const [groupData, setGroupData] = useState<GroupData | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  const [showRegisterInterestModal, setShowRegisterInterestModal] = useState(false);
  const [projectBadges, setProjectBadges] = useState<BadgeData[]>([]);

  const fetchOtherDeveloperProjects = useCallback(
    async (developerUuid: string) => {
      const response = await searchProjects({
        paging: {
          limit: 4,
          beforeCursor: null,
          afterCursor: null,
        },
        filter: {
          results: {
            developer: {
              uuid: { operator: "eq", value: developerUuid },
            },
          },
        },
      });

      if (response.status === Status.Success) {
        const projects = response.data?.results
          .filter((el) => el.uuid !== uuid) // excluding the already selected project from the other projects list
          .map((el) => ({
            uuid: el.uuid,
            displayName: el.displayName,
            image: el.listingFiles?.length > 0 ? el.listingFiles[0]?.file?.url : null,
            description: el.listing?.content?.shortDescription,
            codeName: el.standard?.displayName || undefined,
          }));
        if (projects) {
          setOtherDeveloperProjects(projects.slice(0, 3));
        }
      }
    },
    [uuid]
  );

  const fetchGroupData = useCallback(
    async (projectGroupUuid: string) => {
      const response = await getPublicGroupDetails({ projectGroupUuid });

      if (response.status === Status.Success) {
        const projects = response.data?.projects
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .filter((el: any) => el.uuid !== uuid && el.listing?.cachedHidden !== true) // excluding the already selected project from the other projects list
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .map((el: any) => ({
            uuid: el.uuid,
            displayName: el.displayName,
            image: el.listingFiles?.length > 0 ? el.listingFiles[0]?.file?.url : undefined,
            description: el.listing?.content?.shortDescription,
            codeName: el.standard?.displayName || undefined,
          }));
        if (projects && response.data) {
          setGroupData({
            groupUuid: response.data.groupGuid,
            groupName: response.data.groupName,
            groupCount: projects.length,
            projects,
          });
        }
      }
    },
    [uuid]
  );

  const onBackToSearchResults = (): void => {
    navigate({
      pathname: "/p/projects",
      search: searchParams?.toString(),
    });
  };

  const onRegisterInterest = (): void => {
    setShowRegisterInterestModal(true);
  };

  const onCloseRegisterInterestModal = (): void => {
    setShowRegisterInterestModal(false);
  };

  const fetchData = useCallback(
    async (projectUuid: string) => {
      const response = await getProjectDetails({ projectUuid });
      if (response.status === Status.Success) {
        if (response.data?.listing.hidden) navigate(getPublicPageNotFoundRoute()); // If the project is hidden redirect to 404

        setProject(response.data);
        setProjectDetails(response.data); // Storing the current project details in the context
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setDeveloperIcon(response.data?.developer.files.find((f: any) => f.type === FileType.SmallLogo)?.file.url);
        setProjectBadges(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          response.data?.badges.filter((b: any) => b.type === BadgeTypeConstants.BADGE_TYPE_BADGE) || []
        );
        if (response.data?.developer) {
          await fetchOtherDeveloperProjects(response.data?.developer.uuid);
        }
        if (response.data?.group) {
          await fetchGroupData(response.data?.group.groupUuid);
        }
      }
    },
    [uuid]
  );

  useEffect(() => {
    if (uuid) {
      fetchData(uuid).then(() => setIsLoading(false));
    }
  }, [fetchData, uuid]);

  const images = project?.listing?.content?.images?.flatMap((imgUuid: string) => {
    const listingFile = project?.listingFiles?.find((lf) => lf.uuid === imgUuid);
    if (listingFile) {
      return listingFile.file.url;
    }
    return [];
  });

  return {
    project,
    images,
    otherDeveloperProjects,
    groupData,
    developerIcon,
    projectBadges,
    isLoading,
    searchParams: searchParams?.toString(),
    showRegisterInterestModal,
    onBackToSearchResults,
    onRegisterInterest,
    onCloseRegisterInterestModal,
  };
};
