import { Location } from "react-router-dom";

import { OrganisationTypeConstants } from "../constants";
import { logError } from "../service/error";

export const getLogonRoute = (params?: { origin: string }): string => {
  return `/logon${params?.origin ? `?origin=${params.origin}` : ""}`;
};

export const getForgottenPasswordRoute = (): string => {
  return "/forgotten-password";
};

export const getDashboardRoute = (userType: OrganisationTypeConstants): string => {
  const sharedUrl = "/dashboard";

  switch (userType) {
    case OrganisationTypeConstants.DEVELOPER:
      return `/d${sharedUrl}`;
    case OrganisationTypeConstants.VERIFIER:
      return `/v${sharedUrl}`;
    case OrganisationTypeConstants.DEVELOPMENT_MANAGER:
      return `/dm${sharedUrl}`;
    case OrganisationTypeConstants.ASSET_MANAGER:
      return `/am${sharedUrl}`;
    default:
      logError({
        error: `The following organisation type does not exist:  ${userType}`,
      });
      return "";
  }
};

export const getVerifierQueueManagementAssessmentsRoute = (userType: OrganisationTypeConstants): string => {
  const sharedUrl = "/queue-management/assessments";

  switch (userType) {
    case OrganisationTypeConstants.VERIFIER:
      return `/v${sharedUrl}`;
    default:
      logError({
        error: `The following organisation type does not exist:  ${userType}`,
      });
      return "";
  }
};

export const getVerifierQueueManagementReviewsRoute = (userType: OrganisationTypeConstants): string => {
  const sharedUrl = "/queue-management/reviews";

  switch (userType) {
    case OrganisationTypeConstants.VERIFIER:
      return `/v${sharedUrl}`;
    default:
      logError({
        error: `The following organisation type does not exist:  ${userType}`,
      });
      return "";
  }
};

export const getVerifierQueueManagementUnassignedActivitiesRoute = (userType: OrganisationTypeConstants): string => {
  const sharedUrl = "/queue-management/unassigned-activities";

  switch (userType) {
    case OrganisationTypeConstants.VERIFIER:
      return `/v${sharedUrl}`;
    default:
      logError({
        error: `The following organisation type does not exist:  ${userType}`,
      });
      return "";
  }
};

export const getUserSettingsRoute = (userType: OrganisationTypeConstants): string => {
  const sharedUrl = "/settings/users";

  switch (userType) {
    case OrganisationTypeConstants.DEVELOPER:
      return `/d${sharedUrl}`;
    case OrganisationTypeConstants.VERIFIER:
      return `/v${sharedUrl}`;
    case OrganisationTypeConstants.DEVELOPMENT_MANAGER:
      return `/dm${sharedUrl}`;
    case OrganisationTypeConstants.ASSET_MANAGER:
      return `/am${sharedUrl}`;
    default:
      logError({
        error: `The following organisation type does not exist:  ${userType}`,
      });
      return "";
  }
};

export const getUserSettingsOrganisationRoute = (
  userType: OrganisationTypeConstants,
  params?: {
    origin: string;
  }
): string => {
  const sharedUrl = `/settings/organisation${params?.origin ? `?origin=${params.origin}` : ""}`;

  switch (userType) {
    case OrganisationTypeConstants.DEVELOPER:
      return `/d${sharedUrl}`;
    case OrganisationTypeConstants.VERIFIER:
      return `/v${sharedUrl}`;
    case OrganisationTypeConstants.DEVELOPMENT_MANAGER:
      return `/dm${sharedUrl}`;
    case OrganisationTypeConstants.ASSET_MANAGER:
      return `/am${sharedUrl}`;
    default:
      logError({
        error: `The following organisation type does not exist:  ${userType}`,
      });
      return "";
  }
};

export const getDeveloperSettingsMarketingAssetsRoute = (
  params?: {
    origin: string;
  },
  uuid: string | undefined = undefined
): string => {
  return `/d/settings/marketing-assets${uuid ? `/${uuid}` : ""}${params?.origin ? `?origin=${params.origin}` : ""}`;
};

export const getVerifierSettingsMarketingAssetsRoute = (
  params?: {
    origin: string;
  },
  uuid: string | undefined = undefined
): string => {
  return `/v/settings/marketing-assets${uuid ? `/${uuid}` : ""}${params?.origin ? `?origin=${params.origin}` : ""}`;
};

export const getProjectsRoute = (userType: OrganisationTypeConstants): string => {
  const sharedUrl = "/projects";

  switch (userType) {
    case OrganisationTypeConstants.DEVELOPER:
      return `/d${sharedUrl}`;
    case OrganisationTypeConstants.VERIFIER:
      return `/v${sharedUrl}`;
    case OrganisationTypeConstants.DEVELOPMENT_MANAGER:
      return `/dm${sharedUrl}`;
    case OrganisationTypeConstants.ASSET_MANAGER:
      return `/am${sharedUrl}`;
    default:
      logError({
        error: `The following organisation type does not exist:  ${userType}`,
      });
      return "";
  }
};

export const getActivitiesRoute = (userType: OrganisationTypeConstants): string => {
  const sharedUrl = "/activities";

  switch (userType) {
    case OrganisationTypeConstants.DEVELOPER:
      return `/d${sharedUrl}`;
    case OrganisationTypeConstants.VERIFIER:
      return `/v${sharedUrl}`;
    case OrganisationTypeConstants.DEVELOPMENT_MANAGER:
      return `/dm${sharedUrl}`;
    case OrganisationTypeConstants.ASSET_MANAGER:
      return `/am${sharedUrl}`;
    default:
      logError({
        error: `The following organisation type does not exist:  ${userType}`,
      });
      return "";
  }
};

export const getSettingsRoute = (userType: OrganisationTypeConstants): string => {
  const sharedUrl = "/settings";

  switch (userType) {
    case OrganisationTypeConstants.DEVELOPER:
      return `/d${sharedUrl}`;
    case OrganisationTypeConstants.VERIFIER:
      return `/v${sharedUrl}`;
    case OrganisationTypeConstants.DEVELOPMENT_MANAGER:
      return `/dm${sharedUrl}`;
    case OrganisationTypeConstants.ASSET_MANAGER:
      return `/am${sharedUrl}`;
    default:
      logError({
        error: `The following organisation type does not exist:  ${userType}`,
      });
      return "";
  }
};

export const getMarketingRoute = (): string => {
  return "/d/marketing/marketing-stats";
};

export const getProjectDetailsByUuid = (
  uuid: string,
  userType: OrganisationTypeConstants,
  params?: {
    origin: string;
  }
): string => {
  const sharedUrl = `/projects/${uuid}/overview${params?.origin ? `?origin=${params.origin}` : ""}`;
  switch (userType) {
    case OrganisationTypeConstants.DEVELOPER:
      return `/d${sharedUrl}`;
    case OrganisationTypeConstants.VERIFIER:
      return `/v${sharedUrl}`;
    case OrganisationTypeConstants.DEVELOPMENT_MANAGER:
      return `/dm${sharedUrl}`;
    case OrganisationTypeConstants.ASSET_MANAGER:
      return `/am${sharedUrl}`;
    default:
      logError({
        error: `In 'routes.ts, method getProjectDetailsByUuid', the following organisation type does not exists:  ${userType}`,
      });
      return "";
  }
};

export const getProjectMarketingSettingsRoute = (uuid: string, queryParams?: string): string => {
  return `/d/projects/${uuid}/marketing-settings${queryParams ? `?${queryParams}` : ""}`;
};

export const getProjectAccessRoute = (
  uuid: string,
  params?: {
    origin: string;
  }
): string => {
  return `/d/projects/${uuid}/access${params?.origin ? `?origin=${params.origin}` : ""}`;
};

export const getProjectAuditHistoryRoute = (uuid: string, userType: OrganisationTypeConstants): string => {
  const sharedUrl = `/projects/${uuid}/audit-history`;

  // TODO: Implement other user types when we introduce audit for them
  switch (userType) {
    case OrganisationTypeConstants.DEVELOPER:
      return `/d${sharedUrl}`;
    case OrganisationTypeConstants.VERIFIER:
      return `/v${sharedUrl}`;
    default:
      logError({
        error: `In 'routes.ts, method getProjectAuditHistoryRoute', the following organisation type does not exists:  ${userType}`,
      });
      return "";
  }
};

export const getProjectDocumentsRoute = (uuid: string, userType: OrganisationTypeConstants): string => {
  const sharedUrl = `/projects/${uuid}/documents`;

  switch (userType) {
    case OrganisationTypeConstants.DEVELOPER:
      return `/d${sharedUrl}`;
    case OrganisationTypeConstants.VERIFIER:
      return `/v${sharedUrl}`;
    case OrganisationTypeConstants.DEVELOPMENT_MANAGER:
      return `/dm${sharedUrl}`;
    case OrganisationTypeConstants.ASSET_MANAGER:
      return `/am${sharedUrl}`;
    default:
      logError({
        error: `In 'routes.ts, method getProjectDocumentsRoute', the following organisation type does not exists:  ${userType}`,
      });
      return "";
  }
};

export const getProjectActivityReviewRoute = (projectUuid: string, userType: OrganisationTypeConstants): string => {
  const sharedUrl = `/projects/${projectUuid}/activity-review`;

  switch (userType) {
    case OrganisationTypeConstants.VERIFIER:
      return `/v${sharedUrl}`;
    default:
      logError({
        error: `In 'routes.ts, method getProjectActivityReviewRoute', the following organisation type does not exists:  ${userType}`,
      });
      return "";
  }
};

export const getWorkflowsRoute = (userType: OrganisationTypeConstants): string => {
  const sharedUrl = `/workflows`;

  switch (userType) {
    case OrganisationTypeConstants.ASSET_MANAGER:
      return `/am${sharedUrl}`;
    default:
      logError({
        error: `In 'routes.ts, method getProjectWorkflowsRoute', the following organisation type does not exists:  ${userType}`,
      });
      return "";
  }
};

export const getInsightsRoute = (userType: OrganisationTypeConstants): string => {
  const sharedUrl = `/insights`;

  switch (userType) {
    case OrganisationTypeConstants.ASSET_MANAGER:
      return `/am${sharedUrl}`;
    default:
      logError({
        error: `In 'routes.ts, method getProjectInsightsRoute', the following organisation type does not exists:  ${userType}`,
      });
      return "";
  }
};

export const getWatchlistRoute = (userType: OrganisationTypeConstants): string => {
  const sharedUrl = `/watchlist`;

  switch (userType) {
    case OrganisationTypeConstants.ASSET_MANAGER:
      return `/am${sharedUrl}`;
    default:
      logError({
        error: `In 'routes.ts, method getProjectWatchlistRoute', the following organisation type does not exists:  ${userType}`,
      });
      return "";
  }
};

export const getProjectManageProjectGroupRoute = (uuid: string, queryParams: string): string => {
  return `/d/projects/${uuid}/manage-project-group${queryParams ? `?${queryParams}` : ""}`;
};

export const getProjectAccessDetailsRoute = (uuid: string, params?: { origin: string }): string => {
  return `/d/project/access-details/${uuid}${params?.origin ? `?origin=${params.origin}` : ""}`;
};

export const getProjectAccessInviteAcceptRoute = (uuid: string): string => {
  return `/d/project/access/invite/accept/${uuid}`;
};

export const getUserEditRouteByUuid = (
  uuid: string,
  userType: OrganisationTypeConstants,
  params?: {
    origin: string;
  }
): string => {
  const sharedUrl = `/settings/users/${uuid}/edit${params?.origin ? `?origin=${params.origin}` : ""}`;

  switch (userType) {
    case OrganisationTypeConstants.DEVELOPER:
      return `/d${sharedUrl}`;
    case OrganisationTypeConstants.VERIFIER:
      return `/v${sharedUrl}`;
    case OrganisationTypeConstants.DEVELOPMENT_MANAGER:
      return `/dm${sharedUrl}`;
    case OrganisationTypeConstants.ASSET_MANAGER:
      return `/am${sharedUrl}`;
    default:
      logError({
        error: `The following organisation type does not exist:  ${userType}`,
      });
      return "";
  }
};

export const getUserSettingsSendInvitationRoute = (userType: OrganisationTypeConstants): string => {
  const sharedUrl = "/settings/users/send-invitation";

  switch (userType) {
    case OrganisationTypeConstants.DEVELOPER:
      return `/d${sharedUrl}`;
    case OrganisationTypeConstants.VERIFIER:
      return `/v${sharedUrl}`;
    case OrganisationTypeConstants.DEVELOPMENT_MANAGER:
      return `/dm${sharedUrl}`;
    case OrganisationTypeConstants.ASSET_MANAGER:
      return `/am${sharedUrl}`;
    default:
      logError({
        error: `The following organisation type does not exist:  ${userType}`,
      });
      return "";
  }
};

export const getSettingsProjectAccessRoute = (userType: OrganisationTypeConstants): string => {
  const sharedUrl = "/settings/project-access";

  switch (userType) {
    case OrganisationTypeConstants.DEVELOPER:
      return `/d${sharedUrl}`;
    default:
      logError({
        error: `In 'routes.ts, method getSettingsProjectAccessRoute', the following organisation type does not exists:  ${userType}`,
      });
      return "";
  }
};

export const getSettingsDocumentTypesRoute = (userType: OrganisationTypeConstants): string => {
  const sharedUrl = "/settings/document-types";

  switch (userType) {
    case OrganisationTypeConstants.DEVELOPER:
      return `/d${sharedUrl}`;
    default:
      logError({
        error: `In 'routes.ts, method getSettingsDocumentTypesRoute', the following organisation type does not exists:  ${userType}`,
      });
      return "";
  }
};

export const getSettingsAuditHistoryRoute = (userType: OrganisationTypeConstants): string => {
  const sharedUrl = "/settings/audit-history";

  switch (userType) {
    case OrganisationTypeConstants.DEVELOPER:
      return `/d${sharedUrl}`;
    default:
      logError({
        error: `In 'routes.ts, method getSettingsAuditHistoryRoute', the following organisation type does not exists:  ${userType}`,
      });
      return "";
  }
};

export const getSettingsViewDocumentTypeRoute = (
  documentTypeUuid: string,
  userType: OrganisationTypeConstants
): string => {
  const sharedUrl = `/settings/document-types/${documentTypeUuid}/view`;

  switch (userType) {
    case OrganisationTypeConstants.DEVELOPER:
      return `/d${sharedUrl}`;
    default:
      logError({
        error: `In 'routes.ts, method getSettingsViewDocumentTypeRoute', the following organisation type does not exists:  ${userType}`,
      });
      return "";
  }
};

export const getProfileChangePasswordRoute = (userType: OrganisationTypeConstants): string => {
  const sharedUrl = "/profile/change-password";

  switch (userType) {
    case OrganisationTypeConstants.DEVELOPER:
      return `/d${sharedUrl}`;
    case OrganisationTypeConstants.VERIFIER:
      return `/v${sharedUrl}`;
    case OrganisationTypeConstants.DEVELOPMENT_MANAGER:
      return `/dm${sharedUrl}`;
    case OrganisationTypeConstants.ASSET_MANAGER:
      return `/am${sharedUrl}`;
    default:
      logError({
        error: `In 'routes.ts, method getProfileChangePasswordRoute', the following organisation type does not exist:  ${userType}`,
      });
      return "";
  }
};

export const getProfileChangePersonalDetailsRoute = (userType: OrganisationTypeConstants): string => {
  const sharedUrl = "/profile/change-personal-details";

  switch (userType) {
    case OrganisationTypeConstants.DEVELOPER:
      return `/d${sharedUrl}`;
    case OrganisationTypeConstants.VERIFIER:
      return `/v${sharedUrl}`;
    case OrganisationTypeConstants.DEVELOPMENT_MANAGER:
      return `/dm${sharedUrl}`;
    case OrganisationTypeConstants.ASSET_MANAGER:
      return `/am${sharedUrl}`;
    default:
      logError({
        error: `In 'routes.ts, method getProfileChangePersonalDetailsRoute', the following organisation type does not exist:  ${userType}`,
      });
      return "";
  }
};

export const getPublicProjectsRoute = (): string => "/p/projects";

export const getPublicCodeDetailsRoute = (uuid: string): string => `/p/code/${uuid}/details`;

export const getPublicBadgeDetailsRoute = (uuid: string): string => `/p/badge/${uuid}/details`;

export const getPublicDeveloperDetailsRoute = (uuid: string): string => `/p/developer/${uuid}/details`;

export const getPublicDeveloperDetailsPreviewRoute = (uuid: string): string => `/d/developer/details/preview/${uuid}`;
export const getPublicVerifierDetailsPreviewRoute = (uuid: string): string => `/v/verifier/details/preview/${uuid}`;
export const getCodePublicProjectsRoute = (codeUuid?: string, notCodeUuids?: string[]): string => {
  const falseCodeUrls = notCodeUuids?.map((uuid) => `%2C${uuid}%3Afalse`);
  return codeUuid && notCodeUuids ? `/p/projects?code=${codeUuid}%3Atrue${falseCodeUrls}` : "/p/projects";
};
export const getBadgePublicProjectsRoute = (uuid?: string): string =>
  uuid ? `/p/projects?badges=${uuid}` : "/p/projects";

export const getPublicProjectOverviewRoute = (uuid: string): string => {
  return `/p/project/${uuid}/overview`;
};

export const getPublicProjectDetailsRoute = (uuid: string): string => {
  return `/p/project/${uuid}/details`;
};

export const getPublicProjectDocumentsRoute = (uuid: string): string => {
  return `/p/project/${uuid}/documents`;
};

export const getPublicProjectIssuancesRoute = (uuid: string): string => {
  return `/p/project/${uuid}/issuances`;
};

export const getPublicProjectOverviewPreviewRoute = (uuid: string, previewUuid: string): string => {
  return `/d/project/${uuid}/preview/${previewUuid}/overview`;
};

export const getPublicValidatorRoute = (uuid: string): string => {
  return `/p/verifier/${uuid}/details`;
};

export const getPublicProjectTypeRoute = (code: string): string => {
  return `/p/project-type/${code}`;
};

export const getPublicCodeComparisonRoute = (): string => {
  return "/p/code-comparison";
};

export const getPublicProjectTypesRoute = (): string => {
  return `/p/project-types`;
};

export const getPublicPageNotFoundRoute = (): string => {
  return `/p/404/page-not-found`;
};

export const getAccessDeniedRoute = (): string => {
  return "/d/403/access-denied";
};

export const getActivityViewRoute = (
  activityHistoryUuid: string,
  userType: OrganisationTypeConstants,
  queryParams?: string
): string => {
  const sharedUrl = `/activities/${activityHistoryUuid}/view${queryParams ? `?${queryParams}` : ""}`;

  switch (userType) {
    case OrganisationTypeConstants.DEVELOPER:
      return `/d${sharedUrl}`;
    case OrganisationTypeConstants.DEVELOPMENT_MANAGER:
      return `/dm${sharedUrl}`;
    case OrganisationTypeConstants.ASSET_MANAGER:
      return `/am${sharedUrl}`;
    case OrganisationTypeConstants.VERIFIER:
      return `/v${sharedUrl}`;
    default:
      logError({
        error: `In 'routes.ts, method getActivityViewRoute', the following organisation type does not exist:  ${userType}`,
      });
      return "";
  }
};

export const getActivityDashboardTabRoute = (
  activityUuid: string,
  tab: string,
  userType: OrganisationTypeConstants,
  queryParams?: string
): string => {
  const sharedUrl = `/activity/${activityUuid}/${tab}${queryParams ? `?${queryParams}` : ""}`;

  switch (userType) {
    case OrganisationTypeConstants.DEVELOPER:
      return `/d${sharedUrl}`;
    case OrganisationTypeConstants.DEVELOPMENT_MANAGER:
      return `/dm${sharedUrl}`;
    case OrganisationTypeConstants.ASSET_MANAGER:
      return `/am${sharedUrl}`;
    case OrganisationTypeConstants.VERIFIER: // This shouldn't be called but will prevent error
      return "";
    default:
      logError({
        error: `In 'routes.ts, method getActivityDashboardTabRoute', the following organisation type does not exist:  ${userType}`,
      });
      return "";
  }
};

export const getActivityEditRoute = (activityReviewUuid: string, queryParams?: string): string => {
  return `/d/activities/${activityReviewUuid}/edit${queryParams ? `?${queryParams}` : ""}`;
};

export const getActivityReviewDashboardTabRoute = (
  activityReviewActivityUuid: string,
  tab: string,
  queryParams?: string
): string => {
  return `/v/activity-review/${activityReviewActivityUuid}/${tab}${queryParams ? `?${queryParams}` : ""}`;
};

export const getActivityReviewRoute = (activityHistoryUuid: string, queryParams?: string): string => {
  return `/v/activity-review/${activityHistoryUuid}/review${queryParams ? `?${queryParams}` : ""}`;
};

export const getActivityReviewViewRoute = (activityHistoryUuid: string, queryParams?: string): string => {
  return `/v/activity-review/${activityHistoryUuid}/view${queryParams ? `?${queryParams}` : ""}`;
};

export const getActivityDocumentRoute = (
  activityUuid: string,
  activityDocumentUuid: string,
  userType: OrganisationTypeConstants,
  queryParams?: string
): string => {
  const sharedUrl = `/activity/${activityUuid}/document/${activityDocumentUuid}${queryParams ? `?${queryParams}` : ""}`;

  switch (userType) {
    case OrganisationTypeConstants.DEVELOPER:
      return `/d${sharedUrl}`;
    case OrganisationTypeConstants.DEVELOPMENT_MANAGER:
      return `/dm${sharedUrl}`;
    case OrganisationTypeConstants.ASSET_MANAGER:
      return `/am${sharedUrl}`;
    case OrganisationTypeConstants.VERIFIER: // This shouldn't be called but will prevent error
      return `/v${sharedUrl}`;
    default:
      logError({
        error: `In 'routes.ts, method getActivityDocumentRoute', the following organisation type does not exists:  ${userType}`,
      });
      return "";
  }
};

export const getActivityDocumentHistoryDownload = (activityHistoryUuid: string, mimeType: string): string => {
  return `/activity/file/download?ActivityDocumentHistoryUuid=${activityHistoryUuid}&MimeType=${encodeURIComponent(
    mimeType
  )}`;
};

export const getProjectActivitiesRoute = (projectUuid: string, userType: OrganisationTypeConstants): string => {
  const sharedUrl = `/projects/${projectUuid}/activities`;

  switch (userType) {
    case OrganisationTypeConstants.DEVELOPER:
      return `/d${sharedUrl}`;
    case OrganisationTypeConstants.DEVELOPMENT_MANAGER:
      return `/dm${sharedUrl}`;
    case OrganisationTypeConstants.ASSET_MANAGER:
      return `/am${sharedUrl}`;
    default:
      logError({
        error: `In 'routes.ts, method getProjectActivitiesRoute', the following organisation type does not exists:  ${userType}`,
      });
      return "";
  }
};

export const getProjectIssuancesRoute = (
  projectUuid: string,
  userType: OrganisationTypeConstants,
  params?: {
    origin: string;
  }
): string => {
  const sharedUrl = `/projects/${projectUuid}/issuances${params?.origin ? `?origin=${params.origin}` : ""}`;

  switch (userType) {
    case OrganisationTypeConstants.DEVELOPER:
      return `/d${sharedUrl}`;
    case OrganisationTypeConstants.VERIFIER:
      return `/v${sharedUrl}`;
    case OrganisationTypeConstants.DEVELOPMENT_MANAGER:
      return `/dm${sharedUrl}`;
    case OrganisationTypeConstants.ASSET_MANAGER:
      return `/am${sharedUrl}`;
    default:
      logError({
        error: `In 'routes.ts, method getProjectIssuancesRoute', the following organisation type does not exists:  ${userType}`,
      });
      return "";
  }
};

export const getProjectAccountingRoute = (projectUuid: string, userType: OrganisationTypeConstants): string => {
  const sharedUrl = `/projects/${projectUuid}/accounting`;

  switch (userType) {
    case OrganisationTypeConstants.ASSET_MANAGER:
      return `/am${sharedUrl}`;
    default:
      logError({
        error: `In 'routes.ts, method getProjectAccountingRoute', the following organisation type does not exists:  ${userType}`,
      });
      return "";
  }
};

export const getProjectTasksRoute = (projectUuid: string, userType: OrganisationTypeConstants): string => {
  const sharedUrl = `/projects/${projectUuid}/tasks`;

  switch (userType) {
    case OrganisationTypeConstants.ASSET_MANAGER:
      return `/am${sharedUrl}`;
    default:
      logError({
        error: `In 'routes.ts, method getProjectTasksRoute', the following organisation type does not exists:  ${userType}`,
      });
      return "";
  }
};

export const getDocumentHistoryRoute = (
  projectUuid: string,
  projectDocumentUuid: string,
  userType: OrganisationTypeConstants,
  params?: { origin: string }
): string => {
  const sharedUrl = `/projects/${projectUuid}/documents/${projectDocumentUuid}${
    params?.origin ? `?origin=${params.origin}` : ""
  }`;

  switch (userType) {
    case OrganisationTypeConstants.DEVELOPER:
      return `/d${sharedUrl}`;
    case OrganisationTypeConstants.VERIFIER:
      return `/v${sharedUrl}`;
    case OrganisationTypeConstants.DEVELOPMENT_MANAGER:
      return `/dm${sharedUrl}`;
    case OrganisationTypeConstants.ASSET_MANAGER:
      return `/am${sharedUrl}`;
    default:
      logError({
        error: `In 'routes.ts, method getDocumentHistoryRoute', the following organisation type does not exists:  ${userType}`,
      });
      return "";
  }
};

export const getProjectAccessDetailsRoute2 = (uuid: string, params?: { origin: string }): string => {
  return `/d/project/access-details/${uuid}${params?.origin ? `?origin=${params.origin}` : ""}`;
};

export const getPathnameWithoutLastElement = (pathName: string): string => {
  return pathName.substring(0, pathName.lastIndexOf("/"));
};

export const getProjectPathnameForBackNavigation = (
  projectUuid: string,
  previousLocation: Location | undefined
): string => {
  const routePathname = getPublicProjectOverviewRoute(projectUuid);

  if (
    previousLocation &&
    getPathnameWithoutLastElement(routePathname) === getPathnameWithoutLastElement(previousLocation.pathname)
  ) {
    return routePathname + previousLocation.search;
  }

  return routePathname;
};
