import "./Name.css";

import { RoundedMinusSignIcon, RoundedPlusSignIcon } from "../../../assets";
import { Divider } from "../Divider";
import { useName } from "./useName";

interface NameProps {
  header: string;
  children: JSX.Element;
}
export const Name = ({ header, children }: NameProps): JSX.Element => {
  const { showContent, setShowContent } = useName();

  return (
    <div className="Name">
      <div
        className="NameHeader"
        role="button"
        onKeyDown={(e) => e.key === "Enter" && setShowContent(!showContent)}
        onClick={() => setShowContent(!showContent)}
        tabIndex={0}
        aria-label="NameHeader"
      >
        <h3>{header}</h3>
        {showContent ? <RoundedMinusSignIcon /> : <RoundedPlusSignIcon />}
      </div>
      {showContent && <div className="NameContent">{children}</div>}
      <Divider className="NameDivider" type="horizontal" />
    </div>
  );
};
