import "./ReturnToAssessorModal.css";

import { Button, Column, Modal, Row } from "../../../../widget";
import { useReturnToAssessorModal } from "./useReturnToAssessorModal";

interface ReturnToAssessorModalProps {
  show: boolean;
  onClose: () => void;
  activityUuid: string;
  activityHistoryUuid: string;
  activityHistoryRowVersion: number | undefined;
  isDraft: boolean;
  shouldRefreshActivities?: () => void;
  exitActivityWizard?: () => void;
}

export const ReturnToAssessorModal = ({
  show,
  onClose,
  activityUuid,
  activityHistoryUuid,
  activityHistoryRowVersion,
  isDraft,
  shouldRefreshActivities,
  exitActivityWizard,
}: ReturnToAssessorModalProps): JSX.Element => {
  const { isLoading, onConfirmClick } = useReturnToAssessorModal({
    activityUuid,
    activityHistoryUuid,
    activityHistoryRowVersion,
    isDraft,
    onClose,
    shouldRefreshActivities,
    exitActivityWizard,
  });

  return (
    <Modal show={show} title="Return to assessor" onClose={onClose}>
      <Row spacingV="ll" justify="center">
        <Column span={10}>
          <span className="body1">
            Submitting this review will notify the assessor. Once sent, all external discussions with be published and
            you will no longer be able to edit your review responses until the document is returned.
          </span>
        </Column>
      </Row>
      <Row justify="center">
        <Column span={6}>
          <div className="ReturnToAssessorModalButtons">
            <Button text="Confirm" onClick={onConfirmClick} isLoading={isLoading} />
            <Button variant="secondary" text="Cancel" onClick={onClose} />
          </div>
        </Column>
      </Row>
    </Modal>
  );
};
