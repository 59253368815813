export class OrganisationRoleConstants {
  public static readonly OWNER = "Owner";

  public static readonly ADMIN = "Admin";

  public static readonly STAFF = "Staff";

  public static readonly VVB_MANAGER = "VVB Manager";

  public static readonly VVB_AUDITOR = "VVB Auditor";

  public static readonly AM_LEAD = "AM Lead";

  public static readonly AM_Team_Member = "AM Team Member";

  public static readonly DM_Team_Member = "DM Team Member";

  public static readonly DM_LEAD = "DM Lead";
}

export type UserRole =
  | typeof OrganisationRoleConstants.OWNER
  | typeof OrganisationRoleConstants.ADMIN
  | typeof OrganisationRoleConstants.STAFF
  | typeof OrganisationRoleConstants.VVB_AUDITOR
  | typeof OrganisationRoleConstants.VVB_MANAGER
  | typeof OrganisationRoleConstants.AM_LEAD
  | typeof OrganisationRoleConstants.AM_Team_Member
  | typeof OrganisationRoleConstants.DM_Team_Member
  | typeof OrganisationRoleConstants.DM_LEAD;
