import "./ActivityReviewProgressIndicator.css";

import { LockDarkIcon } from "../../../../../../../../assets";
import { ProjectActivitiesConstants, ProjectActivityReviewsConstants } from "../../../../../../../../constants";
import { WorkflowActivityReview } from "../../../../../../../../models/shared/workflowActivityReviewType";

interface ActivityReviewProgressIndicatorProps {
  activityReview: WorkflowActivityReview;
}

const lockActivityIcon = (activityReviewStatus?: string): JSX.Element | undefined => {
  if (
    activityReviewStatus === ProjectActivityReviewsConstants.STATUS_IN_REVIEW ||
    activityReviewStatus === ProjectActivityReviewsConstants.STATUS_AWAITING_FEEDBACK ||
    activityReviewStatus === ProjectActivityReviewsConstants.STATUS_AWAITING_APPROVAL
  ) {
    return <LockDarkIcon />;
  }
  return undefined;
};

export const ActivityReviewProgressIndicator = ({
  activityReview,
}: ActivityReviewProgressIndicatorProps): JSX.Element => {
  return (
    <div className="ActivityReviewProgressIndicator">
      <div className="ActivityReviewStatusContainer">
        <div className="ActivityDisplayName">
          <h3>{activityReview.type}</h3>
          {lockActivityIcon(activityReview.status)}
        </div>
        <p className="body2">{`Status: ${activityReview.status}`}</p>
        {activityReview.activityStatus !== ProjectActivitiesConstants.STATUS_SUBMITTED_TO_CODE &&
          activityReview.activityStatus !== ProjectActivitiesConstants.STATUS_SUBMITTED_TO_REGISTRY &&
          activityReview.activityStatus !== ProjectActivitiesConstants.STATUS_APPROVED && (
            <>
              <div className="ActivityReviewStatusBar">
                <div className="ActivityReviewStatusBarFilled" style={{ flex: activityReview.completionPercentage }} />
                <div
                  className="ActivityReviewStatusBarUnfilled"
                  style={{ flex: 100 - activityReview.completionPercentage }}
                />
              </div>
              <p className="body2">{Math.round(activityReview.completionPercentage)}% complete</p>
            </>
          )}
      </div>
    </div>
  );
};
