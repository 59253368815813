export class ColorConstants {
  public static readonly BLUE = "#28245c";

  public static readonly BLUE_RGB = "40, 36, 92";

  public static readonly BLUE_80 = "#53507d";

  public static readonly BLUE_60 = "#7e7c9d";

  public static readonly BLUE_40 = "#a9a7be";

  public static readonly BLUE_20 = "#d4d3de";

  public static readonly CORAL = "#ff865d";

  public static readonly CORAL_80 = "#ff9e7d";

  public static readonly CORAL_60 = "#ffb69e";

  public static readonly CORAL_40 = "#ffcfbe";

  public static readonly CORAL_20 = "#ffe7df";

  public static readonly GREEN = "#78bb96";

  public static readonly GREEN_80 = "#93c9ab";

  public static readonly GREEN_60 = "#aed6c0";

  public static readonly GREEN_40 = "#c9e4d5";

  public static readonly GREEN_20 = "#e4f1ea";

  public static readonly YELLOW_DARK = "#D5B502";

  public static readonly YELLOW_DARK_80 = "#DEC435";

  public static readonly YELLOW = "#fce359";

  public static readonly YELLOW_80 = "#fde97a";

  public static readonly YELLOW_60 = "#fdee9c";

  public static readonly YELLOW_40 = "#fef4bd";

  public static readonly YELLOW_20 = "#fefade";

  public static readonly GREY = "#2c303b";

  public static readonly GREY_RGB = "44, 48, 59";

  public static readonly GREY_80 = "#565962";

  public static readonly GREY_60 = "#808389";

  public static readonly GREY_40 = "#abacb1";

  public static readonly GREY_20 = "#d5d6d8";

  public static readonly WHITE = "#ffffff";

  public static readonly LIGHT_GREY = "#f5f5f8";

  public static readonly RED = "#d71f2a";

  public static readonly RED_80 = "#e3626a";

  public static readonly RED_60 = "#eb8f95";

  public static readonly RED_40 = "#f3bcbf";

  public static readonly RED_20 = "#fbe9ea";

  public static readonly PINK = "#dc72b8";

  public static readonly PINK_80 = "#e38ec6";

  public static readonly PINK_60 = "#eaabd4";

  public static readonly PINK_40 = "#f1c7e3";

  public static readonly PINK_20 = "#f8e3f1";

  public static readonly PURPLE = "#a968d0";

  public static readonly PURPLE_80 = "#ba86da";

  public static readonly PURPLE_60 = "#cba4e3";

  public static readonly PURPLE_40 = "#dcc3ec";

  public static readonly PURPLE_20 = "#eee1f6";

  public static readonly I_BLUE = "#6679da";

  public static readonly I_BLUE_80 = "#8594e1";

  public static readonly I_BLUE_60 = "#a3aee9";

  public static readonly I_BLUE_40 = "#c2c9f0";

  public static readonly I_BLUE_20 = "#e0e4f8";

  public static readonly CYAN = "#69c1dd";

  public static readonly CYAN_80 = "#87cee4";

  public static readonly CYAN_60 = "#a5daeb";

  public static readonly CYAN_40 = "#c3e6f1";

  public static readonly CYAN_20 = "#e1f3f8";

  public static readonly WIZARD_GREEN = "#547d66";

  public static readonly WIZARD_LIGHT_GREEN = "#628772";

  public static readonly WIZARD_LIGHT_GREY = "#3e414b";

  public static readonly WIZARD_LIGHT_BLUE = "#3a3669";

  public static readonly CHART_COLORS = [
    ColorConstants.BLUE,
    ColorConstants.GREEN,
    ColorConstants.CORAL,
    ColorConstants.YELLOW,
    ColorConstants.BLUE_60,
    ColorConstants.GREEN_60,
    ColorConstants.CORAL_60,
    ColorConstants.YELLOW_60,
  ];

  public static readonly CHART_HIGHLIGHT_COLORS = [
    ColorConstants.BLUE_80,
    ColorConstants.GREEN_80,
    ColorConstants.CORAL_80,
    ColorConstants.YELLOW_80,
    ColorConstants.BLUE_40,
    ColorConstants.GREEN_40,
    ColorConstants.CORAL_40,
    ColorConstants.YELLOW_40,
  ];

  public static readonly CHART_COLORS_VVB = [ColorConstants.CORAL, ColorConstants.YELLOW, ColorConstants.GREEN];

  public static readonly CHART_HIGHLIGHT_COLORS_VVB = [
    ColorConstants.CORAL_80,
    ColorConstants.YELLOW_80,
    ColorConstants.GREEN_80,
  ];

  public static readonly CHART_COLORS_DISCUSSIONS = [
    ColorConstants.GREEN_80,
    ColorConstants.CORAL,
    ColorConstants.CORAL_40,
  ];

  public static readonly CHART_HIGHLIGHT_COLORS_DISCUSSIONS = [
    ColorConstants.GREEN_60,
    ColorConstants.CORAL_80,
    ColorConstants.CORAL_20,
  ];

  public static readonly CHART_GRADIENT_COLORS = [
    [ColorConstants.BLUE, ColorConstants.BLUE_20],
    [ColorConstants.GREEN, ColorConstants.GREEN_20],
    [ColorConstants.CORAL, ColorConstants.CORAL_20],
    [ColorConstants.YELLOW, ColorConstants.YELLOW_20],
    [ColorConstants.I_BLUE, ColorConstants.I_BLUE_20],
    [ColorConstants.PINK, ColorConstants.PINK_20],
    [ColorConstants.CYAN, ColorConstants.CYAN_20],
    [ColorConstants.PURPLE, ColorConstants.PURPLE_20],
    [ColorConstants.GREY, ColorConstants.GREY_20],
  ];

  public static readonly CHART_GRADIENT_HIGHLIGHT_COLORS = [
    ColorConstants.BLUE_60,
    ColorConstants.GREEN_60,
    ColorConstants.CORAL_60,
    ColorConstants.YELLOW_60,
    ColorConstants.I_BLUE_60,
    ColorConstants.PINK_60,
    ColorConstants.CYAN_60,
    ColorConstants.PURPLE_60,
    ColorConstants.GREY_60,
  ];
}
