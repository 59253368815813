import "./ReviewContent.css";

import { ActivityReviewTypeConstants, ProjectActivityReviewsConstants } from "../../../../../../constants";
import { Button, Column, CursorDataGrid, Divider, DownloadDropdown, Row } from "../../../../../../widget";
import { ActivityDefinition } from "../../../../../developer/activities/activity/types";
import {
  CompleteAssessmentModal,
  CompleteReviewModal,
  ReturnToAssessorModal,
  ReturnToDeveloperModal,
  SubmitToReviewerModal,
} from "../../../../components";
import { useReviewContent } from "./useReviewContent";

interface ReviewContentProps {
  activityDefinition: ActivityDefinition;
  activityReviewType: string;
  activityReviewStatus: string;
  activityReviewCompletionPercentage: number;
  isAssignedAuditor: boolean;
  handleMoveToStep: (targetStepKeys: string[]) => void;
}

export const ReviewContent = ({
  activityDefinition,
  activityReviewType,
  activityReviewStatus,
  activityReviewCompletionPercentage,
  isAssignedAuditor,
  handleMoveToStep,
}: ReviewContentProps): JSX.Element => {
  const {
    activityData,
    activityReview,
    activityAssessment,
    columns,
    onChange,
    dataIsLoading,
    defaultSortingCriteria,
    showDownloadActivityFilesModal,
    setShowDownloadActivityFilesModal,
    showReturnToDeveloperModal,
    setShowReturnToDeveloperModal,
    showCompleteAssessmentModal,
    setShowCompleteAssessmentModal,
    showCompleteReviewModal,
    setShowCompleteReviewModal,
    showReturnToAssessorModal,
    setShowReturnToAssessorModal,
    showSubmitToReviewerModal,
    setShowSubmitToReviewerModal,

    exitActivityReviewWizard,
  } = useReviewContent(activityDefinition, handleMoveToStep);

  const assessmentButtons = (): JSX.Element | undefined => {
    const isComplete = activityReviewCompletionPercentage === 1;
    const hasBothReviews = activityAssessment !== undefined && activityReview !== undefined;

    switch (activityReviewStatus) {
      case ProjectActivityReviewsConstants.STATUS_IN_PROGRESS:
        return (
          <Button
            text={isComplete ? "Submit" : "Return to Developer"}
            onClick={
              isComplete
                ? () => (hasBothReviews ? setShowSubmitToReviewerModal(true) : setShowCompleteAssessmentModal(true))
                : () => setShowReturnToDeveloperModal(true)
            }
            variant="primary"
          />
        );
      case ProjectActivityReviewsConstants.STATUS_FEEDBACK_RECEIVED:
        return (
          <Button
            text={isComplete ? "Submit" : "Return to Developer"}
            onClick={
              isComplete
                ? () => (hasBothReviews ? setShowSubmitToReviewerModal(true) : setShowCompleteAssessmentModal(true))
                : () => setShowReturnToDeveloperModal(true)
            }
            variant="primary"
          />
        );
      default:
        return undefined;
    }
  };

  const reviewButtons = (): JSX.Element | undefined => {
    const isComplete = activityReviewCompletionPercentage === 1;

    switch (activityReviewStatus) {
      case ProjectActivityReviewsConstants.STATUS_IN_PROGRESS:
        return (
          <Button
            text={isComplete ? "Submit" : "Return to Assessor"}
            onClick={isComplete ? () => setShowCompleteReviewModal(true) : () => setShowReturnToAssessorModal(true)}
            variant="primary"
          />
        );
      case ProjectActivityReviewsConstants.STATUS_FEEDBACK_RECEIVED:
        return (
          <Button
            text={isComplete ? "Submit" : "Return to Assessor"}
            onClick={isComplete ? () => setShowCompleteReviewModal(true) : () => setShowReturnToAssessorModal(true)}
            variant="primary"
          />
        );
      default:
        return undefined;
    }
  };

  // BR-1766
  const transitionButtons = (): JSX.Element | undefined =>
    activityReviewType === ActivityReviewTypeConstants.ASSESSMENT ? assessmentButtons() : reviewButtons();

  return (
    <>
      <Row spacingV="xl" className="ActivityReviewHeader">
        <Column span={12}>
          <h1>Review</h1>
        </Column>
      </Row>
      {activityData !== undefined && (
        <div className="ActivityReviewContent">
          <div className="ActivityReviewContentHeader">
            <Row spacingV="l">
              <Column className="ActivityReviewContentTitle" justify="center" align="start" span={6}>
                <h2>{`Version ${activityData.versionNumber} ${activityData.isCurrent ? "(Latest)" : ""}`}</h2>
              </Column>
              <Column span={4} justify="end">
                {isAssignedAuditor && transitionButtons()}
              </Column>
              <Column span={2}>
                <DownloadDropdown
                  setShowDownloadActivityFilesModal={setShowDownloadActivityFilesModal}
                  showDownloadActivityFilesModal={showDownloadActivityFilesModal}
                  activityHistoryUuid={activityData.uuid}
                  currentOnly={activityData.isCurrent}
                />
              </Column>
            </Row>
            <Row spacingV="ll">
              <Column span={2}>
                <h3>Author</h3>
                <p className="body2">{activityData.createdByUser.fullName}</p>
              </Column>
              <Column span={2}>
                <h3>Variant</h3>
                <p className="body2">{activityData.activity.variant ?? "N/A"}</p>
              </Column>
            </Row>
          </div>
          <Row spacingV="ll">
            <Column span={12}>
              <Divider type="horizontal" />
            </Column>
          </Row>
          <Row spacingV="ml">
            <Column span={12}>
              <h3>Activity documents</h3>
            </Column>
          </Row>
          <CursorDataGrid
            columns={columns}
            clientSide={false}
            filterable={false}
            onChange={onChange}
            data={[]}
            noDataMessage="No activity documents to display"
            dataIsLoading={dataIsLoading}
            defaultSortingCriteria={defaultSortingCriteria}
          />
        </div>
      )}
      {activityData !== undefined && activityAssessment !== undefined && (
        <>
          <ReturnToDeveloperModal
            show={showReturnToDeveloperModal}
            onClose={() => setShowReturnToDeveloperModal(false)}
            activityUuid={activityData.activity.uuid}
            activityStatus={activityData.activity.status}
            activityRowVersion={activityData.activity.rowVersion}
            activityAssessmentUuid={activityAssessment.activityReviewUuid}
            activityAssessmentStatus={activityAssessment.status}
            activityAssessmentRowVersion={activityAssessment.rowVersion}
            exitActivityReviewWizard={exitActivityReviewWizard}
          />
          <CompleteAssessmentModal
            show={showCompleteAssessmentModal}
            onClose={() => setShowCompleteAssessmentModal(false)}
            activityUuid={activityData.activity.uuid}
            activityStatus={activityData.activity.status}
            activityRowVersion={activityData.activity.rowVersion}
            activityAssessmentUuid={activityAssessment.activityReviewUuid}
            activityAssessmentStatus={activityAssessment.status}
            activityAssessmentRowVersion={activityAssessment.rowVersion}
            exitActivityReviewWizard={exitActivityReviewWizard}
          />
        </>
      )}
      {activityData !== undefined && activityAssessment !== undefined && activityReview !== undefined && (
        <>
          <ReturnToAssessorModal
            show={showReturnToAssessorModal}
            onClose={() => setShowReturnToAssessorModal(false)}
            activityUuid={activityData.activity.uuid}
            activityStatus={activityData.activity.status}
            activityRowVersion={activityData.activity.rowVersion}
            activityReviewUuid={activityReview.activityReviewUuid}
            activityReviewStatus={activityReview.status}
            activityReviewRowVersion={activityReview.rowVersion}
            activityAssessmentUuid={activityAssessment.activityReviewUuid}
            activityAssessmentStatus={activityAssessment.status}
            activityAssessmentRowVersion={activityAssessment.rowVersion}
            exitActivityReviewWizard={exitActivityReviewWizard}
          />
          <SubmitToReviewerModal
            show={showSubmitToReviewerModal}
            onClose={() => setShowSubmitToReviewerModal(false)}
            activityUuid={activityData.activity.uuid}
            activityStatus={activityData.activity.status}
            activityRowVersion={activityData.activity.rowVersion}
            activityReviewUuid={activityReview.activityReviewUuid}
            activityReviewStatus={activityReview.status}
            activityReviewRowVersion={activityReview.rowVersion}
            activityAssessmentUuid={activityAssessment.activityReviewUuid}
            activityAssessmentStatus={activityAssessment.status}
            activityAssessmentRowVersion={activityAssessment.rowVersion}
            exitActivityReviewWizard={exitActivityReviewWizard}
          />
          <CompleteReviewModal
            show={showCompleteReviewModal}
            onClose={() => setShowCompleteReviewModal(false)}
            activityUuid={activityData.activity.uuid}
            activityStatus={activityData.activity.status}
            activityRowVersion={activityData.activity.rowVersion}
            activityReviewUuid={activityReview.activityReviewUuid}
            activityReviewStatus={activityReview.status}
            activityReviewRowVersion={activityReview.rowVersion}
            activityAssessmentUuid={activityAssessment.activityReviewUuid}
            activityAssessmentStatus={activityAssessment.status}
            activityAssessmentRowVersion={activityAssessment.rowVersion}
            exitActivityReviewWizard={exitActivityReviewWizard}
          />
        </>
      )}
    </>
  );
};
