import "./Activities.css";

import { useLocation, useNavigate } from "react-router-dom";

import { TabsList } from "../../../models";
import { IStateOrigin } from "../../../navigators/navigateStateObjects";
import { DeveloperSingleCardWithTabsTemplate } from "../../../templates";
import { ChartsSection } from "../../../templates/components";
import { Column, Row } from "../../../widget";
import { useActivities } from "./useActivities";

const Activities = (): JSX.Element => {
  const navigate = useNavigate();
  const { activityTypeChartData, activityStatusChartData, activityCodeChartData } = useActivities();
  const { state } = useLocation();
  const stateOrigin = state?.origin as IStateOrigin;
  const getTabsList = (): TabsList => {
    const list = [
      {
        label: "In progress",
        route: "in_progress",
      },
      {
        label: "In review",
        route: "in_review",
      },
      {
        label: "Completed",
        route: "completed",
      },
    ];
    return list;
  };

  const kpis = (): JSX.Element => {
    return (
      <Row spacingV="ll">
        <Column span={12}>
          <ChartsSection
            title="At a glance"
            firstChartData={activityTypeChartData}
            firstChartTitle="Type"
            secondChartData={activityStatusChartData}
            secondChartTitle="Status"
            thirdChartData={activityCodeChartData}
            thirdChartTitle="Code"
          />
        </Column>
      </Row>
    );
  };

  return DeveloperSingleCardWithTabsTemplate({
    activeElement: "Activities",
    pageTitle: "Activities",
    tabsList: getTabsList(),
    titlePill: "",
    developer: undefined,
    developerIcon: undefined,
    workflow: kpis(),
    backButtonText: stateOrigin?.goBackText ?? "Back to dashboard",
    handleBackClick: () => navigate(stateOrigin?.from ?? `/d/dashboard`),
  });
};

export default Activities;
