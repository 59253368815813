import { Message, Thread } from "../../../models";

interface useDiscussionThreadProps {
  threads: Thread[];
}

interface useDiscussionThreadReturnData {
  firstMessagesInEachThread: Message[] | undefined;
  replies: Message[][];
}

export const useDiscussionThread = ({ threads }: useDiscussionThreadProps): useDiscussionThreadReturnData => {
  return {
    firstMessagesInEachThread: threads.map((subThread) => subThread.messages[0]),
    replies: threads.map((subThread) => subThread.messages.slice(1)),
  };
};
