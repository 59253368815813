import "./OrganisationIcon.css";

export interface OrganisationIconProps {
  organisationIcon: string;
  corners?: "Rounded" | "Square";
}

export const OrganisationIcon = ({ organisationIcon, corners = "Rounded" }: OrganisationIconProps): JSX.Element => {
  return (
    <img
      key={organisationIcon}
      aria-labelledby="icon"
      className={`OrganisationIcon ${corners}`}
      src={organisationIcon}
      alt="OrganisationIcon"
    />
  );
};
