import "../components/ComponentRow/ComponentRow.css";

import { RcFile } from "rc-upload/lib/interface";
import React from "react";

import kanaExpressionInterpreter, { ExpressionResult } from "../../../../../lib/KanaExpressionInterpreter";
import { PreviewFile } from "../../../../../models";
import { uploadActivityDocument } from "../../../../../service/activity";
import { logError } from "../../../../../service/error";
import { GetDiscussionThreadsResponse } from "../../../../../service/query";
import { Status } from "../../../../../service/Shared";
import { getErrorMessageFromCode } from "../../../../../service/ValidationErrorFormatter";
import {
  canPreviewFile,
  getFormattedDate,
  INVALID_DATE_OBJECT_STRING_SUFFIX,
  isDate,
  isValidDate,
  validateAndParseDate,
} from "../../../../../utils";
import {
  CheckboxGroup,
  CheckboxGroupProps,
  Column,
  DatePicker,
  DatePickerProps,
  InformationBox,
  InformationBoxProps,
  KanaTooltip,
  MultipleFileDownload,
  MultipleFileDownloadProps,
  MultipleFileUpload,
  RadioButtonGroup,
  RadioButtonGroupProps,
  ReadonlyText,
  ReadonlyTextProps,
  Row,
  Select,
  SelectProps,
  SingleFileUploadSwitcher,
  SingleFileUploadSwitcherProps,
  SingleFileUploadTypeConstants,
  Slider,
  SliderProps,
  TextArea,
  TextAreaProps,
  TextInput,
  TextInputProps,
  Toggle,
  ToggleProps,
  TooltipProps,
} from "../../../../../widget";
import { RadioChangeEvent } from "../../../../../widget/forms/Input/RadioButtonGroup/components";
import { ComponentRow } from "../components/ComponentRow";
import { Component, DataType, DocumentVariantSourceConstants, UploadedDocumentEnriched } from "../types";
import { downloadActivityDocumentToDesktop, previewActivityDocument } from "./ActivityDownloadUtils";
import {
  createReadonlyRepeater,
  createReadonlyTableRepeater,
  createRepeater,
  createTableRepeater,
} from "./CreateRepeaterComponentUtils";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const convertToCorrectType = (value: any, dataType: DataType): any => {
  if (Array.isArray(value)) {
    if (!value.length) {
      return undefined;
    }
    return value;
  }

  switch (dataType) {
    case "Boolean":
      if (value === "") return undefined;
      // eslint-disable-next-line eqeqeq
      return (typeof value === "boolean" && value) || value.toString().toLowerCase() === "true";
    case "Number":
      if (typeof value === "number") return value;
      if (value === "") return undefined;
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(value)) return undefined;
      return parseFloat(value);
    case "Date":
      if (isDate(value)) return value;
      if (!value) return undefined;
      if (!isValidDate(value)) return `${value}${INVALID_DATE_OBJECT_STRING_SUFFIX}`;
      return validateAndParseDate(value);
    case "String":
      if (typeof value === "string") return value === "" ? undefined : value;
      return value ? value.toString() : value;
    default:
      // for File - assume the component returns the correct type
      return value;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const convertExpressionResultToCorrectType = (value: ExpressionResult, dataType: DataType): any => {
  if (value === null) return undefined;
  switch (dataType) {
    case "Boolean":
      if (value === "") return undefined;
      return (typeof value === "boolean" && value) || value.toString().toLowerCase() === "true";
    case "Number":
      if (typeof value === "number") return value;
      if (value === "") return undefined;
      if (typeof value === "boolean") return undefined;
      if (value instanceof Date) return undefined;
      return parseFloat(value);
    case "Date":
      if (typeof value === "boolean") return undefined;
      if (!value) return undefined;
      // eslint-disable-next-line no-case-declarations
      let dateValue = value;
      if (typeof dateValue === "number") dateValue = new Date(value);
      if (isDate(dateValue)) return dateValue;
      return validateAndParseDate(dateValue as string);
    case "String":
      if (typeof value === "string") return value === "" ? undefined : value;
      return value ? value.toString() : value;
    default:
      return value;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const convertValueToText = (value: any): string => {
  if (isDate(value)) return getFormattedDate(value) || "";
  if (value === true) return "Yes";
  if (value === false) return "No";
  if (value === null || value === undefined) return "";
  return value.toString();
};

export const computeComponentKey = (dataPath: string, compKey: string): string => `${dataPath}-${compKey}`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const maybeAddTooltipToProps = (componentProperties: any, props: any): any => {
  const modifiedProps = props;

  if (componentProperties.tooltip) {
    modifiedProps.tooltip = React.createElement(KanaTooltip, {
      tooltipText: props.tooltip,
      allowDangerousHtml: true,
    } as TooltipProps);
  }

  return modifiedProps;
};

export const createReadonlyText = (
  component: Component,
  discussions: GetDiscussionThreadsResponse[] | undefined,
  dataPath: string,
  defaultExpressionResult?: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any,
  onChange?: (value: string) => void,
  rowWrapKey?: string
): JSX.Element => {
  const props = {
    ...component.componentProperties,
  } as ReadonlyTextProps;

  props.allowLabelDangerousHtml = true;
  props.value = value;

  if (component.componentProperties.tooltip) {
    props.tooltipHeader = "";
    props.tooltipText = component.componentProperties.tooltip;
    props.allowTooltipDangerousHtml = true;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete props.tooltip;
  }

  if (
    defaultExpressionResult !== undefined &&
    onChange &&
    convertValueToText(defaultExpressionResult) !==
      convertValueToText(props.value ?? component.componentProperties.defaultValue)
  ) {
    onChange(defaultExpressionResult);
    props.value = convertValueToText(defaultExpressionResult);
  } else {
    props.value = convertValueToText(props.value ?? component.componentProperties.defaultValue);
  }

  const reactEl = React.createElement(ReadonlyText, props);

  if (rowWrapKey) {
    return (
      <ComponentRow
        key={rowWrapKey}
        rowWrapKey={rowWrapKey}
        id={rowWrapKey}
        reactEl={reactEl}
        discussions={discussions}
        componentKey={component.key}
        dataPath={dataPath}
      />
    );
  }

  return reactEl;
};

export const createParagraph = (component: Component, rowWrapKey?: string): JSX.Element => {
  const props = {
    ...component.componentProperties,
  };

  const reactEl = React.createElement(
    () => (
      // eslint-disable-next-line react/no-danger
      <p dangerouslySetInnerHTML={{ __html: component.componentProperties.label }} />
    ),
    props
  );

  if (rowWrapKey) {
    return (
      <Row key={rowWrapKey} id={rowWrapKey} spacingV="ll" className="UneditableComponentRow">
        <Column span={6}>{reactEl}</Column>
      </Row>
    );
  }

  return reactEl;
};

export const createInformationBox = (component: Component, rowWrapKey?: string): JSX.Element => {
  const props = {
    ...component.componentProperties,
  } as InformationBoxProps;

  props.variant = "info";

  // eslint-disable-next-line react/no-danger
  props.children = React.createElement(() => (
    // eslint-disable-next-line react/no-danger
    <p dangerouslySetInnerHTML={{ __html: component.componentProperties.label }} />
  ));

  const reactEl = React.createElement(InformationBox, props);

  if (rowWrapKey) {
    return (
      <Row key={rowWrapKey} id={rowWrapKey} spacingV="ll" className="UneditableComponentRow">
        <Column span={6}>{reactEl}</Column>
      </Row>
    );
  }

  return reactEl;
};

export const createTextInput = (
  component: Component,
  discussions: GetDiscussionThreadsResponse[] | undefined,
  dataPath: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any,
  onChange?: (value: string) => void,
  rowWrapKey?: string
): JSX.Element => {
  let props = {
    ...component.componentProperties,
  } as TextInputProps; // clone object to avoid mutating arguments

  props = maybeAddTooltipToProps(component.componentProperties, props);
  props.allowLabelDangerousHtml = true;
  props.debounceMilliseconds = 350;

  if (onChange) {
    props.onChange = onChange;
  }

  const convertedValue = convertValueToText(value);

  if (component.dataType === "Date" && convertedValue && !isValidDate(convertedValue)) {
    // Setting value to invalid date string so we keep the user input instead of rendering NaN/NaN/NaN
    const [invalidDateValue] = convertedValue.split(INVALID_DATE_OBJECT_STRING_SUFFIX);
    props.value = invalidDateValue;
  } else {
    props.value = convertedValue;
  }

  const reactEl = React.createElement(TextInput, props);

  if (rowWrapKey) {
    return (
      <ComponentRow
        key={rowWrapKey}
        id={rowWrapKey}
        rowWrapKey={rowWrapKey}
        discussions={discussions}
        reactEl={reactEl}
        componentKey={component.key}
        dataPath={dataPath}
      />
    );
  }

  return reactEl;
};

export const createTextArea = (
  component: Component,
  discussions: GetDiscussionThreadsResponse[] | undefined,
  dataPath: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any,
  onChange?: (value: string) => void,
  rowWrapKey?: string
): JSX.Element => {
  let props = {
    ...component.componentProperties,
  } as TextAreaProps;

  props.displayCharacterCount = true;
  props.debounceMilliseconds = 350;
  props.allowLabelDangerousHtml = true;
  props = maybeAddTooltipToProps(component.componentProperties, props);
  props.value = convertValueToText(value);

  if (onChange) {
    props.onChange = onChange;
  }

  const reactEl = React.createElement(TextArea, props);

  if (rowWrapKey) {
    return (
      <ComponentRow
        key={rowWrapKey}
        id={rowWrapKey}
        rowWrapKey={rowWrapKey}
        reactEl={reactEl}
        discussions={discussions}
        componentKey={component.key}
        dataPath={dataPath}
      />
    );
  }

  return reactEl;
};

export const createSlider = (
  component: Component,
  discussions: GetDiscussionThreadsResponse[] | undefined,
  dataPath: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any,
  onChange?: (v: number | number[]) => void,
  rowWrapKey?: string
): JSX.Element => {
  const props = {
    ...component.componentProperties,
  } as SliderProps;

  props.allowLabelDangerousHtml = true;
  props.value = value;

  if (onChange) {
    props.onChange = onChange;
  }

  const reactEl = React.createElement(Slider, props);

  if (rowWrapKey) {
    return (
      <ComponentRow
        key={rowWrapKey}
        id={rowWrapKey}
        rowWrapKey={rowWrapKey}
        reactEl={reactEl}
        discussions={discussions}
        componentKey={component.key}
        dataPath={dataPath}
      />
    );
  }

  return reactEl;
};

export const createToggle = (
  component: Component,
  discussions: GetDiscussionThreadsResponse[] | undefined,
  dataPath: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any,
  onChange?: (v: boolean) => void,
  rowWrapKey?: string
): JSX.Element => {
  const props = {
    ...component.componentProperties,
  } as ToggleProps;

  props.allowLabelDangerousHtml = true;
  props.value = value;

  if (onChange) {
    props.onChange = onChange;
  }

  const reactEl = React.createElement(Toggle, props);

  if (rowWrapKey) {
    return (
      <ComponentRow
        key={rowWrapKey}
        id={rowWrapKey}
        rowWrapKey={rowWrapKey}
        reactEl={reactEl}
        discussions={discussions}
        componentKey={component.key}
        dataPath={dataPath}
      />
    );
  }

  return reactEl;
};

export const createRadioButtonGroup = (
  component: Component,
  discussions: GetDiscussionThreadsResponse[] | undefined,
  dataPath: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any,
  onChange?: (e: RadioChangeEvent) => void,
  rowWrapKey?: string
): JSX.Element => {
  let props = {
    ...component.componentProperties,
  } as RadioButtonGroupProps;

  props = maybeAddTooltipToProps(component.componentProperties, props);
  props.allowLabelDangerousHtml = true;
  props.data = Object.keys(component.componentProperties.data).map((k) => ({
    key: k,
    value: component.componentProperties.data[k],
  }));
  props.value = value?.toString();

  if (onChange) {
    props.onChange = onChange;
  }

  const reactEl = React.createElement(RadioButtonGroup, props);

  if (rowWrapKey) {
    return (
      <ComponentRow
        key={rowWrapKey}
        id={rowWrapKey}
        rowWrapKey={rowWrapKey}
        reactEl={reactEl}
        discussions={discussions}
        componentKey={component.key}
        dataPath={dataPath}
      />
    );
  }

  return reactEl;
};

export const createCheckBox = (
  component: Component,
  discussions: GetDiscussionThreadsResponse[] | undefined,
  dataPath: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any,
  onChange?: (values: string[]) => void,
  rowWrapKey?: string
): JSX.Element => {
  let props = {
    ...component.componentProperties,
  } as CheckboxGroupProps;

  props = maybeAddTooltipToProps(component.componentProperties, props);
  props.allowLabelDangerousHtml = true;
  props.value = value ?? [];
  props.checkboxesData = component.componentProperties.data ?? {};

  if (onChange) {
    props.onChange = onChange;
  }

  const reactEl = React.createElement(CheckboxGroup, props);

  if (rowWrapKey) {
    return (
      <ComponentRow
        key={rowWrapKey}
        id={rowWrapKey}
        rowWrapKey={rowWrapKey}
        reactEl={reactEl}
        discussions={discussions}
        componentKey={component.key}
        dataPath={dataPath}
      />
    );
  }

  return reactEl;
};

export const createSelect = (
  component: Component,
  discussions: GetDiscussionThreadsResponse[] | undefined,
  dataPath: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any,
  onChange?: (value: string) => void,
  rowWrapKey?: string
): JSX.Element => {
  const props = {
    ...component.componentProperties,
  } as SelectProps;

  props.allowLabelDangerousHtml = true;
  props.data = Object.keys(component.componentProperties.data).map((k) => ({
    key: k,
    value: component.componentProperties.data[k],
  }));
  props.value = convertValueToText(value);

  if (onChange) {
    props.onChange = onChange;
  }

  const reactEl = React.createElement(Select, props);

  if (rowWrapKey) {
    return (
      <ComponentRow
        key={rowWrapKey}
        rowWrapKey={rowWrapKey}
        id={rowWrapKey}
        reactEl={reactEl}
        discussions={discussions}
        componentKey={component.key}
        dataPath={dataPath}
      />
    );
  }

  return reactEl;
};

export const createDateInput = (
  component: Component,
  discussions: GetDiscussionThreadsResponse[] | undefined,
  dataPath: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any,
  onChange?: (date: Date | undefined) => void,
  rowWrapKey?: string
): JSX.Element => {
  let props = {
    ...component.componentProperties,
  } as DatePickerProps;

  props = maybeAddTooltipToProps(component.componentProperties, props);
  props.allowLabelDangerousHtml = true;
  props.value = value;

  if (onChange) {
    props.onChange = onChange;
  }

  const reactEl = React.createElement(DatePicker, props);

  if (rowWrapKey) {
    return (
      <ComponentRow
        key={rowWrapKey}
        id={rowWrapKey}
        rowWrapKey={rowWrapKey}
        reactEl={reactEl}
        discussions={discussions}
        componentKey={component.key}
        dataPath={dataPath}
      />
    );
  }

  return reactEl;
};

export const createSingleFileUpload = (
  component: Component,
  discussions: GetDiscussionThreadsResponse[] | undefined,
  dataPath: string,
  activityUuid: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  expressionContext: any,
  singleDocumentSetter: (
    document?: UploadedDocumentEnriched,
    deletedActivityDefinitionVersionDocumentTypeUuidAndVariant?: string
  ) => void,
  activityDocuments: UploadedDocumentEnriched[],
  stepDocuments: UploadedDocumentEnriched[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  repeaterValues?: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any,
  onChange?: (value: string | undefined) => void,
  rowWrapKey?: string
): JSX.Element => {
  const { activityDefinitionVersionDocumentTypeUuid, documentVariant, documentVariantSource, ...rest } =
    component.componentProperties;
  let props = { ...rest };

  props.maxFileSize = props.maxFileSize || 10;
  props.accept = component.componentProperties.validFileExtensions;
  props = maybeAddTooltipToProps(component.componentProperties, props);
  props.allowLabelDangerousHtml = true;
  props.shouldConfirmDeletion = true;
  props.deleteFileModalData = {
    title: "Delete document?",
    text: [
      "If you delete this document it will be removed from everywhere within the activity wizard. If you wish to replace the document from the current field only, select 'Upload a new version'.",
      "Are you sure you want to delete this document?",
    ],
    confirmText: "Delete",
    cancelText: "Cancel",
  };

  if (
    documentVariantSource &&
    (documentVariantSource === DocumentVariantSourceConstants.Expression ||
      documentVariantSource === DocumentVariantSourceConstants.Hardcoded ||
      documentVariantSource === DocumentVariantSourceConstants.Filename ||
      documentVariantSource === DocumentVariantSourceConstants.HardcodedAndIndex)
  ) {
    // list other documents of the same type in the dropdown
    props.inputFiles = activityDocuments
      .filter(
        (ad) =>
          ad.activityDefinitionVersionDocumentTypeUuidAndVariant.startsWith(
            `${activityDefinitionVersionDocumentTypeUuid}|`
            // KAN-2918: remove uploaded files
          ) && ad.activityDocumentHistoryUuid !== null
      )
      // checked for null in the filter above
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      .map((d) => ({ mimeType: d.mimeType, uuid: d.activityDocumentHistoryUuid!, url: "", filename: d.filename }));
    props.type = SingleFileUploadTypeConstants.Dropdown;
  } else {
    props.type = SingleFileUploadTypeConstants.Replace;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const processDefaultValue = (activityDefinitionVersionDocumentTypeUuidAndVariant: string | undefined): any => {
    const previousUpload =
      activityDefinitionVersionDocumentTypeUuidAndVariant !== undefined
        ? stepDocuments.find(
            (sd) =>
              sd.activityDefinitionVersionDocumentTypeUuidAndVariant ===
                // KAN-2918: remove uploaded files
                activityDefinitionVersionDocumentTypeUuidAndVariant && sd.activityDocumentHistoryUuid !== null
          )
        : undefined;
    return previousUpload === undefined
      ? undefined
      : {
          // checked for null in the `find` above
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          uuid: previousUpload.activityDocumentHistoryUuid!,
          filename: previousUpload.filename,
          mimeType: previousUpload.mimeType,
          url: "",
        };
  };

  if (repeaterValues) {
    props.repeaterTransformDefaultValue = processDefaultValue;
  } else {
    props.value = processDefaultValue(value);
  }

  props.onPreviewFile = (file: PreviewFile) => {
    if (canPreviewFile(file.mimeType)) {
      previewActivityDocument(file.uuid);
    } else {
      downloadActivityDocumentToDesktop(file.uuid);
    }
  };

  props.onFileUpload = async (file: RcFile, repeaterIndex?: number): Promise<PreviewFile> => {
    let variant: string | null | undefined = documentVariant; // could be a hardcoded value, an expression or undefined

    if (documentVariantSource === "Expression") {
      variant = kanaExpressionInterpreter(
        documentVariant,
        expressionContext,
        undefined,
        repeaterIndex === undefined ? undefined : [repeaterIndex]
      ) as string | null;
      if (variant == null) {
        return Promise.reject(
          getErrorMessageFromCode("VALIDATION_DOCUMENT_UPLOAD_VARIANT_REQUIRED", {
            property: component.componentProperties.label,
          })
        );
      }
    }

    if (documentVariantSource === "Filename") {
      variant = file.name;
    }

    if (documentVariantSource === DocumentVariantSourceConstants.HardcodedAndIndex) {
      variant = `${variant} ${(repeaterIndex ?? 0) + 1}`;
    }
    const uploadRes = await uploadActivityDocument({
      activityDefinitionVersionDocumentTypeUuid,
      activityUuid,
      file,
      variant: variant || null,
    });

    if (uploadRes.status === Status.Success && uploadRes.data) {
      return {
        // uploaded files always have a filename and mimeType -> non-null assertion should be OK.
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        filename: uploadRes.data.file.filename!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        mimeType: uploadRes.data.file.mimeType!,
        uuid: uploadRes.data.uuid,
        // use this data in the `onChange` event
        url: `${uploadRes.data.uuid}|${activityDefinitionVersionDocumentTypeUuid}|${variant || ""}`,
      };
    }

    return Promise.reject(uploadRes?.errors?.[0]?.message);
  };

  const onInternalChange = (file: PreviewFile | undefined, repeaterIndex?: number): string | undefined => {
    if (file === undefined) {
      let deletedFileValue = value;

      if (repeaterIndex !== undefined && repeaterValues) {
        deletedFileValue = repeaterValues[repeaterIndex][props.key];
      }

      singleDocumentSetter(undefined, deletedFileValue);

      if (onChange) {
        onChange(undefined);
      }
      return undefined;
    }
    let activityDefinitionVersionDocumentTypeUuidAndVariant: string | undefined;
    if (file?.url?.indexOf("|") > -1) {
      // specially formatted URL for newly uploaded files from the 'onFileUpload' event above
      const spl = file.url.split("|");
      const [activityDocumentHistoryUuid] = spl;
      activityDefinitionVersionDocumentTypeUuidAndVariant = `${spl[1]}|${spl[2] || ""}`;
      singleDocumentSetter(
        {
          activityDefinitionVersionDocumentTypeUuidAndVariant,
          activityDocumentHistoryUuid,
          mimeType: file.mimeType,
          filename: file.filename,
        },
        undefined
      );
    } else {
      activityDefinitionVersionDocumentTypeUuidAndVariant = activityDocuments.find(
        (sd) => sd.activityDocumentHistoryUuid === file.uuid
      )?.activityDefinitionVersionDocumentTypeUuidAndVariant;
      if (activityDefinitionVersionDocumentTypeUuidAndVariant === undefined)
        throw new Error(
          `Couldn't find activityDocumentHistory with uuid ${file.uuid} in the previously uploaded documents`
        );
    }

    if (onChange) {
      onChange(activityDefinitionVersionDocumentTypeUuidAndVariant);
      return undefined;
    }

    return activityDefinitionVersionDocumentTypeUuidAndVariant;
  };

  if (onChange) {
    props.onChange = onInternalChange;
  } else {
    props.repeaterTransformSavedValue = onInternalChange;
  }

  const reactEl = React.createElement(SingleFileUploadSwitcher, props as SingleFileUploadSwitcherProps);

  if (rowWrapKey) {
    return (
      <ComponentRow
        key={rowWrapKey}
        id={rowWrapKey}
        rowWrapKey={rowWrapKey}
        reactEl={reactEl}
        discussions={discussions}
        componentKey={component.key}
        dataPath={dataPath}
      />
    );
  }

  return reactEl;
};

export const createMultiFileUpload = (
  component: Component,
  discussions: GetDiscussionThreadsResponse[] | undefined,
  dataPath: string,
  activityUuid: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  multiDocumentSetter: (key: string, documents: UploadedDocumentEnriched[]) => void,
  stepDocuments: UploadedDocumentEnriched[],
  repeaterKey?: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any,
  onChange?: (value: string[] | undefined) => void,
  rowWrapKey?: string
): JSX.Element => {
  const { activityDefinitionVersionDocumentTypeUuid, ...rest } = component.componentProperties;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const props = { ...rest } as any;

  props.maxFileSize = props.maxFileSize || 10;
  props.title = component?.componentProperties?.label;
  props.allowLabelDangerousHtml = true;
  props.accept = component.componentProperties.validFileExtensions;
  props.isShowImageThumbnails = false;

  if (component.componentProperties.documentVariantSource !== "Filename")
    throw new Error(
      `component data path '${dataPath}', the multiFileUpload component only supports a documentVariantSource of 'Filename', but received '${component.componentProperties.documentVariantSource}'`
    );

  const processDefaultValue = (
    activityDefinitionVersionDocumentTypeUuidAndVariantArr: string[] | undefined
  ): Partial<UploadedDocumentEnriched>[] | undefined => {
    const previousUploads =
      activityDefinitionVersionDocumentTypeUuidAndVariantArr !== undefined &&
      activityDefinitionVersionDocumentTypeUuidAndVariantArr !== null
        ? stepDocuments.filter(
            (sd) =>
              activityDefinitionVersionDocumentTypeUuidAndVariantArr.includes(
                sd.activityDefinitionVersionDocumentTypeUuidAndVariant
              ) &&
              // KAN-2918: remove uploaded files
              sd.activityDocumentHistoryUuid !== null
          )
        : undefined;

    return previousUploads === undefined
      ? undefined
      : previousUploads.map((previousUpload) => ({
          // checked for null in the filter statement above
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          uuid: previousUpload.activityDocumentHistoryUuid!,
          filename: previousUpload.filename,
          mimeType: previousUpload.mimeType,
          url: "",
        }));
  };

  if (repeaterKey) {
    props.repeaterTransformDefaultValue = processDefaultValue;
  } else {
    props.value = processDefaultValue(value);
  }

  props.onPreviewFile = (file: PreviewFile) => {
    if (canPreviewFile(file.mimeType)) {
      previewActivityDocument(file.uuid);
    } else {
      downloadActivityDocumentToDesktop(file.uuid);
    }
  };

  props.onFileUpload = async (file: RcFile): Promise<PreviewFile> => {
    const uploadRes = await uploadActivityDocument({
      activityDefinitionVersionDocumentTypeUuid,
      activityUuid,
      file,
      variant: file.name,
    });

    if (uploadRes.status === Status.Success && uploadRes.data) {
      return {
        // uploaded files always have a filename and mimeType -> non-null assertion should be OK.
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        filename: uploadRes.data.file.filename!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        mimeType: uploadRes.data.file.mimeType!,
        uuid: uploadRes.data.uuid,
        url: "",
      };
    }

    return Promise.reject(uploadRes?.errors?.[0]?.message);
  };

  const onInternalChange = (previewFiles: PreviewFile[], repeaterIndex?: number): string[] | undefined => {
    const ret = previewFiles.map(
      (f) =>
        ({
          activityDefinitionVersionDocumentTypeUuidAndVariant: `${activityDefinitionVersionDocumentTypeUuid}|${f.filename}`,
          activityDocumentHistoryUuid: f.uuid,
          mimeType: f.mimeType,
          filename: f.filename,
        }) as UploadedDocumentEnriched
    );

    if (repeaterKey && repeaterIndex !== undefined) {
      multiDocumentSetter(`${repeaterKey}|${repeaterIndex}|${component.key}`, ret);
    } else {
      multiDocumentSetter(component.key, ret);
    }

    if (onChange) {
      onChange(ret.map((d) => d.activityDefinitionVersionDocumentTypeUuidAndVariant));
      return undefined;
    }

    return ret.map((d) => d.activityDefinitionVersionDocumentTypeUuidAndVariant);
  };

  if (onChange) {
    props.onChange = onInternalChange;
  } else {
    props.repeaterTransformSavedValue = onInternalChange;
  }

  const reactEl = React.createElement(MultipleFileUpload, props);

  if (rowWrapKey) {
    return (
      <ComponentRow
        key={rowWrapKey}
        rowWrapKey={rowWrapKey}
        id={rowWrapKey}
        reactEl={reactEl}
        discussions={discussions}
        componentKey={component.key}
        dataPath={dataPath}
      />
    );
  }

  return reactEl;
};

const createViewReadonlyText = (
  component: Component,
  discussions: GetDiscussionThreadsResponse[] | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any,
  dataPath: string,
  isViewMode: boolean,
  isForRepeater?: boolean,
  repeaterIdx?: number
): JSX.Element => {
  const props = {
    ...component.componentProperties,
  } as ReadonlyTextProps; // clone object to avoid mutating arguments

  props.value = convertValueToText(values[component.key] ?? component.componentProperties.defaultValue); // TODO: handle default value expressions
  props.allowLabelDangerousHtml = true;

  if (component.componentProperties.tooltip) {
    props.tooltipHeader = "";
    props.tooltipText = component.componentProperties.tooltip;
    props.allowTooltipDangerousHtml = true;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete props.tooltip;
  }

  const key = computeComponentKey(dataPath, component.key);

  return (
    <ComponentRow
      key={key}
      rowWrapKey={key}
      id={key}
      reactEl={React.createElement(ReadonlyText, props)}
      discussions={discussions}
      componentKey={component.key}
      dataPath={dataPath}
      isForViewMode={isViewMode}
      isForRepeater={isForRepeater}
      repeaterIndex={repeaterIdx}
    />
  );
};

const createReadonlyTextReverseKey = (
  component: Component,
  discussions: GetDiscussionThreadsResponse[] | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any,
  dataPath: string,
  isViewMode: boolean,
  isForRepeater?: boolean,
  repeaterIdx?: number
): JSX.Element => {
  const props = {
    ...component.componentProperties,
  } as ReadonlyTextProps; // clone object to avoid mutating arguments
  props.value = convertValueToText(
    component.componentProperties.data[values[component.key]] ?? component.componentProperties.defaultValue
  );
  props.allowLabelDangerousHtml = true;
  props.allowTooltipDangerousHtml = true;

  const key = computeComponentKey(dataPath, component.key);

  return (
    <ComponentRow
      key={key}
      rowWrapKey={key}
      id={key}
      reactEl={React.createElement(ReadonlyText, props)}
      discussions={discussions}
      componentKey={component.key}
      dataPath={dataPath}
      isForViewMode={isViewMode}
      isForRepeater={isForRepeater}
      repeaterIndex={repeaterIdx}
    />
  );
};

const createReadonlyCheckBox = (
  component: Component,
  discussions: GetDiscussionThreadsResponse[] | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any,
  dataPath: string,
  isViewMode: boolean,
  isForRepeater?: boolean,
  repeaterIdx?: number
): JSX.Element => {
  const checkboxValues = values[component.key] ?? [];
  const checkboxes = component.componentProperties.data ?? {};
  const props = {
    ...component.componentProperties,
    allowLabelDangerousHtml: true,
    allowTooltipDangerousHtml: true,
    value:
      checkboxValues
        .map((value: string) => Object.entries(checkboxes).find(([dataValue]) => dataValue === value)?.[1] ?? "")
        .filter(Boolean)
        .join(", ") ?? "",
  } as ReadonlyTextProps; // clone object to avoid mutating arguments

  const key = computeComponentKey(dataPath, component.key);

  return (
    <ComponentRow
      key={key}
      rowWrapKey={key}
      id={key}
      reactEl={React.createElement(ReadonlyText, props)}
      discussions={discussions}
      componentKey={component.key}
      dataPath={dataPath}
      isForViewMode={isViewMode}
      isForRepeater={isForRepeater}
      repeaterIndex={repeaterIdx}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createReadonlyMultipleFileDownload = (
  component: Component,
  discussions: GetDiscussionThreadsResponse[] | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any,
  activityDocuments: UploadedDocumentEnriched[],
  dataPath: string,
  isViewMode: boolean,
  isForRepeater?: boolean,
  repeaterIdx?: number
): JSX.Element => {
  const props = {
    ...component.componentProperties,
    allowLabelDangerousHtml: true,
    title: component?.componentProperties?.label,
    inputFiles: [] as PreviewFile[],
    downloadHandler: (previewFile) => downloadActivityDocumentToDesktop(previewFile.uuid),
  } as MultipleFileDownloadProps; // clone object to avoid mutating arguments

  props.onPreviewFile = (file: PreviewFile) => {
    previewActivityDocument(file.uuid);
  };

  if (Array.isArray(values[component.key])) {
    props.inputFiles = activityDocuments
      .filter(
        (ad) =>
          values[component.key].includes(ad.activityDefinitionVersionDocumentTypeUuidAndVariant) &&
          // KAN-2918: remove uploaded files
          ad.activityDocumentHistoryUuid !== null
      )
      // this is checked in the line above
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      .map((d) => ({ mimeType: d.mimeType, uuid: d.activityDocumentHistoryUuid!, url: "", filename: d.filename }));
  }

  const key = computeComponentKey(dataPath, component.key);

  return (
    <ComponentRow
      key={key}
      rowWrapKey={key}
      id={key}
      reactEl={React.createElement(MultipleFileDownload, props)}
      discussions={discussions}
      componentKey={component.key}
      dataPath={dataPath}
      isForViewMode={isViewMode}
      isForRepeater={isForRepeater}
      repeaterIndex={repeaterIdx}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createReadonlySingleFileDownload = (
  component: Component,
  discussions: GetDiscussionThreadsResponse[] | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any,
  activityDocuments: UploadedDocumentEnriched[],
  dataPath: string,
  isViewMode: boolean,
  isForRepeater?: boolean,
  repeaterIdx?: number
): JSX.Element => {
  const props = {
    ...component.componentProperties,
    title: component?.componentProperties?.label,
    allowLabelDangerousHtml: true,
    inputFiles: [] as PreviewFile[],
    downloadHandler: (previewFile) => downloadActivityDocumentToDesktop(previewFile.uuid),
  } as MultipleFileDownloadProps; // clone object to avoid mutating arguments

  if (values[component.key]) {
    const activityDocument = activityDocuments.find(
      (ad) =>
        values[component.key] === ad.activityDefinitionVersionDocumentTypeUuidAndVariant &&
        // KAN-2918: remove uploaded files
        ad.activityDocumentHistoryUuid !== null
    );
    if (activityDocument)
      props.inputFiles = [
        {
          filename: activityDocument.filename,
          mimeType: activityDocument.mimeType,
          // checked for null in the `find` above
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          uuid: activityDocument.activityDocumentHistoryUuid!,
          url: "",
        },
      ];
  }

  props.onPreviewFile = (file: PreviewFile) => {
    previewActivityDocument(file.uuid);
  };

  const key = computeComponentKey(dataPath, component.key);

  return (
    <ComponentRow
      key={key}
      rowWrapKey={key}
      id={key}
      reactEl={React.createElement(MultipleFileDownload, props)}
      discussions={discussions}
      componentKey={component.key}
      dataPath={dataPath}
      isForViewMode={isViewMode}
      isForRepeater={isForRepeater}
      repeaterIndex={repeaterIdx}
    />
  );
};

export const createEditComponent = (
  component: Component,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any,
  discussions: GetDiscussionThreadsResponse[] | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setter: (value: any, key: string) => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  expressionContext: any,
  singleDocumentSetter: (
    document?: UploadedDocumentEnriched,
    deletedActivityDefinitionVersionDocumentTypeUuidAndVariant?: string
  ) => void,
  multipleDocumentsSetter: (key: string, documents: UploadedDocumentEnriched[]) => void,
  activityDocuments: UploadedDocumentEnriched[],
  stepDocuments: UploadedDocumentEnriched[],
  activityUuid: string,
  dataPath: string
): JSX.Element | null => {
  if (
    component.conditionExpression !== undefined &&
    !kanaExpressionInterpreter(component.conditionExpression, expressionContext, values[component.key])
  )
    return null;

  const rowWrapKey = computeComponentKey(dataPath, component.key);

  switch (component.component) {
    case "ReadonlyText":
      // eslint-disable-next-line no-case-declarations
      const defaultExpressionResult =
        component.defaultValueExpression !== undefined &&
        convertExpressionResultToCorrectType(
          kanaExpressionInterpreter(component.defaultValueExpression, expressionContext, values[component.key]),
          component.dataType
        );

      return createReadonlyText(
        component,
        discussions,
        dataPath,
        defaultExpressionResult,
        values[component.key],
        (value: string) => {
          setter(value, component.key);
        },
        rowWrapKey
      );
    case "Paragraph":
      return createParagraph(component, rowWrapKey);
    case "InformationBox":
      return createInformationBox(component, rowWrapKey);
    case "TextInput":
      return createTextInput(
        component,
        discussions,
        dataPath,
        values[component.key],
        (value: string) => {
          setter(convertToCorrectType(value, component.dataType), component.key);
        },
        rowWrapKey
      );
    case "TextArea":
      return createTextArea(
        component,
        discussions,
        dataPath,
        values[component.key],
        (value: string) => {
          setter(convertToCorrectType(value, component.dataType), component.key);
        },
        rowWrapKey
      );
    case "Slider":
      return createSlider(
        component,
        discussions,
        dataPath,
        values[component.key],
        (value: number | number[]) => {
          setter(convertToCorrectType(value, component.dataType), component.key);
        },
        rowWrapKey
      );
    case "Toggle":
      return createToggle(
        component,
        discussions,
        dataPath,
        values[component.key],
        (value: boolean) => {
          setter(convertToCorrectType(value, component.dataType), component.key);
        },
        rowWrapKey
      );
    case "RadioButtonGroup":
      return createRadioButtonGroup(
        component,
        discussions,
        dataPath,
        values[component.key],
        (e: RadioChangeEvent) => {
          setter(convertToCorrectType(e.target.value, component.dataType), component.key);
        },
        rowWrapKey
      );
    case "CheckBox":
      return createCheckBox(
        component,
        discussions,
        dataPath,
        values[component.key],
        (value: string[]) => {
          setter(convertToCorrectType(value, component.dataType), component.key);
        },
        rowWrapKey
      );
    case "Select":
      return createSelect(
        component,
        discussions,
        dataPath,
        values[component.key],
        (value: string) => {
          setter(convertToCorrectType(value, component.dataType), component.key);
        },
        rowWrapKey
      );
    case "DateInput":
      return createDateInput(
        component,
        discussions,
        dataPath,
        values[component.key],
        (value: Date | undefined) => {
          setter(convertToCorrectType(value, component.dataType), component.key);
        },
        rowWrapKey
      );
    case "SingleFileUpload":
      return createSingleFileUpload(
        component,
        discussions,
        dataPath,
        activityUuid,
        expressionContext,
        singleDocumentSetter,
        activityDocuments,
        stepDocuments,
        undefined,
        values[component.key],
        (value: string | undefined) => {
          setter(value, component.key);
        },
        rowWrapKey
      );
    case "MultiFileUpload":
      return createMultiFileUpload(
        component,
        discussions,
        dataPath,
        activityUuid,
        multipleDocumentsSetter,
        stepDocuments,
        undefined,
        values[component.key],
        (value: string[] | undefined) => {
          setter(value, component.key);
        },
        rowWrapKey
      );
    case "Repeater":
      return createRepeater(
        component,
        discussions,
        values,
        setter,
        expressionContext,
        singleDocumentSetter,
        multipleDocumentsSetter,
        activityDocuments,
        stepDocuments,
        activityUuid,
        dataPath
      );
    case "TableRepeater":
      return createTableRepeater(component, discussions, dataPath, values, setter, expressionContext);
    default:
      logError({ error: `There's no implementation for ${component.component} in the Editable Activity` });
      return <div> Not implemented {component.component}</div>;
  }
};

export const createViewComponent = (
  component: Component,
  discussions: GetDiscussionThreadsResponse[] | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  expressionContext: any,
  activityDocuments: UploadedDocumentEnriched[],
  dataPath: string,
  repeaterIndexes?: number[]
): JSX.Element | null => {
  if (
    values[component.key] === undefined &&
    component.component !== "Repeater" &&
    component.component !== "TableRepeater"
  ) {
    // `component.component !== "Repeater"` was added so that repeaters without values will always have their children displayed in view mode (since children also do not have values, their labels will be visible)
    // If `values[component.key]`, the call to `kanaExpressionInterpreter` will fail with an exception

    return createViewReadonlyText(component, discussions, values, dataPath, true);
  }

  if (
    component.conditionExpression !== undefined &&
    !kanaExpressionInterpreter(component.conditionExpression, expressionContext, values[component.key], repeaterIndexes)
  )
    return null;

  const rowWrapKey = computeComponentKey(dataPath, component.key);

  switch (component.component) {
    case "Paragraph":
      return createParagraph(component, rowWrapKey);
    case "InformationBox":
      return createInformationBox(component, rowWrapKey);
    case "Select":
    case "RadioButtonGroup":
      return createReadonlyTextReverseKey(component, discussions, values, dataPath, true);
    case "CheckBox":
      return createReadonlyCheckBox(component, discussions, values, dataPath, true);
    case "MultiFileUpload":
      return createReadonlyMultipleFileDownload(component, discussions, values, activityDocuments, dataPath, true);
    case "SingleFileUpload":
      return createReadonlySingleFileDownload(component, discussions, values, activityDocuments, dataPath, true);
    case "Repeater":
      return createReadonlyRepeater(
        component,
        discussions,
        values,
        expressionContext,
        activityDocuments,
        dataPath,
        true
      );
    case "TableRepeater":
      return createReadonlyTableRepeater(
        component,
        discussions,
        values,
        expressionContext,
        activityDocuments,
        dataPath,
        true
      );
    default:
      return createViewReadonlyText(component, discussions, values, dataPath, true);
  }
};

export const createReviewComponent = (
  component: Component,
  discussions: GetDiscussionThreadsResponse[] | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  expressionContext: any,
  activityDocuments: UploadedDocumentEnriched[],
  dataPath: string,
  isForRepeater: boolean,
  repeaterIndex?: number
): JSX.Element | null => {
  if (
    values[component.key] === undefined &&
    component.component !== "Repeater" &&
    component.component !== "TableRepeater" &&
    component.component !== "InformationBox" &&
    component.component !== "Paragraph"
  ) {
    // `component.component !== "Repeater"` was added so that repeaters without values will always have their children displayed in view mode (since children also do not have values, their labels will be visible)
    // If `values[component.key]`, the call to `kanaExpressionInterpreter` will fail with an exception
    return createViewReadonlyText(component, discussions, values, dataPath, false, isForRepeater, repeaterIndex);
  }

  if (
    component.conditionExpression !== undefined &&
    !kanaExpressionInterpreter(
      component.conditionExpression,
      expressionContext,
      values[component.key],
      repeaterIndex !== undefined ? [repeaterIndex] : []
    )
  )
    return null;

  const rowWrapKey = computeComponentKey(dataPath, component.key);

  switch (component.component) {
    case "Paragraph":
      return createParagraph(component, rowWrapKey);
    case "InformationBox":
      return createInformationBox(component, rowWrapKey);
    case "Select":
    case "RadioButtonGroup":
      return createReadonlyTextReverseKey(
        component,
        discussions,
        values,
        dataPath,
        false,
        isForRepeater,
        repeaterIndex
      );
    case "CheckBox":
      return createReadonlyCheckBox(component, discussions, values, dataPath, false, isForRepeater, repeaterIndex);
    case "MultiFileUpload":
      return createReadonlyMultipleFileDownload(
        component,
        discussions,
        values,
        activityDocuments,
        dataPath,
        false,
        isForRepeater,
        repeaterIndex
      );
    case "SingleFileUpload":
      return createReadonlySingleFileDownload(
        component,
        discussions,
        values,
        activityDocuments,
        dataPath,
        false,
        isForRepeater,
        repeaterIndex
      );
    case "Repeater":
      return createReadonlyRepeater(
        component,
        discussions,
        values,
        expressionContext,
        activityDocuments,
        dataPath,
        false
      );
    case "TableRepeater":
      return createReadonlyTableRepeater(
        component,
        discussions,
        values,
        expressionContext,
        activityDocuments,
        dataPath,
        false,
        isForRepeater,
        repeaterIndex
      );
    default:
      return createViewReadonlyText(component, discussions, values, dataPath, false, isForRepeater, repeaterIndex);
  }
};
