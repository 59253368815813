import "./SubmitToRegistryModal.css";

import { Dispatch, SetStateAction } from "react";

import { Button, Column, Modal, Row } from "../../../../widget";
import { useSubmitToRegistryModal } from "./useSubmitToRegistryModal";

interface SubmitToRegistryModalProps {
  show: boolean;
  onClose: () => void;
  activityUuid: string;
  activityStatus: string;
  activityRowVersion: number;
  refreshActivityReviews?: boolean;
  setRefreshActivityReviews?: Dispatch<SetStateAction<boolean>>;
}

export const SubmitToRegistryModal = ({
  show,
  activityUuid,
  activityRowVersion,
  activityStatus,
  refreshActivityReviews,
  setRefreshActivityReviews,
  onClose,
}: SubmitToRegistryModalProps): JSX.Element => {
  const { isLoading, onConfirmClick } = useSubmitToRegistryModal({
    activityUuid,
    activityRowVersion,
    activityStatus,
    refreshActivityReviews,
    setRefreshActivityReviews,
    onClose,
  });

  return (
    <Modal show={show} title="Submit to registry" onClose={onClose}>
      <Row spacingV="ll" justify="center">
        <Column span={10}>
          <span className="body1">
            Submitting this review will notify the Developer that the process has passed review by the code.
          </span>
        </Column>
      </Row>
      <Row justify="center">
        <Column span={6}>
          <div className="SubmitToRegistryModalButtons">
            <Button text="Complete assessment" onClick={onConfirmClick} isLoading={isLoading} />
            <Button variant="secondary" text="Cancel" onClick={onClose} />
          </div>
        </Column>
      </Row>
    </Modal>
  );
};
