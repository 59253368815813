export enum Origin {
  PasswordReset = "password-reset",
  SessionTimeout = "session-timeout",
  DeveloperDetailsPreview = "developer-details-preview",
  VerifierDetailsPreview = "verifier-details-preview",
  ProjectAuditPage = "project-audit-page",
  OrganisationAuditPage = "organisation-audit-page",
  DeveloperDashboardPage = "developer-dashboard-page",
  DeveloperActivitiesPage = "developer-activities-page",
}
