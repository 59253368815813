/* Note this page is also imported from outside /developer - this was done rather than
 * moving the file to shared, as we wanted to keep the git history of the file given the
 * complexity of some of bugs that have occured surrounding the wizard in the past.
 */
import "../../../../../developer/activities/activity/css/ActivityWizard.css";
import "./View.css";

import { ArrowRoundLeftDarkIcon, ArrowRoundRightLightIcon } from "../../../../../../assets";
import { ProjectActivityReviewsConstants } from "../../../../../../constants";
import { Button, Column, Row, Stepper, VersionBanner, Wizard } from "../../../../../../widget";
import { ActivityWizardContent, ReviewContent } from "../../../../../developer/activities/activity/components";
import { useView } from "./useView";

export const View = (): JSX.Element => {
  const {
    steps,
    l3Steps,
    stepFields,
    stepReviews,
    currentStepName,
    currentStepKeys,
    hasNext,
    hasPrevious,
    activityData,
    isLoading,
    showReviewPage,
    isReviewPage,
    activityDefinition,
    activityDefinitionInfo,
    activityReviewStatus,
    activityReviewCompletionPercentage,
    isAssignedAuditor,
    currentUserType,
    activityHistoryUuidData,
    // handlers
    movePrevious,
    moveNext,
    moveTo,
    moveToLastStep,
    moveToReview,
    onActivityHistoryUuidChange,
    onClose,
    onEdit,
  } = useView();

  return isLoading ? (
    <div />
  ) : (
    <div className={`${currentUserType} ActivityWizardContainer ActivityWizardContainer_View`}>
      <VersionBanner
        projectName={activityData?.activity?.project?.displayName || ""}
        activityDefinitionName={activityData?.activity.activityDefinition.displayName || ""}
        updatedByUserFullName={activityData?.createdByUser.fullName || ""}
        updatedAt={activityData?.createdAt || undefined}
        activityHistoryVersionNumber={activityData?.versionNumber.toString() || ""}
        onActivityHistoryUuidChange={onActivityHistoryUuidChange}
        activityHistoryUuidData={activityHistoryUuidData}
        isCurrentVersion={activityData?.isCurrent || false}
        isDraftVersion={activityData?.isDraft || false}
        isUnderReview={activityData?.isUnderReview ?? true}
        canManageActivity={
          isAssignedAuditor &&
          (activityReviewStatus === ProjectActivityReviewsConstants.STATUS_IN_PROGRESS ||
            activityReviewStatus === ProjectActivityReviewsConstants.STATUS_FEEDBACK_RECEIVED)
        }
        currentUserType={currentUserType}
        onClose={onClose}
        onEdit={onEdit}
      />
      <div className="ActivityWizardContainerMain">
        <div className="ActivityWizardContainerWizard">
          <Wizard
            projectName={activityData?.activity?.project?.displayName || ""}
            activityName={activityDefinitionInfo?.displayName || ""}
            activityVersion={activityDefinitionInfo?.versionNumber || ""}
            currentUserType={currentUserType}
            steps={steps}
            isReview={isReviewPage}
            showLogo={false}
            completionPercentage={activityReviewCompletionPercentage}
            handleMoveToStep={moveTo}
            handleMoveToReview={showReviewPage ? moveToReview : undefined}
          />
        </div>
        <div className="ActivityWizardContainerContent">
          {showReviewPage && isReviewPage ? (
            <ReviewContent activityDefinition={activityDefinition} handleMoveToStep={moveTo} />
          ) : (
            <>
              {currentStepName && (
                <Row
                  spacingV={l3Steps && l3Steps.length > 0 ? "ml" : "ll"}
                  className="ActivityWizardContainerContentStepName"
                >
                  <Column span={12}>
                    <h1>{currentStepName}</h1>
                  </Column>
                </Row>
              )}
              {l3Steps && l3Steps.length > 0 && (
                <Row spacingV="ll" className="ActivityWizardContainerStepper">
                  <Column span={12}>
                    <Stepper
                      steps={l3Steps}
                      currentSubStepKey={currentStepKeys.length === 3 ? currentStepKeys[2] : undefined}
                      onClick={(stepKey) => {
                        const isCurrentStep = currentStepKeys.length === 3 && currentStepKeys[2] === stepKey;
                        const l3StepKeys = [...currentStepKeys.slice(0, -1), stepKey];
                        return !isCurrentStep ? moveTo(l3StepKeys) : undefined;
                      }}
                      currentUserType={currentUserType}
                    />
                  </Column>
                </Row>
              )}
              <Row spacingV="ll">
                <Column span={12}>
                  <ActivityWizardContent fields={stepFields} errors={undefined} />
                  {stepReviews !== undefined && stepReviews.length > 1 && (
                    <div className="ActivityWizardReviewStepContainer">{stepReviews}</div>
                  )}
                </Column>
              </Row>
            </>
          )}
          <Row spacingV="ll">
            <Column span={12}>
              <div className="ActivityWizardContainerContentActions">
                {hasPrevious && (
                  <Button
                    text="Previous"
                    onClick={movePrevious}
                    variant="tertiary"
                    icon={<ArrowRoundLeftDarkIcon />}
                    iconPosition="left"
                  />
                )}
                {isReviewPage && (
                  <Button
                    text="Previous"
                    onClick={moveToLastStep}
                    variant="tertiary"
                    icon={<ArrowRoundLeftDarkIcon />}
                    iconPosition="left"
                  />
                )}
                {hasNext && (
                  <Button text="Next" onClick={moveNext} variant="primary" icon={<ArrowRoundRightLightIcon />} />
                )}
                {!hasNext && showReviewPage && !isReviewPage && (
                  <Button text="Next" onClick={moveToReview} variant="primary" icon={<ArrowRoundRightLightIcon />} />
                )}
              </div>
            </Column>
          </Row>
        </div>
      </div>
    </div>
  );
};
