import "./ChartLegend.css";

import { thousandsFormatter } from "../../../utils";
import { ChartPropsWithStringData } from "../models";

type ChartLegendVariant = "Primary" | "Secondary";

interface ChartLegendProps extends ChartPropsWithStringData {
  colours: string[];
  hasData: boolean;
  showValues?: boolean;
  variant?: ChartLegendVariant;
}

export const ChartLegendWithSuffix = ({
  data,
  colours,
  hasData,
  showValues = true,
  variant = "Primary",
}: ChartLegendProps): JSX.Element => {
  return (
    <div className="ChartLegend">
      <table>
        <tbody>
          {Object.keys(data).map((key, idx) => {
            return (
              <tr key={key}>
                {variant === "Primary" && (
                  <td>
                    <div className="ChartLegendBox" style={{ backgroundColor: colours[idx] }} />
                  </td>
                )}
                {showValues && (
                  <td
                    className={hasData ? "ChartLegendValue" : "ChartLegendNoValue"}
                    style={variant === "Secondary" ? { color: colours[idx] } : undefined}
                  >
                    <p className="body2">
                      {hasData
                        ? `${thousandsFormatter.format(data[key].value).toLowerCase()} ${data[key].suffix ?? ""}`
                        : null}
                    </p>
                  </td>
                )}
                <td className={showValues ? undefined : "RowPadding"}>
                  <p className="body2">{key}</p>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
