import { useState } from "react";

import {
  ActivityReviewTypeConstants,
  ProjectActivitiesConstants,
  ProjectActivityReviewsConstants,
} from "../../../../constants";
import { transitionActivity } from "../../../../service/activity";
import { getActivityDetails, searchActivityReviews } from "../../../../service/query";
import { Status } from "../../../../service/Shared";
import { getErrorMessageFromCode } from "../../../../service/ValidationErrorFormatter";
import { useIsLoadingWrapper } from "../../../../utils";
import { dataGridMapFilterCriteria, Toast } from "../../../../widget";
import { isSuccessfulCompleteActivityDraft } from "../../../shared/utils/activities/completeActivity";

interface useReturnToAssessorModalProps {
  activityUuid: string;
  activityHistoryUuid: string;
  activityHistoryRowVersion: number | undefined;
  isDraft: boolean;
  onClose: () => void;
  shouldRefreshActivities?: () => void;
  exitActivityWizard?: () => void;
}
interface useReturnToAssessorModalReturnData {
  onConfirmClick: () => void;
  isLoading: boolean;
}

export const useReturnToAssessorModal = ({
  activityUuid,
  activityHistoryUuid,
  activityHistoryRowVersion,
  isDraft,
  onClose,
  shouldRefreshActivities,
  exitActivityWizard,
}: useReturnToAssessorModalProps): useReturnToAssessorModalReturnData => {
  const [isLoading, setIsLoading] = useState(false);

  const onConfirmClick = useIsLoadingWrapper(async (): Promise<void> => {
    // We only ever have activityHistoryRowVersion when in draft
    if (isDraft && activityHistoryRowVersion) {
      const successfullyCompletedDraft = await isSuccessfulCompleteActivityDraft(
        activityHistoryUuid,
        activityHistoryRowVersion
      );

      if (!successfullyCompletedDraft) {
        return;
      }
    }

    // `completeDraft` endpoint also updates the `activity` table, this call is needed to be get latest activity rowVersion
    const activityDetailsRes = await getActivityDetails({ activityUuid });

    if (activityDetailsRes.status === Status.Success && activityDetailsRes.data) {
      const filterCriteria = dataGridMapFilterCriteria([]);

      filterCriteria.activity = {
        uuid: {
          operator: "eq",
          value: activityUuid,
        },
      };

      filterCriteria.type = {
        operator: "eq",
        value: ActivityReviewTypeConstants.ASSESSMENT,
      };

      filterCriteria.status = {
        operator: "neq",
        value: ProjectActivityReviewsConstants.STATUS_CANCELLED,
      };

      // Get the assessment related to the activity
      const searchActivityReviewsRes = await searchActivityReviews({
        filter: { results: filterCriteria },
        paging: {
          afterCursor: null,
          beforeCursor: null,
          limit: 10,
        },
      });

      if (searchActivityReviewsRes.status === Status.Success && searchActivityReviewsRes.data?.results.length === 1) {
        const assessment = searchActivityReviewsRes.data.results[0];

        const transitionActivityRes = await transitionActivity({
          activity: {
            uuid: activityUuid,
            rowVersion: activityDetailsRes.data.rowVersion,
            toStatus: ProjectActivitiesConstants.STATUS_ASSESSMENT_IN_PROGRESS,
            fromStatus: ProjectActivitiesConstants.STATUS_CHANGES_REQUIRED,
            publishExternalMessages: true,
            publishInternalMessages: false,
          },
          activityReviews: [
            {
              activityReviewUuid: assessment.activityReviewUuid,
              rowVersion: assessment.rowVersion,
              fromStatus: assessment.status,
              toStatus: ProjectActivityReviewsConstants.STATUS_FEEDBACK_RECEIVED,
            },
          ],
        });
        if (transitionActivityRes.status === Status.Success) {
          Toast.success({ message: "Activity submitted successfully" });
          // if called from dashboard refresh context
          if (shouldRefreshActivities) {
            shouldRefreshActivities();
          }

          // if called from wizard, navigate
          if (exitActivityWizard !== undefined) exitActivityWizard();

          onClose();
        } else if (transitionActivityRes.errors && transitionActivityRes.errors.length > 0) {
          Toast.error({ message: getErrorMessageFromCode(transitionActivityRes.errors[0].message) });
        }
      }
    }
  }, setIsLoading);

  return {
    isLoading,
    onConfirmClick,
  };
};
