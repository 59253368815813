import { createContext, Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import {
  OrganisationTypeConstants,
  ProjectActivityReviewsConstants,
  ProjectPermissionConstants,
} from "../../../constants";
import { ActivityReviewPageParams } from "../../../models";
import { getPublicGroupDetails } from "../../../service/publicquery/PublicQueryService";
import {
  getActivityDetails,
  GetActivityDetailsResponse,
  GetActivityReviewAggregateResponse,
  getActivityReviewDetails,
  getCurrentUserProjectPermissions,
  searchActivityReviews,
  SearchActivityReviewsResponse,
} from "../../../service/query";
import { fetchHasUnreadMessages, getActivityReviewAggregate } from "../../../service/query/QueryService.full";
import { Status } from "../../../service/Shared";
import { useAuth } from "../../../useAuth";
import { hasActivityPermissionForProject } from "../../../utils";
import { getDashboardRoute } from "../../../utils/routes";
import { dataGridMapFilterCriteria } from "../../../widget";

interface ActivityReviewDashboardProviderProps {
  children?: JSX.Element | JSX.Element[];
}

/**
 *  Given that we need to be able to account for multiple reviews in the
 *  workflow, we base this context around the activityUuid
 */
interface ActivityReviewDashboardContextType {
  activityReviews: SearchActivityReviewsResponse | undefined;
  activityReviewActivityUuid: string | undefined;
  activityReviewAggregate: GetActivityReviewAggregateResponse | undefined;
  activityDetails: GetActivityDetailsResponse | undefined;
  currentActivityReviewUuid: string | undefined; // For auditor we know if we need the assessment / review so we can populate this for navigation purposes
  currentActivityReviewType: string | undefined;
  queryParams: string | undefined;
  hasManageProjectActivityReviewPermission: boolean;
  hasWriteProjectActivityReviewPermission: boolean;
  canReviewActivity: boolean;
  hasUnreadDiscussions: boolean;
  isAssignedAuditor: boolean;
  showDetailsPanel: boolean;
  refreshActivityReviews: boolean;
  currentOrganisationUuid: string | undefined;
  isLoading: boolean;
  setRefreshActivityReviews: Dispatch<SetStateAction<boolean>>;
  setShowDetailsPanel: Dispatch<SetStateAction<boolean>>;
  onCloseDetailsPanel: () => void;
  setHasUnreadDiscussions: (val: boolean) => void;
  refreshResolvedDiscussionsKPI: () => Promise<void>;
  refreshUnreadDiscussionsTabIndicator: (currentOrganisationUuid2?: string | undefined) => Promise<void>;
}

export const ActivityReviewDashboardContext = createContext<ActivityReviewDashboardContextType>(
  {} as ActivityReviewDashboardContextType
);

export const ActivityReviewDashboardProvider = ({ children }: ActivityReviewDashboardProviderProps): JSX.Element => {
  const { user } = useAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const userUuid = useMemo(() => user?.userUuid ?? "", [user]);

  const [currentOrganisationUuid, setCurrentOrganisationUuid] = useState<string | undefined>(undefined);
  const [activityReviews, setActivityReviews] = useState<SearchActivityReviewsResponse>();
  const { activityReviewActivityUuid } = useParams<ActivityReviewPageParams>();
  const [currentActivityReviewUuid, setCurrentActivityReviewUuid] = useState<string>();
  const [activityDetails, setActivityDetails] = useState<GetActivityDetailsResponse>();
  const [activityReviewAggregate, setActivityReviewAggregate] = useState<GetActivityReviewAggregateResponse>();

  const [hasManageProjectActivityReviewPermission, setHasManageProjectActivityReviewPermission] = useState(true);
  const [hasWriteProjectActivityReviewPermission, setHasWriteProjectActivityReviewPermission] = useState(true);
  const [isAssignedAuditor, setIsAssignedAuditor] = useState(true);
  const [canReviewActivity, setCanReviewActivity] = useState(false);
  const [hasUnreadDiscussions, setHasUnreadDiscussions] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [refreshActivityReviews, setRefreshActivityReviews] = useState(false);

  const [showDetailsPanel, setShowDetailsPanel] = useState(false);

  const currentActivityReviewType = searchParams.get("type") ? searchParams.get("type") ?? undefined : undefined;

  const URLprojectUuid = searchParams.get("projectUuid");
  const URLorigin = searchParams.get("origin");
  // eslint-disable-next-line no-nested-ternary
  let queryParams = URLprojectUuid ? `projectUuid=${URLprojectUuid}` : URLorigin ? `origin=${URLorigin}` : "";

  if (queryParams === "") queryParams = currentActivityReviewType ? `type=${currentActivityReviewType}` : "";
  else queryParams = currentActivityReviewType ? `${queryParams}&type=${currentActivityReviewType}` : queryParams;

  const onCloseDetailsPanel = (): void => {
    setShowDetailsPanel(false);
  };

  const refreshResolvedDiscussionsKPI = async (): Promise<void> => {
    // for discussions KPI
    await getActivityReviewAggregate({
      activityReviewUuid: null,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      activityUuid: activityReviewActivityUuid!,
      assignedToUserUuid: null,
    }).then((res) => {
      if (res.status === Status.Success && res.data) {
        setActivityReviewAggregate(res.data);
      }
    });
  };

  const refreshUnreadDiscussionsTabIndicator = async (
    currentOrganisationUuid2 = currentOrganisationUuid
  ): Promise<void> => {
    if (activityReviewActivityUuid) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const hasUnread = await fetchHasUnreadMessages(activityReviewActivityUuid, userUuid, currentOrganisationUuid2!);
      setHasUnreadDiscussions(hasUnread);
    }
  };

  const fetchActivityReviewDetails = useCallback(
    async (uuid: string) => {
      const filterCriteria = dataGridMapFilterCriteria([]);
      filterCriteria.activity = {
        uuid: { operator: "eq", value: uuid },
      };

      filterCriteria.status = {
        operator: "neq",
        value: ProjectActivityReviewsConstants.STATUS_CANCELLED,
      };

      const response = await searchActivityReviews({
        filter: { results: filterCriteria },
        paging: {
          afterCursor: null,
          beforeCursor: null,
          limit: 10,
        },
      });
      if (response.status === Status.Success && response.data) {
        setActivityReviews(response.data);

        let isUnderReview = false;
        let currentActivityReviewStatus: string | undefined;
        let isCurrentUserAnAssignedAuditor = false;
        if (currentActivityReviewType != null) {
          const currentActivityReview = response.data.results.find(
            (review) =>
              review.type === currentActivityReviewType &&
              review.status !== ProjectActivityReviewsConstants.STATUS_CANCELLED
          );
          if (currentActivityReview != null) {
            setCurrentActivityReviewUuid(currentActivityReview?.activityReviewUuid);
            currentActivityReviewStatus = currentActivityReview?.status;

            const activityReviewDetailsResponse = await getActivityReviewDetails({
              activityReviewUuid: currentActivityReview?.activityReviewUuid,
            });

            isCurrentUserAnAssignedAuditor =
              activityReviewDetailsResponse?.data?.assignedToUserUuids.some(
                (assignedUserUuid: string) => assignedUserUuid === user?.userUuid
              ) ?? false;
            setIsAssignedAuditor(isCurrentUserAnAssignedAuditor);
            isUnderReview = activityReviewDetailsResponse?.data?.isUnderReview ?? false;
          }
        } else {
          setIsAssignedAuditor(false);
        }

        let projectUuid = response.data.results[0].project?.uuid;
        if (!projectUuid?.length && response.data.results[0].group?.uuid) {
          const groupDetailsRes = await getPublicGroupDetails({
            projectGroupUuid: response.data.results[0].group?.uuid,
          });

          projectUuid = groupDetailsRes.data.projects[0].uuid;
        }

        if (!projectUuid?.length) {
          return;
        }

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await getCurrentUserProjectPermissions({ projectUuids: [projectUuid!] }).then((permissions) => {
          if (permissions.status === Status.Success && permissions.data) {
            setHasManageProjectActivityReviewPermission(
              hasActivityPermissionForProject(
                permissions.data,
                projectUuid,
                ProjectPermissionConstants.MANAGE_PROJECT_ACTIVITY_REVIEW
              )
            );

            const hasWritePermission = hasActivityPermissionForProject(
              permissions.data,
              projectUuid,
              ProjectPermissionConstants.WRITE_PROJECT_ACTIVITY_REVIEW
            );

            setHasWriteProjectActivityReviewPermission(hasWritePermission);
            // Logic for BR-0725
            setCanReviewActivity(
              hasWritePermission &&
                (currentActivityReviewStatus === ProjectActivityReviewsConstants.STATUS_IN_PROGRESS ||
                  currentActivityReviewStatus === ProjectActivityReviewsConstants.STATUS_FEEDBACK_RECEIVED) &&
                isCurrentUserAnAssignedAuditor &&
                isUnderReview
            );
          } else {
            setHasManageProjectActivityReviewPermission(false);
            setHasWriteProjectActivityReviewPermission(false);
            setCanReviewActivity(false);
          }
        });

        const activityUuid = response.data.results[0].activity.uuid;

        await getActivityDetails({ activityUuid }).then(async (activityDetailsResponse) => {
          setActivityDetails(activityDetailsResponse.data);
          setCurrentOrganisationUuid(activityDetailsResponse.data?.project.verifier?.organisationUuid || undefined);
          // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain, @typescript-eslint/no-non-null-assertion
          await refreshUnreadDiscussionsTabIndicator(activityDetailsResponse.data?.project.verifier?.organisationUuid!);
          await refreshResolvedDiscussionsKPI();
        });
      }
    },
    [activityReviewActivityUuid, refreshActivityReviews]
  );

  useEffect(() => {
    if (userUuid && activityReviewActivityUuid && activityReviewActivityUuid.length > 10) {
      fetchActivityReviewDetails(activityReviewActivityUuid).finally(() => setIsLoading(false));
    }
  }, [activityReviewActivityUuid, refreshActivityReviews, userUuid]);

  useEffect(() => {
    if (!isAssignedAuditor && !hasManageProjectActivityReviewPermission)
      navigate(getDashboardRoute(OrganisationTypeConstants.VERIFIER));
  }, [isAssignedAuditor, hasManageProjectActivityReviewPermission]);

  const memoisedValue = useMemo(
    () => ({
      activityReviewActivityUuid,
      activityReviews,
      activityDetails,
      activityReviewAggregate,
      currentActivityReviewUuid,
      currentActivityReviewType,
      queryParams,
      hasManageProjectActivityReviewPermission,
      hasWriteProjectActivityReviewPermission,
      isAssignedAuditor,
      canReviewActivity,
      hasUnreadDiscussions,
      showDetailsPanel,
      refreshActivityReviews,
      currentOrganisationUuid,
      isLoading,

      setRefreshActivityReviews,
      onCloseDetailsPanel,
      setShowDetailsPanel,
      setHasUnreadDiscussions,
      refreshResolvedDiscussionsKPI,
      refreshUnreadDiscussionsTabIndicator,
    }),
    [
      activityReviewActivityUuid,
      activityReviews,
      activityDetails,
      showDetailsPanel,
      activityReviewAggregate,
      hasUnreadDiscussions,
      refreshActivityReviews,
      currentActivityReviewUuid,
      currentActivityReviewType,
      queryParams,

      hasManageProjectActivityReviewPermission,
      hasWriteProjectActivityReviewPermission,
      canReviewActivity,
    ]
  );

  return (
    <ActivityReviewDashboardContext.Provider value={memoisedValue}>{children}</ActivityReviewDashboardContext.Provider>
  );
};
