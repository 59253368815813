import { ActivityReviewTypeConstants } from "../../../../../constants";
import { StepItem, StepState } from "../../../../../models";
import { StepProps } from "../../../../../widget";
import {
  ActivityData,
  getStep,
  HasComponents,
  HasData,
  HasDataSteps,
  HasDiscussions,
  HasKey,
  HasReviews,
  HasSteps,
  Step,
} from "../../../../developer/activities";

/**
 * Perform a depth-first traversal of an object hierarchy, returning an array of keys
 * that represent the path of traversal through the hierarchy.
 * @param stepKeys An array of string values representing the current path of traversal
 * @param definitionStep An object with `steps` and `components` properties representing a step in the hierarchy
 * @param dataStep An object with `steps` and `valid` properties representing data for the current step
 * @returns An array of string values representing the path of traversal through the hierarchy
 */
export const getNextUnreviewedStepKeys = (
  stepKeys: string[],
  definitionStep: HasSteps & HasKey & HasComponents,
  reviewType: ActivityReviewTypeConstants,
  dataStep?: HasKey & HasDataSteps & HasData & HasReviews
): string[] => {
  // Traverse child steps recursively
  if (definitionStep.steps) {
    // Loop through each child step in the `steps` array
    // eslint-disable-next-line no-restricted-syntax
    for (const childDefinitionStep of definitionStep.steps) {
      // Find the corresponding child data step, if any
      const childDataStep = dataStep?.steps?.find((s) => s.key === childDefinitionStep.key);

      // Recursively call `getNextUnvalidatedStepKeys` on the child step
      const result = definitionStep.key
        ? getNextUnreviewedStepKeys([...stepKeys, definitionStep.key], childDefinitionStep, reviewType, childDataStep)
        : getNextUnreviewedStepKeys([...stepKeys], childDefinitionStep, reviewType, childDataStep);

      if (result.length > 0) {
        return result;
      }
    }
  }
  // If we've reached a step with `components` and no `valid` data, return `stepKeysClone`
  else if (
    (reviewType === ActivityReviewTypeConstants.ASSESSMENT &&
      dataStep?.activityReviews?.assessment?.isValid === undefined) ||
    (reviewType === ActivityReviewTypeConstants.REVIEW && dataStep?.activityReviews?.review?.isValid === undefined)
  ) {
    return [...stepKeys, definitionStep.key];
  }

  return [];
};

const checkReviewStep = (
  reviewStep: (HasKey & HasDataSteps & HasData & HasDiscussions & HasReviews) | undefined,
  reviewType: ActivityReviewTypeConstants
): boolean | null | undefined => {
  if (
    reviewStep?.activityReviews?.review?.isValid === false ||
    reviewStep?.activityReviews?.assessment?.isValid === false
  ) {
    return false;
  }
  if (reviewType === ActivityReviewTypeConstants.ASSESSMENT) {
    return reviewStep?.activityReviews?.assessment?.isValid;
  }
  if (reviewType === ActivityReviewTypeConstants.REVIEW) {
    return reviewStep?.activityReviews?.review?.isValid;
  }
  return undefined;
};

/* Step.valid value meanings in vvb wizards
 * true - valid - green
 * false - invalid - yellow
 * undefined - default - grey
 * null - warning (vvb didn't write response) - red
 */
export const mapToReviewStepProps = (
  stepKeys: string[],
  activityData: ActivityData,
  reviewType: ActivityReviewTypeConstants,
  steps?: Step[],
  isReview?: boolean
): StepProps[] => {
  return (
    steps?.map((s: Step) => ({
      key: s.key,
      stepHeader: s.label,
      current: s.key === stepKeys[0] && !isReview,
      // eslint-disable-next-line no-nested-ternary
      valid: s.steps?.length ? undefined : checkReviewStep(getStep([s.key], activityData), reviewType),
      subSteps:
        s.steps?.map((c: Step) => {
          let isSubStepValid = checkReviewStep(getStep([s.key, c.key], activityData), reviewType);
          let inProgress = false;

          if (c.steps !== undefined) {
            isSubStepValid = undefined;
            const validationStatuses = c.steps.map((sss) =>
              checkReviewStep(getStep([s.key, c.key, sss.key], activityData), reviewType)
            );

            if (validationStatuses.some((el) => el !== undefined)) {
              inProgress = true;
            }

            if (validationStatuses.every((el) => el === true)) {
              isSubStepValid = true;
            }

            if (validationStatuses.some((el) => el === false)) {
              isSubStepValid = false;
            }

            if (validationStatuses.some((el) => el === null)) {
              isSubStepValid = null;
            }
          }

          return {
            key: c.key,
            current: s.key === stepKeys[0] && c.key === stepKeys[1],
            stepName: c.label,
            valid: isSubStepValid,
            inProgress,
          };
        }) || [],
    })) || []
  );
};

export const mapToReviewStepItem = (
  stepKeys: string[],
  activityData: ActivityData,
  reviewType: ActivityReviewTypeConstants,
  steps?: Step[]
): StepItem[] | undefined => {
  const cs = steps?.find((s) => s.key === stepKeys[0]);
  const css = cs?.steps?.find((s) => s.key === stepKeys[1]);
  const sssSteps = css?.steps;

  const res = sssSteps?.map((s) => {
    const isValid = checkReviewStep(getStep([cs?.key || "", css?.key || "", s.key], activityData), reviewType);

    const isCurrent = stepKeys.length === 3 && stepKeys[2] === s.key;

    let stepState = StepState.NotStarted;

    if (isValid) {
      stepState = StepState.Complete;
    } else if (isValid === false) {
      stepState = StepState.Invalid;
    } else if (isValid === null) {
      stepState = StepState.Warning;
    } else if (isCurrent) {
      stepState = StepState.InProgress;
    }

    return {
      key: s.key,
      stepName: s.label,
      stepState,
    };
  });

  // only show the L3 stepper if there are more than 1 steps
  if (res?.length === 1) return undefined;

  return res;
};
