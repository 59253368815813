import { ChartData, ChartOptions, Plugin, TooltipItem } from "chart.js";
import { useCallback, useEffect, useMemo, useState } from "react";

import { ColorConstants } from "../../../constants";
import { thousandsFormatter } from "../../../utils";
import { PieChartColors } from "../models";
import {
  getChartColours,
  getChartGradientColours,
  getChartGradientHighlightColours,
  getChartHighlightColours,
} from "../utils";

interface UsePieChartReturnData {
  chartData: ChartData<"doughnut", number[], string>;
  legendData: { [key: string]: number };
  chartColours: string[];
  plugins: Plugin<"doughnut">[] | undefined;
  options: ChartOptions<"doughnut">;
  hasData: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pieChartRef: (node: any) => void;
}

const isFirefox = navigator.userAgent.indexOf("Firefox") !== -1;

export const usePieChart = (
  data: {
    [key: string]: number;
  },
  centerTextHidden: boolean,
  noDataMessage: string,
  isGradient: boolean,
  colors: PieChartColors
): UsePieChartReturnData => {
  const hasData: boolean = useMemo(() => data && Object.values(data).find((x) => !!x) !== undefined, [data]);
  const [chartData, setChartData] = useState<ChartData<"doughnut", number[], string>>({ datasets: [] });
  const [legendData, setLegendData] = useState<{ [key: string]: number }>(data || { [noDataMessage]: 0 });
  const [chartColours, setChartColours] = useState<string[]>([]);
  const [chartNoDataColour] = useState<string>(ColorConstants.GREY_20);

  const [plugins, setPlugins] = useState<Plugin<"doughnut">[] | undefined>(undefined);

  const pieChartRef = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (node: any) => {
      if (data && node !== null && isGradient && !isFirefox) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const setGradients = (gradientColours: string[][]): any => {
          const { ctx } = node;

          const gradientsArr = [];
          const dataValues = Object.values(data);
          const sumValues = dataValues.reduce((sum, currentValue) => {
            return sum + currentValue;
          }, 0);

          for (let i = 0; i < gradientColours.length; i++) {
            let gradientAngle = Math.PI / 2;
            if (i > 0) {
              const angleRatio =
                dataValues.slice(0, i).reduce((sum, currentValue) => {
                  return sum + currentValue;
                }, 0) / sumValues;
              gradientAngle += 2 * Math.PI * angleRatio;
            }

            const gradient = ctx.createConicGradient(gradientAngle, node.height / 2, node.width / 2);
            gradient.addColorStop(0, gradientColours[i][0]);
            gradient.addColorStop(0.6, gradientColours[i][1]);

            gradientsArr.push(gradient);
          }

          return gradientsArr;
        };

        const xLabels = Object.keys(data);
        const dataPoints = xLabels.map((label) => data[label]);

        const colours = hasData ? getChartColours(dataPoints.length) : [chartNoDataColour];
        const gradientColours = hasData
          ? getChartGradientColours(dataPoints.length)
          : [[chartNoDataColour, chartNoDataColour]];
        const highlightColours = hasData ? getChartGradientHighlightColours(dataPoints.length) : [chartNoDataColour];
        setChartColours(colours);
        setChartData({
          labels: xLabels,
          datasets: [
            {
              data: hasData ? dataPoints : [1],
              backgroundColor: hasData ? setGradients(gradientColours) : chartNoDataColour,
              borderWidth: 0,
              hoverBackgroundColor: highlightColours,
              rotation: 180,
            },
          ],
        });
        setLegendData(hasData ? data : { [noDataMessage]: 0 });
      }
    },
    [data]
  );

  useEffect(() => {
    if (data && Object.values(data).length) {
      setPlugins([
        {
          id: "text",
          beforeDraw: (chart) => {
            if (centerTextHidden || !hasData) return;
            const { width, height, ctx } = chart;
            ctx.restore();
            const centerText = thousandsFormatter
              .format(
                Math.abs(
                  chart.data.datasets
                    .reduce<number[]>((a, b) => a.concat(b.data as number[]), [])
                    .reduce((a, b) => a + b, 0)
                )
              )
              .toLowerCase();
            const fontSize = (width / 110).toFixed(2);
            ctx.font = `${fontSize}em "Space Grotesk", sans-serif`;
            ctx.textBaseline = "middle";
            const textX = Math.round((width - ctx.measureText(centerText?.toString() || "").width) / 2);
            const textY = height / 2;

            ctx.fillText(centerText?.toString() || "", textX, textY);
            ctx.save();
          },
        },
      ]);
    }
  }, [data]);
  const options: ChartOptions<"doughnut"> = useMemo(
    () => ({
      cutout: isGradient ? "65%" : "50%",
      animation: {
        duration: 0,
      },
      layout: {
        padding: {
          top: 3,
          right: 3,
          bottom: 3,
          left: 3,
        },
      },
      devicePixelRatio: 4, // Use 4 for clarity
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        tooltip: {
          enabled: hasData,
          displayColors: false,
          callbacks: {
            label: (tooltipItem: TooltipItem<"doughnut">) => {
              return tooltipItem.formattedValue;
            },
            title: () => {
              return "";
            },
          },
        },
      },
    }),
    [data]
  );

  useEffect(() => {
    if (!data || (!isFirefox && isGradient)) return;

    const xLabels = Object.keys(data);
    const dataPoints = xLabels.map((label) => data[label]);

    let colourConstants;
    let hilightColourConstants;
    switch (colors) {
      case "verifierDashboard":
        colourConstants = ColorConstants.CHART_COLORS_VVB;
        hilightColourConstants = ColorConstants.CHART_HIGHLIGHT_COLORS_VVB;
        break;
      case "discussionsKPI":
        colourConstants = ColorConstants.CHART_COLORS_DISCUSSIONS;
        hilightColourConstants = ColorConstants.CHART_HIGHLIGHT_COLORS_DISCUSSIONS;
        break;
      case "default":
      default:
        colourConstants = ColorConstants.CHART_COLORS;
        hilightColourConstants = ColorConstants.CHART_HIGHLIGHT_COLORS;
    }

    const colours = hasData ? getChartColours(dataPoints.length, colourConstants) : [chartNoDataColour];
    const highlightColours = hasData
      ? getChartHighlightColours(dataPoints.length, hilightColourConstants)
      : [chartNoDataColour];
    setChartColours(colours);
    setChartData({
      labels: xLabels,
      datasets: [
        {
          data: hasData ? dataPoints : [1],
          backgroundColor: hasData ? colours : chartNoDataColour,
          borderWidth: 0,
          hoverBackgroundColor: highlightColours,
        },
      ],
    });
    setLegendData(hasData ? data : { [noDataMessage]: 0 });
  }, [data]);

  return {
    chartData,
    legendData,
    chartColours,
    plugins,
    options,
    hasData,
    pieChartRef,
  };
};
