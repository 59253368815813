export interface SearchResultsGraphQL<T> {
  pageInfo: {
    startCursor: string;
    endCursor: string;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
  totalCount: number;
  edges: {
    cursor: string;
    node: T;
  }[];
}

export interface TypeDictionary {
  [index: string]: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const generateOrder = (request: any): string | null => {
  if (request.sort?.length && request.sort[0].key && request.sort[0].direction) {
    const key = request.sort[0].key.replace("results.", "");
    const keys = key.split(".");
    let orderBlock = "order: { ";
    for (let i = 0; i < keys.length; i++) {
      orderBlock += `${keys[i]}: `;
      if (i < keys.length - 1) orderBlock += "{ ";
    }
    orderBlock += request.sort[0].direction.toUpperCase();
    for (let i = 0; i < keys.length; i++) {
      orderBlock += " }";
    }
    return orderBlock;
  }
  return null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const generatePaging = (request: any): [string, string, any] | null => {
  if (request.paging) {
    let pagingBlock = `${request.paging.beforeCursor ? "last" : "first"}: $limit`;
    let pagingVarsBlock = "$limit: Int";
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const pagingVars: any = { limit: request.paging.limit };
    if (request.paging.beforeCursor) {
      pagingBlock += `
        before: $beforeCursor`;
      pagingVarsBlock += ", $beforeCursor: String";
      pagingVars.beforeCursor = request.paging.beforeCursor;
    }
    if (request.paging.afterCursor) {
      pagingBlock += `
        after: $afterCursor`;
      pagingVarsBlock += ", $afterCursor: String";
      pagingVars.afterCursor = request.paging.afterCursor;
    }
    return [pagingBlock, pagingVarsBlock, pagingVars];
  }
  return null;
};

const pathToCamelCase = (path: string[]): string => {
  let res = path[0];
  for (let i = 1; i < path.length; i++) res += path[i][0].toUpperCase() + path[i].substring(1);
  return res;
};

const recurseFilterKeys = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  obj: any,
  path: string[],
  filterBlock: string,
  filterVarsBlock: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterVars: any,
  typeDict: TypeDictionary,
  isWrapped?: boolean
): [string, string, string] => {
  if (obj === null || obj === undefined) return [filterBlock, filterVarsBlock, filterVars];
  Object.keys(obj).forEach((key, idx) => {
    if (obj[key] === null || obj[key] === undefined) return;
    if (obj[key].operator !== undefined && obj[key].value !== undefined) {
      // this is an instance of FilterCriteria<T>
      const varName = pathToCamelCase(path.concat(key));

      if (isWrapped) {
        // eslint-disable-next-line no-param-reassign
        filterBlock += `{ ${key}: { ${obj[key].operator}: $${varName} } }`;
      } else {
        // eslint-disable-next-line no-param-reassign
        filterBlock += `${key}: { ${obj[key].operator}: $${varName} }`;
      }

      if (idx < Object.keys(obj).length - 1) {
        // eslint-disable-next-line no-param-reassign
        filterBlock += ", ";
      }

      // eslint-disable-next-line no-param-reassign
      filterVarsBlock += `$${varName}: ${typeDict[path.concat(key).join(".")]}, `;
      // eslint-disable-next-line no-param-reassign
      filterVars[varName] = obj[key].value;
      // eslint-disable-next-line no-underscore-dangle
    } else if (key.startsWith("_group") && obj[key].operator !== undefined && obj[key]._group_filter !== undefined) {
      /*
     Example: how to use 'or' operator inside an object
      filterResults = {
        ...filterResults,
        _group_objectNameAAA: {
          operator: "or",
          _group_filter: [
            {
              objectAAAFieldName1: {
                  operator: "eq",
                value: projectDetails?.group?.groupUuid || "",
              },
            },
            {
              objectAAAFieldName2: {
                operator: "eq",
                value: projectUuid,
              },
            },
          ],
        },
      };
    }
      or
    // eslint-disable-next-line no-underscore-dangle
    // filterCriteria._group_ = {
    //   operator: "or",
    //   _group_filter: activityStatuses.map((el) => {
    //     return {
    //       status: {
    //         operator: "eq",
    //         value: el,
    //       },
    //     };
    //   }),
    // };
      */
      const groupKey = key.substring(7);
      let endFilterBlock = ``;
      if (groupKey) {
        // eslint-disable-next-line no-param-reassign
        filterBlock += `{${groupKey}: {${obj[key].operator}: [`;
        endFilterBlock = `]}},`;
      } else {
        // eslint-disable-next-line no-param-reassign
        filterBlock += `${obj[key].operator}: [`;
        endFilterBlock = `]`;
      }

      // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/no-explicit-any
      let filterIndex = 0;
      // eslint-disable-next-line no-underscore-dangle
      obj[key]._group_filter.forEach((objInGrp: unknown) => {
        // eslint-disable-next-line no-param-reassign
        filterBlock += `{`;
        // eslint-disable-next-line no-param-reassign
        [filterBlock, filterVarsBlock, filterVars] = recurseFilterKeys(
          objInGrp,
          path.concat(groupKey || `_${filterIndex.toString()}_`), //
          filterBlock,
          filterVarsBlock,
          filterVars,
          typeDict
        );
        filterIndex += 1;
        // eslint-disable-next-line no-param-reassign
        filterBlock += `} ,`;
      });
      // remove last ","
      // eslint-disable-next-line no-param-reassign
      filterBlock = filterBlock.slice(0, -1);
      // eslint-disable-next-line no-param-reassign
      filterBlock += endFilterBlock;
    } else {
      if (isWrapped) {
        // eslint-disable-next-line no-param-reassign
        filterBlock += `{ ${key}: { `;
      } else {
        // eslint-disable-next-line no-param-reassign
        filterBlock += `${key}: { `;
      }
      // eslint-disable-next-line no-param-reassign
      [filterBlock, filterVarsBlock, filterVars] = recurseFilterKeys(
        obj[key],
        path.concat(key),
        filterBlock,
        filterVarsBlock,
        filterVars,
        typeDict
      );

      if (isWrapped) {
        // eslint-disable-next-line no-param-reassign
        filterBlock += " } }";
      } else {
        // eslint-disable-next-line no-param-reassign
        filterBlock += " }";
      }

      if (idx < Object.keys(obj).length - 1) {
        // eslint-disable-next-line no-param-reassign
        filterBlock += ", ";
      }
    }
  });
  return [filterBlock, filterVarsBlock, filterVars];
};

const generateFilter = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  request: any,
  typeDict: TypeDictionary
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): [string, string, any] | null => {
  if (request.filter?.results && Object.keys(request.filter.results).length > 0) {
    // eslint-disable-next-line prefer-const
    let [filterBlock, filterVarsBlock, filterVars] = recurseFilterKeys(
      request.filter.results,
      [],
      "",
      "",
      {},
      typeDict
    );

    filterVarsBlock = filterVarsBlock.substring(0, filterVarsBlock.length - 2); // remove trailing comma and space ", "
    return [filterBlock, filterVarsBlock, filterVars];
  }
  return null;
};

const generateFilterFromGroup = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  request: any,
  typeDict: TypeDictionary
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any[] | null => {
  if (request.filterGroups && request.filterGroups.length) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filterArr: any[] = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    request.filterGroups.forEach((group: any) => {
      // eslint-disable-next-line prefer-const
      let [filterBlock, filterVarsBlock, filterVars] = recurseFilterKeys(
        group.filter.results,
        [],
        "",
        "",
        {},
        typeDict,
        true
      );

      filterVarsBlock = filterVarsBlock.substring(0, filterVarsBlock.length - 2); // remove trailing comma and space ", "
      const filterBlockWithOperator = `${group.operator.toLowerCase()}: [ ${filterBlock} ]`;
      filterArr.push([filterBlockWithOperator, filterVarsBlock, filterVars]);
    });

    return filterArr;
  }
  return null;
};

// this method creates `where`, `paging` and `sort`
// blocks for GraphQL queries based on a request object
export const generateGraphQLHelpers = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  req: any,
  typeDict: TypeDictionary
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): [string, string, any] => {
  const orderBlock = generateOrder(req);
  const [pagingBlock, pagingVarsBlock, pagingVars] = generatePaging(req) || [null, null, null];
  const [filterBlock, filterVarsBlock, filterVars] = generateFilter(req, typeDict) || [null, null, null];
  const filterGroups = generateFilterFromGroup(req, typeDict);

  let varBlock = filterVarsBlock || "";
  let formattedFilterBlock = "";
  let formattedFilterVars = filterVars;

  if (filterBlock && filterBlock.length > 0) {
    formattedFilterBlock += filterBlock;
  }

  if (filterGroups) {
    filterGroups.forEach((el, idx) => {
      const isLastElement = idx === filterGroups.length - 1;
      formattedFilterBlock += `${el[0]}${!isLastElement ? ", " : ""}`;
      varBlock += `${el[1]}${!isLastElement ? ", " : ""}`;
      formattedFilterVars = { ...formattedFilterVars, ...el[2] };
    });
  }

  if (formattedFilterBlock !== "") {
    formattedFilterBlock = `where: { ${formattedFilterBlock} }`;
  }

  const sortFilterPagingBlock = `${orderBlock || ""}
        ${formattedFilterBlock}
        ${pagingBlock || ""}
  `;

  if (varBlock.length > 0 && pagingVarsBlock) varBlock += ", ";
  if (pagingVarsBlock) varBlock += pagingVarsBlock;

  let variables = {};
  if (pagingVars) variables = { ...variables, ...pagingVars };
  if (formattedFilterVars) variables = { ...variables, ...formattedFilterVars };

  return [sortFilterPagingBlock, varBlock, variables];
};
