import { AuditChangesPanelTemplate, DeveloperSingleCardTemplate } from "../../../../../templates";
import { Column, CursorDataGrid, Row } from "../../../../../widget";
import { useAuditHistory } from "./useAuditHistory";

export const AuditHistory = (): JSX.Element => {
  const {
    projectDisplayName,
    exportFileName,
    handleBackClick,
    columns,
    defaultSortingCriteria,
    dataIsLoading,
    auditDataUuid,
    auditDataChange,
    showAuditChangesPanel,
    setShowAuditChangesPanel,
    onChange,
  } = useAuditHistory();
  return (
    <div className="ProjectAuditHistoryDetails">
      {projectDisplayName && (
        <DeveloperSingleCardTemplate
          pageTitle="Audit History"
          handleBackClick={handleBackClick}
          backButtonText="Back to full project"
          activeElement="Projects"
        >
          <Row spacingV="ll">
            <Column span={9}>
              <h2>{projectDisplayName}</h2>
            </Column>
          </Row>
          <CursorDataGrid
            columns={columns}
            clientSide={false}
            filterable
            onChange={onChange}
            data={[]}
            noDataMessage="No audit data to display"
            dataIsLoading={dataIsLoading}
            defaultSortingCriteria={defaultSortingCriteria}
            exportable
            exportFileName={exportFileName}
          />
        </DeveloperSingleCardTemplate>
      )}
      {auditDataUuid && (
        <AuditChangesPanelTemplate
          auditDataUuid={auditDataUuid}
          auditDataChange={auditDataChange}
          show={showAuditChangesPanel}
          onClose={() => setShowAuditChangesPanel(false)}
        />
      )}
    </div>
  );
};
