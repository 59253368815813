import React, { SetStateAction, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { OrganisationTypeConstants } from "../../../../constants";
import { logError } from "../../../../service/error";
import { logon } from "../../../../service/identity";
import { GetCurrentUserResponse } from "../../../../service/query";
import { ServiceError, Status } from "../../../../service/Shared";
import { getErrorMessageFromCode } from "../../../../service/ValidationErrorFormatter";
import { useAuth } from "../../../../useAuth";
import { useIsLoadingWrapper } from "../../../../utils";
import { getDashboardRoute } from "../../../../utils/routes";
import { Toast } from "../../../../widget";

interface UseLogonFormReturnData {
  origin: string | null;
  email: string;
  password: string;
  errors: ServiceError[] | undefined;
  isHandleSubmitLoading: boolean;
  setEmail: React.Dispatch<SetStateAction<string>>;
  setPassword: React.Dispatch<SetStateAction<string>>;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
}

export const useLogonForm = (): UseLogonFormReturnData => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setCurrentUser } = useAuth();
  const [searchParams] = useSearchParams();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState<ServiceError[] | undefined>([]);
  const [isHandleSubmitLoading, setIsHandleSubmitLoading] = useState(false);

  const errorCodeNoLinkedOrganisations = "VALIDATION_LOGON_NO_ACTIVE_LINKED_ORGANISATIONS";

  const navigateUserToDashboard = (currentUserResult: GetCurrentUserResponse): void => {
    const hasDeveloperUuid = currentUserResult.organisations.some((org) => org.developerUuid !== null);
    const hasVerifierUuid = currentUserResult.organisations.some((org) => org.verifierUuid !== null);
    const hasDevelopmentManagerUuid = currentUserResult.organisations.some(
      (org) => org.developmentManagerUuid !== null
    );
    const hasAssetManagerUuid = currentUserResult.organisations.some((org) => org.assetManagerUuid !== null);

    if (hasDeveloperUuid) {
      navigate(getDashboardRoute(OrganisationTypeConstants.DEVELOPER), { replace: true });
      return;
    }

    if (hasVerifierUuid) {
      navigate(getDashboardRoute(OrganisationTypeConstants.VERIFIER), { replace: true });
      return;
    }

    if (hasDevelopmentManagerUuid) {
      navigate(getDashboardRoute(OrganisationTypeConstants.DEVELOPMENT_MANAGER), { replace: true });
      return;
    }

    if (hasAssetManagerUuid) {
      navigate(getDashboardRoute(OrganisationTypeConstants.ASSET_MANAGER), { replace: true });
      return;
    }

    logError({ error: `Could not navigate user with uuid ${currentUserResult.userUuid} to dashboard` });
    Toast.error({ message: "Could not navigate user to dashboard" });
  };

  const handleSubmit = useIsLoadingWrapper(async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setErrors([]);

    const res = await logon({ username: email, password });
    if (res.status === Status.Success && res.data) {
      window.ACCESS_TOKEN = res.data?.accessToken;
      const currentUserResult = await setCurrentUser(res.data?.userUuid);

      if (!currentUserResult?.organisations || currentUserResult.organisations.length === 0)
        setErrors([
          {
            code: errorCodeNoLinkedOrganisations,
            message: getErrorMessageFromCode(errorCodeNoLinkedOrganisations),
            property: "username",
          },
        ]);
      else if (location.state && (location.state as Location)?.pathname.includes("download-zip")) {
        navigate((location.state as Location)?.pathname, { replace: true });
      } else {
        navigateUserToDashboard(currentUserResult);
      }
    } else {
      setErrors(res.errors);
    }
  }, setIsHandleSubmitLoading);

  return {
    origin: searchParams.get("origin"),
    email,
    password,
    errors,
    isHandleSubmitLoading,
    setEmail,
    setPassword,
    handleSubmit,
  };
};
