/* eslint-disable jsx-a11y/click-events-have-key-events */
import "./Step.css";

import { Dispatch, forwardRef, SetStateAction } from "react";

import {
  CircleFilledIcon,
  CompletedCircleGreenIcon,
  ErrorIcon,
  MinusIcon,
  PlusIcon,
  RingIcon,
  VerticalLineLightIcon,
} from "../../../../../assets";
import { ColorConstants, OrganisationTypeConstants } from "../../../../../constants";
import { StepState } from "../../../../../models";
import { TabNotificationIndicator } from "../../../../wayfinding/Tabs/components";
import { SubStep, useStep } from "./useStep";

export interface StepProps {
  key: string;
  stepHeader: string;
  current: boolean;
  // only applies to L1 steps with no children; `undefined` otherwise.
  valid?: boolean | null;
  subSteps: SubStep[];
}

interface StepComponentProps {
  step: StepProps;
  lastStep: boolean;
  isReview?: boolean;
  currentUserType: OrganisationTypeConstants;
  handleMoveToStep?: (stepKeys: string[]) => void;
  clickedSubStep: string[] | undefined;
  setClickedSubStep: Dispatch<SetStateAction<string[] | undefined>>;
}

export const Step = forwardRef(
  (
    {
      step,
      lastStep,
      isReview,
      currentUserType,
      handleMoveToStep,
      clickedSubStep,
      setClickedSubStep,
    }: StepComponentProps,
    forwardedRef
  ): JSX.Element => {
    const { showSubSteps, setShowSubSteps, stepState, onStepSelect, onSubStepSelect } = useStep(
      { step, handleMoveToStep, isReview, clickedSubStep, setClickedSubStep },
      forwardedRef
    );

    const hasSubSteps = !!step.subSteps.length;

    let stepColours = [] as string[];
    switch (currentUserType) {
      case OrganisationTypeConstants.ASSET_MANAGER:
        stepColours = [
          ColorConstants.GREEN_60,
          ColorConstants.GREY_80,
          ColorConstants.BLUE,
          ColorConstants.BLUE,
          ColorConstants.BLUE,
        ];
        break;
      case OrganisationTypeConstants.VERIFIER:
        stepColours = [
          ColorConstants.WHITE,
          ColorConstants.WHITE,
          ColorConstants.GREEN_80,
          ColorConstants.GREEN,
          ColorConstants.YELLOW_DARK_80,
          ColorConstants.RED_60,
        ];
        break;
      case OrganisationTypeConstants.DEVELOPER:
        stepColours = [
          ColorConstants.WHITE,
          ColorConstants.WHITE,
          ColorConstants.GREEN_40,
          ColorConstants.GREEN_60,
          ColorConstants.RED_60,
          ColorConstants.YELLOW_DARK_80,
        ];
        break;
      case OrganisationTypeConstants.DEVELOPMENT_MANAGER:
        stepColours = [
          ColorConstants.WHITE,
          ColorConstants.WHITE,
          ColorConstants.GREEN_80,
          ColorConstants.GREEN,
          ColorConstants.RED,
        ];
        break;
      default:
        stepColours = [
          ColorConstants.GREEN_20,
          ColorConstants.GREY,
          ColorConstants.GREEN_80,
          ColorConstants.GREEN,
          ColorConstants.RED,
        ];
    }

    const getSubStepColor = (subStep: SubStep): string | undefined => {
      let color = stepColours[0];

      if (subStep.valid) {
        color = ColorConstants.GREEN;
      }

      if (subStep.valid === false) {
        color =
          currentUserType === OrganisationTypeConstants.VERIFIER ? ColorConstants.YELLOW_DARK_80 : ColorConstants.RED;
      }
      // Special case for verifier and developer
      if (subStep.valid === null) {
        color =
          currentUserType === OrganisationTypeConstants.VERIFIER
            ? ColorConstants.RED_60
            : ColorConstants.YELLOW_DARK_80;
      }

      return color;
    };

    const getStepLabelColor = (subStep: SubStep): string | undefined => {
      let color = stepColours[1];

      if (!isReview && subStep.current) {
        // eslint-disable-next-line prefer-destructuring
        color = stepColours[2];
        if (subStep.valid) {
          // eslint-disable-next-line prefer-destructuring
          color = stepColours[3];
        }

        if (subStep.valid === false) {
          // eslint-disable-next-line prefer-destructuring
          color = stepColours[4];
        }

        // Special case for verifier and developer
        if (subStep.valid === null && stepColours[5] !== undefined) {
          // eslint-disable-next-line prefer-destructuring
          color = stepColours[5];
        }
      }
      return color;
    };

    const getHeaderStepIcon = (): JSX.Element => {
      switch (stepState) {
        case StepState.NotStarted:
          return <RingIcon fill={stepColours[0]} />;
        case StepState.InProgress:
          return <RingIcon fill={ColorConstants.GREEN} />;
        case StepState.Complete:
          return <CompletedCircleGreenIcon />;
        case StepState.Invalid:
          return currentUserType === OrganisationTypeConstants.VERIFIER ? (
            <RingIcon fill={ColorConstants.YELLOW_DARK_80} />
          ) : (
            <ErrorIcon className="ErrorIcon" width={24} height={24} />
          );
        // Special case for verifier and developer
        case StepState.Warning:
          return currentUserType === OrganisationTypeConstants.DEVELOPER ? (
            <RingIcon fill={ColorConstants.YELLOW_DARK_80} />
          ) : (
            <ErrorIcon className="ErrorIcon" width={24} height={24} />
          );
        default:
          return <RingIcon fill={stepColours[0]} />;
      }
    };

    // TODO: add unread message logic
    const stepHasUnreadMessages = false;

    return (
      <div className={`${currentUserType} Step`}>
        <div
          role="button"
          onKeyDown={(e) => (e.key === "Enter" && hasSubSteps ? setShowSubSteps(!showSubSteps) : onStepSelect())}
          onClick={() => (hasSubSteps ? setShowSubSteps(!showSubSteps) : onStepSelect())}
          tabIndex={0}
          aria-label={hasSubSteps ? "Show Substeps" : "Select Step"}
          className="Header"
        >
          <div className="HeaderLeftSection">
            {getHeaderStepIcon()}
            <div>
              <p className={`${step.current && !isReview ? "Current" : ""} body2`}>{step.stepHeader}</p>
              {stepHasUnreadMessages && <TabNotificationIndicator />}
            </div>
          </div>
          {hasSubSteps && <div className="ExpandOrContractButton">{showSubSteps ? <MinusIcon /> : <PlusIcon />}</div>}
        </div>
        {hasSubSteps && showSubSteps && (
          <div className={`SubSteps ${lastStep && "LastStep"}`}>
            {step.subSteps.map((subStep, index) => {
              // TODO: add unread message logic
              const subStepHasUnreadMessages = false;
              return index !== 0 ? (
                <div className={`SubStep${subStep.current && !isReview ? " Current" : ""}`} key={subStep.key}>
                  <VerticalLineLightIcon className="VerticalLineLightIcon" stroke={getSubStepColor(subStep)} />
                  <div
                    role="button"
                    onKeyDown={(e) => (e.key === "Enter" ? onSubStepSelect(subStep.key) : null)}
                    onClick={() => onSubStepSelect(subStep.key)}
                    className="SubStepSelectable"
                    tabIndex={0}
                  >
                    <CircleFilledIcon fill={getSubStepColor(subStep)} />
                    <div
                      className="body3"
                      style={{
                        color: getStepLabelColor(subStep),
                      }}
                    >
                      <div>
                        {subStep.stepName}
                        {subStepHasUnreadMessages && <TabNotificationIndicator />}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={`SubStep${subStep.current && !isReview ? " Current" : ""}`} key={subStep.stepName}>
                  <div
                    role="button"
                    onKeyDown={(e) => (e.key === "Enter" ? onSubStepSelect(subStep.key) : null)}
                    onClick={() => onSubStepSelect(subStep.key)}
                    className="SubStepSelectable"
                    tabIndex={0}
                  >
                    <CircleFilledIcon fill={getSubStepColor(subStep)} />
                    <div
                      className="body3 SubStepSelectable"
                      style={{
                        color: getStepLabelColor(subStep),
                      }}
                    >
                      <div>
                        {subStep.stepName}
                        {subStepHasUnreadMessages && <TabNotificationIndicator />}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
);
