import "./StatusCard.css";

import { ProjectActivitiesConstants, ProjectStatusConstants } from "../../../../constants";
import { logError } from "../../../../service/error";

interface StatusCardProps {
  status: string;
  isFixed?: boolean;
  variant?: "Default" | "AssetManager";
}

export const StatusCard = ({ status, isFixed = false, variant = "Default" }: StatusCardProps): JSX.Element => {
  let classNames = isFixed ? "FixedStatusCard" : "StatusCard";
  switch (variant) {
    case "AssetManager":
      classNames += " AssetManager";
      break;
    default:
      break;
  }

  switch (status) {
    case ProjectStatusConstants.VALIDATED:
    case ProjectStatusConstants.VERIFIED:
    case ProjectStatusConstants.VALIDATION_PENDING:
    case ProjectStatusConstants.VERIFICATION_PENDING:
    case ProjectStatusConstants.ONGOING_VERIFICATION:
    case ProjectActivitiesConstants.STATUS_REVIEW_COMPLETE:
    case ProjectActivitiesConstants.STATUS_APPROVED:
    case ProjectActivitiesConstants.STATUS_SUBMITTED:
      classNames += " StatusCard_color_green";
      break;
    case ProjectStatusConstants.UNDER_DEVELOPMENT:
    case ProjectStatusConstants.SETUP_IN_PROGRESS:
    case ProjectActivitiesConstants.STATUS_CHANGES_REQUIRED:
    case ProjectActivitiesConstants.STATUS_REVIEW_IN_PROGRESS:
    case ProjectActivitiesConstants.STATUS_ASSESSMENT_IN_PROGRESS:
    case ProjectActivitiesConstants.STATUS_IN_PROGRESS:
      classNames += " StatusCard_color_coral";
      break;
    case ProjectStatusConstants.DRAFT:
    case ProjectStatusConstants.PRE_DEVELOPMENT:
    case ProjectStatusConstants.NOT_DELIVERED:
    case ProjectStatusConstants.CANCELLED:
    case ProjectActivitiesConstants.STATUS_SUBMITTED_TO_VVB:
    case ProjectActivitiesConstants.STATUS_ASSESSMENT_ASSIGNED:
    case ProjectActivitiesConstants.STATUS_REVIEW_ASSIGNED:
    case ProjectActivitiesConstants.STATUS_DELETED:
      classNames += " StatusCard_color_grey";
      break;

    case ProjectActivitiesConstants.STATUS_SUPERSEDED:
      classNames += " StatusCard_color_pink";
      break;
    case ProjectActivitiesConstants.STATUS_SUBMITTED_TO_REGISTRY:
    case ProjectActivitiesConstants.STATUS_SUBMITTED_TO_CODE:
    case ProjectActivitiesConstants.STATUS_ASSESSMENT_AWAITING_REVIEW:
      classNames += " StatusCard_color_cyan";
      break;
    case ProjectActivitiesConstants.STATUS_REVIEW_CANCELLED:
    case ProjectActivitiesConstants.STATUS_REJECTED:
      classNames += " StatusCard_color_red";
      break;
    default:
      classNames += " StatusCard_color_grey";
      logError({ error: `No card variant could be found for project status ${status}` });
      break;
  }

  return (
    <div className={classNames}>
      {variant === "Default" ? <h3>Status</h3> : <p className="body1">Status</p>}
      {variant === "Default" ? <h2>{status}</h2> : <p className="body01">{status}</p>}
    </div>
  );
};
