import { DeveloperTemplate } from "../../../templates";
import { getActivitiesRoute, getProjectsRoute } from "../../../utils/routes";
import { Column, KanaTooltip, Row, UserHeader } from "../../../widget";
import { DashboardTableCard } from "../../developer/dashboard/components";
import { DashboardPieCard } from "../../shared/components";
import { useDashboard } from "./useDashboard";

const Dashboard = (): JSX.Element => {
  const {
    isLoading,
    statusChartData,
    unitsChartData,
    currentUserType,
    onRecentActivitiesDataLoad,
    tableColumnsRecentActivities,
    isLoadingActivities,
    onTopProjectsDataLoad,
    tableColumnsTopProjects,
    isLoadingProjects,
  } = useDashboard();

  return (
    <DeveloperTemplate activeElement="Dashboard">
      <div>
        <Row spacingV="ml">
          <Column span={12}>
            <UserHeader />
          </Column>
        </Row>
        <Row spacingV="ml">
          <Column span={12}>
            <div className="HeaderContainer">
              <h1>Dashboard</h1>
            </div>
          </Column>
        </Row>
        {!isLoading && (
          <Row spacingV="ml">
            <Column span={6}>
              <DashboardPieCard
                title="Status"
                data={statusChartData}
                tooltip={
                  <KanaTooltip
                    tooltipHeader="Status"
                    tooltipText="Only projects where you are the registered developer are included in this graph"
                  />
                }
              />
            </Column>
            <Column span={6}>
              <DashboardPieCard
                title="Units"
                data={unitsChartData}
                noDataMessage="No units issued"
                tooltip={
                  <KanaTooltip
                    tooltipHeader="Buffer units"
                    tooltipText="Please note that buffer units are not shown in this chart"
                  />
                }
              />
            </Column>
          </Row>
        )}
        <Row spacingV="ml">
          <Column span={12}>
            <DashboardTableCard
              title="Recent activities"
              expandRoute={getActivitiesRoute(currentUserType)}
              onChange={onRecentActivitiesDataLoad}
              columns={tableColumnsRecentActivities}
              isLoading={isLoadingActivities}
            />
          </Column>
        </Row>
        <Row spacingV="ml">
          <Column span={12}>
            <DashboardTableCard
              title="Top projects"
              expandRoute={getProjectsRoute(currentUserType)}
              onChange={onTopProjectsDataLoad}
              columns={tableColumnsTopProjects}
              isLoading={isLoadingProjects}
            />
          </Column>
        </Row>
      </div>
    </DeveloperTemplate>
  );
};

export default Dashboard;
