/* eslint-disable dot-notation */
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { OrganisationRoleConstants, ProjectActivityReviewsConstants } from "../../../../constants";
import { TabsList } from "../../../../models";
import { WorkflowActivityReview } from "../../../../models/shared/workflowActivityReviewType";
import { IStateOrigin } from "../../../../navigators/navigateStateObjects";
import { logError } from "../../../../service/error";
import { SearchActivityReviewsResponse } from "../../../../service/query";
import { useAuth } from "../../../../useAuth";
import {
  getActivityReviewDashboardTabRoute,
  getActivityReviewRoute,
  getActivityReviewViewRoute,
  getDashboardRoute,
} from "../../../../utils/routes";
import { ChartData } from "../../../../widget";
import { ActivityReviewDashboardContext } from "../ActivityReviewDashboardContext";

interface UseActivityReviewPageReturnData {
  activityReviews: SearchActivityReviewsResponse | undefined;
  discussionsChartData: ChartData;
  workflowActivityReviews: WorkflowActivityReview[] | undefined;
  tabsList: TabsList;
  refreshActivityReviews: boolean;
  setRefreshActivityReviews: Dispatch<SetStateAction<boolean>>;
  handleBackClick?: () => void;
  checkIsManagerUser: boolean;
  backButtonText: string;
  isLoading: boolean;
  currentActivityReviewType?: string;
}

export const useActivityReviewPage = (): UseActivityReviewPageReturnData => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const stateOrigin = state?.origin as IStateOrigin;
  const { currentUserType, currentUserRole } = useAuth();
  const {
    activityReviewActivityUuid,
    activityReviews,
    activityReviewAggregate,
    hasUnreadDiscussions,
    refreshActivityReviews,
    queryParams,
    currentActivityReviewType,
    isLoading,
    setRefreshActivityReviews,
  } = useContext(ActivityReviewDashboardContext);

  const [workflowActivityReviews, setWorkflowActivityReviews] = useState<WorkflowActivityReview[]>();
  const [discussionsChartData, setDiscussionsChartData] = useState<ChartData>({});

  const checkIsManagerUser = currentUserRole === OrganisationRoleConstants.VVB_MANAGER;

  const backButtonText = stateOrigin?.goBackText ?? "Back to dashboard";

  const handleBackClick = (): void => {
    if (stateOrigin?.from) navigate(stateOrigin?.from);
    else navigate(getDashboardRoute(currentUserType));
  };

  const getTabsList = (): TabsList => {
    const list = [
      {
        label: "Documents",
        route: getActivityReviewDashboardTabRoute(
          activityReviewActivityUuid ?? "",
          "documents",
          // eslint-disable-next-line no-nested-ternary
          queryParams
        ),
      },
      {
        label: "Discussions",
        route: getActivityReviewDashboardTabRoute(
          activityReviewActivityUuid ?? "",
          "discussions",
          // eslint-disable-next-line no-nested-ternary
          queryParams
        ),
        notify: hasUnreadDiscussions,
      },
      {
        label: "Version history",
        route: getActivityReviewDashboardTabRoute(
          activityReviewActivityUuid ?? "",
          "history",
          // eslint-disable-next-line no-nested-ternary
          queryParams
        ),
      },
    ];

    if (checkIsManagerUser) {
      list.push({
        label: "Reviews",
        route: getActivityReviewDashboardTabRoute(
          activityReviewActivityUuid ?? "",
          "reviews",
          // eslint-disable-next-line no-nested-ternary
          queryParams
        ),
      });
    }

    return list;
  };

  const fetchWorkflowData = async (): Promise<void> => {
    if (activityReviews) {
      const mappedActivities = activityReviews.results.map((activityReview) => ({
        uuid: activityReview.activityReviewUuid,
        rowVersion: activityReview.rowVersion,
        activityUuid: activityReview.activity.uuid,
        activityRowVersion: activityReview.activity.rowVersion,
        activityHistoryUuid: activityReview.activity.currentVersion?.uuid ?? undefined,
        activityHistoryRowVersion: activityReview.activity.currentVersion?.rowVersion ?? undefined,
        assignedUserUuid: activityReview.assignedToUser?.uuid || "",
        isUnderReview:
          activityReview.status !== ProjectActivityReviewsConstants.STATUS_IN_REVIEW &&
          activityReview.status !== ProjectActivityReviewsConstants.STATUS_AWAITING_FEEDBACK &&
          activityReview.status !== ProjectActivityReviewsConstants.STATUS_AWAITING_APPROVAL,
        type: activityReview.type,
        status: activityReview.status,
        activityStatus: activityReview.activity.status,
        displayName: activityReview.activity.activityDefinition.displayName,
        completionPercentage: Math.round(activityReview.completionPercentage * 100),
        onEdit: () =>
          navigate(getActivityReviewRoute(activityReview.activityReviewUuid, `type=${activityReview.type}`)),
        onView: () => {
          if (activityReview.activity.currentVersion) {
            navigate(
              getActivityReviewViewRoute(
                activityReview.activity.currentVersion.uuid,
                checkIsManagerUser ? undefined : `type=${activityReview.type}`
              )
            );
          } else {
            logError({ error: "No cached activity history received for ActivityService.SearchActivityReviews" });
          }
        },
      }));

      setWorkflowActivityReviews(mappedActivities);
    }
  };

  useEffect(() => {
    if (activityReviewAggregate) {
      setDiscussionsChartData({
        "Resolved discussions": activityReviewAggregate?.resolvedDiscussions ?? 0,
        "Your unresolved discussions": activityReviewAggregate?.unresolvedDiscussionsAuthor ?? 0,
        "Other unresolved discussions": activityReviewAggregate?.unresolvedDiscussionsOrganisation ?? 0,
      });
    }
  }, [activityReviewAggregate]);

  useEffect(() => {
    fetchWorkflowData();
  }, [activityReviews]);

  return {
    activityReviews,
    discussionsChartData,
    tabsList: getTabsList(),
    refreshActivityReviews,
    setRefreshActivityReviews,
    handleBackClick,
    workflowActivityReviews,
    checkIsManagerUser,
    backButtonText,
    currentActivityReviewType,
    isLoading,
  };
};
