import { createContext, Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { ProjectPermissionConstants } from "../../../constants";
import { ActivityPageParams } from "../../../models";
import {
  getActivityDetails,
  GetActivityDetailsResponse,
  getActivityReviewAggregate,
  GetActivityReviewAggregateResponse,
  getCurrentUserProjectPermissions,
  SearchActivityHistoryResponse,
} from "../../../service/query";
import { fetchHasUnreadMessages } from "../../../service/query/QueryService.full";
import { Status } from "../../../service/Shared";
import { useAuth } from "../../../useAuth";
import { hasActivityPermissionForProject } from "../../../utils";

interface ActivityDashboardProviderProps {
  children?: JSX.Element | JSX.Element[];
}

interface ActivityDashboardContextType {
  activityUuid: string | undefined;
  activityDetails: GetActivityDetailsResponse | undefined;
  activityReviewAggregate: GetActivityReviewAggregateResponse | undefined;
  isExternalActivity: () => boolean;
  setShowVersionConflictModal: Dispatch<SetStateAction<boolean>>;
  setNewVersionActivityHistory: Dispatch<SetStateAction<SearchActivityHistoryResponse | undefined>>;
  setDraftActivityHistoryUuid: Dispatch<SetStateAction<string | undefined>>;
  setShowDetailsPanel: Dispatch<SetStateAction<boolean>>;
  onCloseDetailsPanel: () => void;
  setHasUnreadDiscussions: (val: boolean) => void;
  refreshUnreadDiscussionsTabIndicator: () => Promise<void>;
  refreshResolvedDiscussionsKPI: () => Promise<void>;
  shouldRefreshActivities: () => void;
  currentOrganisationUuid: string | undefined;
  refreshActivities: boolean;
  queryParams: string | undefined;
  showVersionConflictModal: boolean;
  hasUnreadDiscussions: boolean;
  showDetailsPanel: boolean;
  hasManageProjectActivityPermission: boolean;
  newVersionActivityHistory?: SearchActivityHistoryResponse;
  draftActivityHistoryUuid?: string;
}

export const ActivityDashboardContext = createContext<ActivityDashboardContextType>({} as ActivityDashboardContextType);

export const ActivityDashboardProvider = ({ children }: ActivityDashboardProviderProps): JSX.Element => {
  const { user, currentDeveloperUuid } = useAuth();
  const [searchParams] = useSearchParams();

  const userUuid = useMemo(() => user?.userUuid ?? "", [user]);

  const [currentOrganisationUuid, setCurrentOrganisationUuid] = useState<string | undefined>(undefined);
  const { activityUuid: rawActivityUuid } = useParams<ActivityPageParams>();
  const [activityDetails, setActivityDetails] = useState<GetActivityDetailsResponse>();

  const activityUuid = useMemo(() => rawActivityUuid, [rawActivityUuid]);

  const [hasManageProjectActivityPermission, setHasManageProjectActivityPermission] = useState(false);
  const [showVersionConflictModal, setShowVersionConflictModal] = useState(false);
  const [newVersionActivityHistory, setNewVersionActivityHistory] = useState<SearchActivityHistoryResponse>();
  const [draftActivityHistoryUuid, setDraftActivityHistoryUuid] = useState<string>();
  const [hasUnreadDiscussions, setHasUnreadDiscussions] = useState(false);
  const [activityReviewAggregate, setActivityReviewAggregate] = useState<GetActivityReviewAggregateResponse>();

  const [showDetailsPanel, setShowDetailsPanel] = useState(false);
  const [refreshActivities, setRefreshActivities] = useState(false);

  const shouldRefreshActivities = (): void => {
    setRefreshActivities((prev) => !prev);
  };

  const projectUuidURL = searchParams.get("projectUuid");
  const originURL = searchParams.get("origin");
  // eslint-disable-next-line no-nested-ternary
  const queryParams = projectUuidURL ? `projectUuid=${projectUuidURL}` : originURL ? `origin=${originURL}` : undefined;

  const onCloseDetailsPanel = (): void => {
    setShowDetailsPanel(false);
  };

  const refreshResolvedDiscussionsKPI = async (): Promise<void> => {
    // for discussions KPI
    await getActivityReviewAggregate({
      activityReviewUuid: null,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      activityUuid: activityUuid!,
      assignedToUserUuid: null,
    }).then((res) => {
      if (res.status === Status.Success && res.data) {
        setActivityReviewAggregate(res.data);
      }
    });
  };

  const refreshUnreadDiscussionsTabIndicator = async (
    currentOrganisationUuid2 = currentOrganisationUuid
  ): Promise<void> => {
    if (activityUuid) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const hasUnread = await fetchHasUnreadMessages(activityUuid, userUuid, currentOrganisationUuid2!);
      setHasUnreadDiscussions(hasUnread);
    }
  };

  const isExternalActivity = (): boolean => {
    return currentDeveloperUuid !== activityDetails?.project?.developer.uuid;
  };

  const fetchActivityDetails = useCallback(
    async (uuid: string) => {
      const response = await getActivityDetails({ activityUuid: uuid });

      if (response.status === Status.Success && response.data) {
        setActivityDetails(response.data);
        setCurrentOrganisationUuid(response.data.project.developer.organisationUuid || undefined);
        const projectUuid = response.data?.project.uuid;
        if (!projectUuid?.length) {
          return;
        }

        await getCurrentUserProjectPermissions({ projectUuids: [response.data.project.uuid] }).then((permissions) => {
          if (permissions.status === Status.Success && permissions.data) {
            setHasManageProjectActivityPermission(
              hasActivityPermissionForProject(
                permissions.data,
                response.data?.project.uuid,
                ProjectPermissionConstants.MANAGE_PROJECT_ACTIVITY
              )
            );
          }
        });

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await refreshUnreadDiscussionsTabIndicator(response.data.project.developer.organisationUuid!);
        await refreshResolvedDiscussionsKPI();
      }
    },
    [activityUuid]
  );

  useEffect(() => {
    if (userUuid && activityUuid && activityUuid.length > 10) {
      fetchActivityDetails(activityUuid);
    }
  }, [activityUuid, userUuid]);

  const memoisedValue = useMemo(
    () => ({
      activityUuid,
      activityDetails,
      queryParams,
      hasManageProjectActivityPermission,
      showVersionConflictModal,
      newVersionActivityHistory,
      draftActivityHistoryUuid,
      showDetailsPanel,
      hasUnreadDiscussions,
      activityReviewAggregate,
      refreshActivities,
      currentOrganisationUuid,

      isExternalActivity,
      shouldRefreshActivities,
      setShowVersionConflictModal,
      setNewVersionActivityHistory,
      setDraftActivityHistoryUuid,
      onCloseDetailsPanel,
      setShowDetailsPanel,
      setHasUnreadDiscussions,
      refreshUnreadDiscussionsTabIndicator,
      refreshResolvedDiscussionsKPI,
    }),
    [
      hasUnreadDiscussions,
      activityUuid,
      activityDetails,
      showDetailsPanel,
      activityReviewAggregate,
      showVersionConflictModal,
      newVersionActivityHistory,
      draftActivityHistoryUuid,
      hasManageProjectActivityPermission,
      refreshActivities,
    ]
  );

  return <ActivityDashboardContext.Provider value={memoisedValue}>{children}</ActivityDashboardContext.Provider>;
};
