import "./Pill.css";

import { capitalize } from "../../../utils";
import { usePill } from "./usePill";

export type PillVariant =
  | "green"
  | "blue"
  | "cyan"
  | "coral"
  | "pink"
  | "purple"
  | "red"
  | "yellow"
  | "grey"
  | "grey-clear"
  | "dark-green";

export type PillVariantMap = {
  [key: string]: PillVariant;
};
interface PillProps {
  label?: string;
  variant?: PillVariant;
  link?: string;
  variantMap?: PillVariantMap;
}

export const Pill = ({ label, variant, link, variantMap }: PillProps): JSX.Element => {
  if (!label) return <div />;

  const { computedVariant } = usePill(label, variant, variantMap);

  return link ? (
    <a href={link} target="_blank" rel="noopener noreferrer" className={`Pill Pill${capitalize(computedVariant)} Link`}>
      <p className="body2">{label}</p>
    </a>
  ) : (
    <div className={`Pill Pill${capitalize(computedVariant)}`}>
      <p className="body2">{label}</p>
    </div>
  );
};
