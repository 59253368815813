import "../DeveloperSingleCardTemplate/DeveloperSingleCardTemplate.css";
import "./DeveloperSingleCardWithTabsTemplate.css";

import { StandardConstants } from "../../constants";
import { NavigationActiveElement, TabsList } from "../../models";
import { Column, LeftNavigation, OrganisationIcon, Pill, Row, Tabs, UserHeader } from "../../widget";

interface DeveloperSingleCardWithTabsTemplateProps {
  activeElement: NavigationActiveElement;
  pageTitle: string | undefined;
  titlePill?: string;
  developer?: string;
  developerIcon?: string;
  tabsList: TabsList;
  backButtonText?: string;
  handleBackClick?: () => void;
  workflow?: JSX.Element | JSX.Element[];
}

export const DeveloperSingleCardWithTabsTemplate = ({
  activeElement,
  pageTitle,
  titlePill,
  developer,
  developerIcon,
  tabsList,
  backButtonText,
  handleBackClick,
  workflow,
}: DeveloperSingleCardWithTabsTemplateProps): JSX.Element => {
  return (
    <div className="DeveloperSingleCardTemplate">
      <div className="DeveloperSingleCardTemplateNavigation">
        <LeftNavigation activeElement={activeElement} />
      </div>
      <div className="DeveloperSingleCardTemplateContainer">
        {(backButtonText && handleBackClick && (
          <UserHeader backButtonText={backButtonText} handleBackClick={handleBackClick} />
        )) || <UserHeader />}
        <Row spacingV={developer && developerIcon ? "s" : "ll"}>
          <Column span={12} className="TitleAndPill">
            <h1>{pageTitle}</h1>
            {titlePill && <Pill label={titlePill} variantMap={StandardConstants.CODE_PILL_VARIANT_MAP} />}
          </Column>
        </Row>
        {developer && developerIcon && (
          <Row spacingV="ll">
            <Column span={6}>
              <div className="DeveloperInfo">
                {developerIcon && <OrganisationIcon organisationIcon={developerIcon} />}
                <p className="body1">{developer}</p>
              </div>
            </Column>
          </Row>
        )}
        {workflow && (
          <Row spacingV="ll">
            <Column span={12}>{workflow}</Column>
          </Row>
        )}
        <Tabs className="TemplateTabs" tabsList={tabsList} />
      </div>
    </div>
  );
};
