import { VersionConflictModal } from "../../../../templates/DeveloperProjectCardWithTabsTemplate/components";
import { Column, CursorDataGrid, Row } from "../../../../widget";
import { useActivitiesTab } from "./useActivitiesTab";

const ActivitiesTab = (): JSX.Element => {
  const {
    columns,
    isLoading,
    defaultSortingCriteria,
    setShowVersionConflictModal,
    showVersionConflictModal,
    newVersionActivityHistory,
    draftActivityHistoryUuid,
    onChange,
    subTitle,
  } = useActivitiesTab();

  return (
    <>
      <div className="ActivitiesTable">
        <Row spacingV="m">
          <Column span={12}>
            <h2>{subTitle}</h2>
          </Column>
        </Row>
        <Row>
          <Column span={12}>
            <CursorDataGrid
              columns={columns}
              clientSide={false}
              filterable
              onChange={onChange}
              data={[]}
              dataIsLoading={isLoading}
              noDataMessage="No activities to display"
              defaultSortingCriteria={defaultSortingCriteria}
            />
          </Column>
        </Row>
      </div>
      <VersionConflictModal
        showVersionConflictModal={showVersionConflictModal}
        draftActivityHistoryUuid={draftActivityHistoryUuid}
        closeModal={() => setShowVersionConflictModal(false)}
        newVersionActivityHistory={newVersionActivityHistory}
      />
    </>
  );
};

export default ActivitiesTab;
