import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";

import { ActivityReviewTypeConstants, ProjectActivitiesConstants } from "../../../constants";
import { WorkflowActivityReview } from "../../../models/shared/workflowActivityReviewType";
import { ActivityReviewDashboardContext } from "../../../route/verifier/activity-review-dashboard";
import { ProfileMenuItemType } from "../../../widget";

interface UseVerifierSingleCardWithTabsTemplateReturnData {
  quickActionsMenuOptionsList: ProfileMenuItemType[];
  setShowQuickActionMenu: Dispatch<SetStateAction<boolean>>;
  showQuickActionMenu: boolean;
  showReturnToAssessorModal: boolean;
  setShowReturnToAssessorModal: Dispatch<SetStateAction<boolean>>;
  showSubmitToRegistryModal: boolean;
  setShowSubmitToRegistryModal: Dispatch<SetStateAction<boolean>>;
  showSubmitToCodeModal: boolean;
  setShowSubmitToCodeModal: Dispatch<SetStateAction<boolean>>;
  showApproveModal: boolean;
  setShowApproveModal: Dispatch<SetStateAction<boolean>>;
  showCancelReviewModal: boolean;
  setShowCancelReviewModal: Dispatch<SetStateAction<boolean>>;

  activityAssessment?: WorkflowActivityReview;
  activityReview?: WorkflowActivityReview;
  refreshActivityReviews: boolean;
  setRefreshActivityReviews: Dispatch<SetStateAction<boolean>>;
}

interface VerifierSingleCardWithTabsTemplateProps {
  activityReviews?: WorkflowActivityReview[];
}

export const useVerifierSingleCardWithTabsTemplate = ({
  activityReviews,
}: VerifierSingleCardWithTabsTemplateProps): UseVerifierSingleCardWithTabsTemplateReturnData => {
  const { refreshActivityReviews, setRefreshActivityReviews } = useContext(ActivityReviewDashboardContext);

  const [showQuickActionMenu, setShowQuickActionMenu] = useState(false);
  const [showReturnToAssessorModal, setShowReturnToAssessorModal] = useState(false);
  const [showSubmitToRegistryModal, setShowSubmitToRegistryModal] = useState(false);
  const [showSubmitToCodeModal, setShowSubmitToCodeModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showCancelReviewModal, setShowCancelReviewModal] = useState(false);

  const [activityAssessment, setActivityAssessment] = useState<WorkflowActivityReview>();
  const [activityReview, setActivityReview] = useState<WorkflowActivityReview>();

  const quickActionsMenuOptionsList: ProfileMenuItemType[] = [];

  if (
    activityReviews !== undefined &&
    activityReviews[0]?.activityStatus !== ProjectActivitiesConstants.STATUS_REVIEW_CANCELLED &&
    activityReviews[0]?.activityStatus !== ProjectActivitiesConstants.STATUS_APPROVED
  ) {
    quickActionsMenuOptionsList.push({
      id: 1,
      value: "Cancel review",
      action: () => setShowCancelReviewModal(true),
    });
  }

  if (
    activityReviews !== undefined &&
    activityReviews[0]?.activityStatus === ProjectActivitiesConstants.STATUS_SUBMITTED_TO_CODE
  ) {
    quickActionsMenuOptionsList.push(
      {
        id: 2,
        value: "Return to assessor",
        action: () => setShowReturnToAssessorModal(true),
      },
      {
        id: 3,
        value: "Submit to registry",
        action: () => setShowSubmitToRegistryModal(true),
      }
    );
  }

  if (
    activityReviews !== undefined &&
    activityReviews[0]?.activityStatus === ProjectActivitiesConstants.STATUS_SUBMITTED_TO_REGISTRY
  ) {
    quickActionsMenuOptionsList.push(
      {
        id: 2,
        value: "Approve",
        action: () => setShowApproveModal(true),
      },
      {
        id: 3,
        value: "Return to assessor",
        action: () => setShowReturnToAssessorModal(true),
      }
    );
  }

  if (
    activityReviews !== undefined &&
    activityReviews[0]?.activityStatus === ProjectActivitiesConstants.STATUS_REVIEW_COMPLETE
  ) {
    quickActionsMenuOptionsList.push(
      {
        id: 2,
        value: "Submit to code",
        action: () => setShowSubmitToCodeModal(true),
      },
      {
        id: 3,
        value: "Return to assessor",
        action: () => setShowReturnToAssessorModal(true),
      }
    );
  }

  useEffect(() => {
    setActivityAssessment(activityReviews?.find((review) => review.type === ActivityReviewTypeConstants.ASSESSMENT));
    setActivityReview(activityReviews?.find((review) => review.type === ActivityReviewTypeConstants.REVIEW));
  }, [activityReviews]);

  return {
    showQuickActionMenu,
    setShowQuickActionMenu,
    quickActionsMenuOptionsList,
    showReturnToAssessorModal,
    setShowReturnToAssessorModal,
    showSubmitToRegistryModal,
    setShowSubmitToRegistryModal,
    showSubmitToCodeModal,
    setShowSubmitToCodeModal,
    showApproveModal,
    setShowApproveModal,
    showCancelReviewModal,
    setShowCancelReviewModal,

    activityAssessment,
    activityReview,
    refreshActivityReviews,
    setRefreshActivityReviews,
  };
};
