import "./DiscussionDetails.css";

import { ArrowLeftDarkIcon } from "../../../../../assets";
import { DiscussionVisibilityConstants } from "../../../../../constants";
import { Button, CursorDataGrid } from "../../../../../widget";
import { DiscussionThreadWithReply } from "../../../../../widget/general/Discussions/components";
import { useDiscussionDetails } from "./useDiscussionDetails";

export type DiscussionDetailsProps = {
  onHideDiscussion: () => void;
  isExternalActivity: () => boolean;
  visible?: boolean;
  canReply?: boolean;
};

export const DiscussionDetails = ({
  onHideDiscussion,
  isExternalActivity,
  visible,
  canReply,
}: DiscussionDetailsProps): JSX.Element => {
  const { thread, onChange, setThreadData, columns, selectedThreadDetails, refresh } = useDiscussionDetails();
  const className = `DiscussionViewWrapper DiscussionTable ${!visible && "hidden"}`;
  return (
    <div className={className}>
      <CursorDataGrid
        columns={columns}
        clientSide={false}
        data={[selectedThreadDetails ?? {}]}
        sortable={false}
        pageable={false}
        showTotalCount={false}
        onRowClick={onHideDiscussion}
        onChange={onChange}
        refresh={refresh}
      />
      <Button
        icon={<ArrowLeftDarkIcon />}
        text="Hide discussion"
        iconPosition="left"
        variant="reversedPrimary"
        onClick={onHideDiscussion}
        className="HideDiscussionButton"
      />
      <DiscussionThreadWithReply
        thread={thread}
        setThreadData={setThreadData}
        canReply={
          canReply &&
          (selectedThreadDetails?.visibility === DiscussionVisibilityConstants.INTERNAL ||
            (selectedThreadDetails?.visibility === DiscussionVisibilityConstants.EXTERNAL && !isExternalActivity())) &&
          !thread?.resolved
        }
      />
    </div>
  );
};
