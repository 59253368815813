import { Dispatch, SetStateAction, useState } from "react";

import { convertDateToStringDDMMYYYY } from "../../../../../../utils";
import { InformationBox, RadioButtonGroup } from "../../../../../../widget";
import { RadioChangeEvent } from "../../../../../../widget/forms/Input/RadioButtonGroup/components";
import { Review } from "../../../../../developer/activities";

interface EditableStepReviewProps {
  review: Review;
  handleIsValidChange: Dispatch<SetStateAction<boolean | undefined>>;
}

export const EditableStepReview = ({ review, handleIsValidChange }: EditableStepReviewProps): JSX.Element => {
  const [isEditState, setIsEditState] = useState(false);

  return isEditState ? (
    <div className="IsReviewValidSection">
      <p>Can you confirm this section is valid?</p>
      <RadioButtonGroup
        data={[
          { key: true, value: "Yes" },
          { key: false, value: "No" },
        ]}
        direction="horizontal"
        value={undefined}
        onChange={(e: RadioChangeEvent) => handleIsValidChange(e.target.value)}
      />
    </div>
  ) : (
    <InformationBox
      variant={review?.isValid ? "success" : "alert"}
      direction="row"
      corners="rounded"
      onEdit={() => setIsEditState(true)}
    >
      <p>
        This section was marked as {review?.isValid ? "valid" : "invalid"} by <b>{review?.createdByUserFullname} </b>
        on <b>{convertDateToStringDDMMYYYY(review?.createdAt)}</b>
      </p>
    </InformationBox>
  );
};
