import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { SortColumn } from "react-data-grid";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { CursorChangeProps, Origin, PrivateProjectDocumentsParams, ResultType } from "../../../../../models";
import { IStateOrigin } from "../../../../../navigators/navigateStateObjects";
import {
  getOrganisationDetails,
  SearchAuditDataRequest,
  SearchAuditDataResponse,
  SearchProjectOrganisationInvitations,
  SearchProjectOrganisationInvitationsResponse,
} from "../../../../../service/query";
import { searchAuditData } from "../../../../../service/query/QueryService.full";
import { FilterGroups, ResultData, Status } from "../../../../../service/Shared";
import { useAuth } from "../../../../../useAuth";
import { flattenObject } from "../../../../../utils";
import { getAuditObjectLink } from "../../../../../utils/audit";
import { getProjectDetailsByUuid } from "../../../../../utils/routes";
import {
  DataGridButtonLinkCellFormatterData,
  DataGridColumnDefinition,
  DataGridLinkCellFormatterData,
  dataGridMapFilterCriteria,
} from "../../../../../widget";
import { ProjectContext } from "../ProjectContext";

interface UseDocumentHistoryReturnData {
  projectDisplayName?: string;
  columns: DataGridColumnDefinition[];
  defaultSortingCriteria: SortColumn[];
  dataIsLoading: boolean;
  exportFileName: string;
  auditDataUuid?: string;
  auditDataChange?: string;
  showAuditChangesPanel: boolean;
  setShowAuditChangesPanel: Dispatch<SetStateAction<boolean>>;
  handleBackClick: () => void;
  onChange: ({ filtering, paging, sorting }: CursorChangeProps) => Promise<{
    resultData: ResultData[];
    paging: {
      pageSize: number;
      totalCount: number;
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  }>;
}

export const useAuditHistory = (): UseDocumentHistoryReturnData => {
  const navigate = useNavigate();
  const location = useLocation();

  const { currentUserType, currentOrganisationUuid } = useAuth();
  const { projectUuid } = useParams<PrivateProjectDocumentsParams>();
  const { projectDetails } = useContext(ProjectContext);

  const [dataIsLoading, setDataIsLoading] = useState(true);
  const [showAuditChangesPanel, setShowAuditChangesPanel] = useState(false);
  const [auditDataUuid, setAuditDataUuid] = useState<string>();
  const [auditDataChange, setAuditDataChange] = useState<string>();

  const [organisationDisplayName, setOrganisationDisplayName] = useState<string>();

  const handleBackClick = (): void => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    navigate(getProjectDetailsByUuid(projectUuid!, currentUserType));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: DataGridColumnDefinition[] = [
    {
      key: "id",
      name: "Audit ID",
      dataType: "number",
      minWidth: 40,
    },
    {
      name: "Type",
      key: "typeLink",
      dataType: "string",
      formatter: "link",
      filterable: true,
      sortable: true,
      minWidth: 80,
    },
    {
      key: "action",
      name: "Detail",
      dataType: "string",
      minWidth: 80,
    },
    {
      key: "changeButton",
      name: "Change",
      dataType: "string",
      formatter: "buttonLink",
      filterable: true,
      sortable: true,
      minWidth: 240,
    },
    {
      key: "userFullName",
      name: "User",
      dataType: "string",
      formatter: "stringWithEllipsis",
      minWidth: 120,
    },
    {
      key: "eventDateTime",
      name: "Date / Time",
      dataType: "Date",
      formatter: "timestamp",
      filterable: false,
      minWidth: 200,
    },
  ];

  const defaultSortingCriteria: SortColumn[] = [{ columnKey: "id", direction: "DESC" }];

  const formatData = useCallback(
    (responseData: SearchAuditDataResponse | undefined): ResultData[] => {
      return (
        responseData?.results?.map((d) => {
          const result = flattenObject(d);

          result.typeLink = {
            text: d.type,
            to: getAuditObjectLink(d.detail, d.objectUuid, currentUserType, Origin.ProjectAuditPage, projectUuid),
            state: { origin: { goBackText: "Back to audit", from: location.pathname } as IStateOrigin },
          } as DataGridLinkCellFormatterData;

          if (d.detail !== "Project created" && d.detail !== "Activity created") {
            result.changeButton = {
              text: d.detail,
              action: () => {
                setAuditDataUuid(d.uuid);
                setAuditDataChange(d.detail || undefined);
                setShowAuditChangesPanel(true);
              },
            } as DataGridButtonLinkCellFormatterData;
          } else {
            result.changeButton = {
              text: d.detail,
            } as DataGridButtonLinkCellFormatterData;
          }

          return result;
        }) || []
      );
    },
    [projectUuid]
  );

  const searchRelatedProjectInvitations = useCallback(async (): Promise<
    SearchProjectOrganisationInvitationsResponse | undefined
  > => {
    const filterCriteria = dataGridMapFilterCriteria([]);

    filterCriteria.project = {
      uuid: {
        operator: "eq",
        value: projectUuid,
      },
    };

    filterCriteria.sourceOrganisation = {
      uuid: {
        operator: "eq",
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        value: currentOrganisationUuid!,
      },
    };

    let data: SearchProjectOrganisationInvitationsResponse | undefined;
    await SearchProjectOrganisationInvitations({
      paging: { beforeCursor: null, afterCursor: null, limit: 50 },
      /* eslint-disable @typescript-eslint/no-explicit-any */
      filter: { results: filterCriteria },
    }).then((response) => {
      data = response.data;
    });

    return data;
  }, [projectUuid, currentOrganisationUuid]);

  const onChange = async ({ filtering, paging, sorting }: CursorChangeProps): Promise<ResultType> => {
    if (!projectUuid) throw new Error("Uuid must not be empty!");

    // eslint-disable-next-line prefer-const
    let data: ResultType = {
      resultData: [],
      paging: {
        pageSize: 0,
        totalCount: 0,
        startCursor: "",
        endCursor: "",
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };

    const request: SearchAuditDataRequest & FilterGroups<SearchAuditDataRequest["filter"]> = {
      paging: {
        limit: paging.pageSize,
        beforeCursor: paging.beforeCursor || null,
        afterCursor: paging.afterCursor || null,
      },
      /* eslint-disable @typescript-eslint/no-explicit-any */
      sort: sorting.map((s: { key: any; direction: any }) => ({
        // eslint-disable-next-line no-nested-ternary
        key: s.key === "typeLink" ? "type" : s.key === "changeButton" ? "detail" : (s.key as any),
        direction: s.direction,
      })),
    };

    const filterCriteria = dataGridMapFilterCriteria(filtering);

    if (filterCriteria.typeLink) {
      filterCriteria.objectType = filterCriteria.typeLink;
      filterCriteria.typeLink = undefined;
    }

    if (filterCriteria.changeButton) {
      filterCriteria.detail = filterCriteria.changeButton;
      filterCriteria.changeButton = undefined;
    }

    delete filterCriteria.typeLink;
    delete filterCriteria.changeButton;

    request.filter = { results: filterCriteria };

    // Always filter by project
    let filterResults: any = {
      project: {
        projectUuid: {
          operator: "eq",
          value: projectUuid,
        },
      },
    };

    // If in group, filter by group
    if (projectDetails?.group?.groupUuid) {
      filterResults = {
        ...filterResults,
        projectGroup: {
          projectGroupUuid: {
            operator: "eq",
            value: projectDetails?.group?.groupUuid || "",
          },
        },
      };
    }

    // If related invitations, filter by invitation[]
    const searchRelatedProjectInvitationsResponse = await searchRelatedProjectInvitations();
    if (searchRelatedProjectInvitationsResponse != null && searchRelatedProjectInvitationsResponse.results.length > 0) {
      filterResults = {
        ...filterResults,
        objectUuid: {
          operator: "in",
          value: searchRelatedProjectInvitationsResponse.results.map((invitation) => invitation.uuid),
        },
      };
    }

    request.filterGroups = [
      {
        operator: "or",
        filter: {
          results: filterResults,
        },
      },
    ];

    await searchAuditData(request)
      .then((response) => {
        data = {
          resultData: formatData(response.data),
          paging: {
            startCursor: response.data?.paging?.startCursor || "",
            endCursor: response.data?.paging?.endCursor || "",
            pageSize: paging.pageSize || 10,
            totalCount: response.data?.paging?.total || 0,
            hasNextPage: response.data?.paging?.hasNextPage || false,
            hasPreviousPage: response.data?.paging?.hasPreviousPage || false,
          },
        };
      })
      .finally(() => {
        setDataIsLoading(false);
      });
    return data;
  };

  const getOrganisationDisplayName = useCallback(
    async (organisationUuid: string) => {
      await getOrganisationDetails({ organisationUuid }).then((res) => {
        if (res.status === Status.Success && res.data) {
          setOrganisationDisplayName(res.data.displayName ?? undefined);
        }
      });
    },
    [currentOrganisationUuid]
  );

  useEffect(() => {
    if (currentOrganisationUuid) getOrganisationDisplayName(currentOrganisationUuid);
  }, [currentOrganisationUuid]);

  return {
    projectDisplayName: projectDetails?.displayName,
    exportFileName: `${projectDetails?.displayName ? `${projectDetails?.displayName}_` : ""}${
      organisationDisplayName ? `${organisationDisplayName}_` : ""
    }Audit_History_`.replaceAll(" ", "_"),
    columns,
    defaultSortingCriteria,
    dataIsLoading,
    auditDataUuid,
    auditDataChange,
    showAuditChangesPanel,
    setShowAuditChangesPanel,
    handleBackClick,
    onChange,
  };
};
