import "./ActivityReviewWorkflowButtonsTemplate.css";

import { Dispatch, SetStateAction } from "react";

import { DownloadDarkIcon } from "../../../../../../../../assets";
import { Button } from "../../../../../../../../widget";

interface ActivityReviewWorkflowButtonsTemplateProps {
  btn1Text?: string;
  btn1OnClick?: (() => void | Promise<void> | (() => Promise<void>)) | undefined;
  btn1IsLoading?: boolean;
  btn2Text?: string;
  btn2OnClick?: (() => void | Promise<void> | (() => Promise<void>)) | undefined;
  btn2IsLoading?: boolean;
  setDropdownOffsetX: Dispatch<SetStateAction<number>>;
  setDropdownOffsetY: Dispatch<SetStateAction<number>>;
  setShowDownloadDropdown: Dispatch<SetStateAction<boolean>>;
}

export const ActivityReviewWorkflowButtonsTemplate = ({
  btn1Text,
  btn1OnClick,
  btn1IsLoading,
  btn2Text,
  btn2OnClick,
  btn2IsLoading,
  setDropdownOffsetX,
  setDropdownOffsetY,
  setShowDownloadDropdown,
}: ActivityReviewWorkflowButtonsTemplateProps): JSX.Element => (
  <div className="ActivityReviewWorkflowButtonsWrapper">
    <div className="ButtonContainer">
      {btn1Text && btn1OnClick && (
        <Button size="small" text={btn1Text} onClick={btn1OnClick} isLoading={btn1IsLoading} />
      )}
      {btn2Text && btn2OnClick && (
        <Button variant="tertiary" size="small" text={btn2Text} onClick={btn2OnClick} isLoading={btn2IsLoading} />
      )}
      <div
        ref={(el) => {
          if (!el) return;
          setDropdownOffsetX(el.getBoundingClientRect().left);
          setDropdownOffsetY(el.getBoundingClientRect().top);
        }}
      >
        <DownloadDarkIcon className="DownloadButton" onClick={() => setShowDownloadDropdown(true)} />
      </div>
    </div>
  </div>
);
