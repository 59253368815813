import "./NewDiscussion.css";

import { Dispatch, SetStateAction } from "react";

import { InformationIcon } from "../../../../../assets";
import { Button, Column, ErrorBox, ProfileMenu, ProfileMenuItem, Row, TextArea } from "../../../..";
import { Thread } from "../../../models";
import { discussionInfoBox } from "../DiscussionInfoBox";
import { DiscussionThread } from "../DiscussionThread";
import { NewThreadInformation, useNewDiscussion } from "./useNewDiscussion";

interface NewDiscussionProps {
  threads: Thread[];
  setThreads: Dispatch<SetStateAction<Thread[]>>;
  internalTeamSelected: boolean;
  externalSelected: boolean;
  handleInternalTeamSelect: () => void;
  handleExternalSelect: () => void;
  closeDiscussion: () => void;
  newThreadInformation: NewThreadInformation;
  externalUserType: string;
  canWriteExternal: boolean;
}

export const NewDiscussion = ({
  threads,
  setThreads,
  internalTeamSelected,
  externalSelected,
  handleInternalTeamSelect,
  handleExternalSelect,
  closeDiscussion,
  newThreadInformation,
  externalUserType,
  canWriteExternal,
}: NewDiscussionProps): JSX.Element => {
  const {
    errors,
    showMessageDropdown,
    messageDropdownOptions,
    setShowMessageDropdown,
    newMessage,
    setNewMessage,
    handleSendDiscussion,
    handleNewInternalDiscussion,
    firstStepCompleted,
    setFirstStepCompleted,
    secondStepCompleted,
    isHandleSendDiscussionLoading,
  } = useNewDiscussion({
    threads,
    setThreads,
    newThreadInformation,
    internalTeamSelected,
    externalUserType,
    handleInternalTeamSelect,
    handleExternalSelect,
  });

  return (
    <>
      {!firstStepCompleted && !secondStepCompleted && (
        <>
          <div className="NewDiscussionHeader">
            <p className="DiscussionTitle body1">Add a discussion</p>
          </div>
          <div className="NewDiscussionButton">
            <Button
              text={canWriteExternal ? "Discuss with..." : "New discussion"}
              onClick={() => (canWriteExternal ? setShowMessageDropdown(true) : handleNewInternalDiscussion())}
            />
            <ProfileMenu
              show={showMessageDropdown}
              onClose={() => setShowMessageDropdown(false)}
              position={{ top: "2px", right: "unset" }}
            >
              {messageDropdownOptions.map((el) => (
                <ProfileMenuItem
                  key={el.id}
                  profileMenuItem={el}
                  onClick={() => {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    el.action!();
                  }}
                />
              ))}
            </ProfileMenu>
          </div>
        </>
      )}

      {firstStepCompleted && !secondStepCompleted && (
        <div className="DiscussionCardBody">
          <div className="NewDiscussionHeader">
            <div>
              <p className="DiscussionTitle body1">Add a discussion</p>
              {errors && errors.length > 0 && (
                <Row spacingV="ml" justify="center">
                  <Column span={12}>
                    <ErrorBox
                      title="Please solve the below issues before saving"
                      text={errors.map((error) => error.message)}
                    />
                  </Column>
                </Row>
              )}
            </div>
          </div>
          <div className="VisibilityInfo">
            <InformationIcon width={16} height={16} />
            <p>
              {internalTeamSelected
                ? "Visible to internal team only"
                : `Discussing with the ${externalUserType.toLowerCase()}`}
            </p>
          </div>
          <div className="NewDiscussionMessage">
            <TextArea
              name="NewDiscussionMessage"
              displayCharacterCount
              maxLength={500}
              value={newMessage}
              onChange={setNewMessage}
            />
          </div>
          {discussionInfoBox({ externalUserType, internalTeamSelected })}
          <div className="NewDiscussionMessageActions">
            <Button text="Send discussion" onClick={handleSendDiscussion} isLoading={isHandleSendDiscussionLoading} />
            <Button
              text="Cancel"
              variant="secondary"
              onClick={() => {
                setFirstStepCompleted(false);
                closeDiscussion();
              }}
            />
          </div>
        </div>
      )}

      {secondStepCompleted && (
        <DiscussionThread
          threads={threads}
          setThreads={setThreads}
          internalTeamSelected={internalTeamSelected}
          externalSelected={externalSelected}
          handleInternalTeamSelect={handleInternalTeamSelect}
          handleExternalSelect={handleExternalSelect}
          closeDiscussion={closeDiscussion}
          newThreadInformation={newThreadInformation}
          externalUserType={externalUserType}
          canWriteExternal={canWriteExternal}
        />
      )}
    </>
  );
};
