import "./DeveloperProjectCardWithTabsTemplate.css";

import { OrganisationTypeConstants, ProjectPermissionConstants, StandardConstants } from "../../constants";
import { NavigationActiveElement, TabsList } from "../../models";
import { getProjectPendingUnits, getProjectUnitPrice, getProjectVerifiedUnits } from "../../utils";
import {
  Column,
  ExpandableCard,
  InformationBox,
  KanaTooltip,
  LeftNavigation,
  OrganisationIcon,
  PieChart,
  Pill,
  ProfileMenu,
  ProfileMenuItem,
  QuickActionsMenu,
  RatingKPI,
  RiskKPI,
  Row,
  Tabs,
  UserHeader,
  Workflow,
} from "../../widget";
import { UnitsAveragePriceKPI } from "../../widget/general/Kpis/UnitsAveragePriceKPI";
import {
  DeleteProjectModal,
  MergeProjectsModal,
  MultiDraftModal,
  PublishProjectModal,
  VersionConflictModal,
} from "./components";
import { useDeveloperProjectCardWithTabsTemplate } from "./useDeveloperProjectCardWithTabsTemplate";

interface DeveloperProjectCardWithTabsTemplateProps {
  activeElement: NavigationActiveElement;
  pageTitle: string | undefined;
  projectCode: string | undefined;
  projectVerifier: string | undefined;
  projectDeveloper?: string | undefined;
  projectDeveloperIcon?: string | undefined;
  projectStatus: string | undefined;
  projectUuid: string | undefined;
  chartData: { [key: string]: number };
  tabsList: TabsList;
  backButtonText?: string;
  handleBackClick?: () => void;
}

export const DeveloperProjectCardWithTabsTemplate = ({
  activeElement,
  pageTitle,
  projectCode,
  projectVerifier,
  projectDeveloper,
  projectDeveloperIcon,
  projectStatus,
  projectUuid,
  chartData,
  tabsList,
  backButtonText,
  handleBackClick,
}: DeveloperProjectCardWithTabsTemplateProps): JSX.Element => {
  const {
    isProjectAssociatedWithCode,
    setShowQuickActionMenu,
    showQuickActionMenu,
    setShowMergeProjectsModal,
    showMergeProjectsModal,
    setShowPublishProjectModal,
    showPublishProjectModal,
    setShowDeleteProjectModal,
    showDeleteProjectModal,
    setShowMultiDraftModal,
    showMultiDraftModal,
    setShowVersionConflictModal,
    showVersionConflictModal,
    draftActivityHistory,
    newVersionActivityHistory,
    draftActivityHistoryUuid,
    quickActionsMenuOptionsList,
    workflowActivities,
    navigateToDraftActivity,
    resumeDraft,
    isExternalProject,
    hasProjectPermission,
    shouldRefreshActivities,
    shouldRefreshProjectDetails,
    currentUserType,
  } = useDeveloperProjectCardWithTabsTemplate(projectStatus, projectUuid);

  return (
    <div className="DeveloperProjectCardWithTabsTemplate">
      <div className="DeveloperProjectCardWithTabsTemplateNavigation">
        <LeftNavigation activeElement={activeElement} />
      </div>
      <div className="DeveloperProjectCardWithTabsTemplateContainer">
        <Row spacingV="ll">
          <Column span={12}>
            <UserHeader backButtonText={backButtonText} handleBackClick={handleBackClick} />
          </Column>
        </Row>
        {draftActivityHistory && (
          <Row spacingV="l">
            <Column span={12}>
              <InformationBox variant="info">
                <div className="ProjectActivityNotificationContent">
                  <p className="body2">You have a draft activity that has not been saved. You can resume the draft </p>
                  <button className="BtnLink body2" onClick={navigateToDraftActivity}>
                    here
                  </button>
                </div>
              </InformationBox>
            </Column>
          </Row>
        )}
        <Row
          spacingV={
            (projectDeveloper && projectDeveloperIcon) ||
            (isProjectAssociatedWithCode && projectCode) ||
            projectVerifier
              ? "m"
              : "ll"
          }
        >
          <Column span={12}>
            <div className="HeaderContent">
              <h1>{pageTitle}</h1>
              {/* KAN-5081: Hiding the quick action menu for developers */}
              {/* {(projectStatus === ProjectStatusConstants.DRAFT ||
                projectStatus === ProjectStatusConstants.PRE_DEVELOPMENT ||
                !isProjectAssociatedWithCode || */}
              {quickActionsMenuOptionsList.length > 0 && (
                <div>
                  <QuickActionsMenu onClick={() => setShowQuickActionMenu(true)} />
                  <ProfileMenu
                    show={showQuickActionMenu}
                    onClose={() => setShowQuickActionMenu(false)}
                    position={{ top: "0" }}
                  >
                    {quickActionsMenuOptionsList.map((el) => (
                      <ProfileMenuItem
                        key={el.id}
                        profileMenuItem={el}
                        onClick={() => {
                          if (el.action) {
                            el.action();
                          }
                          setShowQuickActionMenu(false);
                        }}
                      />
                    ))}
                  </ProfileMenu>
                </div>
              )}
            </div>
          </Column>
        </Row>
        {((isProjectAssociatedWithCode && projectCode) ||
          projectVerifier ||
          projectDeveloper ||
          projectDeveloperIcon) && (
          <Row spacingV="ll">
            <Column span={12}>
              <div className="ProjectVerifierAndCode">
                {isProjectAssociatedWithCode && projectCode && (
                  <Pill label={projectCode} variantMap={StandardConstants.CODE_PILL_VARIANT_MAP} />
                )}
                {projectVerifier && <Pill label={projectVerifier} variant="grey-clear" />}
                {(projectDeveloper || projectDeveloperIcon) && (
                  <div className="DeveloperInfo">
                    {projectDeveloperIcon && <OrganisationIcon organisationIcon={projectDeveloperIcon} />}
                    <p className="body1">{projectDeveloper}</p>
                  </div>
                )}
              </div>
            </Column>
          </Row>
        )}
        {currentUserType === OrganisationTypeConstants.ASSET_MANAGER ? (
          <Row spacingV="ll">
            <Column span={4}>
              <Workflow
                status={projectStatus}
                activities={workflowActivities ? [workflowActivities[0]] : []}
                canManageActivity={false}
                isExternal
                variant="AssetManager"
                shouldRefreshActivities={shouldRefreshActivities}
                shouldRefreshProjectDetails={shouldRefreshProjectDetails}
              />
            </Column>
            <Column span={8}>
              <Row style={{ height: "100%" }}>
                <Column span={5}>
                  <UnitsAveragePriceKPI
                    unitsData={{
                      pending: getProjectPendingUnits(projectUuid || ""),
                      verified: getProjectVerifiedUnits(projectUuid || ""),
                    }}
                    averagePrice={{
                      value: getProjectUnitPrice(projectUuid || ""),
                      percentageChange: 3,
                      lastUpdated: new Date(),
                    }}
                  />
                </Column>
                <Column span={7}>
                  <Row style={{ height: "100%" }}>
                    <Column span={6}>
                      <RiskKPI risk={2} />
                    </Column>
                    <Column span={6}>
                      <RatingKPI rating={4} />
                    </Column>
                  </Row>
                </Column>
              </Row>
            </Column>
          </Row>
        ) : (
          <Row spacingV="ll">
            <Column span={7}>
              <Workflow
                status={projectStatus}
                activities={workflowActivities || []}
                canManageActivity={hasProjectPermission(ProjectPermissionConstants.MANAGE_PROJECT_ACTIVITY)}
                isExternal={isExternalProject()}
                shouldRefreshActivities={shouldRefreshActivities}
                shouldRefreshProjectDetails={shouldRefreshProjectDetails}
              />
            </Column>
            <Column span={5}>
              <ExpandableCard
                title="Units"
                contentAlignment="center"
                tooltip={
                  <KanaTooltip
                    tooltipHeader="Buffer units"
                    tooltipText="Please note that buffer units created by this project are not shown in this chart"
                  />
                }
                height="100%"
              >
                <PieChart size={120} data={chartData} noDataMessage="No units issued" />
              </ExpandableCard>
            </Column>
          </Row>
        )}
        <Tabs className="TemplateTabs" tabsList={tabsList} />
      </div>
      <MergeProjectsModal
        showMergeProjectsModal={showMergeProjectsModal}
        closeModal={() => setShowMergeProjectsModal(false)}
      />
      <PublishProjectModal
        showPublishProjectModal={showPublishProjectModal}
        closeModal={() => setShowPublishProjectModal(false)}
      />
      <DeleteProjectModal
        showDeleteProjectModal={showDeleteProjectModal}
        closeModal={() => setShowDeleteProjectModal(false)}
      />
      <MultiDraftModal
        showMultiDraftModal={showMultiDraftModal}
        resume={resumeDraft}
        closeModal={() => setShowMultiDraftModal(false)}
        draftActivityHistory={draftActivityHistory}
      />
      <VersionConflictModal
        showVersionConflictModal={showVersionConflictModal}
        draftActivityHistoryUuid={draftActivityHistoryUuid}
        closeModal={() => setShowVersionConflictModal(false)}
        newVersionActivityHistory={newVersionActivityHistory}
      />
    </div>
  );
};
