import "./IconForm.css";

import { Dispatch } from "react";

import { OrganisationPermissionConstants, OrganisationTypeConstants } from "../../../../../../constants";
import {
  ActionButtons,
  Column,
  ErrorBox,
  KanaTooltip,
  OrganisationIcon,
  Row,
  SingleFileUpload,
} from "../../../../../../widget";
import { OrganisationFormChangedAction } from "../../models";
import { useIconForm } from "./useIconForm";

interface LogoFormProps {
  objectUuid: string;
  objectType: string;
  organisationDefaultIcon: string | null;
  dispatch: Dispatch<OrganisationFormChangedAction>;
}

export const IconForm = ({ objectUuid, objectType, organisationDefaultIcon, dispatch }: LogoFormProps): JSX.Element => {
  const {
    displayedIcon,
    iconUploadRef,
    errors,
    isHandleSubmitLoading,
    currentUserType,
    setIcon,
    handleCancel,
    handleSubmit,
    hasPermission,
  } = useIconForm(objectUuid, objectType, organisationDefaultIcon, dispatch);

  return (
    <>
      {errors && errors.length > 0 && (
        <Row spacingV="ml">
          <Column span={5}>
            <ErrorBox title="Please solve the below issues before saving" text={errors.map((error) => error.message)} />
          </Column>
        </Row>
      )}
      <form onSubmit={(e) => handleSubmit(e)} className="OrganisationIconForm">
        <Row spacingV="2xl">
          <Column span={5}>
            <SingleFileUpload
              label="New icon"
              informationLabel="The image should be a square, maximum 192px x 192px"
              ref={iconUploadRef}
              maxFileSize={0.5}
              tooltip={
                currentUserType === OrganisationTypeConstants.DEVELOPER && (
                  <KanaTooltip
                    tooltipHeader="Where will the icon show?"
                    tooltipText="This is the icon which will be displayed next to your developer name on the public project list and project details page"
                  />
                )
              }
              onChange={(f) => {
                setIcon(f);
              }}
            />
          </Column>
          <Column span={2} offset={1}>
            <div className="IconContainer">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label id="icon">Current icon</label>
              {displayedIcon && <OrganisationIcon organisationIcon={displayedIcon} />}
            </div>
          </Column>
        </Row>
        {hasPermission(OrganisationPermissionConstants.WRITE) && (
          <Row spacingV="2xl">
            <Column span={5}>
              <ActionButtons onCancel={handleCancel} isLoading={isHandleSubmitLoading} />
            </Column>
          </Row>
        )}
      </form>
    </>
  );
};
