import { Dispatch, SetStateAction, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  OrganisationTypeConstants,
  ProjectActivitiesConstants,
  ProjectActivityReviewsConstants,
} from "../../../../constants";
import { transitionActivity } from "../../../../service/activity";
import { deleteActivityReview } from "../../../../service/activity/ActivityService.full";
import { Status } from "../../../../service/Shared";
import { getErrorMessageFromCode } from "../../../../service/ValidationErrorFormatter";
import { useIsLoadingWrapper } from "../../../../utils";
import { getDashboardRoute } from "../../../../utils/routes";
import { Toast } from "../../../../widget";

/* eslint-disable unused-imports/no-unused-vars */
interface useCancelReviewModalProps {
  onClose: () => void;
  activityUuid: string;
  activityStatus: string;
  activityRowVersion: number;
  activityReviewUuid?: string;
  activityReviewStatus?: string;
  activityReviewRowVersion?: number;
  activityAssessmentUuid: string;
  activityAssessmentStatus: string;
  activityAssessmentRowVersion: number;
  refreshActivityReviews?: boolean;
  setRefreshActivityReviews?: Dispatch<SetStateAction<boolean>>;
  exitActivityReviewWizard?: () => void;
}

interface useCancelReviewModalReturnData {
  isLoading: boolean;
  onConfirmClick: () => void;
}

export const useCancelReviewModal = ({
  onClose,
  activityUuid,
  activityStatus,
  activityRowVersion,
  activityReviewUuid,
  activityReviewStatus,
  activityReviewRowVersion,
  activityAssessmentUuid,
  activityAssessmentStatus,
  activityAssessmentRowVersion,
}: useCancelReviewModalProps): useCancelReviewModalReturnData => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const onConfirmClick = useIsLoadingWrapper(async (): Promise<void> => {
    await deleteActivityReview({ activityUuid, rowVersion: activityRowVersion }).then(
      async (deleteActivityReviewRes) => {
        if (deleteActivityReviewRes.status === Status.Success) {
          const activityReviews = [
            {
              activityReviewUuid: activityAssessmentUuid,
              rowVersion: activityAssessmentRowVersion + 1,
              fromStatus: activityAssessmentStatus,
              toStatus: ProjectActivityReviewsConstants.STATUS_CANCELLED,
            },
          ];

          if (activityReviewUuid && activityReviewStatus && activityReviewRowVersion) {
            activityReviews.push({
              activityReviewUuid,
              rowVersion: activityReviewRowVersion + 1,
              fromStatus: activityReviewStatus,
              toStatus: ProjectActivityReviewsConstants.STATUS_CANCELLED,
            });
          }

          await transitionActivity({
            activity: {
              uuid: activityUuid,
              rowVersion: activityRowVersion,
              toStatus: ProjectActivitiesConstants.STATUS_REVIEW_CANCELLED,
              fromStatus: activityStatus,
              publishExternalMessages: false,
              publishInternalMessages: false,
            },
            activityReviews,
          }).then((transitionActivityRes) => {
            if (transitionActivityRes.status === Status.Success) {
              Toast.success({ message: "Activity review cancelled" });
              navigate(getDashboardRoute(OrganisationTypeConstants.VERIFIER));
              onClose();
            } else if (transitionActivityRes.errors && transitionActivityRes.errors.length > 0) {
              Toast.error({ message: getErrorMessageFromCode(transitionActivityRes.errors[0].message) });
            }
          });
        } else if (deleteActivityReviewRes.errors && deleteActivityReviewRes.errors.length > 0) {
          Toast.error({ message: getErrorMessageFromCode(deleteActivityReviewRes.errors[0].message) });
        }
      }
    );
  }, setIsLoading);

  return {
    isLoading,
    onConfirmClick,
  };
};
