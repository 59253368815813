import { Dispatch, SetStateAction, useCallback, useContext, useState } from "react";
import { SortColumn } from "react-data-grid";
import { useNavigate, useParams } from "react-router-dom";

import { EyeBlueIcon } from "../../../../../assets";
import { OrganisationTypeConstants, ProjectPermissionConstants } from "../../../../../constants";
import { CursorChangeProps, ResultType } from "../../../../../models";
import { downloadProjectDocument } from "../../../../../service/project";
import { searchProjectDocuments, SearchProjectDocumentsResponse } from "../../../../../service/query";
import { ResultData } from "../../../../../service/Shared";
import { useAuth } from "../../../../../useAuth";
import { flattenObject, initiateDocumentDownloadViaBrowser } from "../../../../../utils";
import { getDocumentHistoryRoute } from "../../../../../utils/routes";
import {
  DataGridButtonLinkCellFormatterData,
  DataGridColumnDefinition,
  DataGridColumnDefinitionWithCustomCellFormatter,
  dataGridMapFilterCriteria,
} from "../../../../../widget";
import { IconCellFormatterData } from "../../../../../widget/data/DataGrid/models";
import { downloadDocumentCellFormatter } from "../../../../developer/activities";
import { ProjectContext } from "../ProjectContext";
import { uploadDocumentCellFormatter } from "./components";

interface UseDocumentsReturnData {
  columns: DataGridColumnDefinition[];
  defaultSortingCriteria: SortColumn[];
  hasManageProjectDocumentPermission: boolean;
  dataIsLoading: boolean;
  uploadNewDocument: () => void;
  uploadDocumentUuid?: string;
  latestDocumentVersionUuid?: string;
  refreshDocuments: boolean;
  setRefreshDocuments: Dispatch<SetStateAction<boolean>>;
  showUploadModal: boolean;
  setShowUploadModal: Dispatch<SetStateAction<boolean>>;
  onChange: ({ filtering, paging, sorting }: CursorChangeProps) => Promise<{
    resultData: ResultData[];
    paging: {
      pageSize: number;
      totalCount: number;
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  }>;
}

export const useDocuments = (): UseDocumentsReturnData => {
  const navigate = useNavigate();
  const { currentUserType } = useAuth();
  const { projectUuid } = useParams<"projectUuid">();
  const { projectDetails, hasProjectPermission } = useContext(ProjectContext);

  const [dataIsLoading, setDataIsLoading] = useState(true);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [uploadDocumentUuid, setUploadDocumentUuid] = useState<string>();
  const [latestDocumentVersionUuid, setLatestDocumentVersionUuid] = useState<string>();
  const [refreshDocuments, setRefreshDocuments] = useState(false);

  const hasManageProjectDocumentPermission = hasProjectPermission(ProjectPermissionConstants.MANAGE_PROJECT_DOCUMENT);

  const uploadNewDocument = (): void => {
    setUploadDocumentUuid(undefined);
    setLatestDocumentVersionUuid(undefined);
    setShowUploadModal(true);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: (DataGridColumnDefinition | DataGridColumnDefinitionWithCustomCellFormatter<any>)[] = [
    { key: "type", name: "Type", dataType: "string", formatter: "stringWithEllipsis", minWidth: 180 },
    {
      key: "variant",
      name: "Variant",
      dataType: "string",
      formatter: "stringWithEllipsis",
      filterable: false,
      minWidth: 180,
    },
    {
      key: "groupId",
      name: "Group doc",
      dataType: "number",
      formatter: "yesNo",
      filterable: false,
    },
    {
      key: "version",
      name: "Version",
      dataType: "number",
      formatter: "align",
      minWidth: 140,
      filterable: true,
    },
    {
      key: "updatedAt",
      name: "Last updated",
      dataType: "Date",
      formatter: "dateOnly",
      filterable: false,
      minWidth: 140,
    },
    {
      key: "updatedByUser.fullName",
      name: "Last updated by",
      dataType: "string",
      formatter: "stringWithEllipsis",
      filterable: true,
      sortable: true,
      minWidth: 140,
    },
    {
      key: "detailsLink",
      name: "Details",
      dataType: "string",
      formatter: "icon",
      alignment: "center",
      filterable: false,
      sortable: false,
      minWidth: 140,
    },
    {
      key: "downloadIcon",
      name: "Download latest",
      dataType: "string",
      formatter: "custom",
      alignment: "center",
      filterable: false,
      sortable: false,
      minWidth: 140,
      customCellFormatter: downloadDocumentCellFormatter,
    },
  ];

  if (currentUserType === OrganisationTypeConstants.DEVELOPER)
    columns.push({
      key: "uploadIcon",
      name: "Upload new version",
      dataType: "string",
      formatter: "custom",
      alignment: "center",
      filterable: false,
      sortable: false,
      minWidth: 140,
      customCellFormatter: uploadDocumentCellFormatter,
    });

  const defaultSortingCriteria: SortColumn[] = [{ columnKey: "type", direction: "ASC" }];

  const formatData = useCallback((responseData: SearchProjectDocumentsResponse | undefined): ResultData[] => {
    return (
      responseData?.results?.map((d) => {
        const result = flattenObject(d);

        result.variant = d.customVariant || d.variant;

        result.downloadIcon = {
          action: async () => {
            const res = await downloadProjectDocument({ projectDocumentHistoryUuid: d.versionUuid });
            initiateDocumentDownloadViaBrowser(res);
          },
          text: "Download latest",
        } as DataGridButtonLinkCellFormatterData;

        if (hasManageProjectDocumentPermission)
          result.uploadIcon = {
            action: () => {
              setUploadDocumentUuid(d.uuid);
              setLatestDocumentVersionUuid(d.versionUuid);
              setShowUploadModal(true);
            },
            text: "Upload new version",
          } as DataGridButtonLinkCellFormatterData;

        result.detailsLink = {
          action: () => {
            navigate(
              getDocumentHistoryRoute(
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                projectUuid!,
                d.uuid,
                currentUserType
              )
            );
          },
          icon: <EyeBlueIcon width={20} height={20} strokeWidth={1.5} />,
        } as IconCellFormatterData;

        return result;
      }) || []
    );
  }, []);

  const onChange = async ({ filtering, paging, sorting }: CursorChangeProps): Promise<ResultType> => {
    let data: ResultType = {
      resultData: [],
      paging: {
        pageSize: 0,
        totalCount: 0,
        startCursor: "",
        endCursor: "",
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };

    if (!projectUuid || projectUuid.length < 10) {
      return data;
    }

    await searchProjectDocuments({
      projectUuid,
      groupUuid: projectDetails?.group?.groupUuid || null,
      paging: {
        limit: paging.pageSize,
        beforeCursor: paging.beforeCursor || null,
        afterCursor: paging.afterCursor || null,
      },
      /* eslint-disable @typescript-eslint/no-explicit-any */
      sort: sorting.map((s: { key: any; direction: any }) => ({
        key: s.key as any,
        direction: s.direction,
      })),
      filter: { results: dataGridMapFilterCriteria(filtering) },
    })
      .then((response) => {
        data = {
          resultData: formatData(response.data),
          paging: {
            startCursor: response.data?.paging?.startCursor || "",
            endCursor: response.data?.paging?.endCursor || "",
            pageSize: paging.pageSize || 10,
            totalCount: response.data?.paging?.total || 0,
            hasNextPage: response.data?.paging?.hasNextPage || false,
            hasPreviousPage: response.data?.paging?.hasPreviousPage || false,
          },
        };
      })
      .finally(() => {
        setDataIsLoading(false);
      });

    return data;
  };

  return {
    hasManageProjectDocumentPermission,
    columns,
    defaultSortingCriteria,
    dataIsLoading,
    uploadNewDocument,
    uploadDocumentUuid,
    latestDocumentVersionUuid,
    refreshDocuments,
    setRefreshDocuments,
    showUploadModal,
    setShowUploadModal,
    onChange,
  };
};
