import { DigitalVerificationIcon, ManualVerificationIcon } from "../../../../../assets";
import { KanaTooltip } from "../../../../forms";

export const supportsDigitalVerificationIcon = (
  supportsDigitalVerification?: boolean,
  isManuallyApproved?: boolean
): JSX.Element | undefined => {
  if (isManuallyApproved === true) {
    return (
      <KanaTooltip
        tooltipHeader="The verifier for this project is not a Kana Digital Verification Partner"
        tooltipText="<p>The verifier for this project is not using the Kana platform. You can complete the activity within Kana, download the activity and associated documents once complete and manually send to the chosen verifier for review.</p><p>Please see the intro page of the activity for more details.</p>"
        allowDangerousHtml
        icon={<ManualVerificationIcon className="SupportsDigitalVerificationIcon" />}
        fill={false}
      />
    );
  }
  if (isManuallyApproved === false && supportsDigitalVerification === false) {
    return (
      <KanaTooltip
        tooltipHeader="The verifier for this project is not a Kana Digital Verification Partner"
        tooltipText="<p>The verifier for this project is not using the Kana platform. You can complete the activity within Kana, download the activity and associated documents once complete and manually send to the chosen verifier for review.</p><p>Please see the intro page of the activity for more details.</p>"
        allowDangerousHtml
        icon={<ManualVerificationIcon className="SupportsDigitalVerificationIcon" />}
        fill={false}
      />
    );
  }
  if (isManuallyApproved === false && supportsDigitalVerification === true) {
    return (
      <KanaTooltip
        tooltipHeader="The verifier for this project is a Kana Digital Verification Partner"
        tooltipText="<p>The verifier for this project is using the Kana platform. You can complete and submit the activity within Kana, track its progress and correspond with the verifier using the ‘external discussions’ feature.</p><p>Please see the intro page of the activity for more details.</p>"
        allowDangerousHtml
        icon={<DigitalVerificationIcon className="SupportsDigitalVerificationIcon" />}
        fill={false}
      />
    );
  }
  return undefined;
};
