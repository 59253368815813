import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { SortColumn } from "react-data-grid";

import { OrganisationRoleConstants, ProjectActivityReviewsConstants } from "../../../../../constants";
import { CursorChangeProps, ResultType } from "../../../../../models";
import { searchActivityReviews, SearchActivityReviewsResponse } from "../../../../../service/query";
import { ResultData } from "../../../../../service/Shared";
import { useAuth } from "../../../../../useAuth";
import { flattenObject } from "../../../../../utils";
import { getActivityReviewDashboardTabRoute } from "../../../../../utils/routes";
import {
  DataGridButtonLinkCellFormatterData,
  DataGridColumnDefinition,
  DataGridColumnDefinitionWithCustomCellFormatter,
  DataGridCursorDataLoadEventHandler,
  DataGridLinkCellFormatterData,
  dataGridMapFilterCriteria,
} from "../../../../../widget";
import { assignReviewCellFormatter } from "../../components";

interface UseUnassignedActivitiesReturnData {
  tableColumnsUnassignedActivities: DataGridColumnDefinition[];
  isLoading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedActivityReview?: any;
  refreshUnassignedActivities: boolean;
  showAssignActivityModal: boolean;
  defaultSortingCriteria: SortColumn[];
  setShowAssignActivityModal: Dispatch<SetStateAction<boolean>>;
  setRefreshUnassignedActivities: Dispatch<SetStateAction<boolean>>;
  onUnassignedActivitiesDataLoad: DataGridCursorDataLoadEventHandler;
}

export const useUnassignedActivities = (): UseUnassignedActivitiesReturnData => {
  const { currentUserRole } = useAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [showAssignActivityModal, setShowAssignActivityModal] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedActivityReview, setSelectedActivityReview] = useState<any>();
  const [refreshUnassignedActivities, setRefreshUnassignedActivities] = useState(false);

  const defaultSortingCriteria: SortColumn[] = [{ columnKey: "calculatedDaysInQueue", direction: "DESC" }];

  const tableColumnsUnassignedActivities:
    | DataGridColumnDefinition
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | DataGridColumnDefinitionWithCustomCellFormatter<any>[] = [
    {
      key: "projectLink",
      name: "Project / Group",
      dataType: "string",
      formatter: "link",
      filterable: false,
      sortable: false,
      minWidth: 120,
    },
    {
      key: "developer.displayName",
      name: "Developer",
      dataType: "string",
      formatter: "stringWithEllipsis",
      minWidth: 90,
    },
    { key: "standard.displayName", name: "Code", dataType: "string", formatter: "stringWithEllipsis" },
    {
      key: "activity.activityDefinition.displayName",
      name: "Activity",
      dataType: "string",
      formatter: "stringWithEllipsis",
    },
    {
      name: "Activity status",
      key: "activity.status",
      dataType: "string",
      formatter: "projectActivitiesStatusPill",
      minWidth: 240,
    },
    {
      name: "Days in queue",
      key: "calculatedDaysInQueue",
      dataType: "number",
      filterable: false,
      sortable: true,
      minWidth: 140,
    },
    {
      name: "Assign",
      key: "assign",
      dataType: "string",
      formatter: "custom",
      filterable: false,
      sortable: false,
      minWidth: 110,
      customCellFormatter: assignReviewCellFormatter,
    },
  ];

  const formatUnassignedActivitiesData = useCallback(
    (responseData: SearchActivityReviewsResponse | undefined): ResultData[] =>
      responseData?.results?.map((el) => {
        const result = flattenObject(el);

        result.projectLink = {
          text: el.group?.displayName ?? el.project?.displayName,
          to: getActivityReviewDashboardTabRoute(el.activity.uuid, "documents"),
        } as DataGridLinkCellFormatterData;

        result.assign = {
          action: async () => {
            setSelectedActivityReview(el);
            setShowAssignActivityModal(true);
          },
          text: "Assign review",
        } as DataGridButtonLinkCellFormatterData;

        return result;
      }) || [],
    []
  );

  const onUnassignedActivitiesDataLoad: DataGridCursorDataLoadEventHandler = async ({
    paging,
    filtering,
    sorting,
  }: CursorChangeProps) => {
    let data: ResultType = {
      resultData: [],
      paging: {
        pageSize: 5,
        totalCount: 0,
        startCursor: "",
        endCursor: "",
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };
    if (currentUserRole === OrganisationRoleConstants.VVB_MANAGER) {
      setIsLoading(true);

      const filterCriteria = dataGridMapFilterCriteria(filtering);

      filterCriteria.assignedToUser = {
        uuid: {
          operator: "eq",
          value: null,
        },
      };

      filterCriteria.status = {
        operator: "neq",
        value: ProjectActivityReviewsConstants.STATUS_CANCELLED,
      };

      await searchActivityReviews({
        paging: {
          afterCursor: null,
          beforeCursor: null,
          limit: 10,
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        sort: sorting.map((s: any) => ({
          key: s.key,
          direction: s.direction,
        })),
        filter: { results: filterCriteria },
      })
        .then((response) => {
          data = {
            resultData: formatUnassignedActivitiesData(response.data),
            paging: {
              startCursor: response.data?.paging?.startCursor || "",
              endCursor: response.data?.paging?.endCursor || "",
              hasNextPage: false,
              hasPreviousPage: false,
              pageSize: paging.pageSize || 10,
              totalCount: response.data?.paging?.total || response.data?.results?.length || 0,
            },
          };
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    return data;
  };

  return {
    tableColumnsUnassignedActivities,
    isLoading,
    showAssignActivityModal,
    selectedActivityReview,
    refreshUnassignedActivities,
    defaultSortingCriteria,
    setRefreshUnassignedActivities,
    setShowAssignActivityModal,
    onUnassignedActivitiesDataLoad,
  };
};
