import "./VerifierProjectCardWithTabsTemplate.css";

import { StandardConstants } from "../../../constants";
import { NavigationActiveElement, TabsList } from "../../../models";
import {
  Column,
  ExpandableCard,
  KanaTooltip,
  LeftNavigation,
  OrganisationIcon,
  PieChart,
  Pill,
  ProfileMenu,
  ProfileMenuItem,
  QuickActionsMenu,
  Row,
  Tabs,
  UserHeader,
} from "../../../widget";
import { StatusCard } from "../../DeveloperProjectCardWithTabsTemplate/components";
import { useVerifierProjectCardWithTabsTemplate } from "./useVerifierProjectCardWithTabsTemplate";

interface VerifierProjectCardWithTabsTemplateProps {
  activeElement: NavigationActiveElement;
  pageTitle: string | undefined;
  projectCode: string | undefined;
  projectDeveloper?: string | undefined;
  projectDeveloperIcon?: string | undefined;
  projectStatus: string | undefined;
  chartData: { [key: string]: number };
  tabsList: TabsList;
  backButtonText?: string;
  handleBackClick?: () => void;
}

export const VerifierProjectCardWithTabsTemplate = ({
  activeElement,
  pageTitle,
  projectCode,
  projectDeveloper,
  projectDeveloperIcon,
  projectStatus,
  chartData,
  tabsList,
  backButtonText,
  handleBackClick,
}: VerifierProjectCardWithTabsTemplateProps): JSX.Element => {
  const { isProjectAssociatedWithCode, setShowQuickActionMenu, showQuickActionMenu, quickActionsMenuOptionsList } =
    useVerifierProjectCardWithTabsTemplate(projectStatus);

  return (
    <div className="VerifierProjectCardWithTabsTemplate">
      <div className="VerifierProjectCardWithTabsTemplateNavigation">
        <LeftNavigation activeElement={activeElement} />
      </div>
      <div className="VerifierProjectCardWithTabsTemplateContainer">
        <Row spacingV="ll">
          <Column span={12}>
            <UserHeader backButtonText={backButtonText} handleBackClick={handleBackClick} />
          </Column>
        </Row>
        <Row spacingV={projectDeveloper && projectDeveloperIcon ? "s" : "ll"}>
          <Column span={12}>
            <div className="HeaderContent">
              <div className="ProjectTitleAndCode">
                <h1>{pageTitle}</h1>
                {isProjectAssociatedWithCode && projectCode && (
                  <Pill label={projectCode} variantMap={StandardConstants.CODE_PILL_VARIANT_MAP} />
                )}
              </div>
              {quickActionsMenuOptionsList.length > 0 && (
                <div>
                  <QuickActionsMenu onClick={() => setShowQuickActionMenu(true)} />
                  <ProfileMenu
                    show={showQuickActionMenu}
                    onClose={() => setShowQuickActionMenu(false)}
                    position={{ top: "0" }}
                  >
                    {quickActionsMenuOptionsList.map((el) => (
                      <ProfileMenuItem
                        key={el.id}
                        profileMenuItem={el}
                        onClick={() => {
                          if (el.action) {
                            el.action();
                          }
                          setShowQuickActionMenu(false);
                        }}
                      />
                    ))}
                  </ProfileMenu>
                </div>
              )}
            </div>
          </Column>
        </Row>
        {projectDeveloper && (
          <Row spacingV="ll">
            <Column span={6}>
              <div className="DeveloperInfo">
                {projectDeveloperIcon && <OrganisationIcon organisationIcon={projectDeveloperIcon} corners="Rounded" />}
                <p className="body1">{projectDeveloper}</p>
              </div>
            </Column>
          </Row>
        )}
        <Row spacingV="ll">
          <Column span={4}>
            <StatusCard status={projectStatus || ""} />
          </Column>
          <Column span={8}>
            <ExpandableCard
              title="Units"
              contentAlignment="center"
              tooltip={
                <KanaTooltip
                  tooltipHeader="Buffer units"
                  tooltipText="Please note that buffer units created by this project are not shown in this chart"
                />
              }
              height="100%"
            >
              <PieChart size={120} data={chartData} noDataMessage="No units issued" />
            </ExpandableCard>
          </Column>
        </Row>
        <Tabs className="TemplateTabs" tabsList={tabsList} />
      </div>
    </div>
  );
};
