/* eslint-disable prettier/prettier */
import "./VersionBanner.css";

import { OrganisationTypeConstants, ProjectActivitiesConstants } from "../../../../../constants";
import { VersionBannerData } from "../../../../../models";
import { getFormattedDate } from "../../../../../utils";
import { Button, Select } from "../../../../forms";

export const VersionBanner = ({
  projectName,
  activityDefinitionName,
  updatedByUserFullName,
  updatedAt,
  activityHistoryVersionNumber,
  activityHistoryUuidData,
  isCurrentVersion,
  isUnderReview,
  canManageActivity,
  currentUserType,
  status,
  onActivityHistoryUuidChange,
  onClose,
  onEdit,
}: VersionBannerData): JSX.Element => {
  // Lock activity logic
  const showEditButton = (): JSX.Element | undefined => {
    if (canManageActivity) {
      // Show edit for dev
      if (
        currentUserType === OrganisationTypeConstants.DEVELOPER &&
        isCurrentVersion &&
        !isUnderReview &&
        (status === ProjectActivitiesConstants.STATUS_IN_PROGRESS ||
          status === ProjectActivitiesConstants.STATUS_CHANGES_REQUIRED)
      )
        return <Button text="Edit" variant="reversedPrimary" onClick={onEdit} size="small" />;

      // Show edit for vvb
      if (currentUserType === OrganisationTypeConstants.VERIFIER && isUnderReview)
        return <Button text="Edit" variant="reversedPrimary" onClick={onEdit} size="small" />;
    }
    return undefined;
  };

  return (
    <div className="VersionBannerWrapper">
      <div className={`${currentUserType} VersionBanner`}>
        <div className="TextArea">
          <h3>
            {projectName} {activityDefinitionName} v{activityHistoryVersionNumber}
          </h3>
          <p className="body2">
            Last updated by {updatedByUserFullName} on {updatedAt ? getFormattedDate(updatedAt) : ""}
          </p>
        </div>
        <div className="FormArea">
          <h3>View version</h3>
          <Select
            initialKey={
              isCurrentVersion && currentUserType !== OrganisationTypeConstants.VERIFIER
                ? `${activityHistoryVersionNumber} (Latest)`
                : activityHistoryVersionNumber
            }
            data={activityHistoryUuidData}
            onChange={onActivityHistoryUuidChange}
          />
          {showEditButton()}
          <Button
            text="Close"
            variant={currentUserType === OrganisationTypeConstants.ASSET_MANAGER ? "primary" : "reversedPrimary"}
            onClick={onClose}
            size="small"
          />
        </div>
      </div>
    </div>
  );
};
