import { Dispatch, RefObject, SetStateAction, useContext, useEffect, useRef, useState } from "react";

import { DiscussionVisibilityConstants, OrganisationTypeConstants } from "../../../../../constants";
import { ActivityDiscussionContext } from "../../../../../route/shared/activities-dashboard";
import { useActivityDashboardContextSwitcher } from "../../../../../route/shared/utils/activities/useActivityDashboardContextSwitcher";
import { createDiscussionMessage, deleteDiscussionMessage } from "../../../../../service/discussion";
import { Status } from "../../../../../service/Shared";
import { useAuth } from "../../../../../useAuth";
import { useIsLoadingWrapper } from "../../../../../utils";
import { Toast } from "../../../Toast";
import { MessageThread } from "../../models/Message";

type DiscussionThreadWithReplyProps = {
  thread: MessageThread | undefined;
  setThreadData: (data: MessageThread) => void;
};

type useDiscussionThreadReturnData = {
  replyTextAreaShown: boolean;
  showReplyTextArea: () => void;
  hideReplyTextArea: () => void;
  handleSendReply: () => Promise<void>;

  setReplyText: Dispatch<SetStateAction<string>>;
  textAreaRef: RefObject<HTMLTextAreaElement>;
  deleteMessage: (uuid: string) => void;
  deleteThread: () => void;
  resolveThread: () => void;
  isHandleSendReplyLoading: boolean;
};

export const useDiscussionThreadWithReply = ({
  setThreadData,
  thread,
}: DiscussionThreadWithReplyProps): useDiscussionThreadReturnData => {
  const { selectedThreadDetails, refreshDiscussionTables } = useContext(ActivityDiscussionContext);
  const { user, currentUserType, currentOrganisationUuid } = useAuth();
  const { activityUuid } = useActivityDashboardContextSwitcher();

  const [replyTextAreaShown, setShowReplyTextArea] = useState<boolean>(false);
  const [replyText, setReplyText] = useState<string>("");
  const [isHandleSendReplyLoading, setIsHandleSendReplyLoading] = useState(false);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (replyTextAreaShown) {
      textAreaRef.current?.focus();
    }
  }, [replyTextAreaShown]);

  useEffect(() => {
    if (selectedThreadDetails === undefined) {
      setShowReplyTextArea(false);
    }
  }, [selectedThreadDetails]);

  const showReplyTextArea = (): void => {
    setShowReplyTextArea(true);
  };

  const hideReplyTextArea = (): void => {
    setShowReplyTextArea(false);
    setReplyText("");
  };

  const internalMessageTargetOrganisationRoles =
    currentUserType === OrganisationTypeConstants.DEVELOPER
      ? ["Developer", "Guest - Read", "Guest - Write"]
      : ["Validator"];

  const handleSendReply = useIsLoadingWrapper(async (): Promise<void> => {
    if (activityUuid && currentOrganisationUuid && selectedThreadDetails?.messages) {
      const res = await createDiscussionMessage({
        content: replyText,
        messageObjectType: "Activity",
        messageObjectUuid: activityUuid ?? "",
        messageType: "Comment",
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-explicit-any
        messageStatus: (selectedThreadDetails.messages as unknown as Array<any>).at(-1).status as string,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-explicit-any
        parentMessageUuid: (selectedThreadDetails.messages as unknown as Array<any>).at(-1).uuid as string,
        sourceOrganisationUuid: currentOrganisationUuid,
        threadUuid: selectedThreadDetails.uuid as string,
        targetOrganisationRoles:
          selectedThreadDetails.visibility === DiscussionVisibilityConstants.INTERNAL
            ? internalMessageTargetOrganisationRoles
            : ["Developer", "Validator", "Guest - Read", "Guest - Write"],
      });

      if (res.status === Status.Success && res.data && user) {
        setThreadData({
          resolved: thread?.resolved ?? false,
          messages: [
            ...(thread?.messages ?? []),
            {
              uuid: res.data.messageUuid,
              content: replyText,
              createdAt: new Date(),
              createdByUserUuid: user.userUuid,
              createdByUserFullName: user.fullName,
              createdByUserAvatarUrl: user.avatar?.url,
            },
          ],
        } as MessageThread);
        refreshDiscussionTables();
        hideReplyTextArea();
      }

      if (res.status === Status.Error) {
        res.errors?.forEach((e) => {
          Toast.error({ message: e.message });
        });
      }
    }
  }, setIsHandleSendReplyLoading);

  const deleteMessage = async (uuid: string): Promise<void> => {
    const res = await deleteDiscussionMessage({ messageUuid: uuid });

    if (res.status === Status.Success) {
      const messagesCopy = [...(thread?.messages ?? [])];
      const newMessages = messagesCopy.filter((m) => m.uuid !== uuid);

      setThreadData({
        resolved: thread?.resolved ?? false,
        messages: newMessages,
      } as MessageThread);

      Toast.success({ message: "Message deleted" });
      refreshDiscussionTables();
    } else if (res.status === Status.Error && res.errors && res.errors.length) {
      Toast.error({ message: res.errors[0].code });
    }
  };

  const deleteThread = (): void => {
    refreshDiscussionTables();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (selectedThreadDetails?.actionsData as any).onDelete();
  };

  const resolveThread = (): void => {
    refreshDiscussionTables();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (selectedThreadDetails?.actionsData as any).onResolve();
  };

  return {
    replyTextAreaShown,
    showReplyTextArea,
    hideReplyTextArea,
    handleSendReply,
    setReplyText,
    textAreaRef,
    deleteMessage,
    deleteThread,
    resolveThread,
    isHandleSendReplyLoading,
  };
};
