import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { OrganisationTypeConstants } from "../../../../../../constants";
import { Origin } from "../../../../../../models";
import { GetGroupDetailsResponse, GetProjectDetailsResponse } from "../../../../../../service/query";
import { ServiceError } from "../../../../../../service/Shared";
import { useAuth } from "../../../../../../useAuth";
import {
  getActivitiesRoute,
  getDashboardRoute,
  getProjectAuditHistoryRoute,
  getProjectDetailsByUuid,
  getSettingsAuditHistoryRoute,
} from "../../../../../../utils/routes";
import { GroupContext } from "./GroupContext";

interface UseManageProjectGroupReturnData {
  groupDetails?: GetGroupDetailsResponse;
  projectDetails?: GetProjectDetailsResponse;
  backButtonText: string;
  errors: ServiceError[] | undefined;
  handleBackClick: () => void;
}

export const useManageProjectGroup = (): UseManageProjectGroupReturnData => {
  const navigate = useNavigate();
  const { currentUserType } = useAuth();

  const { groupDetails, origin, currentProjectUuid, errors } = useContext(GroupContext);

  let backButtonText: string;
  let route: string;
  switch (origin) {
    case Origin.ProjectAuditPage:
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      route = getProjectAuditHistoryRoute(currentProjectUuid!, currentUserType);
      backButtonText = "Back to audit";
      break;
    case Origin.OrganisationAuditPage:
      route = getSettingsAuditHistoryRoute(currentUserType);
      backButtonText = "Back to audit";
      break;
    case Origin.DeveloperDashboardPage:
      route = getDashboardRoute(OrganisationTypeConstants.DEVELOPER);
      backButtonText = "Back to dashboard";
      break;
    case Origin.DeveloperActivitiesPage:
      route = getActivitiesRoute(OrganisationTypeConstants.DEVELOPER);
      backButtonText = "Back to activities";
      break;
    default:
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      route = getProjectDetailsByUuid(currentProjectUuid!, OrganisationTypeConstants.DEVELOPER);
      backButtonText = "Back to project";
      break;
  }

  const handleBackClick = (): void => {
    navigate(route);
  };

  return {
    groupDetails,
    backButtonText,
    errors,
    handleBackClick,
  };
};
