import "./RemainingTime.css";

import { CircleFilledIcon } from "../../../assets";
import { logError } from "../../../service/error";

interface RemainingTimeProps {
  time: number;
  isComplete?: boolean;
}

export const RemainingTime = ({ time, isComplete = false }: RemainingTimeProps): JSX.Element => {
  if (isComplete) {
    return (
      <div className="RemainingTimeContainer Complete">
        <CircleFilledIcon />
        <h3>Completed</h3>
      </div>
    );
  }
  switch (time) {
    case 0:
      return (
        <div className="RemainingTimeContainer High">
          <CircleFilledIcon />
          <h3>Due today</h3>
        </div>
      );
    case 1:
      return (
        <div className="RemainingTimeContainer Medium">
          <CircleFilledIcon />
          <h3>{time} day left</h3>
        </div>
      );
    case -1:
      return (
        <div className="RemainingTimeContainer High">
          <CircleFilledIcon />
          <h3>{-time} day late</h3>
        </div>
      );
    case time < 0 && time:
      return (
        <div className="RemainingTimeContainer High">
          <CircleFilledIcon />
          <h3>{-time} days late</h3>
        </div>
      );
    case time >= 2 && time <= 29 && time:
      return (
        <div className="RemainingTimeContainer Medium">
          <CircleFilledIcon />
          <p>{time} days left</p>
        </div>
      );
    case time >= 30 && time:
      return (
        <div className="RemainingTimeContainer Low">
          <CircleFilledIcon />
          <p>{time} days left</p>
        </div>
      );
    default:
      logError({ error: `${time} is not a valid time` });

      return (
        <div className="RemainingTimeContainer High">
          <CircleFilledIcon />
          <h3>Error: {time}</h3>
        </div>
      );
  }
};
