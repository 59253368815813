import "./DashboardPieCard.css";

import { ReactNode } from "react";

import { Card, PieChart, PieChartColors } from "../../../../widget";

export interface DashboardPieCardProps {
  data: { [key: string]: number };
  title: string;
  size?: number;
  colours?: PieChartColors;
  noDataMessage?: string;
  tooltip?: ReactNode;
  showValues?: boolean;
}

export const DashboardPieCard = ({
  data,
  title,
  size = 120,
  colours = "default",
  noDataMessage,
  tooltip,
  showValues = true,
}: DashboardPieCardProps): JSX.Element => {
  return (
    <Card height="100%">
      <div className="DashboardPieCardContainer">
        <div className="DashboardPieCardHeader">
          <h3>{title}</h3>
          <div>{tooltip}</div>
        </div>
        <div className="DashboardPieCardContent">
          <PieChart size={size} data={data} noDataMessage={noDataMessage} colors={colours} showValues={showValues} />
        </div>
      </div>
    </Card>
  );
};
