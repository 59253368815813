import "./Workflow.css";

import { ArrowheadLeftLightIcon, CircleArrowheadLeftDarkIcon, CircleArrowheadRightDarkIcon } from "../../../assets";
import { WorkflowActivity } from "../../../models/shared/workflowActivityType";
import { StatusCard } from "../../../templates/DeveloperProjectCardWithTabsTemplate/components";
import { checkCanManageActivity } from "../../../utils";
import { ProgressIndicator } from "./components";
import { useWorkflow } from "./useWorkflow";

type WorkflowVariant = "Default" | "AssetManager";
type WorkflowLocation = "Project" | "Activity";

interface WorkflowProps {
  status?: string;
  activities: WorkflowActivity[];
  canManageActivity: boolean;
  isExternal: boolean;
  location?: WorkflowLocation;
  variant?: WorkflowVariant;
  shouldRefreshActivities: () => void;
  shouldRefreshProjectDetails?: () => void;
}

export const Workflow = ({
  status,
  activities,
  canManageActivity,
  isExternal,
  variant = "Default",
  location = "Project",
  shouldRefreshActivities,
  shouldRefreshProjectDetails,
}: WorkflowProps): JSX.Element => {
  const {
    hasLeftArrow,
    hasNoSubmitButtonPadding,
    hasNoCompleteButtonPadding,
    areRightActivitiesHidden,
    onRightArrowClick,
    onLeftArrowClick,
    workflowRef,
    activitiesWrapperWidth,
    onScroll,
  } = useWorkflow();

  return (
    <div className={`WorkflowWrapper ${variant}`}>
      <div className="Workflow" ref={workflowRef} onScroll={onScroll}>
        {activities.length === 2 && hasLeftArrow && (
          <ArrowheadLeftLightIcon className="ArrowheadLeftLightIcon" onClick={onLeftArrowClick} />
        )}
        {activities.length === 3 && hasLeftArrow && (
          <CircleArrowheadLeftDarkIcon className="CircleArrowheadLeftDarkIcon" onClick={onLeftArrowClick} />
        )}
        <div className="WorkflowStatus">
          {status !== undefined && <StatusCard status={status} isFixed variant={variant} />}
        </div>
        <div
          className={`ActivitiesWrapper ${activities.length < 2 && "Full"}`}
          style={{ width: activitiesWrapperWidth }}
        >
          {variant === "Default" ? (
            <h3 className="ActivitiesHeader">Activities</h3>
          ) : (
            <p className="body01 ActivitiesHeader">Activities</p>
          )}
          {activities.length < 2 ? (
            <div className="Activities">
              <ProgressIndicator
                activity={activities[0]}
                canManageActivity={checkCanManageActivity(canManageActivity, activities[0]?.status)}
                isExternal={isExternal}
                hasNoSubmitButtonPadding={hasNoSubmitButtonPadding}
                hasNoCompleteButtonPadding={hasNoCompleteButtonPadding}
                location={location}
                shouldRefreshActivities={shouldRefreshActivities}
                shouldRefreshProjectDetails={shouldRefreshProjectDetails}
              />
            </div>
          ) : (
            <div className="Activities">
              {activities.map((activity, idx) => (
                <ProgressIndicator
                  key={activity.displayName}
                  activity={activity}
                  canManageActivity={checkCanManageActivity(canManageActivity, activity.status)}
                  isExternal={isExternal}
                  nrOfActivities={activities.length}
                  isDisabled={
                    (activities.length > 2 && idx === 0 && !areRightActivitiesHidden) ||
                    (idx === 1 && areRightActivitiesHidden)
                  }
                  hasNoSubmitButtonPadding={hasNoSubmitButtonPadding}
                  hasNoCompleteButtonPadding={hasNoCompleteButtonPadding}
                  location={location}
                  shouldRefreshActivities={shouldRefreshActivities}
                  shouldRefreshProjectDetails={shouldRefreshProjectDetails}
                />
              ))}
              {areRightActivitiesHidden ? (
                <CircleArrowheadRightDarkIcon
                  className="CircleArrowheadRightDarkIcon Enabled"
                  onClick={onRightArrowClick}
                />
              ) : (
                <CircleArrowheadRightDarkIcon
                  className="CircleArrowheadRightDarkIcon Disabled"
                  onClick={onRightArrowClick}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
