import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { ActivityReviewTypeConstants, OrganisationTypeConstants } from "../../../../../../constants";
import { ActivityPageParams, SelectData, StepItem } from "../../../../../../models";
import {
  GetActivityHistoryDetailsResponse,
  GetGroupDetailsResponse,
  GetProjectDetailsResponse,
  searchActivityHistory,
} from "../../../../../../service/query";
import { Status } from "../../../../../../service/Shared";
import { useAuth } from "../../../../../../useAuth";
import {
  getActivityReviewDashboardTabRoute,
  getActivityReviewRoute,
  getActivityReviewViewRoute,
} from "../../../../../../utils/routes";
import { StepProps } from "../../../../../../widget";
import {
  ActivityData,
  ActivityDefinition,
  ActivityDefinitionInfo,
  checkTargetStepkeysAppended,
  createExpressionContext,
  createViewComponent,
  enrichUploadedDocuments,
  filterConditionalSteps,
  findCurrentStepName,
  getActivityDefinitionFields,
  getDataPath,
  getFirstStepKeys,
  getLastStepKeys,
  getNextStepKeys,
  getPreviousStepKeys,
  getStep,
  hasNextStep,
  hasPreviousStep,
  HasReviews,
  UploadedDocumentEnriched,
} from "../../../../../developer/activities";
import { useFetchDiscussions } from "../../../../../developer/activities/activity/hooks/useFetchDiscussions";
import {
  getDiscussionsForComponent,
  getDiscussionUuids,
} from "../../../../../developer/activities/activity/utils/DiscussionUtils";
import { useFetchActivityReviewViewHistory } from "../../hooks/useFetchActivityReviewViewHistory";
import { createStepReview, mapToReviewStepItem, mapToReviewStepProps } from "../../utils";

interface UseActivityReturnData {
  steps: StepProps[];
  l3Steps: StepItem[] | undefined;
  stepFields: JSX.Element[];
  stepReviews?: (JSX.Element | undefined)[];
  currentStepName: string | null;
  currentStepKeys: string[];
  hasNext: boolean;
  hasPrevious: boolean;
  activityData: GetActivityHistoryDetailsResponse | undefined;
  isLoading: boolean;
  isReviewPage: boolean;
  showReviewPage: boolean;
  activityDefinition: ActivityDefinition;
  activityDefinitionInfo: ActivityDefinitionInfo | undefined;
  activityReviewType: string | undefined;
  activityReviewStatus: string | undefined;
  activityReviewCompletionPercentage: number;
  activityHistoryUuidData: SelectData;
  isAssignedAuditor: boolean;
  currentUserType: OrganisationTypeConstants;
  // handlers
  movePrevious: () => void;
  moveNext: () => void;
  moveTo: (targetStepKeys: string[]) => void;
  moveToLastStep: () => void;
  moveToReview: () => void;
  onActivityHistoryUuidChange: (activityHistoryUuid: string) => void;
  onClose: () => void;
  onEdit: () => void;
}

export const useView = (): UseActivityReturnData => {
  const navigate = useNavigate();
  const { currentUserType } = useAuth();

  const { activityHistoryUuid } = useParams<ActivityPageParams.activityHistoryUuid>();

  const [searchParams] = useSearchParams();

  const URLactivityReviewType = searchParams.get("type") ?? undefined;
  const queryParams = URLactivityReviewType ? `type=${URLactivityReviewType}` : "";

  const [hasReviewStarted, setHasReviewStarted] = useState(false); // Checks whether there are any "review" keys in the json
  const [activityReviewCompletionPercentage, setActivityReviewCompletionPercentage] = useState(0);
  const [activityReviewType, setActivityReviewType] = useState<string>();
  const [activityReviewStatus, setActivityReviewStatus] = useState<string>();
  const [activityAssessmentUuid, setActivityAssessmentUuid] = useState<string>();
  const [activityReviewUuid, setActivityReviewUuid] = useState<string>();
  const [currentStepKeys, setCurrentStepKeys] = useState<string[]>([]);
  const [currentStepName, setCurrentStepName] = useState<string | null>(null);
  const [activityData, setActivityData] = useState<GetActivityHistoryDetailsResponse | undefined>(undefined);
  const [activityDocuments, setActivityDocuments] = useState<UploadedDocumentEnriched[]>([]);
  const [activityDefinitionInfo, setActivityDefinitionInfo] = useState<ActivityDefinitionInfo | undefined>(undefined);
  const [steps, setSteps] = useState<StepProps[]>([]);
  const [l3Steps, setL3Steps] = useState<StepItem[] | undefined>([]);
  const [stepFields, setStepFields] = useState<JSX.Element[]>([]);
  const [hasNext, setHasNext] = useState(true);
  const [hasPrevious, setHasPrevious] = useState(false);
  const [activityDefinition, setActivityDefinition] = useState<ActivityDefinition>({
    components: undefined,
    parent: undefined,
    steps: [],
  });
  const [originalActivityDefinition, setOriginalActivityDefinition] = useState<ActivityDefinition>({
    components: undefined,
    parent: undefined,
    steps: [],
  });
  const [projectDetails, setProjectDetails] = useState<GetProjectDetailsResponse | undefined>(undefined);
  const [groupDetails, setGroupDetails] = useState<GetGroupDetailsResponse | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [showReviewPage, setShowReviewPage] = useState(false);
  const [scrollToElement, setScrollToElement] = useState<string | undefined>(undefined);

  const [stepReviews, setStepReviews] = useState<(JSX.Element | undefined)[]>([]);

  const [isReviewPage, setIsReviewPage] = useState(false);
  const [isAssignedAuditor, setIsAssignedAuditor] = useState(false);

  const [activityHistoryUuidData, setActivityHistoryUuidData] = useState<SelectData>([]);

  const navigation = useNavigate();

  const fetchData = useFetchActivityReviewViewHistory(
    setActivityData,
    setOriginalActivityDefinition,
    setActivityDefinitionInfo,
    setProjectDetails,
    setGroupDetails,
    setActivityAssessmentUuid,
    setActivityReviewUuid,
    setActivityReviewCompletionPercentage,
    setActivityReviewType,
    setActivityReviewStatus,
    setHasReviewStarted,
    setIsAssignedAuditor,
    activityHistoryUuid,
    URLactivityReviewType
  );

  const fetchDiscussionsCallback = useFetchDiscussions(activityHistoryUuid);

  const filterAndUpdateActivityDefinition = (
    activityData2: ActivityData,
    originalActivityDefinition2?: ActivityDefinition,
    projectDetails2?: GetProjectDetailsResponse
  ): ActivityDefinition => {
    // eslint-disable-next-line no-param-reassign
    originalActivityDefinition2 = originalActivityDefinition2 || originalActivityDefinition;
    const expressionContext = createExpressionContext(activityData2, projectDetails2 || projectDetails, groupDetails);
    const filteredActivityDefinition = filterConditionalSteps(originalActivityDefinition2, expressionContext);
    setActivityDefinition(filteredActivityDefinition);
    return filteredActivityDefinition;
  };

  const updateSteps: (
    stepKeys: string[],
    activityDefinition2: ActivityDefinition,
    activityData2: ActivityData,
    activityDocuments2: UploadedDocumentEnriched[],
    isAssignedAuditor2?: boolean,
    activityReviewType2?: string,
    hasReviewStarted2?: boolean,
    projectDetails2?: GetProjectDetailsResponse,
    groupDetails2?: GetGroupDetailsResponse
  ) => void = async (
    stepKeys,
    activityDefinition2,
    activityData2,
    activityDocuments2,
    isAssignedAuditor2 = isAssignedAuditor,
    activityReviewType2 = activityReviewType,
    hasReviewStarted2 = hasReviewStarted,
    projectDetails2 = projectDetails,
    groupDetails2 = groupDetails
  ) => {
    const stepData = getStep(stepKeys, activityData2);
    const stepComponents = getActivityDefinitionFields(stepKeys, activityDefinition2);
    const expressionContext = createExpressionContext(activityData2, projectDetails2, groupDetails2);
    const dataPath = getDataPath(stepKeys);

    const discussionsUuids = await getDiscussionUuids(stepData, fetchDiscussionsCallback);

    setStepFields(
      stepComponents
        .map((c) =>
          createViewComponent(
            c,
            getDiscussionsForComponent(c.key, c.component, c.children, discussionsUuids, stepKeys),
            stepData?.data || {},
            expressionContext,
            activityDocuments2,
            dataPath
          )
        )
        .filter((c) => c !== null) as JSX.Element[]
    );

    setStepReviews(
      createStepReview(
        false,
        { activityReviews: stepData?.activityReviews } as HasReviews,
        dataPath,
        () => undefined,
        () => undefined,
        activityReviewType
      )
    );

    if (activityReviewType2) {
      setSteps(
        mapToReviewStepProps(
          stepKeys,
          activityData2,
          hasReviewStarted2 && !isAssignedAuditor2 ? ActivityReviewTypeConstants.REVIEW : activityReviewType2,
          activityDefinition2.steps
        )
      );
      setL3Steps(
        mapToReviewStepItem(
          stepKeys,
          activityData2,
          hasReviewStarted2 && !isAssignedAuditor2 ? ActivityReviewTypeConstants.REVIEW : activityReviewType2,
          activityDefinition2.steps
        )
      );
    }
    setHasNext(hasNextStep(stepKeys, activityDefinition2));
    setHasPrevious(hasPreviousStep(stepKeys, activityDefinition2));
  };

  useEffect(() => {
    fetchData().then((data) => {
      const filteredActivityDefinition = filterAndUpdateActivityDefinition(
        data.activityData,
        data.activityDefinition,
        data.projectDetails
      );
      setIsLoading(false);
      let stepKeys = getFirstStepKeys(filteredActivityDefinition);
      const enrichedActivityDocuments = enrichUploadedDocuments(data.activityData, data.activityDocuments);
      setActivityDocuments(enrichedActivityDocuments);

      const location = searchParams.get("location");
      if (location && activityDefinition && data.activityData) {
        const locationArr = location.split(/\./g);
        const componentRef = locationArr.splice(-1)[0];
        const componentId = `${locationArr.join(".")}-${componentRef}`;
        stepKeys = locationArr;
        setScrollToElement(componentId);
      }

      setCurrentStepKeys(stepKeys);
      updateSteps(
        stepKeys,
        filteredActivityDefinition,
        data.activityData,
        enrichedActivityDocuments,
        data.isAssignedAuditor,
        data.activityReviewType,
        data.hasReviewStarted
      );

      setShowReviewPage(true);
    });
  }, [activityHistoryUuid]);

  const moveNext = useCallback(async (): Promise<void> => {
    const nextStepKeys = getNextStepKeys(currentStepKeys, activityDefinition);
    setCurrentStepKeys(nextStepKeys);
    updateSteps(nextStepKeys, activityDefinition, activityData?.data, activityDocuments);
    window.scrollTo(0, 0);
  }, [activityDefinition, currentStepKeys]);

  const movePrevious = useCallback(async (): Promise<void> => {
    const previousStepKeys = getPreviousStepKeys(currentStepKeys, activityDefinition);
    setCurrentStepKeys(previousStepKeys);
    updateSteps(previousStepKeys, activityDefinition, activityData?.data, activityDocuments);
    window.scrollTo(0, 0);
  }, [activityDefinition, currentStepKeys]);

  const moveTo = useCallback(
    async (targetStepKeys: string[]): Promise<void> => {
      const targetStepKeysAppend = checkTargetStepkeysAppended(targetStepKeys, activityDefinition);
      setCurrentStepKeys(targetStepKeysAppend);
      updateSteps(targetStepKeysAppend, activityDefinition, activityData?.data, activityDocuments);
      setIsReviewPage(false);
      window.scrollTo(0, 0);
    },
    [activityDefinition, currentStepKeys]
  );

  const moveToReview = useCallback(async (): Promise<void> => {
    setIsReviewPage(true);
    setHasNext(false);
    setHasPrevious(false);
  }, [activityDefinition, currentStepKeys]);

  const moveToLastStep = useCallback((): void => {
    const lastStepKeys = getLastStepKeys(activityDefinition);
    setCurrentStepKeys(lastStepKeys);
    updateSteps(lastStepKeys, activityDefinition, activityData?.data, activityDocuments);
    setIsReviewPage(false);
    window.scrollTo(0, 0);
  }, [activityDefinition, currentStepKeys]);

  const onActivityHistoryUuidChange = useCallback(
    (newActivityHistoryUuid: string) => {
      navigation(getActivityReviewViewRoute(newActivityHistoryUuid, queryParams));
    },
    [navigation]
  );
  const onClose = useCallback(() => {
    navigation(getActivityReviewDashboardTabRoute(activityData?.activity.uuid || "", "history", queryParams));
  }, [activityData]);

  const onEdit = async (): Promise<void> => {
    if (URLactivityReviewType === ActivityReviewTypeConstants.REVIEW && activityReviewUuid)
      navigate(getActivityReviewRoute(activityReviewUuid, queryParams));
    if (URLactivityReviewType === ActivityReviewTypeConstants.ASSESSMENT && activityAssessmentUuid)
      navigate(getActivityReviewRoute(activityAssessmentUuid, queryParams));
  };

  const fetchActivityHistoryData = useCallback(async (activityUuid: string) => {
    await searchActivityHistory({
      activityUuid,
      paging: {
        limit: 30,
        afterCursor: null,
        beforeCursor: null,
      },
      filter: {
        results: {
          activity: { uuid: { operator: "eq", value: activityUuid } },
          isDraft: { operator: "eq", value: false },
          changeSummary: { operator: "eq", value: "submitted" },
        },
      },
      sort: [{ key: "results.versionNumber", direction: "desc" }],
    }).then((res) => {
      if (res.status === Status.Success && res.data) {
        setActivityHistoryUuidData(
          res.data.results.map((d) => ({
            key: d.uuid,
            value: d.isCurrent ? `${d.versionNumber}` : d.versionNumber,
          }))
        );
      }
    });
  }, []);

  useEffect(() => {
    if (activityData) {
      fetchActivityHistoryData(activityData.activity.uuid);
    }
  }, [activityData]);

  useEffect(() => {
    setCurrentStepName(findCurrentStepName(steps));
  }, [steps]);

  useLayoutEffect(() => {
    if (showReviewPage && scrollToElement) {
      setTimeout(() => {
        document.getElementById(scrollToElement)?.scrollIntoView(true);
      }, 50);
    }
  }, [showReviewPage]);

  return {
    steps,
    l3Steps,
    stepFields,
    stepReviews,
    currentStepName,
    currentStepKeys,
    hasNext,
    hasPrevious,
    activityData,
    isLoading,
    isReviewPage,
    showReviewPage,
    activityDefinition,
    activityDefinitionInfo,
    activityReviewType,
    activityReviewStatus,
    activityReviewCompletionPercentage,
    activityHistoryUuidData,

    isAssignedAuditor,
    currentUserType,
    // handlers
    movePrevious,
    moveNext,
    moveTo,
    moveToReview,
    moveToLastStep,
    onActivityHistoryUuidChange,
    onClose,
    onEdit,
  };
};
