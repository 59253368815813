import { useContext } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { ProjectStatusConstants } from "../../../../constants";
import { Origin, TabsList } from "../../../../models";
import { IStateOrigin } from "../../../../navigators/navigateStateObjects";
import { GetProjectDetailsResponse } from "../../../../service/query";
import { useAuth } from "../../../../useAuth";
import { getProjectAuditHistoryRoute, getProjectsRoute, getSettingsAuditHistoryRoute } from "../../../../utils/routes";
import { ProjectContext } from "../../../shared/projects/project/ProjectContext";

interface UseProjectReturnData {
  projectDetails: GetProjectDetailsResponse | undefined;
  handleBackClick: () => void;
  tabsList: TabsList;
  backButtonText: string;
}

export const useProject = (): UseProjectReturnData => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const { state } = useLocation();
  const stateOrigin = state?.origin as IStateOrigin;
  const { currentUserType } = useAuth();
  const { projectDetails, isExternalProject } = useContext(ProjectContext);

  const origin = searchParams.get("origin");

  const backButtonText =
    stateOrigin?.goBackText ??
    (origin === Origin.ProjectAuditPage || origin === Origin.OrganisationAuditPage
      ? "Back to audit"
      : "Back to projects");

  const handleBackClick = (): void => {
    if (stateOrigin?.from) navigate(stateOrigin?.from);
    else if (origin === Origin.ProjectAuditPage && projectDetails?.uuid)
      navigate(getProjectAuditHistoryRoute(projectDetails?.uuid, currentUserType));
    else if (origin === Origin.OrganisationAuditPage) navigate(getSettingsAuditHistoryRoute(currentUserType));
    else navigate(getProjectsRoute(currentUserType));
  };

  const getTabsList = (): TabsList => {
    const list =
      projectDetails?.status !== ProjectStatusConstants.DRAFT &&
      projectDetails?.status !== ProjectStatusConstants.PRE_DEVELOPMENT
        ? [
            {
              label: "Overview",
              route: `/d/projects/${projectDetails?.uuid}/overview${origin ? `?origin=${origin}` : ""}`,
            },
            {
              label: "Issuances",
              route: `/d/projects/${projectDetails?.uuid}/issuances${origin ? `?origin=${origin}` : ""}`,
            },
            {
              label: "Activities",
              route: `/d/projects/${projectDetails?.uuid}/activities${origin ? `?origin=${origin}` : ""}`,
            },
            {
              label: "Marketing settings",
              route: `/d/projects/${projectDetails?.uuid}/marketing-settings${origin ? `?origin=${origin}` : ""}`,
            },
            {
              label: "Documents",
              route: `/d/projects/${projectDetails?.uuid}/documents${origin ? `?origin=${origin}` : ""}`,
            },
          ]
        : [
            {
              label: "Overview",
              route: `/d/projects/${projectDetails?.uuid}/overview${origin ? `?origin=${origin}` : ""}`,
            },
            {
              label: "Issuances",
              route: `/d/projects/${projectDetails?.uuid}/issuances${origin ? `?origin=${origin}` : ""}`,
            },
            {
              label: "Marketing settings",
              route: `/d/projects/${projectDetails?.uuid}/marketing-settings${origin ? `?origin=${origin}` : ""}`,
            },
            {
              label: "Documents",
              route: `/d/projects/${projectDetails?.uuid}/documents${origin ? `?origin=${origin}` : ""}`,
            },
          ];

    if (
      !isExternalProject() &&
      projectDetails?.status !== ProjectStatusConstants.DRAFT &&
      projectDetails?.status !== ProjectStatusConstants.PRE_DEVELOPMENT
    ) {
      list.push({
        label: "Access",
        route: `/d/projects/${projectDetails?.uuid}/access${origin ? `?origin=${origin}` : ""}`,
      });
    }

    return list;
  };

  return {
    projectDetails,
    handleBackClick,
    tabsList: getTabsList(),
    backButtonText,
  };
};
