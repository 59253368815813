import { Dispatch, SetStateAction } from "react";
import { NavigateFunction } from "react-router-dom";

import { searchActivityHistory, SearchActivityHistoryResponse } from "../../../../service/query";
import { Status } from "../../../../service/Shared";
import { dataGridMapFilterCriteria } from "../../../../widget";
import { createDraftOrNavigateToExistingDraft } from ".";

const getDraftVersionNumber = async (activityUuid: string): Promise<number> => {
  const filterCriteria = dataGridMapFilterCriteria([]);

  filterCriteria.activity = {
    uuid: {
      operator: "eq",
      value: activityUuid,
    },
  };

  filterCriteria.isDraft = {
    operator: "eq",
    value: true,
  };

  const response = await searchActivityHistory({
    activityUuid,
    paging: {
      afterCursor: null,
      beforeCursor: null,
      limit: 10,
    },

    filter: { results: filterCriteria },
  });

  if (response.status === Status.Success && response.data) {
    if (response.data.results && response.data.results.length > 0) {
      return response.data.results[0].versionNumber;
    }
  }

  return 0;
};

/**
 * Gets activity histories for the specified activity and project uuid which are current and have a higher version number than the `versionNumber` argument
 * If the `versionNumber` argument is not specified or is `undefined`, the current user's draft will be retrieved and its versionNumber will be used
 */
export const getNewVersion = async (
  activityUuid: string,
  versionNumber?: number | undefined
): Promise<SearchActivityHistoryResponse | undefined> => {
  const filterCriteria = dataGridMapFilterCriteria([]);

  const versionNo = versionNumber ?? (await getDraftVersionNumber(activityUuid));

  filterCriteria.activity = {
    uuid: {
      operator: "eq",
      value: activityUuid,
    },
  };

  filterCriteria.isDraft = {
    operator: "eq",
    value: false,
  };

  filterCriteria.isCurrent = {
    operator: "eq",
    value: true,
  };

  filterCriteria.versionNumber = {
    operator: "gte",
    value: versionNo,
  };

  const response = await searchActivityHistory({
    activityUuid,
    paging: {
      afterCursor: null,
      beforeCursor: null,
      limit: 10,
    },

    filter: { results: filterCriteria },
  });

  if (response.status === Status.Success && response.data) {
    if (response.data.results && response.data.results.length > 0) {
      return response.data;
    }
  }

  return undefined;
};

export const resumeActivity = async (
  navigate: NavigateFunction,
  setDraftActivityHistoryUuid: Dispatch<SetStateAction<string | undefined>>,
  setNewVersionActivityHistory: Dispatch<SetStateAction<SearchActivityHistoryResponse | undefined>>,
  setShowVersionConflictModal: Dispatch<SetStateAction<boolean>>,
  activityUuid: string,
  activityHistoryUuid?: string,
  versionNumber?: number,
  isProjectDashboard = false
): Promise<void> => {
  const newVersion = activityHistoryUuid ? await getNewVersion(activityUuid, versionNumber) : undefined;

  if (newVersion) {
    setDraftActivityHistoryUuid(activityHistoryUuid);
    setNewVersionActivityHistory(newVersion);
    setShowVersionConflictModal(true);
  } else {
    await createDraftOrNavigateToExistingDraft(activityUuid, activityHistoryUuid, isProjectDashboard, navigate);
  }
};
