import { Dispatch, SetStateAction } from "react";

import {
  ActivityReviewTypeConstants,
  ProjectActivitiesConstants,
  ProjectActivityReviewsConstants,
} from "../../../../../../../../constants";
import { WorkflowActivityReview } from "../../../../../../../../models/shared/workflowActivityReviewType";
import { SearchActivityReviewsResponse } from "../../../../../../../../service/query";
import { ProfileMenu, ProfileMenuItem } from "../../../../../../../../widget";
import { DownloadActivityFilesModal } from "../../../../../../../developer/components";
import {
  ApproveModal,
  CompleteAssessmentModal,
  CompleteReviewModal,
  ReturnToAssessorModal,
  ReturnToDeveloperModal,
  SubmitToCodeModal,
  SubmitToRegistryModal,
  SubmitToReviewerModal,
} from "../../../../../../components";
import { AssignActivityModal } from "../../../../../../components/AssignActivityModal";
import { ActivityReviewWorkflowButtonsTemplate } from "../ActivityReviewWorkflowButtonsTemplate";
import { useActivityReviewWorkflowButtons } from "./useActivityReviewWorkflowButtons";

interface ActivityReviewWorkflowButtonsProps {
  type?: string;
  activityReviews: SearchActivityReviewsResponse | undefined;
  workflowActivityReviews?: WorkflowActivityReview[];
  canManageActivityReview: boolean;
  refreshActivityReviews: boolean;
  setRefreshActivityReviews: Dispatch<SetStateAction<boolean>>;
}

export const ActivityReviewWorkflowButtons = ({
  type,
  activityReviews,
  workflowActivityReviews,
  canManageActivityReview,
  refreshActivityReviews,
  setRefreshActivityReviews,
}: ActivityReviewWorkflowButtonsProps): JSX.Element => {
  const {
    showDownloadDropdown,
    setShowDownloadDropdown,
    dropdownOffsetX,
    setDropdownOffsetX,
    dropdownOffsetY,
    setDropdownOffsetY,
    showDownloadActivityFilesModal,
    setShowDownloadActivityFilesModal,
    downloadDropdownOptions,

    activityAssessment,
    startAssessment,
    isStartAssessmentLoading,

    activityReview,
    startReview,
    isStartReviewLoading,

    showReturnToAssessorModal,
    setShowReturnToAssessorModal,
    showCompleteAssessmentModal,
    setShowCompleteAssessmentModal,
    showCompleteReviewModal,
    setShowCompleteReviewModal,
    showReturnToDeveloperModal,
    setShowReturnToDeveloperModal,
    showSubmitToReviewerModal,
    setShowSubmitToReviewerModal,
    showSubmitToRegistryModal,
    setShowSubmitToRegistryModal,
    showSubmitToCodeModal,
    setShowSubmitToCodeModal,
    showApproveModal,
    setShowApproveModal,
    showAssignAssessmentModal,
    setShowAssignAssessmentModal,
    showAssignReviewModal,
    setShowAssignReviewModal,
  } = useActivityReviewWorkflowButtons({ activityReviews: workflowActivityReviews });

  const modals = (): JSX.Element => {
    return activityReview !== undefined || activityAssessment !== undefined ? (
      <>
        {/* Shared modals */}
        <div style={{ position: "fixed", left: dropdownOffsetX + 250, top: dropdownOffsetY + 22 }}>
          <ProfileMenu show={showDownloadDropdown} onClose={() => setShowDownloadDropdown(false)}>
            {downloadDropdownOptions.map((el) => (
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              <ProfileMenuItem key={el.id} profileMenuItem={el} onClick={el.action!} />
            ))}
          </ProfileMenu>
        </div>
        <DownloadActivityFilesModal
          show={showDownloadActivityFilesModal}
          onClose={() => {
            setShowDownloadActivityFilesModal(false);
          }}
        />
        {/* Review & Assessment modals */}
        {activityAssessment !== undefined && activityReview !== undefined && (
          <>
            <SubmitToCodeModal
              show={showSubmitToCodeModal}
              onClose={() => setShowSubmitToCodeModal(false)}
              activityUuid={activityReview.activityUuid}
              activityStatus={activityReview.activityStatus}
              activityRowVersion={activityReview.activityRowVersion}
              refreshActivityReviews={refreshActivityReviews}
              setRefreshActivityReviews={setRefreshActivityReviews}
            />
            <ApproveModal
              show={showApproveModal}
              onClose={() => setShowApproveModal(false)}
              activityUuid={activityReview.activityUuid}
              activityStatus={activityReview.activityStatus}
              activityRowVersion={activityReview.activityRowVersion}
              activityReviewUuid={activityReview.uuid}
              activityReviewStatus={activityReview.status}
              activityReviewRowVersion={activityReview.rowVersion}
              activityAssessmentUuid={activityAssessment.uuid}
              activityAssessmentStatus={activityAssessment.status}
              activityAssessmentRowVersion={activityAssessment.rowVersion}
              refreshActivityReviews={refreshActivityReviews}
              setRefreshActivityReviews={setRefreshActivityReviews}
            />
            <ReturnToAssessorModal
              show={showReturnToAssessorModal}
              onClose={() => setShowReturnToAssessorModal(false)}
              activityUuid={activityReview.activityUuid}
              activityStatus={activityReview.activityStatus}
              activityRowVersion={activityReview.activityRowVersion}
              activityReviewUuid={activityReview.uuid}
              activityReviewStatus={activityReview.status}
              activityReviewRowVersion={activityReview.rowVersion}
              activityAssessmentUuid={activityAssessment.uuid}
              activityAssessmentStatus={activityAssessment.status}
              activityAssessmentRowVersion={activityAssessment.rowVersion}
              refreshActivityReviews={refreshActivityReviews}
              setRefreshActivityReviews={setRefreshActivityReviews}
            />
            <SubmitToReviewerModal
              show={showSubmitToReviewerModal}
              onClose={() => setShowSubmitToReviewerModal(false)}
              activityUuid={activityReview.activityUuid}
              activityStatus={activityReview.activityStatus}
              activityRowVersion={activityReview.activityRowVersion}
              activityReviewUuid={activityReview.uuid}
              activityReviewStatus={activityReview.status}
              activityReviewRowVersion={activityReview.rowVersion}
              activityAssessmentUuid={activityAssessment.uuid}
              activityAssessmentStatus={activityAssessment.status}
              activityAssessmentRowVersion={activityAssessment.rowVersion}
              refreshActivityReviews={refreshActivityReviews}
              setRefreshActivityReviews={setRefreshActivityReviews}
            />
            <CompleteReviewModal
              show={showCompleteReviewModal}
              onClose={() => setShowCompleteReviewModal(false)}
              activityUuid={activityReview.activityUuid}
              activityStatus={activityReview.activityStatus}
              activityRowVersion={activityReview.activityRowVersion}
              activityReviewUuid={activityReview.uuid}
              activityReviewStatus={activityReview.status}
              activityReviewRowVersion={activityReview.rowVersion}
              activityAssessmentUuid={activityAssessment.uuid}
              activityAssessmentStatus={activityAssessment.status}
              activityAssessmentRowVersion={activityAssessment.rowVersion}
              refreshActivityReviews={refreshActivityReviews}
              setRefreshActivityReviews={setRefreshActivityReviews}
            />
          </>
        )}
        {activityAssessment !== undefined && (
          <>
            <CompleteAssessmentModal
              show={showCompleteAssessmentModal}
              onClose={() => setShowCompleteAssessmentModal(false)}
              activityUuid={activityAssessment.activityUuid}
              activityStatus={activityAssessment.activityStatus}
              activityRowVersion={activityAssessment.activityRowVersion}
              activityAssessmentUuid={activityAssessment.uuid}
              activityAssessmentStatus={activityAssessment.status}
              activityAssessmentRowVersion={activityAssessment.rowVersion}
              refreshActivityReviews={refreshActivityReviews}
              setRefreshActivityReviews={setRefreshActivityReviews}
            />
            <ReturnToDeveloperModal
              show={showReturnToDeveloperModal}
              onClose={() => setShowReturnToDeveloperModal(false)}
              activityUuid={activityAssessment.activityUuid}
              activityStatus={activityAssessment.activityStatus}
              activityRowVersion={activityAssessment.activityRowVersion}
              activityAssessmentUuid={activityAssessment.uuid}
              activityAssessmentStatus={activityAssessment.status}
              activityAssessmentRowVersion={activityAssessment.rowVersion}
              refreshActivityReviews={refreshActivityReviews}
              setRefreshActivityReviews={setRefreshActivityReviews}
            />
            {/* Assign Assessment */}
            {canManageActivityReview && (
              <AssignActivityModal
                show={showAssignAssessmentModal}
                closeModal={() => setShowAssignAssessmentModal(false)}
                activityReview={activityReviews?.results.find(
                  (review) => review.type === ActivityReviewTypeConstants.ASSESSMENT
                )}
                refreshActivities={refreshActivityReviews}
                setRefreshActivities={setRefreshActivityReviews}
                modalTitle="Assign Activity"
              />
            )}
          </>
        )}
        {activityReview !== undefined && (
          <>
            <SubmitToRegistryModal
              show={showSubmitToRegistryModal}
              onClose={() => setShowSubmitToRegistryModal(false)}
              activityUuid={activityReview.activityUuid}
              activityStatus={activityReview.activityStatus}
              activityRowVersion={activityReview.activityRowVersion}
              refreshActivityReviews={refreshActivityReviews}
              setRefreshActivityReviews={setRefreshActivityReviews}
            />
            {/* Assign Review */}
            {canManageActivityReview && (
              <AssignActivityModal
                show={showAssignReviewModal}
                closeModal={() => setShowAssignReviewModal(false)}
                activityReview={activityReviews?.results.find(
                  (review) => review.type === ActivityReviewTypeConstants.REVIEW
                )}
                refreshActivities={refreshActivityReviews}
                setRefreshActivities={setRefreshActivityReviews}
                modalTitle="Assign Activity"
              />
            )}
          </>
        )}
      </>
    ) : (
      <div />
    );
  };

  // Button Logic
  const buttons = (): JSX.Element => {
    // Manager
    if (canManageActivityReview) {
      switch (activityAssessment?.activityStatus) {
        case ProjectActivitiesConstants.STATUS_SUBMITTED_TO_VVB:
          return (
            <ActivityReviewWorkflowButtonsTemplate
              btn1Text="Assign assessment"
              btn1OnClick={() => setShowAssignAssessmentModal(true)}
              setDropdownOffsetX={setDropdownOffsetX}
              setDropdownOffsetY={setDropdownOffsetY}
              setShowDownloadDropdown={setShowDownloadDropdown}
            />
          );
        case ProjectActivitiesConstants.STATUS_ASSESSMENT_AWAITING_REVIEW:
          return (
            <ActivityReviewWorkflowButtonsTemplate
              btn1Text="Assign reviewer"
              btn1OnClick={() => setShowAssignReviewModal(true)}
              btn2Text="View"
              btn2OnClick={activityAssessment?.onView}
              setDropdownOffsetX={setDropdownOffsetX}
              setDropdownOffsetY={setDropdownOffsetY}
              setShowDownloadDropdown={setShowDownloadDropdown}
            />
          );
        case ProjectActivitiesConstants.STATUS_REVIEW_COMPLETE:
          return (
            <ActivityReviewWorkflowButtonsTemplate
              btn1Text="Submit to Code"
              btn1OnClick={() => setShowSubmitToCodeModal(true)}
              btn2Text="View"
              btn2OnClick={activityReview?.onView}
              setDropdownOffsetX={setDropdownOffsetX}
              setDropdownOffsetY={setDropdownOffsetY}
              setShowDownloadDropdown={setShowDownloadDropdown}
            />
          );
        case ProjectActivitiesConstants.STATUS_SUBMITTED_TO_CODE:
          return (
            <ActivityReviewWorkflowButtonsTemplate
              btn1Text="Submit to Registry"
              btn1OnClick={() => setShowSubmitToRegistryModal(true)}
              btn2Text="View"
              btn2OnClick={activityReview?.onView}
              setDropdownOffsetX={setDropdownOffsetX}
              setDropdownOffsetY={setDropdownOffsetY}
              setShowDownloadDropdown={setShowDownloadDropdown}
            />
          );
        case ProjectActivitiesConstants.STATUS_SUBMITTED_TO_REGISTRY:
          return (
            <ActivityReviewWorkflowButtonsTemplate
              btn1Text="Approve"
              btn1OnClick={() => setShowApproveModal(true)}
              btn2Text="View"
              btn2OnClick={activityReview?.onView}
              setDropdownOffsetX={setDropdownOffsetX}
              setDropdownOffsetY={setDropdownOffsetY}
              setShowDownloadDropdown={setShowDownloadDropdown}
            />
          );
        default:
          return (
            <ActivityReviewWorkflowButtonsTemplate
              btn1Text="View"
              btn1OnClick={activityReview?.onView ?? activityAssessment?.onView}
              setDropdownOffsetX={setDropdownOffsetX}
              setDropdownOffsetY={setDropdownOffsetY}
              setShowDownloadDropdown={setShowDownloadDropdown}
            />
          );
      }
    }
    // Assessor
    if (activityAssessment !== undefined && type === ActivityReviewTypeConstants.ASSESSMENT) {
      const hasBothReviews = activityAssessment !== undefined && activityReview !== undefined;
      switch (activityAssessment.status) {
        case ProjectActivityReviewsConstants.STATUS_TO_DO:
          return (
            <ActivityReviewWorkflowButtonsTemplate
              btn1Text="Start assessment"
              btn1OnClick={startAssessment}
              btn1IsLoading={isStartAssessmentLoading}
              setDropdownOffsetX={setDropdownOffsetX}
              setDropdownOffsetY={setDropdownOffsetY}
              setShowDownloadDropdown={setShowDownloadDropdown}
            />
          );
        case ProjectActivityReviewsConstants.STATUS_IN_PROGRESS:
          return activityAssessment.completionPercentage < 100 ? (
            <ActivityReviewWorkflowButtonsTemplate
              btn1Text="Resume"
              btn1OnClick={activityAssessment.onEdit}
              btn2Text="Return to developer"
              btn2OnClick={() => setShowReturnToDeveloperModal(true)}
              setDropdownOffsetX={setDropdownOffsetX}
              setDropdownOffsetY={setDropdownOffsetY}
              setShowDownloadDropdown={setShowDownloadDropdown}
            />
          ) : (
            <ActivityReviewWorkflowButtonsTemplate
              btn1Text="Submit assessment"
              btn1OnClick={() =>
                hasBothReviews ? setShowSubmitToReviewerModal(true) : setShowCompleteAssessmentModal(true)
              }
              btn2Text="Edit"
              btn2OnClick={activityAssessment.onEdit}
              setDropdownOffsetX={setDropdownOffsetX}
              setDropdownOffsetY={setDropdownOffsetY}
              setShowDownloadDropdown={setShowDownloadDropdown}
            />
          );
        case ProjectActivityReviewsConstants.STATUS_FEEDBACK_RECEIVED:
          return activityAssessment.completionPercentage < 100 ? (
            <ActivityReviewWorkflowButtonsTemplate
              btn1Text="Resume"
              btn1OnClick={activityAssessment.onEdit}
              btn2Text="Return to developer"
              btn2OnClick={() => setShowReturnToDeveloperModal(true)}
              setDropdownOffsetX={setDropdownOffsetX}
              setDropdownOffsetY={setDropdownOffsetY}
              setShowDownloadDropdown={setShowDownloadDropdown}
            />
          ) : (
            <ActivityReviewWorkflowButtonsTemplate
              btn1Text="Resume"
              btn1OnClick={activityAssessment.onEdit}
              btn2Text="Submit"
              btn2OnClick={() =>
                hasBothReviews ? setShowSubmitToReviewerModal(true) : setShowCompleteAssessmentModal(true)
              }
              setDropdownOffsetX={setDropdownOffsetX}
              setDropdownOffsetY={setDropdownOffsetY}
              setShowDownloadDropdown={setShowDownloadDropdown}
            />
          );
        default:
          return (
            <ActivityReviewWorkflowButtonsTemplate
              btn1Text="View"
              btn1OnClick={activityAssessment.onView}
              setDropdownOffsetX={setDropdownOffsetX}
              setDropdownOffsetY={setDropdownOffsetY}
              setShowDownloadDropdown={setShowDownloadDropdown}
            />
          );
      }
    }
    // Reviewer
    if (activityReview !== undefined && type === ActivityReviewTypeConstants.REVIEW) {
      switch (activityReview.status) {
        case ProjectActivityReviewsConstants.STATUS_TO_DO:
          return (
            <ActivityReviewWorkflowButtonsTemplate
              btn1Text="Start review"
              btn1OnClick={startReview}
              btn1IsLoading={isStartReviewLoading}
              setDropdownOffsetX={setDropdownOffsetX}
              setDropdownOffsetY={setDropdownOffsetY}
              setShowDownloadDropdown={setShowDownloadDropdown}
            />
          );
        case ProjectActivityReviewsConstants.STATUS_IN_PROGRESS:
          return activityReview.completionPercentage < 100 ? (
            <ActivityReviewWorkflowButtonsTemplate
              btn1Text="Resume"
              btn1OnClick={activityReview.onEdit}
              btn2Text="Return to assessor"
              btn2OnClick={() => setShowReturnToAssessorModal(true)}
              setDropdownOffsetX={setDropdownOffsetX}
              setDropdownOffsetY={setDropdownOffsetY}
              setShowDownloadDropdown={setShowDownloadDropdown}
            />
          ) : (
            <ActivityReviewWorkflowButtonsTemplate
              btn1Text="Submit"
              btn1OnClick={() => setShowCompleteReviewModal(true)}
              btn2Text="Edit"
              btn2OnClick={activityReview.onEdit}
              setDropdownOffsetX={setDropdownOffsetX}
              setDropdownOffsetY={setDropdownOffsetY}
              setShowDownloadDropdown={setShowDownloadDropdown}
            />
          );
        case ProjectActivityReviewsConstants.STATUS_FEEDBACK_RECEIVED:
          return activityReview.completionPercentage < 100 ? (
            <ActivityReviewWorkflowButtonsTemplate
              btn1Text="Resume"
              btn1OnClick={activityReview.onEdit}
              btn2Text="Return to assessor"
              btn2OnClick={() => setShowReturnToAssessorModal(true)}
              setDropdownOffsetX={setDropdownOffsetX}
              setDropdownOffsetY={setDropdownOffsetY}
              setShowDownloadDropdown={setShowDownloadDropdown}
            />
          ) : (
            <ActivityReviewWorkflowButtonsTemplate
              btn1Text="Submit"
              btn1OnClick={() => setShowCompleteReviewModal(true)}
              btn2Text="Edit"
              btn2OnClick={activityReview.onEdit}
              setDropdownOffsetX={setDropdownOffsetX}
              setDropdownOffsetY={setDropdownOffsetY}
              setShowDownloadDropdown={setShowDownloadDropdown}
            />
          );
        default:
          return (
            <ActivityReviewWorkflowButtonsTemplate
              btn1Text="View"
              btn1OnClick={activityReview.onView}
              setDropdownOffsetX={setDropdownOffsetX}
              setDropdownOffsetY={setDropdownOffsetY}
              setShowDownloadDropdown={setShowDownloadDropdown}
            />
          );
      }
    }
    // Default
    return <div />;
  };

  return (
    <>
      {buttons()}
      {modals()}
    </>
  );
};
