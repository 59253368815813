import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { SelectData } from "../../../../../models";
import { getOrganisationUserDetails, GetOrganisationUserDetailsResponse, lookUp } from "../../../../../service/query";
import { Status } from "../../../../../service/Shared";
import { useAuth } from "../../../../../useAuth";
import { getUserSettingsRoute } from "../../../../../utils/routes";

interface UseEditReturnData {
  pageTitle: string | undefined;
  dataIsLoading: boolean;
  userDetails: GetOrganisationUserDetailsResponse | undefined;
  userUuid: string | undefined;
  roles: SelectData | undefined;
  rolesInfo: string[];
  userSettingsRoute: string;
}

type EditPageRouteParams = "userUuid";

export const useEdit = (): UseEditReturnData => {
  const { userUuid } = useParams<EditPageRouteParams>();

  const { currentUserType } = useAuth();

  const [dataIsLoading, setDataIsLoading] = useState(true);
  const [userDetails, setUserDetails] = useState<GetOrganisationUserDetailsResponse | undefined>(undefined);
  const [roles, setRoles] = useState<SelectData | undefined>(undefined);

  const userSettingsRoute = getUserSettingsRoute(currentUserType);
  const rolesInfo = [
    "Manager: Can view all projects and organisation details. Managers can invite other managers or assessors & edit all organisation users. There must always be one manager.",
    "Auditor: Can only see the projects that they are assigned to work on and cannot update any organisation settings or invite new users.",
  ];

  const fetchData = useCallback(async () => {
    if (userUuid) {
      const organisationRes = await getOrganisationUserDetails({ userUuid });
      if (organisationRes.status === Status.Success) {
        setUserDetails(organisationRes.data);
      }
    }
    const rolesRes = await lookUp({ type: "VVB_Role" });
    if (rolesRes.status === Status.Success) {
      setRoles(
        rolesRes.data?.map((role) => {
          return {
            key: role.key,
            value: role.valueString,
          };
        })
      );
    }
  }, []);

  useEffect(() => {
    fetchData().then(() => setDataIsLoading(false));
  }, [fetchData]);

  return {
    pageTitle:
      userDetails?.user.firstName && userDetails?.user.lastName
        ? `${userDetails?.user.firstName} ${userDetails?.user.lastName}`
        : undefined,
    dataIsLoading,
    userDetails,
    userUuid,
    roles,
    rolesInfo,
    userSettingsRoute,
  };
};
