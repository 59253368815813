import { Column, CursorDataGrid, Row } from "../../../../../widget";
import { AssignActivityModal } from "../../components";
import { useUnassignedActivities } from "./useUnassignedActivities";

const UnassignedActivities = (): JSX.Element => {
  const {
    tableColumnsUnassignedActivities,
    showAssignActivityModal,
    selectedActivityReview,
    refreshUnassignedActivities,
    defaultSortingCriteria,
    setRefreshUnassignedActivities,
    setShowAssignActivityModal,
    onUnassignedActivitiesDataLoad,
    isLoading,
  } = useUnassignedActivities();

  return (
    <div>
      <Row spacingV="ml">
        <Column span={12}>
          <h2>Unassigned Activities</h2>
        </Column>
      </Row>
      <Row>
        <Column span={12}>
          <CursorDataGrid
            columns={tableColumnsUnassignedActivities}
            clientSide={false}
            filterable
            onChange={onUnassignedActivitiesDataLoad}
            data={[]}
            refresh={refreshUnassignedActivities}
            noDataMessage="No reviews to display"
            dataIsLoading={isLoading}
            defaultSortingCriteria={defaultSortingCriteria}
          />
        </Column>
      </Row>
      <AssignActivityModal
        show={showAssignActivityModal}
        closeModal={() => setShowAssignActivityModal(false)}
        activityReview={selectedActivityReview}
        refreshActivities={refreshUnassignedActivities}
        setRefreshActivities={setRefreshUnassignedActivities}
        modalTitle="Assign Activity"
      />
    </div>
  );
};

export default UnassignedActivities;
