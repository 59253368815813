import React, {
  Attributes,
  Children,
  cloneElement,
  Fragment,
  isValidElement,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";

import { CopyBlueIcon, PasteBlueIcon } from "../../../../assets";
import kanaExpressionInterpreter from "../../../../lib/KanaExpressionInterpreter";
import { CreateChildrenRecursiveArgs, CreateRowArgs, RepeaterData } from "../../../../models";
import { formatClipboardValue } from "../../../../route/developer/activities";
import { ComponentRow } from "../../../../route/developer/activities/activity/components/ComponentRow";
import { GetDiscussionThreadsResponse } from "../../../../service/query";
import { getFormattedDate, isDate } from "../../../../utils";
import { Toast } from "../../../general";
import { Button } from "../../Button";
import { findDiscussionsInChild } from "../utils";

interface UseRepeaterArgs {
  initialValue: RepeaterData[];
  template: ReactNode[];
  maxRows: number;
  shouldConfirmDeletion?: boolean;
  conditionalConfirmDeletionFields?: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  expressionContext?: any;
  repeaterIndex?: number;
  createRow: (args: CreateRowArgs) => ReactNode;
  // affectedRepeaterIdx is an optional param.
  // Currently only used for passing the removed index when a row get's deleted.
  onChange: (value: RepeaterData[], affectedRepeaterIdx?: number) => void;
  discussions: GetDiscussionThreadsResponse[] | undefined;
}

interface UseRepeaterReturnData {
  rows: ReactNode[];
  showDeleteModal: boolean;
  handleAddRow: () => void;
  handleRemoveRow: (idx: number) => void;
  handlePasteFromClipboard: () => void;
  onConfirmDeleteModal: () => void;
  onCloseDeleteModal: () => void;
}

export const useRepeater = ({
  initialValue,
  template,
  maxRows,
  shouldConfirmDeletion,
  conditionalConfirmDeletionFields,
  expressionContext,
  repeaterIndex,
  createRow,
  onChange,
  discussions,
}: UseRepeaterArgs): UseRepeaterReturnData => {
  const [data, setData] = useState(initialValue.length === 0 ? [{}] : initialValue);
  const [rows, setRows] = useState<ReactNode[]>([]);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [idxForConfirmDeletion, setIdxForConfirmDeletion] = useState<number>(0);
  const [copiedValues, setCopiedValues] = useState<{ [key: string]: string | number | boolean | Date }>();

  // eslint-disable-next-line no-undef
  const getLastFromCompositeKey = (key: React.Key | null): string | null => {
    if (key === null) return null;
    return key.toString().slice(key.toString().indexOf("$") + 1);
  };

  const handleCopy = useCallback(
    (idx: number, elementKeys: string[]): void => {
      const filteredEntries = Object.entries(data[idx]).filter((el) => elementKeys.includes(el[0]));
      const valuesObj = Object.fromEntries(filteredEntries);

      setCopiedValues(valuesObj);
    },
    [data, copiedValues]
  );

  const handlePaste = useCallback(
    (idx: number, elementKeys: string[]): void => {
      if (
        copiedValues &&
        Object.keys(copiedValues).length &&
        Object.keys(copiedValues).every((el) => elementKeys.includes(el))
      ) {
        const updatedData = data.map((el, index) => {
          if (idx === index && copiedValues) {
            return { ...el, ...copiedValues };
          }

          return el;
        });

        const undefinedElements = elementKeys.filter((el) => !Object.keys(copiedValues).includes(el));

        undefinedElements.forEach((el: string) => {
          delete updatedData[idx][el];
        });

        setData(updatedData);
        onChange(updatedData);
      } else {
        Toast.warning({ message: "Please copy a value before pasting" });
      }
    },
    [data, copiedValues]
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const computeCopyPasteGroupButtons = (childrenArr: any[], idx: number): any[] => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const formattedChildrenArr = childrenArr.map((el: any, index: number) => {
      let newEl = el;
      const elementCopyPasteGroup = el?.props?.reactEl?.props?.copyPasteGroup;
      if (el?.props?.reactEl?.props?.copyPasteGroup) {
        const nextChild = childrenArr[index + 1] as ReactNode;

        if (
          !nextChild ||
          !isValidElement(nextChild) ||
          nextChild?.props?.reactEl?.props?.copyPasteGroup !== elementCopyPasteGroup
        ) {
          const elementKeys =
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            childrenArr?.flatMap((ek: any) =>
              ek?.props?.reactEl?.props?.copyPasteGroup === elementCopyPasteGroup ? ek.key.split(".$")[1] : []
            ) || [];

          newEl = (
            <Fragment key={elementCopyPasteGroup}>
              {el}
              <div className="CopyPasteActions">
                <Button
                  text="Copy"
                  icon={<CopyBlueIcon />}
                  iconPosition="left"
                  variant="tertiary"
                  onClick={() => handleCopy(idx, elementKeys)}
                />
                <Button
                  text="Paste"
                  icon={<PasteBlueIcon />}
                  iconPosition="left"
                  variant="tertiary"
                  onClick={() => handlePaste(idx, elementKeys)}
                />
              </div>
            </Fragment>
          );
        }
      }

      return newEl;
    });

    return formattedChildrenArr;
  };

  const handlePasteFromClipboard = async (): Promise<void> => {
    if (navigator && navigator.clipboard && navigator.clipboard.readText) {
      const clipboardVal = await navigator.clipboard.readText();
      const formattedTemplate =
        repeaterIndex !== undefined
          ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
            template.map((t: any) => ({ ...t, key: getLastFromCompositeKey(t.key) }))
          : template;
      const formattedData = formatClipboardValue(clipboardVal, formattedTemplate);
      if (formattedData) {
        if (formattedData.length <= maxRows) {
          setData(formattedData);
          onChange(formattedData);
          Toast.success({ message: "Data successfully pasted" });
        } else {
          Toast.warning({ message: `The maximum number of entries is limited to ${maxRows}` });
        }
      } else {
        Toast.warning({ message: "Clipboard data doesn't match the expected format" });
      }
    } else {
      Toast.warning({ message: "Unsupported browser, please use Google Chrome, Microsoft Edge or Safari" });
    }
  };

  const createChildrenRecursive = ({
    children,
    fn,
    currentData,
    idx,
    currentDiscussions,
  }: CreateChildrenRecursiveArgs): ReactNode[] | null | undefined => {
    let childrenArr = Children.map(children, (child: ReactNode) => {
      if (!isValidElement(child)) {
        return child;
      }
      const repeaterRenderProps = child.props?.repeaterRenderProps;

      if (expressionContext && repeaterRenderProps && repeaterRenderProps.conditionExpression !== undefined) {
        const shouldRender = !!kanaExpressionInterpreter(
          repeaterRenderProps.conditionExpression,
          expressionContext,
          undefined,
          [idx]
        );

        if (!shouldRender) {
          // eslint-disable-next-line react/jsx-no-useless-fragment
          return fn(<></>);
        }
      }

      // Conditional fields that are not rendered to the screen initially are not pre-populated from the BE
      // Grabbing default value from expression on a conditional field
      let defaultValueFromExpression;

      if (expressionContext && repeaterRenderProps && repeaterRenderProps.defaultValueExpression !== undefined) {
        defaultValueFromExpression = kanaExpressionInterpreter(
          repeaterRenderProps.defaultValueExpression,
          expressionContext,
          undefined,
          [idx]
        );
      }

      let c: ReactElement;

      // group modification - to be removed or refactored extract function
      const initialChildKey = child.key;
      let childKey = initialChildKey;

      if (repeaterIndex !== undefined) {
        childKey = getLastFromCompositeKey(childKey);
      }
      // end

      const key = initialChildKey ? `${initialChildKey}_${idx}` : undefined;
      const name = initialChildKey ? `${initialChildKey}[${idx}]` : undefined;

      let value;

      if (childKey && currentData.length > idx) {
        value = currentData[idx][childKey];

        if (
          repeaterRenderProps &&
          repeaterRenderProps.componentType === "TextInput" &&
          repeaterRenderProps.dataType === "Date" &&
          isDate(value)
        ) {
          value = getFormattedDate(value as Date);
        }

        if (
          repeaterRenderProps &&
          repeaterRenderProps.componentType === "RadioButtonGroup" &&
          repeaterRenderProps.dataType === "Boolean" &&
          typeof value === "boolean"
        ) {
          value = value.toString();
        }
      }

      if (child.props.repeaterTransformDefaultValue)
        value = child.props.repeaterTransformDefaultValue(value, child.props);

      // if there's a default expression for a conditional field that
      // just got rendered we compute the value on the FE
      // we only change the value if it is empty so we don't override user input
      if (defaultValueFromExpression && value === undefined) {
        value = defaultValueFromExpression;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const onChangeInner = (arg: any): void => {
        let val = arg; // e.g. TextInput
        if (arg && arg.target && arg.target.value) val = arg.target.value; // e.g. RadioButtonGroup
        if (child.props.repeaterTransformSavedValue) val = child.props.repeaterTransformSavedValue(arg, idx);
        if (val === "") val = undefined;

        // don't fire the change event if:
        // - the value is unchanged
        // - the value is `undefined`, and the previous value (or the parent object) is also `undefined`
        // - fire when a change to the documents has been made
        if (
          !childKey ||
          (!((currentData[idx] === undefined && val !== undefined) || currentData[idx][childKey] !== val) &&
            !child.props.repeaterTransformSavedValue)
        )
          return;

        const currElementData = currentData[idx][childKey];

        // don't fire the change event if the value is the same but types are different
        // - type number
        // - type date
        // - do change if a change is made to a file hence we have filename to the passed object
        if (
          (!arg?.filename && currElementData?.toString && currElementData.toString() === val) ||
          (currElementData instanceof Date && getFormattedDate(currElementData) === val)
        ) {
          return;
        }

        // don't fire the change event if the existing value is null and changed values is undefined
        if (currElementData === null && val === undefined) return;

        // eslint-disable-next-line no-param-reassign
        currentData[idx][childKey] = val;
        setData(currentData);
        onChange(currentData);
      };

      if (child.props.children) {
        c = cloneElement(child, {
          children: createChildrenRecursive({
            children: child.props.children,
            fn,
            currentData,
            idx,
            currentDiscussions,
          }),
          key,
          name,
          value,
          onChange: onChangeInner,
          repeaterIndex: idx,
        } as Attributes);
      } else {
        c = cloneElement(child, {
          key,
          name,
          value,
          onChange: onChangeInner,
          repeaterIndex: idx,
        } as Attributes);
      }

      if (!repeaterRenderProps || repeaterRenderProps.parentIsTableRepeater) {
        return fn(c);
      }

      const isForTableRepeater = !!(repeaterRenderProps && repeaterRenderProps.componentType === "TableRepeater");

      return fn(
        <ComponentRow
          isForRepeater
          isForTableRepeater={isForTableRepeater}
          id={key}
          key={key}
          rowWrapKey={key}
          reactEl={c}
          componentKey={repeaterRenderProps.componentKey}
          dataPath={repeaterRenderProps.dataPath}
          discussions={findDiscussionsInChild(currentDiscussions, initialChildKey, idx)}
          repeaterIndex={idx}
        />
      );
    });

    if (childrenArr) {
      childrenArr = computeCopyPasteGroupButtons(childrenArr, idx);
    }

    return childrenArr;
  };

  const handleAddRow = useCallback(() => {
    const d = data.slice();
    d.push({});
    setData(d);
    onChange(d);
  }, [data]);

  const removeRow = useCallback(
    (idx: number) => {
      const d = data.slice();
      d.splice(idx, 1);
      setData(d);
      // we pass the deleted index when removing files
      onChange(d, idx);
    },
    [data]
  );

  // Checking if is document by checking if the field has a `onFileUpload` prop and `documentType` prop
  const isFieldDocumentUpload = useCallback(
    (fieldKey: string) => {
      const templateEl = template.find((el) => {
        if (isValidElement(el) && el.key === fieldKey) return el;
        return undefined;
      });

      if (templateEl && isValidElement(templateEl)) {
        if (templateEl.props.onFileUpload && templateEl.props.documentType) {
          return true;
        }
      }

      return false;
    },
    [data, template]
  );

  const computeConditionalConfirmDeletion = useCallback(
    (idx: number) => {
      if (Object.values(data[idx]).every((el) => el === undefined)) return false;

      if (!conditionalConfirmDeletionFields) return true;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const hasData = conditionalConfirmDeletionFields.every((field: any) => {
        if (data[idx][field] !== undefined) {
          // if the conditional field is a document we need to check if other fields are using this document
          if (isFieldDocumentUpload(field)) {
            const dataWithoutCurrent = [...data];
            dataWithoutCurrent.splice(idx, 1);

            const isDocumentUsedInOtherRepeaterField = dataWithoutCurrent.some((el) => el[field] === data[idx][field]);
            return !isDocumentUsedInOtherRepeaterField;
          }

          return true;
        }

        return false;
      });

      return hasData;
    },
    [data]
  );

  const handleRemoveRow = useCallback(
    (idx: number) => {
      if (shouldConfirmDeletion && computeConditionalConfirmDeletion(idx)) {
        setIdxForConfirmDeletion(idx);
        setShowDeleteModal(true);
      } else {
        removeRow(idx);
      }
    },
    [data]
  );

  const onCloseDeleteModal = useCallback((): void => {
    setShowDeleteModal(false);
  }, []);

  const onConfirmDeleteModal = useCallback((): void => {
    removeRow(idxForConfirmDeletion);
    setShowDeleteModal(false);
  }, [data, idxForConfirmDeletion]);

  const createRows = useCallback(
    (currentData: RepeaterData[], currentDiscussions: GetDiscussionThreadsResponse[] | undefined) => {
      const r = [];
      const d = currentData.slice();
      for (let i = 0; i < d.length; i++) {
        r.push(
          createRow({
            template,
            currentData: d,
            idx: i,
            handleRemoveRow,
            createChildrenRecursive,
            currentDiscussions,
          })
        );
      }
      setRows(r);
      return r;
    },
    [data, copiedValues]
  );

  useEffect(() => {
    createRows(data, discussions);
  }, [data, copiedValues, discussions]);

  return {
    rows,
    showDeleteModal,
    handleAddRow,
    handleRemoveRow,
    handlePasteFromClipboard,
    onConfirmDeleteModal,
    onCloseDeleteModal,
  };
};
