import "./Dashboard.css";

import { useLocation } from "react-router-dom";

import { OrganisationRoleConstants, ProjectPermissionConstants } from "../../../constants";
import { IStateOrigin } from "../../../navigators/navigateStateObjects";
import { DeveloperTemplate } from "../../../templates";
import { VersionConflictModal } from "../../../templates/DeveloperProjectCardWithTabsTemplate/components";
import {
  getActivitiesRoute,
  // getMarketingRoute,  // TODO ENG-557 hidden until post-launch
  getProjectsRoute,
} from "../../../utils/routes";
import {
  Column,
  InformationBox,
  KanaTooltip,
  ProfileMenu,
  ProfileMenuItem,
  QuickActionsMenu,
  Row,
  UserHeader,
} from "../../../widget";
import { DashboardPieCard } from "../../shared/components";
import {
  CreateProjectModal,
  // DashboardBarCard,  // TODO ENG-557 hidden until post-launch
  DashboardTableCard,
} from "./components";
import { useDashboard } from "./useDashboard";

const Dashboard = (): JSX.Element => {
  const {
    developerHasReceivedProjectInvitations,
    navigateToProjectInvitations,
    statusChartData,
    unitsChartData,
    // metricsChartData, // TODO ENG-557 hidden until post-launch
    tableColumnsTopProjects,
    tableColumnsInProgressActivities,
    tableColumnsInReviewActivities,
    isLoading,
    isLoadingProjects,
    isLoadingActivities,
    onTopProjectsDataLoad,
    onRecentActivitiesInProgressDataLoad,
    onRecentActivitiesChangesRequiredDataLoad,
    quickActionsMenuOptionsList,
    showProfileMenu,
    showCreateProjectModal,
    setShowProfileMenu,
    setShowCreateProjectModal,
    setShowVersionConflictModal,
    showVersionConflictModal,
    hasPermission,
    newVersionActivityHistory,
    draftActivityHistoryUuid,
    currentUserType,
    currentUserRole,
  } = useDashboard();
  // const marketingRoute = getMarketingRoute();  // TODO ENG-557 hidden until post-launch
  const location = useLocation();

  return (
    <DeveloperTemplate activeElement="Dashboard">
      <div className="Dashboard">
        <Row spacingV="ml">
          <Column span={12}>
            <UserHeader />
          </Column>
        </Row>
        {developerHasReceivedProjectInvitations &&
          (currentUserRole === OrganisationRoleConstants.OWNER ||
            currentUserRole === OrganisationRoleConstants.ADMIN) && (
            <Row spacingV="l">
              <Column span={12}>
                <InformationBox variant="info">
                  <div className="ProjectInvitationNotificationContent">
                    <p className="body2">
                      You have received a project access invite, please view your organisation&apos;s invites to accept
                      or decline.
                    </p>
                    <button className="BtnLink" onClick={navigateToProjectInvitations}>
                      View invites
                    </button>
                  </div>
                </InformationBox>
              </Column>
            </Row>
          )}
        <Row spacingV="ml">
          <Column span={12}>
            <div className="HeaderContainer">
              <h1>Dashboard</h1>
              {/* KAN-2222: As a request from the WCC / PC we will need to remove the Create New project
                functionality from production. */}
              {/* KAN-2872: This is done by only showing the QuickActionsMenu if the user has the createMergeDeleteProject
                permission, which will be removed from all users for the time being. */}
              {hasPermission(ProjectPermissionConstants.CREATE_MERGE_DELETE_PROJECT) && (
                <div>
                  <QuickActionsMenu onClick={() => setShowProfileMenu(true)} />
                  <ProfileMenu show={showProfileMenu} onClose={() => setShowProfileMenu(false)} position={{ top: "0" }}>
                    {quickActionsMenuOptionsList.map((el) => (
                      <ProfileMenuItem
                        key={el.id}
                        profileMenuItem={el}
                        onClick={() => {
                          setShowCreateProjectModal(true);
                          setShowProfileMenu(false);
                        }}
                      />
                    ))}
                  </ProfileMenu>
                </div>
              )}
            </div>
          </Column>
        </Row>
        {!isLoading && (
          <Row spacingV="ml">
            <Column span={6}>
              <DashboardPieCard
                title="Status"
                data={statusChartData}
                tooltip={
                  <KanaTooltip
                    tooltipHeader="Status"
                    tooltipText="Only projects where you are the registered developer are included in this graph"
                  />
                }
              />
            </Column>
            <Column span={6}>
              <DashboardPieCard
                title="Units"
                data={unitsChartData}
                noDataMessage="No units issued"
                tooltip={
                  <KanaTooltip
                    tooltipHeader="Buffer units"
                    tooltipText="Please note that buffer units are not shown in this chart"
                  />
                }
              />
            </Column>
          </Row>
        )}
        {/* TODO ENG-557 hidden until post-launch */}
        {/* <Row spacingV="ml">
              <Column span={12}>
                <DashboardBarCard
                  title="Marketing page views"
                  expandRoute={marketingRoute}
                  data={metricsChartData}
                />
              </Column>
            </Row> */}
        <Row spacingV="ml">
          <Column span={12}>
            <DashboardTableCard
              title="Activities in progress"
              expandRoute={getActivitiesRoute(currentUserType).concat("/in_progress")}
              state={{ origin: { goBackText: "Back to dashboard", from: location.pathname } as IStateOrigin }}
              onChange={onRecentActivitiesInProgressDataLoad}
              columns={tableColumnsInProgressActivities}
              isLoading={isLoadingActivities}
            />
          </Column>
        </Row>
        <Row spacingV="ml">
          <Column span={12}>
            <DashboardTableCard
              title="Activities in review"
              expandRoute={getActivitiesRoute(currentUserType).concat("/in_review")}
              state={{ origin: { goBackText: "Back to dashboard", from: location.pathname } as IStateOrigin }}
              onChange={onRecentActivitiesChangesRequiredDataLoad}
              columns={tableColumnsInReviewActivities}
              isLoading={isLoadingActivities}
            />
          </Column>
        </Row>
        <Row spacingV="ml">
          <Column span={12}>
            <DashboardTableCard
              title="Top projects"
              expandRoute={getProjectsRoute(currentUserType)}
              onChange={onTopProjectsDataLoad}
              columns={tableColumnsTopProjects}
              isLoading={isLoadingProjects}
            />
          </Column>
        </Row>
      </div>
      <CreateProjectModal
        showCreateProjectModal={showCreateProjectModal}
        closeModal={() => setShowCreateProjectModal(false)}
      />
      <VersionConflictModal
        showVersionConflictModal={showVersionConflictModal}
        draftActivityHistoryUuid={draftActivityHistoryUuid}
        closeModal={() => setShowVersionConflictModal(false)}
        newVersionActivityHistory={newVersionActivityHistory}
      />
    </DeveloperTemplate>
  );
};

export default Dashboard;
