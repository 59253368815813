import { InformationIcon } from "../../../../../assets";
import { OrganisationTypeConstants } from "../../../../../constants";

interface DiscussionInfoBoxProps {
  externalUserType: string;
  internalTeamSelected: boolean;
}

export const discussionInfoBox = ({
  externalUserType,
  internalTeamSelected,
}: DiscussionInfoBoxProps): JSX.Element | undefined => {
  let content;
  switch (externalUserType) {
    case OrganisationTypeConstants.VERIFIER:
      content = internalTeamSelected
        ? "Your discussion won’t be visible to other users until you have published this activity draft"
        : "Your discussion won’t be visible to other users until you have submitted this activity";
      break;
    case OrganisationTypeConstants.DEVELOPER:
      if (internalTeamSelected) return undefined; // Don't show the info box
      content = "Your discussion won’t be visible to the developer until you have returned the activity back to them";
      break;
    default:
      return undefined;
  }

  return (
    <div className="NewDiscussionInfo">
      <div>
        <InformationIcon width={16} height={16} />
      </div>
      <p>{content}</p>
    </div>
  );
};
