export class ProjectPermissionConstants {
  public static readonly READ_PROJECT = "readProject";

  public static readonly READ_PROJECT_AUDIT = "readProjectAudit";

  public static readonly WRITE_PROJECT_DETAILS = "writeProjectDetails";

  public static readonly WRITE_PROJECT_LISTING = "writeProjectListing";

  public static readonly WRITE_PROJECT_ISSUANCES = "writeProjectIssuances";

  public static readonly CREATE_MERGE_DELETE_PROJECT = "createMergeDeleteProject";

  public static readonly MANAGE_PROJECT_ORGANISATION_INVITATION = "manageProjectOrganisationInvitation";

  public static readonly ACCEPT_REJECT_PROJECT_ORGANISATION_INVITATION = "acceptRejectProjectOrganisationInvitation";

  public static readonly MANAGE_PROJECT_ACTIVITY = "manageProjectActivity";

  public static readonly DELETE_PROJECT_ACTIVITY = "deleteProjectActivity";

  public static readonly DOWNLOAD_PROJECT_ACTIVITY_OUTPUT = "downloadProjectActivityOutput";

  public static readonly READ_PROJECT_ACTIVITY = "readProjectActivity";

  public static readonly MANAGE_PROJECT_ACTIVITY_REVIEW = "manageProjectActivityReview";

  public static readonly WRITE_PROJECT_ACTIVITY_REVIEW = "writeProjectActivityReview";

  public static readonly MANAGE_PROJECT_DOCUMENT = "manageProjectDocument";
}
