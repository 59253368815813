import { NavigateFunction } from "react-router-dom";

import { createDraft } from "../../../../service/activity";
import { Status } from "../../../../service/Shared";
import { getActivityEditRoute } from "../../../../utils/routes";
import toast from "../../../../widget/general/Toast/Toast";

/**
 * This method is directly called on VersionConflictModal `Resume The Latest Activity` and in Developer/Project Workflow `Resume` button
 */
export const createActivityDraft = async (
  activityUuid: string,
  isProjectDashboard: boolean,
  navigate: NavigateFunction
): Promise<void> => {
  await createDraft({ activityUuid }).then((response) => {
    if (response.status === Status.Success && response.data) {
      navigate(getActivityEditRoute(response.data.uuid, isProjectDashboard ? "location=Project" : undefined));
    } else {
      const validationNotPublishedError = response.errors?.find(
        (error) => error.code === "VALIDATION_NO_PUBLISHED_VERSION_AVAILABLE"
      );

      if (validationNotPublishedError) {
        toast.error({
          message: `This activity has not yet been published. Once ${validationNotPublishedError?.parameters?.createdByUserFullName} has published the activity, you can begin editing.`,
        });
      }
    }
  });
};

export const createDraftOrNavigateToExistingDraft = async (
  activityUuid: string,
  draftVersionUuid: string | undefined,
  isProjectDashboard: boolean,
  navigate: NavigateFunction
): Promise<void> => {
  if (draftVersionUuid) {
    navigate(getActivityEditRoute(draftVersionUuid, isProjectDashboard ? "location=Project" : undefined));
  } else {
    await createActivityDraft(activityUuid, isProjectDashboard, navigate);
  }
};
