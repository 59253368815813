import { Dispatch, SetStateAction, useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import {
  ActivityReviewTypeConstants,
  OrganisationTypeConstants,
  ProjectActivitiesConstants,
  ProjectPermissionConstants,
} from "../../../../../../constants";
import { ActivityPageParams, SelectData, StepItem } from "../../../../../../models";
import {
  GetActivityHistoryDetailsResponse,
  getCurrentUserProjectPermissions,
  GetGroupDetailsResponse,
  GetProjectDetailsResponse,
  searchActivityHistory,
  SearchActivityHistoryResponse,
} from "../../../../../../service/query";
import { Status } from "../../../../../../service/Shared";
import { useAuth } from "../../../../../../useAuth";
import { hasActivityPermissionForProject } from "../../../../../../utils";
import { getActivityDashboardTabRoute, getActivityViewRoute } from "../../../../../../utils/routes";
import { dataGridMapFilterCriteria, StepProps } from "../../../../../../widget";
import { resumeActivity } from "../../../../../shared/utils";
import { createStepReview } from "../../../../../verifier/activities/activity/utils";
import { useFetchActivityHistory } from "../../hooks";
import { useFetchDiscussions } from "../../hooks/useFetchDiscussions";
import {
  ActivityData,
  ActivityDefinition,
  ActivityDefinitionInfo,
  HasReviews,
  UploadedDocumentEnriched,
} from "../../types";
import {
  checkTargetStepkeysAppended,
  createExpressionContext,
  createViewComponent,
  enrichUploadedDocuments,
  filterConditionalSteps,
  findCurrentStepName,
  getActivityDefinitionFields,
  getDataPath,
  getFirstStepKeys,
  getLastStepKeys,
  getNextStepKeys,
  getPreviousStepKeys,
  getStep,
  hasNextStep,
  hasPreviousStep,
  mapToStepItem,
  mapToStepProps,
} from "../../utils";
import { getDiscussionsForComponent, getDiscussionUuids } from "../../utils/DiscussionUtils";

interface UseActivityReturnData {
  steps: StepProps[];
  l3Steps: StepItem[] | undefined;
  stepFields: JSX.Element[];
  stepReviews?: (JSX.Element | undefined)[];
  currentStepName: string | null;
  currentStepKeys: string[];
  hasNext: boolean;
  hasPrevious: boolean;
  activityData: GetActivityHistoryDetailsResponse | undefined;
  isLoading: boolean;
  isReview: boolean;
  showReviewPage: boolean;
  activityDefinition: ActivityDefinition;
  activityDefinitionInfo: ActivityDefinitionInfo | undefined;
  activityCompletionPercentage: number;
  activityStatus?: ProjectActivitiesConstants;
  activityHistoryUuidData: SelectData;
  showVersionConflictModal: boolean;
  newVersionActivityHistory?: SearchActivityHistoryResponse;
  draftActivityHistoryUuid?: string;
  hasManageProjectActivityPermission: boolean;
  currentUserType: OrganisationTypeConstants;
  // handlers
  movePrevious: () => void;
  moveNext: () => void;
  moveTo: (targetStepKeys: string[]) => void;
  moveToLastStep: () => void;
  moveToReview: () => void;
  onActivityHistoryUuidChange: (activityHistoryUuid: string) => void;
  onClose: () => void;
  onEdit: () => void;
  setShowVersionConflictModal: Dispatch<SetStateAction<boolean>>;
}

export const useView = (): UseActivityReturnData => {
  const navigate = useNavigate();
  const { currentUserType } = useAuth();

  const { activityHistoryUuid } = useParams<ActivityPageParams.activityHistoryUuid>();
  const [searchParams] = useSearchParams();

  const [currentStepKeys, setCurrentStepKeys] = useState<string[]>([]);
  const [currentStepName, setCurrentStepName] = useState<string | null>(null);
  const [activityCompletionPercentage, setActivityCompletionPercentage] = useState(0);
  const [activityStatus, setActivityStatus] = useState<ProjectActivitiesConstants>();
  const [activityData, setActivityData] = useState<GetActivityHistoryDetailsResponse | undefined>(undefined);
  const [activityDocuments, setActivityDocuments] = useState<UploadedDocumentEnriched[]>([]);
  const [activityDefinitionInfo, setActivityDefinitionInfo] = useState<ActivityDefinitionInfo | undefined>(undefined);
  const [steps, setSteps] = useState<StepProps[]>([]);
  const [l3Steps, setL3Steps] = useState<StepItem[] | undefined>([]);
  const [stepFields, setStepFields] = useState<JSX.Element[]>([]);
  const [stepReviews, setStepReviews] = useState<(JSX.Element | undefined)[]>([]);
  const [hasNext, setHasNext] = useState(true);
  const [hasPrevious, setHasPrevious] = useState(false);
  const [newVersionActivityHistory, setNewVersionActivityHistory] = useState<SearchActivityHistoryResponse>();
  const [showVersionConflictModal, setShowVersionConflictModal] = useState(false);
  const [draftActivityHistoryUuid, setDraftActivityHistoryUuid] = useState<string>();
  const [activityDefinition, setActivityDefinition] = useState<ActivityDefinition>({
    components: undefined,
    parent: undefined,
    steps: [],
  });
  const [originalActivityDefinition, setOriginalActivityDefinition] = useState<ActivityDefinition>({
    components: undefined,
    parent: undefined,
    steps: [],
  });
  const [projectDetails, setProjectDetails] = useState<GetProjectDetailsResponse | undefined>(undefined);
  const [groupDetails, setGroupDetails] = useState<GetGroupDetailsResponse | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [showReviewPage, setShowReviewPage] = useState(false);
  const [scrollToElement, setScrollToElement] = useState<string | undefined>(undefined);
  const [isReview, setIsReview] = useState(false);
  const [activityHistoryUuidData, setActivityHistoryUuidData] = useState<SelectData>([]);
  const [hasManageProjectActivityPermission, setHasManageProjectActivityPermission] = useState(false);

  const fetchData = useFetchActivityHistory(
    setActivityCompletionPercentage,
    setActivityStatus,
    setActivityData,
    setOriginalActivityDefinition,
    setActivityDefinitionInfo,
    setProjectDetails,
    setGroupDetails,
    activityHistoryUuid
  );

  const fetchDiscussionsCallback = useFetchDiscussions(activityHistoryUuid);

  const filterAndUpdateActivityDefinition = (
    activityData2: ActivityData,
    originalActivityDefinition2?: ActivityDefinition,
    projectDetails2?: GetProjectDetailsResponse
  ): ActivityDefinition => {
    // eslint-disable-next-line no-param-reassign
    originalActivityDefinition2 = originalActivityDefinition2 || originalActivityDefinition;
    const expressionContext = createExpressionContext(activityData2, projectDetails2 || projectDetails, groupDetails);
    const filteredActivityDefinition = filterConditionalSteps(originalActivityDefinition2, expressionContext);
    setActivityDefinition(filteredActivityDefinition);
    return filteredActivityDefinition;
  };

  const updateSteps: (
    stepKeys: string[],
    activityDefinition2: ActivityDefinition,
    activityData2: ActivityData,
    activityDocuments2: UploadedDocumentEnriched[],
    activityStatus2?: ProjectActivitiesConstants,
    projectDetails2?: GetProjectDetailsResponse,
    groupDetails2?: GetGroupDetailsResponse
  ) => void = async (
    stepKeys,
    activityDefinition2,
    activityData2,
    activityDocuments2,
    activityStatus2 = activityStatus,
    projectDetails2 = projectDetails,
    groupDetails2 = groupDetails
  ) => {
    const stepData = getStep(stepKeys, activityData2);
    const stepComponents = getActivityDefinitionFields(stepKeys, activityDefinition2);
    const expressionContext = createExpressionContext(activityData2, projectDetails2, groupDetails2);
    const dataPath = getDataPath(stepKeys);

    const discussionsUuids = await getDiscussionUuids(stepData, fetchDiscussionsCallback);

    setStepFields(
      stepComponents
        .map((c) =>
          createViewComponent(
            c,
            getDiscussionsForComponent(c.key, c.component, c.children, discussionsUuids, stepKeys),
            stepData?.data || {},
            expressionContext,
            activityDocuments2,
            dataPath
          )
        )
        .filter((c) => c !== null) as JSX.Element[]
    );

    if (activityStatus2 === ProjectActivitiesConstants.STATUS_APPROVED) {
      setStepReviews(
        createStepReview(
          false,
          { activityReviews: stepData?.activityReviews } as HasReviews,
          dataPath,
          () => undefined,
          () => undefined,
          ActivityReviewTypeConstants.REVIEW
        )
      );
    }

    setSteps(mapToStepProps(stepKeys, activityData2, currentUserType, activityDefinition2.steps));
    setL3Steps(mapToStepItem(stepKeys, activityData2, currentUserType, activityDefinition2.steps));
    setHasNext(hasNextStep(stepKeys, activityDefinition2));
    setHasPrevious(hasPreviousStep(stepKeys, activityDefinition2));
  };

  useEffect(() => {
    fetchData().then((data) => {
      if (data.projectDetails) {
        setHasManageProjectActivityPermission(
          hasActivityPermissionForProject(
            [
              {
                currentUserPermissions: data.projectDetails.currentUserPermissions,
                projectUuid: data.projectDetails.uuid,
                groupUuid: null,
              },
            ],
            data.projectDetails.uuid,
            ProjectPermissionConstants.MANAGE_PROJECT_ACTIVITY
          )
        );
      }

      if (data.groupDetails) {
        const projectUuids = data.groupDetails.projects.map((el) => el.uuid) || [];
        getCurrentUserProjectPermissions({ projectUuids }).then((res) => {
          const permissions = res?.data;

          if (permissions && permissions.length) {
            const hasEditPermission = permissions.every((p) =>
              p.currentUserPermissions.includes(ProjectPermissionConstants.MANAGE_PROJECT_ACTIVITY)
            );
            setHasManageProjectActivityPermission(hasEditPermission);
          }
        });
      }

      const filteredActivityDefinition = filterAndUpdateActivityDefinition(
        data.activityData,
        data.activityDefinition,
        data.projectDetails
      );
      setIsLoading(false);
      let stepKeys = getFirstStepKeys(filteredActivityDefinition);
      const enrichedActivityDocuments = enrichUploadedDocuments(data.activityData, data.activityDocuments);
      setActivityDocuments(enrichedActivityDocuments);

      const location = searchParams.get("location");
      if (location && activityDefinition && data.activityData) {
        const locationArr = location.split(/\./g);
        const componentRef = locationArr.splice(-1)[0];
        const componentId = `${locationArr.join(".")}-${componentRef}`;
        stepKeys = locationArr;
        setScrollToElement(componentId);
      }

      setCurrentStepKeys(stepKeys);
      updateSteps(
        stepKeys,
        filteredActivityDefinition,
        data.activityData,
        enrichedActivityDocuments,
        data.activityStatus
      );

      setShowReviewPage(true);
    });
  }, [activityHistoryUuid]);

  const moveNext = useCallback(async (): Promise<void> => {
    const nextStepKeys = getNextStepKeys(currentStepKeys, activityDefinition);
    setCurrentStepKeys(nextStepKeys);
    updateSteps(nextStepKeys, activityDefinition, activityData?.data, activityDocuments);
    window.scrollTo(0, 0);
  }, [activityDefinition, currentStepKeys]);

  const movePrevious = useCallback(async (): Promise<void> => {
    const previousStepKeys = getPreviousStepKeys(currentStepKeys, activityDefinition);
    setCurrentStepKeys(previousStepKeys);
    updateSteps(previousStepKeys, activityDefinition, activityData?.data, activityDocuments);
    window.scrollTo(0, 0);
  }, [activityDefinition, currentStepKeys]);

  const moveTo = useCallback(
    async (targetStepKeys: string[]): Promise<void> => {
      const targetStepKeysAppend = checkTargetStepkeysAppended(targetStepKeys, activityDefinition);
      setCurrentStepKeys(targetStepKeysAppend);
      updateSteps(targetStepKeysAppend, activityDefinition, activityData?.data, activityDocuments);
      setIsReview(false);
      window.scrollTo(0, 0);
    },
    [activityDefinition, currentStepKeys]
  );

  const moveToReview = useCallback(async (): Promise<void> => {
    setIsReview(true);
    setHasNext(false);
    setHasPrevious(false);
  }, [activityDefinition, currentStepKeys]);

  const moveToLastStep = useCallback((): void => {
    const lastStepKeys = getLastStepKeys(activityDefinition);
    setCurrentStepKeys(lastStepKeys);
    updateSteps(lastStepKeys, activityDefinition, activityData?.data, activityDocuments);
    setIsReview(false);
    window.scrollTo(0, 0);
  }, [activityDefinition, currentStepKeys]);

  const onActivityHistoryUuidChange = useCallback(
    (newActivityHistoryUuid: string) => {
      navigate(
        getActivityViewRoute(
          newActivityHistoryUuid,
          currentUserType,
          searchParams.get("projectUuid") ? `projectUuid=${searchParams.get("projectUuid")}` : ""
        )
      );
    },
    [navigate]
  );
  const onClose = useCallback(() => {
    navigate(
      getActivityDashboardTabRoute(
        activityData?.activity.uuid || "",
        "history",
        currentUserType,
        searchParams.get("projectUuid") ? `projectUuid=${searchParams.get("projectUuid")}` : ""
      )
    );
  }, [activityData]);

  const onEdit = async (): Promise<void> => {
    if (activityData) {
      const filterCriteria = dataGridMapFilterCriteria([]);

      filterCriteria.activity = {
        uuid: {
          operator: "eq",
          value: activityData.activity.uuid,
        },
      };

      filterCriteria.isDraft = {
        operator: "eq",
        value: true,
      };

      const res = await searchActivityHistory({
        activityUuid: null,
        paging: {
          afterCursor: null,
          beforeCursor: null,
          limit: 1,
        },

        filter: { results: filterCriteria },
      });

      if (res.status === Status.Success && res.data) {
        const existingDraft = res.data.results.length > 0 ? res.data.results[0] : undefined;

        await resumeActivity(
          navigate,
          setDraftActivityHistoryUuid,
          setNewVersionActivityHistory,
          setShowVersionConflictModal,
          activityData.activity.uuid,
          existingDraft?.uuid,
          existingDraft?.versionNumber
        );
      }
    }
  };

  const fetchActivityHistoryData = useCallback(async (activityUuid: string) => {
    await searchActivityHistory({
      activityUuid,
      paging: {
        limit: 30,
        afterCursor: null,
        beforeCursor: null,
      },
      filter: {
        results: {
          activity: { uuid: { operator: "eq", value: activityUuid } },
          isDraft: { operator: "eq", value: false },
        },
      },
      sort: [{ key: "results.versionNumber", direction: "desc" }],
    }).then((res) => {
      if (res.status === Status.Success && res.data) {
        setActivityHistoryUuidData(
          res.data.results.map((d) => ({
            key: d.uuid,
            value: d.isCurrent ? `${d.versionNumber} (Latest)` : d.versionNumber,
          }))
        );
      }
    });
  }, []);

  useEffect(() => {
    if (activityData) {
      fetchActivityHistoryData(activityData.activity.uuid);
    }
  }, [activityData]);

  useEffect(() => {
    setCurrentStepName(findCurrentStepName(steps));
  }, [steps]);

  useLayoutEffect(() => {
    if (showReviewPage && scrollToElement) {
      setTimeout(() => {
        document.getElementById(scrollToElement)?.scrollIntoView(true);
      }, 50);
    }
  }, [showReviewPage]);

  return {
    steps,
    l3Steps,
    stepFields,
    stepReviews,
    currentStepName,
    currentStepKeys,
    hasNext,
    hasPrevious,
    activityData,
    isLoading,
    isReview,
    showReviewPage,
    activityDefinition,
    activityDefinitionInfo,
    activityCompletionPercentage,
    activityStatus,
    activityHistoryUuidData,
    showVersionConflictModal,
    newVersionActivityHistory,
    draftActivityHistoryUuid,
    hasManageProjectActivityPermission,
    currentUserType,
    // handlers
    movePrevious,
    moveNext,
    moveTo,
    moveToReview,
    moveToLastStep,
    onActivityHistoryUuidChange,
    onClose,
    onEdit,
    setShowVersionConflictModal,
  };
};
