import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { getProjectDetails, getPublicGroupDetails } from "../../../../../service/publicquery";
import {
  getActivityDefinitionVersionDetails,
  GetActivityHistoryDetailsResponse,
  getActivityReviewDetails,
  GetGroupDetailsResponse,
  GetProjectDetailsResponse,
} from "../../../../../service/query";
import { Status } from "../../../../../service/Shared";
import { useAuth } from "../../../../../useAuth";
import { ERROR_ACCESS_DENIED } from "../../../../../utils";
import { getDashboardRoute } from "../../../../../utils/routes";
import {
  ActivityData,
  ActivityDefinition,
  ActivityDefinitionInfo,
  ResponseDocumentData,
} from "../../../../developer/activities/activity/types";
import { populateParentLinks } from "../../../../developer/activities/activity/utils";

export const useFetchActivityReviewHistory: (
  setActivityReviewCompletionPercentage: React.Dispatch<React.SetStateAction<number>>,
  setActivityReviewType: React.Dispatch<React.SetStateAction<string>>,
  setActivityReviewStatus: React.Dispatch<React.SetStateAction<string>>,
  setIsAssignedAuditor: React.Dispatch<React.SetStateAction<boolean>>,
  setHasFetchedData: React.Dispatch<React.SetStateAction<boolean>>,
  setActivityData: React.Dispatch<React.SetStateAction<GetActivityHistoryDetailsResponse | undefined>>,
  setActivityDefinition: React.Dispatch<React.SetStateAction<ActivityDefinition>>,
  setActivityDefinitionInfo: React.Dispatch<React.SetStateAction<ActivityDefinitionInfo | undefined>>,
  setProjectDetails: React.Dispatch<React.SetStateAction<GetProjectDetailsResponse | undefined>>,
  setGroupDetails: React.Dispatch<React.SetStateAction<GetGroupDetailsResponse | undefined>>,
  setActivityUuid: React.Dispatch<React.SetStateAction<string | undefined>>,
  setActivityHistoryUuid: React.Dispatch<React.SetStateAction<string | undefined>>,
  activityReviewUuid?: string
) => () => Promise<{
  activityData: ActivityData;
  activityDefinition: ActivityDefinition;
  projectDetails: GetProjectDetailsResponse | undefined;
  groupDetails: GetGroupDetailsResponse | undefined;
  activityDocuments: ResponseDocumentData[];
  activityUuid: string;
  activityHistoryUuid: string;
  rowVersion: number;
}> = (
  setActivityReviewCompletionPercentage,
  setActivityReviewType,
  setActivityReviewStatus,
  setIsAssignedAuditor,
  setHasFetchedData,
  setActivityData,
  setActivityDefinition,
  setActivityDefinitionInfo,
  setProjectDetails,
  setGroupDetails,
  setActivityUuid,
  setActivityHistoryUuid,
  activityReviewUuid
) => {
  const navigate = useNavigate();
  const { user, currentUserType } = useAuth();

  return useCallback(async () => {
    if (!activityReviewUuid) throw new Error("Missing 'activityReviewUuid' parameter");

    const activityReviewRes = await getActivityReviewDetails({ activityReviewUuid });

    if (activityReviewRes.status !== Status.Success && activityReviewRes.errors) {
      if (activityReviewRes.errors?.find((e) => e.message === ERROR_ACCESS_DENIED)) {
        if (window?.history?.state?.idx === 0) {
          navigate(getDashboardRoute(currentUserType), { replace: true });
        } else {
          navigate(-1);
        }

        throw new Error("Not enough permissions to view this activity review");
      }
    }

    if (activityReviewRes.data == null) {
      throw new Error(`"getActivityReviewDetails" returned no data for uuid ${activityReviewUuid}`);
    }

    const activityDefinitionRes = await getActivityDefinitionVersionDetails({
      activityDefinitionVersionUuid:
        activityReviewRes.data.activityHistoryDetailsResponse.activity.activityDefinitionVersion.uuid,
      activityHistoryUuid: activityReviewRes.data.activityHistoryDetailsResponse.uuid,
    });

    if (activityDefinitionRes.status !== Status.Success || activityDefinitionRes.data == null) {
      throw new Error(
        `"getActivityDefinitionVersionDetails" returned no data for uuid ${activityReviewRes.data.activityHistoryDetailsResponse.activity.activityDefinitionVersion.uuid}`
      );
    }

    let projectDetailsData;

    if (activityReviewRes.data.activityHistoryDetailsResponse.activity?.project?.uuid) {
      const projectDetailsRes = await getProjectDetails({
        projectUuid: activityReviewRes.data.activityHistoryDetailsResponse.activity?.project?.uuid,
      });

      if (projectDetailsRes.status === Status.Success && projectDetailsRes.data != null) {
        projectDetailsData = projectDetailsRes.data;
      } else {
        // Throw error; should result in a toast being shown & an error being logged
        throw new Error(
          `"getProjectDetails" returned no data for uuid ${activityReviewRes.data.activityHistoryDetailsResponse.activity?.project?.uuid}`
        );
      }
    }

    let groupDetailsData;

    if (activityReviewRes.data.activityHistoryDetailsResponse.activity?.group?.uuid) {
      const groupDetailsRes = await getPublicGroupDetails({
        projectGroupUuid: activityReviewRes.data.activityHistoryDetailsResponse.activity?.group.uuid,
      });

      if (groupDetailsRes.status === Status.Success && groupDetailsRes.data != null) {
        groupDetailsData = groupDetailsRes.data;
      } else {
        // Throw error; should result in a toast being shown & an error being logged
        throw new Error(
          `"getGroupDetails" returned no data for uuid ${activityReviewRes.data.activityHistoryDetailsResponse.activity?.group?.uuid}`
        );
      }
    }

    const { definition, activityDefinition, versionNumber } = activityDefinitionRes.data;

    populateParentLinks(definition);
    setActivityDefinition(definition);

    setActivityDefinitionInfo({
      displayName: activityDefinition.displayName,
      versionNumber,
    });

    setActivityReviewCompletionPercentage(activityReviewRes.data.completionPercentage || 0);
    setActivityReviewType(activityReviewRes.data.activityReviewType);
    setActivityReviewStatus(activityReviewRes.data.activityReviewStatus);
    setIsAssignedAuditor(activityReviewRes.data.assignedToUserUuids.some((uuid: string) => uuid === user?.userUuid));
    setHasFetchedData(true);
    setActivityData(activityReviewRes.data.activityHistoryDetailsResponse);
    setActivityHistoryUuid(activityReviewRes.data.activityHistoryDetailsResponse.uuid);
    setActivityUuid(activityReviewRes.data.activityHistoryDetailsResponse.activity.uuid);
    setProjectDetails(projectDetailsData);
    setGroupDetails(groupDetailsData);

    return {
      activityDefinition: definition,
      activityData: activityReviewRes.data.activityHistoryDetailsResponse.data,
      projectDetails: projectDetailsData,
      groupDetails: groupDetailsData,
      rowVersion: activityReviewRes.data.rowVersion,
      activityDocuments: activityReviewRes.data.activityHistoryDetailsResponse.documents,
      activityUuid: activityReviewRes.data.activityHistoryDetailsResponse.activity.uuid,
      activityHistoryUuid: activityReviewRes.data.activityHistoryDetailsResponse.uuid,
    };
  }, [activityReviewUuid]);
};
