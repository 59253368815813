import "./AssignReviewCellFormatter.css";

import { UserPlusBlueIcon } from "../../../../../assets";
import { DataGridCellFormatter, DataGridIconCellFormatterData } from "../../../../../widget";

export const assignReviewCellFormatter: DataGridCellFormatter<
  DataGridIconCellFormatterData | null | undefined
> = () => {
  return (value) => {
    if (value === undefined || value === null) return <div />;

    // eslint-disable-next-line react/destructuring-assignment
    const { action } = value;

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <div className="AssignReviewCellFormatter" onClick={action}>
        <UserPlusBlueIcon />
        <p className="body2">Assign</p>
      </div>
    );
  };
};
