import "./PublicProjectTemplateComponent.css";

import { Link } from "react-router-dom";

import { ArrowFullLeftDarkIcon, RocketSmallCoralIcon } from "../../../../assets";
import { ProjectStatusConstants, StandardConstants } from "../../../../constants";
import { BadgeData, FileType, TabsList } from "../../../../models";
import { GetProjectDetailsResponse } from "../../../../service/publicquery";
import {
  getPublicBadgeDetailsRoute,
  getPublicCodeDetailsRoute,
  getPublicDeveloperDetailsRoute,
} from "../../../../utils/routes";
import {
  Button,
  Card,
  Column,
  Gallery,
  IconPill,
  LocationFlag,
  Map,
  OrganisationIcon,
  Pill,
  Row,
  Tabs,
} from "../../../../widget";
import { ModalButton } from "../../../components";
import { DeveloperProject, GroupData } from "../../usePublicProjectTemplate";
import { AboutDeveloper } from "../AboutDeveloper";
import { GroupProjects } from "../GroupProjects";

interface PublicProjectTemplateComponentProps {
  project: GetProjectDetailsResponse | undefined;
  tabsList: TabsList;
  searchParams: string | undefined;
  developerIcon: string | undefined;
  projectBadges: BadgeData[];
  images: string[] | undefined;
  otherDeveloperProjects: DeveloperProject[] | undefined;
  groupData: GroupData | undefined;
  onBackToSearchResults?: () => void;
  onRegisterInterest?: () => void;
  isPreview?: boolean;
}

export const PublicProjectTemplateComponent = ({
  project,
  tabsList,
  searchParams,
  developerIcon,
  projectBadges,
  images,
  otherDeveloperProjects,
  groupData,
  onBackToSearchResults,
  onRegisterInterest,
  isPreview = false,
}: PublicProjectTemplateComponentProps): JSX.Element => {
  return (
    <div className="PublicProjectTemplateComponent">
      <Row spacingV="ml">
        <Column span={12}>
          <div className="PublicProjectHeader">
            {!isPreview && (
              <Button
                variant="secondary"
                text={searchParams ? "Back to search results" : "View all projects"}
                icon={<ArrowFullLeftDarkIcon />}
                iconPosition="left"
                onClick={onBackToSearchResults}
              />
            )}
            <Row spacingV="s" justify="between">
              <Column span={12}>
                <div className="PublicProjectHeader_Details">
                  <div>
                    <h1>{project?.displayName}</h1>
                    {project?.addressCountryCode && <LocationFlag addressCountryCode={project?.addressCountryCode} />}
                  </div>
                  {!isPreview && project?.calculatedContactable && (
                    <ModalButton overrideText="Register interest" onClick={onRegisterInterest} />
                  )}
                </div>
              </Column>
            </Row>
            <Row justify="between">
              <Column span={12}>
                <div
                  className={`PublicProjectHeader_DeveloperDetails ${
                    projectBadges.length || project?.listing?.pilotProject ? "ColumnGapSmall" : "ColumnGapMedium"
                  }`}
                >
                  {project?.developer &&
                    (isPreview ? (
                      <div className="DeveloperInfo">
                        {developerIcon && <OrganisationIcon organisationIcon={developerIcon} />}
                        <h3>{project.developer.displayName}</h3>
                      </div>
                    ) : (
                      <Link
                        to={{
                          pathname: getPublicDeveloperDetailsRoute(project.developer.uuid),
                          search: `?projectUuid=${project.uuid}`,
                        }}
                      >
                        {developerIcon && <OrganisationIcon organisationIcon={developerIcon} />}
                        <h3>{project.developer.displayName}</h3>
                      </Link>
                    ))}
                  {projectBadges.map((badge, index) => (
                    <div className="BadgeInfo" key={badge.uuid}>
                      <div className="VerticalLineLightGrey" />
                      {isPreview ? (
                        <>
                          <img
                            src={badge.files.find((f) => f.type === FileType.SmallLogo)?.file.url}
                            alt={`${badge.displayName} logo`}
                          />
                          <h3>{badge.displayName}</h3>
                        </>
                      ) : (
                        <Link
                          to={{
                            pathname: getPublicBadgeDetailsRoute(badge.uuid),
                            search: `?projectUuid=${project?.uuid}`,
                          }}
                        >
                          <img
                            src={badge.files.find((f) => f.type === FileType.SmallLogo)?.file.url}
                            alt={`${badge.displayName} logo`}
                          />
                          <h3>{badge.displayName}</h3>
                        </Link>
                      )}
                      {projectBadges.length && index === projectBadges.length - 1 && (
                        <div className="VerticalLineLightGrey" />
                      )}
                    </div>
                  ))}
                  {project?.listing?.seekingFunding ? (
                    <Pill label="This project is seeking funding" variant="dark-green" />
                  ) : (
                    project?.standard &&
                    project?.status !== ProjectStatusConstants.DRAFT &&
                    project?.status !== ProjectStatusConstants.PRE_DEVELOPMENT &&
                    (isPreview ? (
                      <Pill
                        label={project?.standard.displayName || ""}
                        variantMap={StandardConstants.CODE_PILL_VARIANT_MAP}
                      />
                    ) : (
                      <Link
                        to={{
                          pathname: getPublicCodeDetailsRoute(project.standard.uuid),
                          search: `?projectUuid=${project.uuid}`,
                        }}
                      >
                        <Pill
                          label={project?.standard.displayName || ""}
                          variantMap={StandardConstants.CODE_PILL_VARIANT_MAP}
                        />
                      </Link>
                    ))
                  )}
                  {project?.listing?.pilotProject && (
                    <IconPill label="Pilot Project" variant="coral" icon={<RocketSmallCoralIcon />} />
                  )}
                </div>
              </Column>
            </Row>
          </div>
        </Column>
      </Row>
      <Row spacingV="ll">
        <Column span={12}>
          {(images && images?.length) || (project?.listing?.content && project?.listing?.content.videoUrl) ? (
            <Gallery imageSources={images || []} videoUrl={project?.listing.content.videoUrl} />
          ) : (
            project?.locationCoordinates && (
              <Map fullWidth mode="satellite" height={544} coordinates={[project.locationCoordinates]} />
            )
          )}
        </Column>
      </Row>
      <Row>
        <Column span={9}>
          <Tabs className="TemplateTabs" tabsList={tabsList} />
        </Column>
        <Column span={3}>
          {project?.locationCoordinates && (
            <Row spacingV="ml">
              <Column span={12}>
                <Map height={320} coordinates={[project.locationCoordinates]} />
              </Column>
            </Row>
          )}
          {project?.developer && (
            <Row spacingV="ml">
              <Column span={12}>
                <Card>
                  <AboutDeveloper
                    developerUuid={project.developer.uuid}
                    developerName={project.developer.displayName}
                    isOurValuesEnabled={project.developer.listing?.content?.ourValues?.enabled}
                    developerDescription={project.developer.listing?.content?.ourValues?.content}
                    developerIcon={developerIcon}
                    projects={otherDeveloperProjects}
                    projectUuid={project.uuid}
                    isPreview={isPreview}
                  />
                </Card>
              </Column>
            </Row>
          )}
          {groupData && groupData.groupCount > 0 && (
            <Row>
              <Column span={12}>
                <Card>
                  <GroupProjects
                    groupUuid={groupData.groupUuid}
                    groupName={groupData.groupName}
                    groupCount={groupData.groupCount}
                    projects={groupData.projects}
                  />
                </Card>
              </Column>
            </Row>
          )}
        </Column>
      </Row>
    </div>
  );
};
