import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";

import { ActivityReviewTypeConstants, ProjectActivitiesConstants } from "../../../../constants";
import { SelectData } from "../../../../models";
import { createActivityReview, downloadActivityDocumentsZip, transitionActivity } from "../../../../service/activity";
import { updateProjectDetails } from "../../../../service/project";
import {
  getActivityDetails,
  getGroupDetails,
  getProjectDetails,
  GetProjectDetailsResponse,
  searchVerifiers,
  SearchVerifiersResponse,
} from "../../../../service/query";
import { ServiceError, Status } from "../../../../service/Shared";
import { getErrorMessageFromCode } from "../../../../service/ValidationErrorFormatter";
import { useIsLoadingWrapper } from "../../../../utils";
import { dataGridMapFilterCriteria, Toast } from "../../../../widget";
import { isSuccessfulCompleteActivityDraft } from "../../../shared/utils/activities/completeActivity";
import { ModalState } from "./models";

interface useSubmitActivityModalProps {
  groupUuid?: string;
  projectUuid?: string;

  activityUuid: string;
  activityStatus: string;
  activityProjectUuid?: string;
  onClose: () => void;
  activityHistoryUuid: string;
  activityHistoryRowVersion: number | undefined;
  isDraft: boolean;
  shouldRefreshActivities?: () => void;
  shouldRefreshProjectDetails?: () => void;
  exitActivityWizard?: () => void;
}
interface useSubmitActivityModalReturnData {
  onSubmitClick: (isDigital: boolean) => void;
  onContinueClick: () => void;
  onCancel: () => void;
  setCorrectVVBAndResetSelectedVVB: (value: boolean) => void;
  setSelectedVVB: Dispatch<SetStateAction<string | undefined>>;
  downloadActivityDocuments: () => Promise<void>;
  showDownloadMessage: boolean;
  selectedVVB: string | undefined;
  correctVVB: boolean | undefined;
  selectVVBData: SelectData;
  isLoading: boolean;
  modalState: ModalState;
  errors: ServiceError[];
  currentVerifierDisplayName?: string;
}

export const useSubmitActivityModal = ({
  groupUuid,
  projectUuid,

  activityUuid,
  activityStatus,
  onClose,
  activityHistoryUuid,
  activityHistoryRowVersion,
  isDraft,
  shouldRefreshActivities,
  shouldRefreshProjectDetails,
  exitActivityWizard,
}: useSubmitActivityModalProps): useSubmitActivityModalReturnData => {
  const [isLoading, setIsLoading] = useState(false);

  const [searchVerifiersResponse, setSearchVerifiersResponse] = useState<SearchVerifiersResponse>();
  const [errors, setErrors] = useState<ServiceError[]>([]);

  const [modalState, setModalState] = useState(ModalState.SUBMIT_ACTIVITY);
  const [showDownloadMessage, setShowDownloadMessage] = useState(false);
  const [correctVVB, setCorrectVVB] = useState<boolean>();
  const [selectVVBData, setSelectVVBData] = useState<SelectData>([]);
  const [selectedVVB, setSelectedVVB] = useState<string>();
  const [updateProjectUuid, setUpdateProjectUuid] = useState<string | undefined>(projectUuid);
  const [projectDetails, setProjectDetails] = useState<GetProjectDetailsResponse>();

  const onCancel = (): void => {
    setCorrectVVB(undefined);
    setSelectVVBData([]);
    setModalState(ModalState.SUBMIT_ACTIVITY);
    setShowDownloadMessage(false);
    onClose();
  };

  const setCorrectVVBAndResetSelectedVVB = (value: boolean): void => {
    setCorrectVVB(value);
    setSelectedVVB(undefined);
  };

  const onSuccessfulSubmitClose = (): void => {
    Toast.success({ message: "Activity submitted successfully" });
    // if called from dashboard refresh context
    if (shouldRefreshActivities) {
      shouldRefreshActivities();
    }

    // if called from wizard, navigate
    if (exitActivityWizard !== undefined) exitActivityWizard();

    onCancel();
  };

  const getAndSetProjectDetails = async (uuid: string): Promise<void> => {
    await getProjectDetails({ projectUuid: uuid, projectType: null, projectExternalReference: null }).then((res) => {
      if (res.status === Status.Success && res.data) {
        setProjectDetails(res.data);
      }
    });
  };

  const onContinueClick = useIsLoadingWrapper(async (): Promise<void> => {
    setErrors([]);

    if (projectDetails) {
      if (correctVVB === false && selectedVVB && updateProjectUuid) {
        const updateProjectDetailsRes = await updateProjectDetails({
          addressCountryCode: null,
          areaNetHa: null,
          locationCoordinates: null,
          locationGridReference: null,
          projectUuid: updateProjectUuid,
          validatorUuid: selectedVVB,
          displayName: projectDetails.displayName,
          rowVersion: projectDetails.rowVersion,
        });

        if (updateProjectDetailsRes.status === Status.Success && updateProjectDetailsRes.data) {
          Toast.success({ message: "Verifier updated successfully" });
          await getAndSetProjectDetails(updateProjectUuid);
          if (shouldRefreshProjectDetails) shouldRefreshProjectDetails();
          if (shouldRefreshActivities) shouldRefreshActivities();
        } else if (updateProjectDetailsRes.status === Status.Error) {
          setErrors(updateProjectDetailsRes.errors || []);
          return;
        }
      } else if (correctVVB === false && !selectedVVB) {
        setErrors([
          {
            code: "NO_VALIDATOR_SELECTED",
            message: "A VVB must be selected",
          },
        ]);
        return;
      } else if (correctVVB === undefined) {
        setErrors([
          {
            code: "NO_CORRECT_VVB_SELECTED",
            message: "Correct VVB must be selected",
          },
        ]);
        return;
      }

      const supportsDigitalVerification = searchVerifiersResponse?.results.find(
        (verifier) => verifier.uuid === (selectedVVB ?? projectDetails.validator.uuid)
      )?.supportsDigitalVerification;

      // decide next stage based on vvb data
      if (supportsDigitalVerification) {
        setModalState(ModalState.DIGITALLY_SUBMIT_ACTIVITY);
      } else {
        setModalState(ModalState.MANUALLY_SUBMIT_ACTIVITY);
      }
    }
  }, setIsLoading);

  const onSubmitClick = useIsLoadingWrapper(async (isDigital: boolean): Promise<void> => {
    // We only ever have activityHistoryRowVersion when in draft
    if (isDraft && activityHistoryRowVersion) {
      const successfullyCompletedDraft = await isSuccessfulCompleteActivityDraft(
        activityHistoryUuid,
        activityHistoryRowVersion
      );
      if (!successfullyCompletedDraft) {
        return;
      }
    }

    // `completeDraft` endpoint also updates the `activity` table, this call is needed to be get latest activity rowVersion
    const activityDetailsRes = await getActivityDetails({ activityUuid });

    if (activityDetailsRes.status === Status.Success && activityDetailsRes.data) {
      const transitionActivityRes = await transitionActivity({
        activity: {
          uuid: activityUuid,
          rowVersion: activityDetailsRes.data.rowVersion,
          toStatus: ProjectActivitiesConstants.STATUS_SUBMITTED_TO_VVB,
          fromStatus: activityStatus,
          publishExternalMessages: true,
          publishInternalMessages: false,
        },
        activityReviews: [],
      });
      // Digital submit
      if (transitionActivityRes.status === Status.Success && isDigital) {
        // Create 2nd review on activity
        const createActivityReviewRes = await createActivityReview({
          activityUuid,
          activityReviewType: ActivityReviewTypeConstants.ASSESSMENT,
          assignedToUserUuids: [],
          deadline: null,
        });

        if (createActivityReviewRes.status === Status.Success) {
          onSuccessfulSubmitClose();
        } else if (createActivityReviewRes.errors && createActivityReviewRes.errors.length > 0) {
          Toast.error({ message: getErrorMessageFromCode(createActivityReviewRes.errors[0].message) });
        }
      }
      // Manual submit
      else if (transitionActivityRes.status === Status.Success && !isDigital) {
        onSuccessfulSubmitClose();
      } else if (transitionActivityRes.errors && transitionActivityRes.errors.length > 0) {
        Toast.error({ message: getErrorMessageFromCode(transitionActivityRes.errors[0].message) });
      }
    }
  }, setIsLoading);

  const downloadActivityDocuments = async (): Promise<void> => {
    const res = await downloadActivityDocumentsZip({ activityHistoryUuid, isKey: false });

    if (res.status === Status.Error) {
      Toast.error({ message: "Could not download documents for this activity!" });
    }

    setShowDownloadMessage(true);
  };

  const checkGroupProjectsHaveConsistentVVB = useCallback(
    async (projectGroupUuid: string) => {
      await getGroupDetails({ projectGroupUuid }).then((res) => {
        if (res.status === Status.Success && res.data) {
          setUpdateProjectUuid(res.data.projects[0].uuid); // arbitrary projectUuid for updating group VVB

          if (!res.data.projects.every((p) => res.data?.projects[0].validator.uuid === p.validator.uuid)) {
            setModalState(ModalState.SUBMIT_ACTIVITY_MULTIPLE_VVBS);
            setCorrectVVB(false);
          }
        }
      });
    },
    [groupUuid]
  );

  const searchVVBsAndSetSelectData = useCallback(async () => {
    const filterCriteria = dataGridMapFilterCriteria([]);
    filterCriteria.uuid = {
      operator: "in",
      value: [
        "ffdae67a-ec72-4808-a4b9-f0ba7f318e62",
        "2f504a6e-9bc8-4bbb-a657-04ff906db903",
        "a36ff50b-ca37-422e-8841-46a882d91d49",
      ],
    };

    await searchVerifiers({
      filter: {
        results: filterCriteria,
      },
      paging: {
        limit: 10,
        beforeCursor: null,
        afterCursor: null,
      },
      sort: [],
    }).then((response) => {
      if (response.status === Status.Success && response.data?.results && response.data?.results.length > 0) {
        setSearchVerifiersResponse(response.data);

        let verifiers = response.data?.results;
        if (modalState === ModalState.SUBMIT_ACTIVITY) {
          verifiers = verifiers?.filter((x) => x.uuid !== projectDetails?.validator?.uuid);
        }
        const verifiersData =
          verifiers.map((x) => {
            return { key: x.uuid, value: x.displayName };
          }) ?? [];
        setSelectVVBData(verifiersData);
      }
    });
  }, [projectDetails]);

  useEffect(() => {
    if (groupUuid) {
      checkGroupProjectsHaveConsistentVVB(groupUuid);
    }
  }, [groupUuid]);

  useEffect(() => {
    searchVVBsAndSetSelectData();
  }, [projectDetails]);

  useEffect(() => {
    if (updateProjectUuid) {
      getAndSetProjectDetails(updateProjectUuid);
    }
  }, [updateProjectUuid]);

  return {
    modalState,
    correctVVB,
    isLoading,
    selectVVBData,
    selectedVVB,
    errors,
    showDownloadMessage,
    currentVerifierDisplayName: projectDetails?.validator?.displayName,
    setSelectedVVB,
    setCorrectVVBAndResetSelectedVVB,
    onCancel,
    onContinueClick,
    onSubmitClick,
    downloadActivityDocuments,
  };
};
