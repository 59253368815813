export enum StepState {
  NotStarted,
  InProgress,
  Complete,
  Invalid,
  Warning,
}

export type StepItem = {
  key: string;
  stepName: string;
  stepState: StepState;
};
