import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ActivityDashboardContext } from "../../../../../route/shared/activities-dashboard";
import { deleteActivity } from "../../../../../service/activity";
import { Status } from "../../../../../service/Shared";
import { getErrorMessageFromCode } from "../../../../../service/ValidationErrorFormatter";
import { useAuth } from "../../../../../useAuth";
import { useIsLoadingWrapper } from "../../../../../utils";
import { getProjectActivitiesRoute } from "../../../../../utils/routes";
import { Toast } from "../../../../general";

interface UseDeleteActivityModalReturnData {
  onDeleteClick: () => void;
  isOnDeleteClickLoading: boolean;
}

export const useDeleteActivityModal = (): UseDeleteActivityModalReturnData => {
  const { activityUuid, activityDetails } = useContext(ActivityDashboardContext);
  const { currentUserType } = useAuth();
  const navigate = useNavigate();

  const [isOnDeleteClickLoading, setOnDeleteClickLoading] = useState(false);

  const onDeleteClick = useIsLoadingWrapper(async (): Promise<void> => {
    if (activityUuid) {
      const res = await deleteActivity({ uuid: activityUuid });

      if (res.status === Status.Success) {
        if (activityDetails?.project.uuid) {
          navigate(getProjectActivitiesRoute(activityDetails?.project.uuid, currentUserType));
        }
        Toast.success({ message: "Activity deleted successfully" });
      } else {
        Toast.error({ message: getErrorMessageFromCode(res.errors ? res.errors[0].message : "ERROR_APPLICATION") });
      }
    }
  }, setOnDeleteClickLoading);

  return {
    onDeleteClick,
    isOnDeleteClickLoading,
  };
};
