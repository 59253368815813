import { Dispatch, SetStateAction, useCallback, useContext, useState } from "react";
import { SortColumn } from "react-data-grid";

import { EditFileBlueIcon } from "../../../../assets";
import { ProjectActivityReviewsConstants } from "../../../../constants";
import { CursorChangeProps, ResultType } from "../../../../models";
import { searchActivityReviews } from "../../../../service/query";
import { ResultData } from "../../../../service/Shared";
import { useAuth } from "../../../../useAuth";
import { flattenObject } from "../../../../utils";
import {
  DataGridColumnDefinition,
  DataGridColumnDefinitionWithCustomCellFormatter,
  dataGridMapFilterCriteria,
} from "../../../../widget";
import {
  AvatarCellFormatter,
  RemainingTimeCellFormatter,
} from "../../../../widget/data/DataGrid/components/CellFormatters";
import { IconCellFormatterData } from "../../../../widget/data/DataGrid/models";
import { ActivityReviewDashboardContext } from "../ActivityReviewDashboardContext";

interface UseActivityReviewReturnData {
  columns: DataGridColumnDefinition[];
  defaultSortingCriteria: SortColumn[];
  dataIsLoading: boolean;
  currentUserRole?: string;
  showAssignActivityModal: boolean;
  selectedActivityReview: unknown;
  setShowAssignActivityModal: Dispatch<SetStateAction<boolean>>;
  setRefreshActivityReviews: Dispatch<SetStateAction<boolean>>;
  refreshActivityReviews: boolean;
  onChange: ({ filtering, paging, sorting }: CursorChangeProps) => Promise<{
    resultData: ResultData[];
    paging: {
      pageSize: number;
      totalCount: number;
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  }>;
}

export const useActivityReview = (): UseActivityReviewReturnData => {
  const { activityReviewActivityUuid, refreshActivityReviews, setRefreshActivityReviews } =
    useContext(ActivityReviewDashboardContext);
  const { currentUserRole } = useAuth();
  const [dataIsLoading, setDataIsLoading] = useState(true);
  const [showAssignActivityModal, setShowAssignActivityModal] = useState(false);
  const [selectedActivityReview, setSelectedActivityReview] = useState<unknown>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: (DataGridColumnDefinition | DataGridColumnDefinitionWithCustomCellFormatter<any>)[] = [
    {
      key: "type",
      name: "Type",
      dataType: "string",
    },
    {
      name: "Days in queue",
      key: "calculatedDaysInQueue",
      dataType: "number",
      filterable: false,
      sortable: false,
      minWidth: 120,
    },
    {
      name: "Deadline",
      key: "deadline",
      dataType: "number",
      formatter: "custom",
      customCellFormatter: RemainingTimeCellFormatter,
      minWidth: 160,
      filterable: false,
      sortable: false,
    },
    {
      key: "status",
      name: "Status",
      dataType: "string",
      formatter: "projectActivityReviewsStatusPill",
      minWidth: 180,
    },
    {
      name: "Progress",
      key: "completionPercentage",
      dataType: "number",
      formatter: "progress",
      minWidth: 140,
      sortable: true,
      filterable: false,
    },
    {
      key: "avatar",
      name: "Assigned to",
      dataType: "string",
      formatter: "custom",
      alignment: "center",
      customCellFormatter: AvatarCellFormatter,
      filterable: false,
    },
    {
      key: "editReviewLink",
      name: "Edit",
      dataType: "string",
      formatter: "icon",
      alignment: "center",
      filterable: false,
      sortable: false,
    },
  ];

  const defaultSortingCriteria: SortColumn[] = [{ columnKey: "type", direction: "ASC" }];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formatData = useCallback((responseData: any | undefined): ResultData[] => {
    return (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      responseData?.results?.map((d: any) => {
        const result = flattenObject(d);
        const fullName: string = d.assignedToUser?.fullName ?? undefined;

        if (d.status !== ProjectActivityReviewsConstants.STATUS_CANCELLED)
          result.editReviewLink = {
            // action: () => open edit activity review modal,
            action: () => {
              setSelectedActivityReview(d);
              setShowAssignActivityModal(true);
            },
            icon: <EditFileBlueIcon width={20} height={20} />,
          } as IconCellFormatterData;

        return {
          ...result,
          avatar: { fullName },
          deadline: {
            days: d.calculatedDaysToDeadline,
            isComplete: d.status === ProjectActivityReviewsConstants.STATUS_COMPLETE,
          },
        };
      }) || []
    );
  }, []);

  // eslint-disable-next-line unused-imports/no-unused-vars
  const onChange = async ({ filtering, paging, sorting }: CursorChangeProps): Promise<ResultType> => {
    let data: ResultType = {
      resultData: [],
      paging: {
        pageSize: 0,
        totalCount: 0,
        startCursor: "",
        endCursor: "",
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };

    const filterCriteria = dataGridMapFilterCriteria(filtering);

    filterCriteria.activity = {
      uuid: {
        operator: "eq",
        value: activityReviewActivityUuid,
      },
    };

    filterCriteria.status = {
      operator: "neq",
      value: ProjectActivityReviewsConstants.STATUS_CANCELLED,
    };

    if (filterCriteria.activityLink) {
      filterCriteria.activity = {
        activityDefinition: { displayName: filterCriteria.activityLink },
      };
      delete filterCriteria.activityLink;
    }

    sorting.forEach((s) => {
      switch (s.key) {
        case "activityLink":
          // eslint-disable-next-line no-param-reassign
          s.key = "activity.activityDefinition.displayName";
          break;
        case "avatar":
          // eslint-disable-next-line no-param-reassign
          s.key = "primaryAssignedToUser.fullName";
          break;
        default:
          break;
      }
    });

    await searchActivityReviews({
      /* eslint-disable @typescript-eslint/no-explicit-any */
      sort: sorting.map((s: { key: any; direction: any }) => ({
        key: s.key as any,
        direction: s.direction,
      })),
      filter: { results: filterCriteria },
      paging: {
        afterCursor: null,
        beforeCursor: null,
        limit: 10,
      },
    })
      .then((response) => {
        data = {
          resultData: formatData(response.data),
          paging: {
            startCursor: response.data?.paging?.startCursor || "",
            endCursor: response.data?.paging?.endCursor || "",
            pageSize: paging.pageSize || 10,
            totalCount: response.data?.paging?.total || 0,
            hasNextPage: response.data?.paging?.hasNextPage || false,
            hasPreviousPage: response.data?.paging?.hasPreviousPage || false,
          },
        };
      })
      .finally(() => {
        setDataIsLoading(false);
      });

    return data;
  };

  return {
    columns,
    defaultSortingCriteria,
    dataIsLoading,
    currentUserRole,
    showAssignActivityModal,
    selectedActivityReview,
    setShowAssignActivityModal,
    setRefreshActivityReviews,
    refreshActivityReviews,
    onChange,
  };
};
