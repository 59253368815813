import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { ActivityReviewTypeConstants, ProjectActivityReviewsConstants } from "../../../../../constants";
import { getProjectDetails, getPublicGroupDetails } from "../../../../../service/publicquery";
import {
  getActivityDefinitionVersionDetails,
  getActivityHistoryDetails,
  GetActivityHistoryDetailsResponse,
  getActivityReviewDetails,
  GetGroupDetailsResponse,
  GetProjectDetailsResponse,
  searchActivityReviews,
} from "../../../../../service/query";
import { Status } from "../../../../../service/Shared";
import { useAuth } from "../../../../../useAuth";
import { ERROR_ACCESS_DENIED, flattenObject } from "../../../../../utils";
import { getDashboardRoute } from "../../../../../utils/routes";
import { dataGridMapFilterCriteria } from "../../../../../widget";
import {
  ActivityData,
  ActivityDefinition,
  ActivityDefinitionInfo,
  ResponseDocumentData,
} from "../../../../developer/activities/activity/types";
import { populateParentLinks } from "../../../../developer/activities/activity/utils";

export const useFetchActivityReviewViewHistory: (
  setActivityData: React.Dispatch<React.SetStateAction<GetActivityHistoryDetailsResponse | undefined>>,
  setActivityDefinition: React.Dispatch<React.SetStateAction<ActivityDefinition>>,
  setActivityDefinitionInfo: React.Dispatch<React.SetStateAction<ActivityDefinitionInfo | undefined>>,
  setProjectDetails: React.Dispatch<React.SetStateAction<GetProjectDetailsResponse | undefined>>,
  setGroupDetails: React.Dispatch<React.SetStateAction<GetGroupDetailsResponse | undefined>>,
  setActivityAssessmentUuid: React.Dispatch<React.SetStateAction<string | undefined>>,
  setActivityReviewUuid: React.Dispatch<React.SetStateAction<string | undefined>>,
  setActivityReviewCompletionPercentage: React.Dispatch<React.SetStateAction<number>>,
  setActivityReviewType: React.Dispatch<React.SetStateAction<string | undefined>>,
  setActivityReviewStatus: React.Dispatch<React.SetStateAction<string | undefined>>,
  setHasReviewStarted: React.Dispatch<React.SetStateAction<boolean>>,
  setIsAssignedAuditor: React.Dispatch<React.SetStateAction<boolean>>,
  activityHistoryUuid?: string,
  URLactivityReviewType?: string
) => () => Promise<{
  activityData: ActivityData;
  activityDefinition: ActivityDefinition;
  projectDetails: GetProjectDetailsResponse | undefined;
  groupDetails: GetGroupDetailsResponse | undefined;
  activityDocuments: ResponseDocumentData[];
  activityUuid: string;
  hasReviewStarted: boolean;
  isAssignedAuditor: boolean;
  activityReviewType?: string;
  activityAssessmentUuid?: string;
  activityReviewUuid?: string;
}> = (
  setActivityData,
  setActivityDefinition,
  setActivityDefinitionInfo,
  setProjectDetails,
  setGroupDetails,
  setActivityAssessmentUuid,
  setActivityReviewUuid,
  setActivityReviewCompletionPercentage,
  setActivityReviewType,
  setActivityReviewStatus,
  setHasReviewStarted,
  setIsAssignedAuditor,
  activityHistoryUuid,
  URLactivityReviewType
) => {
  const navigate = useNavigate();
  const { user, currentUserType } = useAuth();

  return useCallback(async () => {
    if (!activityHistoryUuid) throw new Error("Missing 'activityHistoryUuid' parameter");

    const activityHistoryRes = await getActivityHistoryDetails({ activityHistoryUuid });

    if (activityHistoryRes.status !== Status.Success && activityHistoryRes.errors) {
      if (activityHistoryRes.errors?.find((e) => e.message === ERROR_ACCESS_DENIED)) {
        if (window?.history?.state?.idx === 0) {
          navigate(getDashboardRoute(currentUserType), { replace: true });
        } else {
          navigate(-1);
        }

        throw new Error("Not enough permissions to view this activity review");
      }
    }

    if (activityHistoryRes.data == null) {
      throw new Error(`"getActivityReviewDetails" returned no data for uuid ${activityHistoryUuid}`);
    }

    const activityDefinitionRes = await getActivityDefinitionVersionDetails({
      activityDefinitionVersionUuid: activityHistoryRes.data.activity.activityDefinitionVersion.uuid,
      activityHistoryUuid,
    });

    if (activityDefinitionRes.status !== Status.Success || activityDefinitionRes.data == null) {
      throw new Error(
        `"getActivityDefinitionVersionDetails" returned no data for uuid ${activityHistoryRes.data.activity.activityDefinitionVersion.uuid}`
      );
    }

    let projectDetailsData;

    if (activityHistoryRes.data.activity?.project?.uuid) {
      const projectDetailsRes = await getProjectDetails({
        projectUuid: activityHistoryRes.data.activity?.project?.uuid,
      });

      if (projectDetailsRes.status === Status.Success && projectDetailsRes.data != null) {
        projectDetailsData = projectDetailsRes.data;
      } else {
        // Throw error; should result in a toast being shown & an error being logged
        throw new Error(
          `"getProjectDetails" returned no data for uuid ${activityHistoryRes.data.activity?.project?.uuid}`
        );
      }
    }

    let groupDetailsData;

    if (activityHistoryRes.data.activity?.group?.uuid) {
      const groupDetailsRes = await getPublicGroupDetails({
        projectGroupUuid: activityHistoryRes.data.activity?.group.uuid,
      });

      if (groupDetailsRes.status === Status.Success && groupDetailsRes.data != null) {
        groupDetailsData = groupDetailsRes.data;
      } else {
        // Throw error; should result in a toast being shown & an error being logged
        throw new Error(`"getGroupDetails" returned no data for uuid ${activityHistoryRes.data.activity?.group?.uuid}`);
      }
    }

    let activityReviewData;
    let activityAssessmentUuid;
    let activityReviewUuid;
    let isAssignedAuditor = false;
    // We have to filter by activity instead of the cachedActivityHistory as an old version won't be cached
    if (activityHistoryRes.data.activity) {
      const filterCriteria = dataGridMapFilterCriteria([]);
      filterCriteria.activity = {
        uuid: {
          operator: "eq",
          value: activityHistoryRes.data.activity.uuid,
        },
      };

      filterCriteria.status = {
        operator: "neq",
        value: ProjectActivityReviewsConstants.STATUS_CANCELLED,
      };

      const searchActivityReviewRes = await searchActivityReviews({
        filter: { results: filterCriteria },
        paging: {
          afterCursor: null,
          beforeCursor: null,
          limit: 10,
        },
      });

      if (searchActivityReviewRes.status === Status.Success && searchActivityReviewRes.data) {
        const activityAssessmentData = searchActivityReviewRes.data.results.find(
          (review) => review.type === ActivityReviewTypeConstants.ASSESSMENT
        );

        const activityReviewData2 = searchActivityReviewRes.data.results.find(
          (review) => review.type === ActivityReviewTypeConstants.REVIEW
        );

        activityAssessmentUuid = activityAssessmentData?.activityReviewUuid;
        activityReviewUuid = activityReviewData2?.activityReviewUuid;

        // If review then use review else use assessment
        activityReviewData = activityReviewData2 ?? activityAssessmentData;

        if (URLactivityReviewType === ActivityReviewTypeConstants.REVIEW && activityReviewData2?.activityReviewUuid) {
          const activityReviewRes = await getActivityReviewDetails({
            activityReviewUuid: activityReviewData2?.activityReviewUuid,
          });

          if (activityReviewRes.status === Status.Success && activityReviewRes.data?.assignedToUserUuids) {
            isAssignedAuditor = activityReviewRes.data.assignedToUserUuids.some(
              (uuid: string) => uuid === user?.userUuid
            );
          }
        } else if (
          URLactivityReviewType === ActivityReviewTypeConstants.ASSESSMENT &&
          activityAssessmentData?.activityReviewUuid
        ) {
          // If assessment in URL then auditor user and should always see auditor review
          activityReviewData = activityAssessmentData;

          const activityReviewRes = await getActivityReviewDetails({
            activityReviewUuid: activityAssessmentData?.activityReviewUuid,
          });

          if (activityReviewRes.status === Status.Success && activityReviewRes.data?.assignedToUserUuids) {
            isAssignedAuditor = activityReviewRes.data.assignedToUserUuids.some(
              (uuid: string) => uuid === user?.userUuid
            );
          }
        }
      }
    }

    const { definition, activityDefinition, versionNumber } = activityDefinitionRes.data;

    populateParentLinks(definition);
    setActivityDefinition(definition);

    setActivityDefinitionInfo({
      displayName: activityDefinition.displayName,
      versionNumber,
    });

    setActivityAssessmentUuid(activityAssessmentUuid);
    setActivityReviewUuid(activityReviewUuid);

    // Check for a review object that is a child of activityReviews
    const hasReviewStarted = JSON.stringify(flattenObject(activityHistoryRes.data.data)).includes(
      "activityReviews.review"
    );
    setHasReviewStarted(hasReviewStarted);

    setActivityReviewCompletionPercentage(activityReviewData?.completionPercentage || 0);
    setActivityReviewType(activityReviewData?.type);
    setActivityReviewStatus(activityReviewData?.status ?? "");
    setIsAssignedAuditor(isAssignedAuditor);

    setActivityData(activityHistoryRes.data);
    setProjectDetails(projectDetailsData);
    setGroupDetails(groupDetailsData);

    return {
      activityDefinition: definition,
      activityData: activityHistoryRes.data.data,
      projectDetails: projectDetailsData,
      groupDetails: groupDetailsData,
      activityReviewType: activityReviewData?.type,
      activityDocuments: activityHistoryRes.data.documents,
      activityUuid: activityHistoryRes.data.activity.uuid,
      hasReviewStarted,
      isAssignedAuditor,
      activityAssessmentUuid,
      activityReviewUuid,
    };
  }, [activityHistoryUuid]);
};
