import { useCallback, useEffect, useState } from "react";

import { SelectData } from "../../../../../models";
import { lookUp } from "../../../../../service/query";
import { Status } from "../../../../../service/Shared";
import { useAuth } from "../../../../../useAuth";
import { getUserSettingsRoute } from "../../../../../utils/routes";

interface UseSendInvitationReturnData {
  roles: SelectData | undefined;
  rolesInfo: string[];
  userSettingsRoute: string;
}

export const useSendInvitation = (): UseSendInvitationReturnData => {
  const [roles, setRoles] = useState<SelectData | undefined>(undefined);

  const { currentUserType } = useAuth();

  const rolesInfo = [
    "Manager: Can view all projects and organisation details. Managers can invite other managers or assessors & edit all organisation users. There must always be one manager.",
    "Auditor: Can only see the projects that they are assigned to work on and cannot update any organisation settings or invite new users.",
  ];

  const fetchRoles = useCallback(async () => {
    const res = await lookUp({ type: "VVB_Role" });
    if (res.status === Status.Success) {
      setRoles(
        res.data?.map((role) => {
          return {
            key: role.key,
            value: role.valueString,
          };
        })
      );
    }
  }, []);

  useEffect(() => {
    fetchRoles().then((r) => r);
  }, [fetchRoles]);

  return {
    roles,
    rolesInfo,
    userSettingsRoute: getUserSettingsRoute(currentUserType),
  };
};
