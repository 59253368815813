import "./DiscussionThread.css";

import { Dispatch, SetStateAction } from "react";

import { Divider } from "../../../../general";
import { Thread } from "../../../models";
import { DiscussionThreadBody } from "../DiscussionThreadBody";
import { NewThreadInformation } from "../NewDiscussion/useNewDiscussion";
import { useDiscussionThread } from "./useDiscussionThread";

interface DiscussionThreadProps {
  threads: Thread[];
  setThreads: Dispatch<SetStateAction<Thread[]>>;
  internalTeamSelected: boolean;
  externalSelected: boolean;
  handleInternalTeamSelect: () => void;
  handleExternalSelect: () => void;
  closeDiscussion: () => void;
  newThreadInformation: NewThreadInformation;
  isForViewMode?: boolean;
  canWriteExternal: boolean;
  externalUserType: string;
}

export const DiscussionThread = ({
  threads,
  // eslint-disable-next-line unused-imports/no-unused-vars
  setThreads,
  internalTeamSelected,
  externalSelected,
  handleInternalTeamSelect,
  handleExternalSelect,
  closeDiscussion,
  newThreadInformation,
  canWriteExternal,
  isForViewMode = false,
  externalUserType,
}: DiscussionThreadProps): JSX.Element => {
  const { firstMessagesInEachThread, replies } = useDiscussionThread({
    threads,
  });

  return (
    <>
      <div className="DiscussionThreadHeader">
        <div className="DiscussionSelectContainer">
          {canWriteExternal && (
            <div
              role="button"
              tabIndex={0}
              className={`DiscussionSelectItem ${externalSelected ? "Selected" : ""}`}
              onClick={handleExternalSelect}
              onKeyDown={(e) => (e.key === "Enter" ? handleExternalSelect() : undefined)}
            >
              <p className="DiscussionTitle body1">External Discussion</p>
            </div>
          )}
          <div
            role="button"
            tabIndex={0}
            className={`DiscussionSelectItem ${internalTeamSelected ? "Selected" : ""}`}
            onClick={handleInternalTeamSelect}
            onKeyDown={(e) => (e.key === "Enter" ? handleInternalTeamSelect() : undefined)}
          >
            <p className="DiscussionTitle body1">Internal Discussion</p>
          </div>
        </div>
      </div>
      <div>
        <Divider type="horizontal" />
      </div>
      <DiscussionThreadBody
        threads={threads}
        setThreads={setThreads}
        firstMessagesInEachThread={firstMessagesInEachThread}
        replies={replies}
        closeDiscussion={closeDiscussion}
        newThreadInformation={newThreadInformation}
        isForViewMode={isForViewMode}
        internalTeamSelected={internalTeamSelected}
        externalUserType={externalUserType}
      />
    </>
  );
};
