import "./Overview.css";

import { ActionButtons, Column, EditableBasic, ErrorBox, HorizontalLifecycle, Row } from "../../../../../widget";
import { GenericModal } from "../../../../developer/components";
import { BadgesSection, BasicDetailsSection, CertificationsSection, StandardSpecificSection } from "./components";
import { GroupSection } from "./components/GroupSection";
import { useOverview } from "./useOverview";

const Overview = (): JSX.Element => {
  const {
    projectDetails,
    currentStatus,
    errors,
    isEditable,
    isReadOnly,
    basicDetailsData,
    setBasicDetailsData,
    locationData,
    setLocationData,
    onCancel,
    onSubmit,
    validatorData,
    certificateData,
    badgeData,
    standardSpecificDefinition,
    isOnSubmitLoading,
    showVVBDropdown,
    setShowVVBDropdown,
    showUpdateGroupVVBModal,
    setShowUpdateGroupVVBModal,
  } = useOverview();

  return (
    <>
      <div className="ProjectTabSection">
        <Row spacingV="2xl">
          <Column span={12}>
            <h2>Overview</h2>
          </Column>
        </Row>
        {errors && errors.length > 0 && (
          <Row spacingV="ml">
            <Column span={4}>
              <ErrorBox
                title="Please solve the below issues before saving"
                text={errors.map((error) => error.message)}
              />
            </Column>
          </Row>
        )}
        <Row spacingV={isEditable ? "2xl" : "ll"}>
          <Column span={7}>
            <Row spacingV="ml">
              <Column span={12}>
                <h3>Lifecycle</h3>
              </Column>
            </Row>
            <HorizontalLifecycle currentStatus={currentStatus} />
          </Column>
        </Row>
        <Row>
          <Column span={12}>
            <form onSubmit={onSubmit}>
              {isEditable ? (
                <EditableBasic
                  basicDetailsData={basicDetailsData}
                  setBasicDetailsData={setBasicDetailsData}
                  locationData={locationData}
                  setLocationData={setLocationData}
                  validatorData={validatorData}
                />
              ) : (
                projectDetails && (
                  <BasicDetailsSection
                    projectDetails={projectDetails}
                    basicDetailsData={basicDetailsData}
                    setBasicDetailsData={setBasicDetailsData}
                    isReadOnly={isReadOnly}
                    validatorData={validatorData}
                    showVVBDropdown={showVVBDropdown}
                    setShowVVBDropdown={setShowVVBDropdown}
                  />
                )
              )}
              {certificateData.length > 0 && <CertificationsSection certificates={certificateData} />}
              {badgeData.length > 0 && <BadgesSection badges={badgeData} />}
              {!isReadOnly && (
                <Row className="OverviewActionButtons">
                  <Column span={2}>
                    <ActionButtons onCancel={onCancel} isLoading={isOnSubmitLoading} />
                  </Column>
                </Row>
              )}
            </form>
          </Column>
        </Row>
      </div>
      {standardSpecificDefinition && projectDetails?.standardSpecificData && (
        <div className="ProjectTabSection">
          <StandardSpecificSection standardSpecificDefinition={standardSpecificDefinition} isReadOnly={isReadOnly} />
        </div>
      )}
      {projectDetails?.group?.groupUuid && projectDetails.uuid && (
        <div className="ProjectTabSection">
          <GroupSection groupUuid={projectDetails?.group?.groupUuid} currentProjectUuid={projectDetails.uuid} />
        </div>
      )}
      <GenericModal
        show={showUpdateGroupVVBModal}
        onConfirm={() => onSubmit(undefined)}
        closeModal={() => setShowUpdateGroupVVBModal(false)}
        data={{
          title: "Update group vvb",
          text: [
            "All projects in a group must have the same VVB. Since this project is part of a group, by updating the VVB for this project all other projects in the group will be updated with the same VVB. ",
          ],
          confirmText: "Update",
          cancelText: "Cancel",
        }}
        isLoading={isOnSubmitLoading}
      />
    </>
  );
};

export default Overview;
