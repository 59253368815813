import { Dispatch, SetStateAction, useState } from "react";

interface useNameReturnData {
  showContent: boolean;
  setShowContent: Dispatch<SetStateAction<boolean>>;
}

export const useName = (): useNameReturnData => {
  const [showContent, setShowContent] = useState(false);

  return {
    showContent,
    setShowContent,
  };
};
