import { Column, CursorDataGrid, Row } from "../../../../../widget";
import { useAssessments } from "./useAssessments";

const Assessments = (): JSX.Element => {
  const { tableColumnsQueue, defaultSortingCriteria, onAssessmentQueueDataLoad, isLoading } = useAssessments();

  return (
    <div>
      <Row spacingV="ml">
        <Column span={12}>
          <h2>Assessment</h2>
        </Column>
      </Row>
      <Row>
        <Column span={12}>
          <CursorDataGrid
            columns={tableColumnsQueue}
            clientSide={false}
            filterable
            onChange={onAssessmentQueueDataLoad}
            data={[]}
            noDataMessage="No assessments to display"
            dataIsLoading={isLoading}
            defaultSortingCriteria={defaultSortingCriteria}
          />
        </Column>
      </Row>
    </div>
  );
};

export default Assessments;
