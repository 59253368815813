import { useCallback, useContext, useEffect, useState } from "react";

import { GroupConstants } from "../../../../../../../../constants";
import { SelectData } from "../../../../../../../../models";
import { addOrRemoveProjectsFromGroup } from "../../../../../../../../service/project";
import { searchProjects } from "../../../../../../../../service/query";
import { ServiceError, Status } from "../../../../../../../../service/Shared";
import { useAuth } from "../../../../../../../../useAuth";
import { useIsLoadingWrapper } from "../../../../../../../../utils";
import { Toast } from "../../../../../../../../widget";
import { GroupContext } from "../../GroupContext";

interface UseAddGroupProjectModalProps {
  closeModal: () => void;
}

interface UseAddGroupProjectModalReturnData {
  projects: SelectData;
  errors: ServiceError[];
  addProject: () => void;
  onCancel: () => void;
  fetchProjects: (projectDisplayName: string) => void;
  setProjectUuid: (projectUuid: string) => void;
  isAddProjectLoading: boolean;
}

export const useAddGroupProjectModal = ({
  closeModal,
}: UseAddGroupProjectModalProps): UseAddGroupProjectModalReturnData => {
  const { currentDeveloperUuid } = useAuth();
  const { groupDetails, refreshGroup, setRefreshGroup } = useContext(GroupContext);

  const [status, setStatus] = useState<string>();
  const [standardUuid, setStandardUuid] = useState<string>();
  const [validatorDisplayName, setValidatorDisplayName] = useState<string>();
  const [projects, setProjects] = useState<SelectData>([]);
  const [projectUuid, setProjectUuid] = useState<string>();
  const [errors, setErrors] = useState<ServiceError[]>([]);
  const [isAddProjectLoading, setIsAddProjectLoading] = useState(false);

  const fetchProjects = useCallback(
    async (projectDisplayName: string) => {
      if (currentDeveloperUuid && status && standardUuid) {
        await searchProjects({
          paging: {
            limit: 50,
            beforeCursor: null,
            afterCursor: null,
          },
          sort: [{ key: "results.displayName", direction: "asc" }],
          filter: {
            results: {
              developer: {
                uuid: {
                  operator: "eq",
                  value: currentDeveloperUuid,
                },
              },
              standard: {
                uuid: {
                  operator: "eq",
                  value: standardUuid,
                },
              },
              displayName: {
                operator: "contains",
                value: projectDisplayName,
              },
              status: {
                operator: "eq",
                value: status,
              },
              validator: validatorDisplayName
                ? {
                    displayName: {
                      operator: "eq",
                      value: validatorDisplayName,
                    },
                  }
                : {
                    displayName: undefined,
                  },
            },
          },
        }).then((response) => {
          const notGroupProjects = response.data?.results?.filter((project) => project.group === null);

          const data = validatorDisplayName
            ? notGroupProjects?.map((project) => ({
                key: project.uuid,
                value: project.displayName,
              }))
            : notGroupProjects
                ?.filter((project) => project.validator.displayName === null)
                .map((project) => ({
                  key: project.uuid,
                  value: project.displayName,
                }));

          setProjects(data || []);
        });
      }
    },
    [status]
  );

  const onCancel = (): void => {
    setErrors([]);
    setProjectUuid(undefined);
    fetchProjects("");
    closeModal();
  };

  const addProject = useIsLoadingWrapper(async (): Promise<void> => {
    if (!projectUuid) {
      setErrors([
        {
          code: "NO_PROJECT",
          message: "A project must be selected",
        },
      ]);
    } else if (!groupDetails?.groupGuid) {
      setErrors([
        {
          code: "NO_GROUP",
          message: "No group provided",
        },
      ]);
    } else {
      await addOrRemoveProjectsFromGroup({
        projectUuid,
        groupUuid: groupDetails?.groupGuid,
        operation: GroupConstants.ADD_PROJECT_OPERATION,
      }).then((response) => {
        if (response.status === Status.Success) {
          Toast.success({ message: "Project added to group successfully" });
          setRefreshGroup(!refreshGroup);

          onCancel();
        }
        if (response.status === Status.Error && response.errors) {
          setErrors(response.errors);
        }
      });
    }
  }, setIsAddProjectLoading);

  useEffect(() => {
    // Validator & status must be the same between all projects in a group
    setStatus(groupDetails?.projects[0].status);
    setStandardUuid(groupDetails?.projects[0].standard.uuid);
    setValidatorDisplayName(groupDetails?.projects[0].validator?.displayName);
    fetchProjects("");
  }, [refreshGroup, status]);

  return {
    projects,
    errors,
    addProject,
    onCancel,
    fetchProjects,
    setProjectUuid,
    isAddProjectLoading,
  };
};
