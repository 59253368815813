import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { SortColumn } from "react-data-grid";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { ActivityReviewTypeConstants, ProjectActivityReviewsConstants } from "../../../../../../constants";
import { ActivityReviewPageParams, CursorChangeProps, ResultType } from "../../../../../../models";
import {
  getActivityDefinitionVersionDetails,
  GetActivityDefinitionVersionDetailsResponse,
  GetActivityHistoryDetailsResponse,
  getActivityReviewDetails,
  searchActivityDocuments,
  SearchActivityDocumentsResponse,
  searchActivityReviews,
  SearchActivityReviewsResponse,
} from "../../../../../../service/query";
import { ResultData, Status } from "../../../../../../service/Shared";
import { flattenObject } from "../../../../../../utils";
import { getActivityReviewDashboardTabRoute } from "../../../../../../utils/routes";
import {
  DataGridButtonLinkCellFormatterData,
  DataGridColumnDefinition,
  DataGridColumnDefinitionWithCustomCellFormatter,
  dataGridMapFilterCriteria,
  Toast,
} from "../../../../../../widget";
import { downloadDocumentCellFormatter } from "../../../../../developer/activities";
import { ActivityDefinition } from "../../../../../developer/activities/activity/types";
import {
  formatActivityDocumentHistoryDownloadLink,
  getStepKeysFromDocument,
} from "../../../../../developer/activities/activity/utils";

interface useReviewContentReturnData {
  activityData?: GetActivityHistoryDetailsResponse;
  columns: DataGridColumnDefinition[];
  defaultSortingCriteria: SortColumn[];
  dataIsLoading: boolean;
  onChange: ({ filtering, paging, sorting }: CursorChangeProps) => Promise<{
    resultData: ResultData[];
    paging: {
      pageSize: number;
      totalCount: number;
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  }>;
  definitionData?: GetActivityDefinitionVersionDetailsResponse;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  activityReview?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  activityAssessment?: any;
  showDownloadActivityFilesModal: boolean;
  setShowDownloadActivityFilesModal: Dispatch<SetStateAction<boolean>>;
  showReturnToDeveloperModal: boolean;
  setShowReturnToDeveloperModal: Dispatch<SetStateAction<boolean>>;
  showCompleteAssessmentModal: boolean;
  setShowCompleteAssessmentModal: Dispatch<SetStateAction<boolean>>;
  showCompleteReviewModal: boolean;
  setShowCompleteReviewModal: Dispatch<SetStateAction<boolean>>;
  showReturnToAssessorModal: boolean;
  setShowReturnToAssessorModal: Dispatch<SetStateAction<boolean>>;
  showSubmitToReviewerModal: boolean;
  setShowSubmitToReviewerModal: Dispatch<SetStateAction<boolean>>;

  exitActivityReviewWizard: () => void;
  // submitActivity: () => Promise<void>;
  // completeActivity: () => Promise<void>;
}

export const useReviewContent = (
  activityDefinition: ActivityDefinition,
  handleMoveToStep: (targetStepKeys: string[]) => void
): useReviewContentReturnData => {
  const navigation = useNavigate();

  const [searchParams] = useSearchParams();

  const { activityReviewUuid } = useParams<ActivityReviewPageParams.activityReviewUuid>();
  const [activityHistoryUuid, setActivtyHistoryUuid] = useState<string>();

  const [dataIsLoading, setDataIsLoading] = useState(true);
  const [activityData, setActivityData] = useState<GetActivityHistoryDetailsResponse>();
  const [activityReviews, setActivityReviews] = useState<SearchActivityReviewsResponse>();
  const [definitionData, setDefinitionData] = useState<GetActivityDefinitionVersionDetailsResponse>();

  const [showDownloadActivityFilesModal, setShowDownloadActivityFilesModal] = useState(false);
  const [showReturnToDeveloperModal, setShowReturnToDeveloperModal] = useState(false);
  const [showCompleteAssessmentModal, setShowCompleteAssessmentModal] = useState(false);
  const [showCompleteReviewModal, setShowCompleteReviewModal] = useState(false);
  const [showReturnToAssessorModal, setShowReturnToAssessorModal] = useState(false);
  const [showSubmitToReviewerModal, setShowSubmitToReviewerModal] = useState(false);

  const exitActivityReviewWizard = (): void =>
    navigation(
      getActivityReviewDashboardTabRoute(
        activityData?.activity.uuid || "",
        "history",
        searchParams.get("type") ? `type=${searchParams.get("type")}` : ""
      )
    );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: (DataGridColumnDefinition | DataGridColumnDefinitionWithCustomCellFormatter<any>)[] = [
    {
      key: "activityDefinitionDocumentType.displayName",
      name: "Type",
      dataType: "string",
      filterable: true,
      sortable: true,
      minWidth: 200,
    },
    {
      key: "file.filename",
      name: "Filename",
      dataType: "string",
      formatter: "stringWithEllipsis",
      filterable: true,
      sortable: true,
      minWidth: 150,
    },
    {
      key: "activityDocument.variant",
      name: "Variant",
      dataType: "string",
      filterable: true,
      sortable: true,
      minWidth: 150,
    },
    {
      key: "activityDefinitionVersionDocumentType.isKey",
      name: "Key doc",
      dataType: "boolean",
      formatter: "yesNo",
      alignment: "center",
      // TODO: Add filtering support for booleans
      filterable: false,
      sortable: true,
    },
    {
      key: "activityDocument.versionNumber",
      name: "Version",
      dataType: "number",
      formatter: "align",
      alignment: "center",
      filterable: true,
      sortable: true,
    },
    {
      key: "activityDocument.createdAt",
      name: "Date uploaded",
      dataType: "Date",
      formatter: "dateOnly",
      alignment: "center",
      filterable: false,
      sortable: true,
      minWidth: 150,
    },
    {
      key: "findLink",
      name: "",
      dataType: "string",
      formatter: "buttonLink",
      alignment: "center",
      filterable: false,
      sortable: false,
    },
    {
      key: "downloadIcon",
      name: "Download",
      dataType: "string",
      formatter: "custom",
      alignment: "center",
      filterable: false,
      sortable: false,
      customCellFormatter: downloadDocumentCellFormatter,
    },
  ];

  const defaultSortingCriteria: SortColumn[] = [
    { columnKey: "activityDefinitionDocumentType.displayName", direction: "ASC" },
  ];

  const formatData = useCallback(
    (responseData: SearchActivityDocumentsResponse | undefined): ResultData[] =>
      responseData?.results?.map((el) => {
        const result = flattenObject(el);
        result.findLink = {
          action: () => {
            const documentStepKeys = getStepKeysFromDocument(
              activityDefinition,
              el.activityDefinitionVersionDocumentType.uuid
            );
            if (documentStepKeys.length > 0) {
              handleMoveToStep(documentStepKeys);
            } else {
              Toast.error({ message: "Could not find document" });
            }
          },
          text: "Find",
        } as DataGridButtonLinkCellFormatterData;

        result.downloadIcon = formatActivityDocumentHistoryDownloadLink({
          activityDocumentHistoryUuid: el.activityDocument.uuid,
        });

        return result;
      }) || [],
    []
  );

  const onChange = async ({ filtering, paging, sorting }: CursorChangeProps): Promise<ResultType> => {
    if (!activityReviewUuid) throw new Error("Uuid must not be empty!");

    const filterCriteria = dataGridMapFilterCriteria(filtering);

    filterCriteria.activityDefinitionDocumentType = {
      ...filterCriteria.activityDefinitionDocumentType,
      isGenerated: {
        operator: "eq",
        value: false,
      },
    };

    let data: ResultType = {
      resultData: [],
      paging: {
        pageSize: 0,
        totalCount: 0,
        startCursor: "",
        endCursor: "",
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };

    if (activityHistoryUuid !== undefined) {
      await searchActivityDocuments({
        paging: {
          limit: paging.pageSize,
          beforeCursor: paging.beforeCursor || null,
          afterCursor: paging.afterCursor || null,
        },
        /* eslint-disable @typescript-eslint/no-explicit-any */
        sort: sorting.map((s: { key: any; direction: any }) => ({
          key: s.key as any,
          direction: s.direction,
        })),
        filter: { results: filterCriteria },
        currentOnly: true,
        activityDocumentHistoryUuid: null,
        activityHistoryUuid,
        activityUuid: null,
      })
        .then((response) => {
          data = {
            resultData: formatData(response.data),
            paging: {
              startCursor: response.data?.paging?.startCursor || "",
              endCursor: response.data?.paging?.endCursor || "",
              pageSize: paging.pageSize || 10,
              totalCount: response.data?.paging?.total || 0,
              hasNextPage: response.data?.paging?.hasNextPage || false,
              hasPreviousPage: response.data?.paging?.hasPreviousPage || false,
            },
          };
        })
        .finally(() => {
          setDataIsLoading(false);
        });
    }

    return data;
  };

  const getAndSetData = async (uuid: string): Promise<void> => {
    const reviewDetailsRes = await getActivityReviewDetails({ activityReviewUuid: uuid });

    if (reviewDetailsRes.status === Status.Success && reviewDetailsRes.data) {
      setActivityData(reviewDetailsRes.data.activityHistoryDetailsResponse);
      setActivtyHistoryUuid(reviewDetailsRes.data.activityHistoryDetailsResponse.uuid);

      const filterCriteria = dataGridMapFilterCriteria([]);
      filterCriteria.activity = {
        uuid: { operator: "eq", value: reviewDetailsRes.data.activityHistoryDetailsResponse.activity.uuid },
      };

      filterCriteria.status = {
        operator: "neq",
        value: ProjectActivityReviewsConstants.STATUS_CANCELLED,
      };

      const activityReviewsRes = await searchActivityReviews({
        filter: { results: filterCriteria },
        paging: {
          afterCursor: null,
          beforeCursor: null,
          limit: 10,
        },
      });

      if (activityReviewsRes.status === Status.Success && activityReviewsRes.data) {
        setActivityReviews(activityReviewsRes.data);
      }

      const definitionVersionDetailsRes = await getActivityDefinitionVersionDetails({
        activityDefinitionVersionUuid:
          reviewDetailsRes.data.activityHistoryDetailsResponse.activity.activityDefinitionVersion.uuid,
        activityHistoryUuid: reviewDetailsRes.data.activityHistoryDetailsResponse.uuid,
      });

      if (definitionVersionDetailsRes.status === Status.Success && definitionVersionDetailsRes.data) {
        setDefinitionData(definitionVersionDetailsRes.data);
      }
    }
  };

  useEffect(() => {
    if (activityReviewUuid) {
      getAndSetData(activityReviewUuid);
    }
    return () => {
      setActivityData(undefined);
    };
  }, [activityReviewUuid]);

  return {
    activityData,
    activityReview: activityReviews?.results.find((review) => review.type === ActivityReviewTypeConstants.REVIEW),
    activityAssessment: activityReviews?.results.find(
      (review) => review.type === ActivityReviewTypeConstants.ASSESSMENT
    ),
    columns,
    defaultSortingCriteria,
    dataIsLoading,
    onChange,
    definitionData,

    showDownloadActivityFilesModal,
    setShowDownloadActivityFilesModal,
    showReturnToDeveloperModal,
    setShowReturnToDeveloperModal,
    showCompleteAssessmentModal,
    setShowCompleteAssessmentModal,
    showCompleteReviewModal,
    setShowCompleteReviewModal,
    showReturnToAssessorModal,
    setShowReturnToAssessorModal,
    showSubmitToReviewerModal,
    setShowSubmitToReviewerModal,

    exitActivityReviewWizard,
  };
};
