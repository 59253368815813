import "../styles/CellFormatters.css";

import { MouseEvent } from "react";
import { NumericFormat } from "react-number-format";
import { Link } from "react-router-dom";

import {
  ColorConstants,
  ProjectAccessConstants,
  ProjectActivitiesConstants,
  ProjectActivityReviewsConstants,
  ProjectStatusConstants,
} from "../../../../constants";
import { DataGridColumnCurrency } from "../../../../models";
import { logError } from "../../../../service/error";
import {
  Avatar,
  Button,
  DataGridButtonCellFormatterData,
  DataGridButtonLinkCellFormatterData,
  DataGridIconCellFormatterData,
  DataGridInputCellFormatterData,
  DataGridLinkCellFormatterData,
  DataGridSelectCellFormatterData,
  Pill,
  RemainingTime,
  Select,
  TextInput,
} from "../../..";
import {
  AvatarCellFormatterData,
  CellFormatter,
  ColumnDefinition,
  RemainingTimeCellFormatterData,
  TextWithIconFormatterData,
} from "../models";

export type CellFormatterNames =
  | "stringWithEllipsis"
  | "number"
  | "percentage"
  | "progress"
  | "currency"
  | "dateOnly"
  | "timestamp"
  | "yesNo"
  | "projectStatusPill"
  | "projectAccessStatusPill"
  | "projectActivitiesStatusPill"
  | "projectActivityReviewsStatusPill"
  | "userStatusPill"
  | "link"
  | "button"
  | "buttonLink"
  | "icon"
  | "input"
  | "select"
  | "textWithIcon"
  | "textWithHiddenIcon"
  | "custom"
  | "align";

const StringWithEllipsisCellFormatter: CellFormatter<string> = (columnDefinition: ColumnDefinition) => {
  return (value: string) => (
    <div className={`DataGridStringWithEllipsis ${columnDefinition.alignment || ""}`} title={value}>
      {value}
    </div>
  );
};

const NumberCellFormatter: CellFormatter<number> = (columnDefinition: ColumnDefinition) => {
  return (value: number) => (
    <div className={`DataGridNumber ${columnDefinition.alignment || ""}`}>
      <NumericFormat value={value} displayType="text" thousandSeparator />
    </div>
  );
};

const PercentageCellFormatter: CellFormatter<number> = (columnDefinition: ColumnDefinition) => {
  return (value) => (
    <div className={`DataGridNumber ${columnDefinition.alignment || ""}`}>
      <NumericFormat value={value} displayType="text" thousandSeparator suffix="%" />
    </div>
  );
};

const ProgressFormatter: CellFormatter<number> = (columnDefinition: ColumnDefinition) => {
  return (value) => {
    // converting received value to 0 if undefined
    const formattedValue = value ?? 0;
    return (
      <div
        className={`DataGridNumber ${columnDefinition.alignment || ""}`}
        style={{ display: "flex", alignItems: "center" }}
      >
        <div className="HorizontalStatusBar" style={{ display: "flex", width: "50%", height: "var(--spacing-s)" }}>
          <div
            className="HorizontalStatusBarFilled"
            style={{ flex: formattedValue, backgroundColor: ColorConstants.GREEN }}
          />
          <div
            className="HorizontalStatusBarUnfilled"
            style={{ flex: 1 - formattedValue, backgroundColor: ColorConstants.GREEN_20 }}
          />
        </div>
        <NumericFormat
          value={Math.round(formattedValue * 100)}
          displayType="text"
          suffix="%"
          style={{ paddingLeft: "var(--spacing-m)" }}
        />
      </div>
    );
  };
};

const CurrencyCellFormatter: CellFormatter<number> = (columnDefinition: ColumnDefinition) => {
  switch (columnDefinition.currency) {
    case DataGridColumnCurrency.Usd:
      return (value) => (
        <div className={`DataGridNumber ${columnDefinition.alignment || ""}`}>
          <NumericFormat
            value={value}
            displayType="text"
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale
            prefix="$"
          />
        </div>
      );
    case DataGridColumnCurrency.Gbp:
      return (value) => (
        <div className={`DataGridNumber ${columnDefinition.alignment || ""}`}>
          <NumericFormat
            value={value}
            displayType="text"
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale
            prefix="£"
          />
        </div>
      );
    case undefined:
    default:
      logError({ error: `Couldn't find column currency: ${columnDefinition.currency}` });
      return (value) => (
        <div className={`DataGridNumber ${columnDefinition.alignment || ""}`}>
          <NumericFormat
            value={value}
            displayType="text"
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale
            prefix="£"
          />
        </div>
      );
  }
};

const DateOnlyCellFormatter: CellFormatter<Date> = (columnDefinition: ColumnDefinition) => {
  return (value) =>
    (value && (
      <div className={`DataGridDateOnly ${columnDefinition.alignment || ""}`}>
        {/* NCU: required as destructuring assignment here results in a compilation error */}
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {value.getDate().toString().padStart(2, "0")}/{/* eslint-disable-next-line react/destructuring-assignment */}
        {(value.getMonth() + 1).toString().padStart(2, "0")}/
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {value.getFullYear()}{" "}
      </div>
    )) ||
    "";
};

const TimestampCellFormatter: CellFormatter<Date> = (columnDefinition: ColumnDefinition) => {
  return (value) =>
    (value && (
      <div className={`DataGridDateOnly ${columnDefinition.alignment || ""}`}>
        {/* NCU: required as destructuring assignment here results in a compilation error */}
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {value.getDate().toString().padStart(2, "0")}/{/* eslint-disable-next-line react/destructuring-assignment */}
        {(value.getMonth() + 1).toString().padStart(2, "0")}/
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {value.getFullYear()} {/* eslint-disable-next-line react/destructuring-assignment */}
        {value.getHours().toString().padStart(2, "0")}:{/* eslint-disable-next-line react/destructuring-assignment */}
        {value.getMinutes().toString().padStart(2, "0")}:{/* eslint-disable-next-line react/destructuring-assignment */}
        {value.getSeconds().toString().padStart(2, "0")}
      </div>
    )) ||
    "";
};

export const RemainingTimeCellFormatter: CellFormatter<RemainingTimeCellFormatterData | undefined | null> = () => {
  return (value) => {
    if (value === undefined || value === null) return <p>-</p>;

    const { days, isComplete } = value;
    return days != null ? <RemainingTime time={days} isComplete={isComplete} /> : <p>-</p>;
  };
};

const BooleanYesNoCellFormatter: CellFormatter<boolean> = (columnDefinition: ColumnDefinition) => {
  return (value) =>
    value !== undefined ? (
      <div className={`DataGridYesNo ${columnDefinition.alignment || ""}`}>{value ? "Yes" : "No"}</div>
    ) : (
      <div />
    );
};

const ProjectStatusPillCellFormatter: CellFormatter<string | null | undefined> = () => {
  return (value) =>
    value === undefined || value === null ? (
      <div />
    ) : (
      <Pill label={value} variantMap={ProjectStatusConstants.PROJECT_STATUS_PILL_VARIANT_MAP} />
    );
};

const ProjectAccessStatusPillCellFormatter: CellFormatter<string | null | undefined> = () => {
  return (value) =>
    value === undefined || value === null ? (
      <div />
    ) : (
      <Pill label={value} variantMap={ProjectAccessConstants.PROJECT_ACCESS_STATUS_PILL_VARIANT_MAP} />
    );
};

const ProjectActivitiesStatusPillCellFormatter: CellFormatter<string | null | undefined> = () => {
  return (value) =>
    value === undefined || value === null ? (
      <div />
    ) : (
      <Pill label={value} variantMap={ProjectActivitiesConstants.PROJECT_ACTIVITIES_STATUS_PILL_VARIANT_MAP} />
    );
};

const ProjectActivityReviewsStatusPillCellFormatter: CellFormatter<string | null | undefined> = () => {
  return (value) =>
    value === undefined || value === null ? (
      <div />
    ) : (
      <Pill
        label={value}
        variantMap={ProjectActivityReviewsConstants.PROJECT_ACTIVITY_REVIEWS_STATUS_PILL_VARIANT_MAP}
      />
    );
};

const UserStatusPillCellFormatter: CellFormatter<string | null | undefined> = () => {
  return (value) =>
    value === undefined || value === null ? (
      <div />
    ) : (
      <Pill
        label={value}
        variantMap={{
          Active: "green",
          Disabled: "red",
        }}
      />
    );
};

const LinkCellFormatter: CellFormatter<DataGridLinkCellFormatterData | null | undefined> = (
  columnDefinition: ColumnDefinition
) => {
  return (value) => {
    if (value === undefined || value === null) return <div />;

    // eslint-disable-next-line react/destructuring-assignment
    const { to, text, target, title, state } = value;

    const stopPropagation = (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>): void => {
      e.stopPropagation();
    };
    return (
      <div className={`DataGridLink ${columnDefinition.alignment || ""}`}>
        <span className="body2">
          {to ? (
            <Link to={to} target={target ?? "_self"} title={title ?? ""} onClick={stopPropagation} state={state}>
              {text}
            </Link>
          ) : (
            <span>{text}</span>
          )}
        </span>
      </div>
    );
  };
};

const ButtonLinkCellFormatter: CellFormatter<DataGridButtonLinkCellFormatterData | null | undefined> = (
  columnDefinition: ColumnDefinition
) => {
  return (value) => {
    if (value === undefined || value === null) return <div />;

    // eslint-disable-next-line react/destructuring-assignment
    const { action, text } = value;

    return (
      <div className={`DataGridLink ${columnDefinition.alignment || ""}`}>
        <span className="body2">
          {action ? (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <Link
              to="#"
              onClick={async () => {
                // todo investigate why we need timeout to navigate
                await new Promise((r) => {
                  setTimeout(r, 50);
                });
                action();
              }}
            >
              {text}
            </Link>
          ) : (
            <span>{text}</span>
          )}
        </span>
      </div>
    );
  };
};

const IconCellFormatter: CellFormatter<DataGridIconCellFormatterData | null | undefined> = (
  columnDefinition: ColumnDefinition
) => {
  return (value) => {
    if (value === undefined || value === null) return <div />;

    const { action, icon } = value;

    return (
      <div
        role="button"
        className={`DataGridIconWrapper ${columnDefinition.alignment || ""}`}
        onClick={action}
        onKeyDown={(e) => {
          switch (e.key) {
            case "Enter":
              e.preventDefault();
              action();
              break;
            default:
              break;
          }
        }}
        tabIndex={0}
      >
        <div className="DataGridIcon">{icon}</div>
      </div>
    );
  };
};

const ButtonCellFormatter: CellFormatter<DataGridButtonCellFormatterData | null | undefined> = (
  columnDefinition: ColumnDefinition
) => {
  return (value) => {
    if (value === undefined || value === null) return <div />;

    return (
      <div className={`DataGridButton ${columnDefinition.alignment || ""}`}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Button {...value} />
      </div>
    );
  };
};

const InputCellFormatter: CellFormatter<DataGridInputCellFormatterData> = (columnDefinition: ColumnDefinition) => {
  return (data) => {
    if (data === undefined || data === null) return <div />;

    return (
      <div className={`DataGridInput ${columnDefinition.alignment || ""}`}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <TextInput {...data} />
      </div>
    );
  };
};

const SelectCellFormatter: CellFormatter<DataGridSelectCellFormatterData | null | undefined> = (
  columnDefinition: ColumnDefinition
) => {
  return (data) => {
    if (data === undefined || data === null) return <div />;

    return (
      <div className={`DataGridSelect ${columnDefinition.alignment || ""}`}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Select {...data} />
      </div>
    );
  };
};

const AlignmentCellFormatter: CellFormatter<string> = (columnDefinition: ColumnDefinition) => {
  return (data) => <div className={`${columnDefinition.alignment || ""}`}>{data}</div>;
};

export const AvatarCellFormatter: CellFormatter<AvatarCellFormatterData | null | undefined> = () => {
  return (value) => {
    if (value === undefined || value === null) return <div />;

    const { url, fullName } = value;
    return fullName ? (
      <div className="AvatarCell">
        <Avatar userUuid="" imageSource={url} name={fullName} />
      </div>
    ) : (
      <div />
    );
  };
};

export const TextWithIconCellFormatter: CellFormatter<TextWithIconFormatterData> = () => {
  return (value) => {
    if (value === undefined || value === null) return <div />;

    const { text, icon } = value;

    return (
      <div className="TextWithIconCell">
        <div>{icon}</div>
        <p>{text}</p>
      </div>
    );
  };
};

export const TextWithHiddenIconCellFormatter: CellFormatter<TextWithIconFormatterData> = () => {
  return (value) => {
    if (value === undefined || value === null) return <div />;

    const { text } = value;

    return (
      <div className="TextWithoutIconCell">
        <p>{text}</p>
      </div>
    );
  };
};

export const cellFormatters = {
  stringWithEllipsis: StringWithEllipsisCellFormatter,
  number: NumberCellFormatter,
  percentage: PercentageCellFormatter,
  progress: ProgressFormatter,
  currency: CurrencyCellFormatter,
  dateOnly: DateOnlyCellFormatter,
  timestamp: TimestampCellFormatter,
  yesNo: BooleanYesNoCellFormatter,
  projectStatusPill: ProjectStatusPillCellFormatter,
  projectAccessStatusPill: ProjectAccessStatusPillCellFormatter,
  projectActivitiesStatusPill: ProjectActivitiesStatusPillCellFormatter,
  projectActivityReviewsStatusPill: ProjectActivityReviewsStatusPillCellFormatter,
  userStatusPill: UserStatusPillCellFormatter,
  link: LinkCellFormatter,
  button: ButtonCellFormatter,
  buttonLink: ButtonLinkCellFormatter,
  icon: IconCellFormatter,
  input: InputCellFormatter,
  select: SelectCellFormatter,
  align: AlignmentCellFormatter,
  textWithIcon: TextWithIconCellFormatter,
  textWithHiddenIcon: TextWithHiddenIconCellFormatter,
  custom: undefined,
};
