import { LockDarkIcon } from "../../../../../assets";
import { ProjectActivitiesConstants } from "../../../../../constants";

export const lockActivityIcon = (activityStatus: string): JSX.Element | undefined => {
  switch (activityStatus) {
    case ProjectActivitiesConstants.STATUS_IN_PROGRESS:
    case ProjectActivitiesConstants.STATUS_CHANGES_REQUIRED:
    case ProjectActivitiesConstants.STATUS_REVIEW_CANCELLED:
      return undefined;
    default:
      return <LockDarkIcon />;
  }
};
