import { jsonReviver, recursivelyValidateAndTransformDateToString } from "../../utils";
import { axios, dateTransformer } from "../axios";
import { BaseResponse, BaseResponseWithSingle, Status } from "../Shared";
import {
  CompleteDraftRequest,
  CompleteDraftResponse,
  CreateActivityRequest,
  CreateActivityResponse,
  CreateActivityReviewRequest,
  CreateActivityReviewResponse,
  CreateDraftRequest,
  CreateDraftResponse,
  DeleteActivityRequest,
  DeleteActivityReviewRequest,
  DownloadActivityDocumentRequest,
  DownloadActivityDocumentsZipRequest,
  DownloadStoredZipRequest,
  TransitionActivityRequest,
  UpdateActivityReviewRequest,
  UpdateActivityReviewResponse,
  UploadActivityDocumentRequest,
  UploadActivityDocumentResponse,
  ValidateAndSaveDraftRequest,
  ValidateAndSaveDraftResponse,
  WriteActivityReviewRequest,
  WriteActivityReviewResponse,
} from "./Types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const pocState: any = {};

export const validateAndSaveDraft = async (
  // eslint-disable-next-line unused-imports/no-unused-vars
  req: ValidateAndSaveDraftRequest
): Promise<BaseResponseWithSingle<ValidateAndSaveDraftResponse>> => {
  const transformedData = recursivelyValidateAndTransformDateToString(req.data);

  if (transformedData.errors.length) {
    return {
      status: Status.Error,
      errors: transformedData.errors || [],
    };
  }

  req.data = JSON.stringify(transformedData.data);

  return axios
    .request<BaseResponseWithSingle<ValidateAndSaveDraftResponse>>({
      method: "put",
      url: "activity/history/draft",
      data: req,
    })
    .then((response) => {
      let resData;
      if (response.data.data) {
        resData = {
          ...response.data.data,
          data: JSON.parse(response.data.data.data, jsonReviver),
        };
      }

      return {
        status: Status.Success,
        data: resData,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const createActivity = async (
  req: CreateActivityRequest
): Promise<BaseResponseWithSingle<CreateActivityResponse>> => {
  return axios
    .request<BaseResponseWithSingle<CreateActivityResponse>>({
      method: "post",
      url: "activity",
      data: req,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const deleteActivity = async (req: DeleteActivityRequest): Promise<BaseResponse> => {
  return axios
    .request<BaseResponse>({
      method: "delete",
      url: "activity",
      data: req,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const createDraft = async (req: CreateDraftRequest): Promise<BaseResponseWithSingle<CreateDraftResponse>> => {
  return axios
    .request<BaseResponseWithSingle<CreateDraftResponse>>({
      method: "post",
      url: "activity/history/draft",
      data: req,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const transitionActivity = async (reg: TransitionActivityRequest): Promise<BaseResponse> => {
  return axios
    .request<BaseResponse>({
      method: "post",
      url: "activity/status",
      data: reg,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const createActivityReview = async (
  req: CreateActivityReviewRequest
): Promise<BaseResponseWithSingle<CreateActivityReviewResponse>> => {
  return axios
    .request<BaseResponseWithSingle<CreateActivityReviewResponse>>({
      method: "post",
      url: "activity/review",
      data: req,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const writeActivityReview = async (
  req: WriteActivityReviewRequest
): Promise<BaseResponseWithSingle<WriteActivityReviewResponse>> => {
  return axios
    .request<BaseResponseWithSingle<WriteActivityReviewResponse>>({
      method: "put",
      url: "activity/review/data",
      data: req,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const completeDraft = async (
  req: CompleteDraftRequest
): Promise<BaseResponseWithSingle<CompleteDraftResponse>> => {
  return axios
    .request<BaseResponseWithSingle<CompleteDraftResponse>>({
      method: "post",
      url: "activity/history/draft/complete",
      data: req,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const uploadActivityDocument = async (
  req: UploadActivityDocumentRequest
): Promise<BaseResponseWithSingle<UploadActivityDocumentResponse>> => {
  const formData = new FormData();

  formData.append("ActivityDefinitionVersionDocumentTypeUuid", req.activityDefinitionVersionDocumentTypeUuid);
  formData.append("ActivityUuid", req.activityUuid);
  formData.append("File", req.file);
  formData.append("Variant", req.variant ?? "");

  return axios
    .request<BaseResponseWithSingle<UploadActivityDocumentResponse>>({
      method: "post",
      url: "activity/file",
      data: formData,
      timeout: 120000,
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const downloadActivityDocument = async (
  req: DownloadActivityDocumentRequest
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<BaseResponseWithSingle<any>> => {
  let url = `activity/file/download?ActivityDocumentHistoryUuid=${req.activityDocumentHistoryUuid}`;
  if (req.mimeType) url += `&MimeType=${encodeURIComponent(req.mimeType)}`;
  return axios
    .request({
      method: "get",
      url,
      data: req,
      responseType: "blob",
      timeout: 120000,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error?.errors || [],
      };
    });
};

export const downloadActivityDocumentsZip = async (
  req: DownloadActivityDocumentsZipRequest
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<BaseResponseWithSingle<any>> => {
  return axios
    .request({
      method: "post",
      url: "/activity/files/zip",
      data: req,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error?.errors || [],
      };
    });
};

export const downloadStoredZip = async (
  req: DownloadStoredZipRequest
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<BaseResponseWithSingle<any>> => {
  return axios
    .request({
      method: "get",
      url: `/activity/file/download-stored-zip/?Uuid=${req.uuid}`,
      data: req,
      responseType: "blob",
      timeout: 120000,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error?.errors || [],
      };
    });
};

export const updateActivityReview = async (
  req: UpdateActivityReviewRequest
): Promise<BaseResponseWithSingle<UpdateActivityReviewResponse>> => {
  return axios
    .request<BaseResponseWithSingle<UpdateActivityReviewResponse>>({
      method: "put",
      url: "activity/review",
      data: { ...req, deadlineDate: dateTransformer(req.deadlineDate) },
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const deleteActivityReview = async (req: DeleteActivityReviewRequest): Promise<BaseResponse> => {
  return axios
    .request<BaseResponse>({
      method: "delete",
      url: "activity/review",
      data: req,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};
