/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";

import { StepProps } from "./components";

interface useWizardReturnData {
  stepRefs: React.MutableRefObject<any[]>;
  clickedSubStep: string[] | undefined;
  setClickedSubStep: Dispatch<SetStateAction<string[] | undefined>>;
  handleMoveToStaticStepAndResetSteps: (handleMoveToStaticStep?: () => void) => void;
}

interface useWizardProps {
  steps: StepProps[];
}

export const useWizard = ({ steps }: useWizardProps): useWizardReturnData => {
  const [clickedSubStep, setClickedSubStep] = useState<string[] | undefined>(undefined);

  // TODO: unsure on type
  const stepRefs = useRef(<any>[]);
  stepRefs.current = steps.map((item, index) => stepRefs.current[index] ?? React.createRef());

  const handleMoveToStaticStepAndResetSteps = (handleMoveToStaticStep?: () => void): void => {
    setClickedSubStep(undefined);
    if (handleMoveToStaticStep) handleMoveToStaticStep();
  };

  // Closes steps without current substeps & expands steps with current substeps
  useEffect(() => {
    steps.forEach((step, index) => {
      stepRefs.current[index].current.resetSubSteps();
    });
  }, [steps]);

  return { stepRefs, clickedSubStep, setClickedSubStep, handleMoveToStaticStepAndResetSteps };
};
