import { Dispatch, SetStateAction, useState } from "react";

import { ProjectActivitiesConstants } from "../../../../constants";
import { transitionActivity } from "../../../../service/activity";
import { Status } from "../../../../service/Shared";
import { getErrorMessageFromCode } from "../../../../service/ValidationErrorFormatter";
import { useIsLoadingWrapper } from "../../../../utils";
import { Toast } from "../../../../widget";

/* eslint-disable unused-imports/no-unused-vars */
interface useSubmitToCodeModalProps {
  onClose: () => void;
  activityUuid: string;
  activityStatus: string;
  activityRowVersion: number;
  refreshActivityReviews?: boolean;
  setRefreshActivityReviews?: Dispatch<SetStateAction<boolean>>;
}
interface useSubmitToCodeModalReturnData {
  isLoading: boolean;
  onConfirmClick: () => void;
}

export const useSubmitToCodeModal = ({
  onClose,
  activityUuid,
  activityStatus,
  activityRowVersion,
  refreshActivityReviews,
  setRefreshActivityReviews,
}: useSubmitToCodeModalProps): useSubmitToCodeModalReturnData => {
  const [isLoading, setIsLoading] = useState(false);

  const onConfirmClick = useIsLoadingWrapper(async (): Promise<void> => {
    const res = await transitionActivity({
      activity: {
        uuid: activityUuid,
        rowVersion: activityRowVersion,
        toStatus: ProjectActivitiesConstants.STATUS_SUBMITTED_TO_CODE,
        fromStatus: activityStatus,
        publishExternalMessages: false,
        publishInternalMessages: false,
      },
      activityReviews: [],
    });

    if (res.status === Status.Success) {
      Toast.success({ message: "Activity submitted to code" });

      // If called from dashboard refresh context
      if (refreshActivityReviews !== undefined && setRefreshActivityReviews !== undefined) {
        setRefreshActivityReviews(!refreshActivityReviews);
      }
      onClose();
    } else if (res.errors && res.errors.length > 0) {
      Toast.error({ message: getErrorMessageFromCode(res.errors[0].message) });
    }
  }, setIsLoading);

  return {
    isLoading,
    onConfirmClick,
  };
};
