import "./CompleteActivityModal.css";

import { ServiceError } from "../../../../service/Shared";
import { Button, Column, ErrorBox, Modal, Row, Select } from "../../../../widget";
import { useCompleteActivityModal } from "./useCompleteActivityModal";

interface CompleteActivityModalProps {
  show: boolean;
  onClose: () => void;
  activityUuid: string;
  activityProjectUuid?: string;
  activityHistoryUuid: string;
  activityHistoryRowVersion: number | undefined;
  isDraft: boolean;
}

export const CompleteActivityModal = ({
  show,
  onClose,
  activityUuid,
  activityProjectUuid,
  activityHistoryUuid,
  activityHistoryRowVersion,
  isDraft,
}: CompleteActivityModalProps): JSX.Element => {
  const {
    outcomeSelectData,
    outcomeValue,
    isLoading,
    errors,
    onCancel,
    setOutcomeValue,
    handleConfirm,
    handleSaveWithoutCompleting,
  } = useCompleteActivityModal({
    activityUuid,
    activityProjectUuid,
    onClose,
    activityHistoryUuid,
    activityHistoryRowVersion,
    isDraft,
  });
  return (
    <Modal show={show} title="Complete activity" onClose={onCancel}>
      {errors && errors.length > 0 && (
        <Row spacingV="ml" justify="center">
          <Column span={8}>
            <ErrorBox
              title="Please solve the below issues before saving"
              text={errors.map((error: ServiceError) => error.message)}
            />
          </Column>
        </Row>
      )}
      <Row spacingV="ll" justify="center">
        <Column span={8}>
          <span className="body1">
            Please confirm the outcome of this activity so that it can be accurately represented within Kana. If the
            outcome has not yet been determined, you can save the activity to return to the project dashboard.
          </span>
        </Column>
      </Row>
      <Row justify="center" spacingV="xl">
        <Column span={6}>
          <Select
            label="Select outcome"
            data={outcomeSelectData}
            value={outcomeValue}
            onChange={(e) => setOutcomeValue(e)}
          />
        </Column>
      </Row>
      <Row justify="center" spacingV="l">
        <Column span={6}>
          <div className="CompleteActivityModalButtons">
            <Button text="Confirm" onClick={handleConfirm} isLoading={isLoading} />
            <Button
              text="Save without completing"
              onClick={handleSaveWithoutCompleting}
              variant="secondary"
              isLoading={isLoading}
            />
          </div>
        </Column>
      </Row>
    </Modal>
  );
};
