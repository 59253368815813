import { PillVariant } from "../../widget";

export class ProjectActivityReviewsConstants {
  public static readonly STATUS_TO_DO = "To do";

  public static readonly STATUS_UNASSIGNED = "Unassigned";

  public static readonly STATUS_IN_PROGRESS = "In progress";

  public static readonly STATUS_IN_REVIEW = "In review";

  public static readonly STATUS_COMPLETE = "Complete";

  public static readonly STATUS_CANCELLED = "Cancelled";

  public static readonly STATUS_APPROVED = "Approved";

  public static readonly STATUS_AWAITING_APPROVAL = "Awaiting approval";

  public static readonly STATUS_AWAITING_FEEDBACK = "Awaiting feedback";

  public static readonly STATUS_FEEDBACK_RECEIVED = "Feedback received";

  public static readonly PROJECT_ACTIVITY_REVIEWS_STATUS_PILL_VARIANT_MAP: { [key: string]: PillVariant } = {
    "To do": "grey",
    Unassigned: "grey",
    Complete: "green",
    Approved: "green",
    Cancelled: "red",
    "In progress": "coral",
    "Feedback received": "coral",
    "Awaiting feedback": "cyan",
    "Awaiting approval": "cyan",
    "In review": "cyan",
  };
}
