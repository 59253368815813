/* eslint-disable no-nested-ternary */
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { SortColumn } from "react-data-grid";
import { useLocation } from "react-router-dom";

import { OrganisationTypeConstants } from "../../../../constants";
import { CursorChangeProps, Origin, ResultType } from "../../../../models";
import { IStateOrigin } from "../../../../navigators/navigateStateObjects";
import {
  getOrganisationDetails,
  searchAuditData,
  SearchAuditDataRequest,
  SearchAuditDataResponse,
} from "../../../../service/query";
import { FilterGroups, ResultData, Status } from "../../../../service/Shared";
import { useAuth } from "../../../../useAuth";
import { flattenObject } from "../../../../utils";
import { getAuditObjectLink } from "../../../../utils/audit";
import { getProjectDetailsByUuid, getProjectManageProjectGroupRoute } from "../../../../utils/routes";
import {
  DataGridButtonLinkCellFormatterData,
  DataGridColumnDefinition,
  DataGridLinkCellFormatterData,
  dataGridMapFilterCriteria,
} from "../../../../widget";

interface UseDocumentHistoryReturnData {
  columns: DataGridColumnDefinition[];
  defaultSortingCriteria: SortColumn[];
  dataIsLoading: boolean;
  exportFileName?: string;
  auditDataUuid?: string;
  auditDataChange?: string;
  showAuditChangesPanel: boolean;
  setShowAuditChangesPanel: Dispatch<SetStateAction<boolean>>;
  onChange: ({ filtering, paging, sorting }: CursorChangeProps) => Promise<{
    resultData: ResultData[];
    paging: {
      pageSize: number;
      totalCount: number;
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  }>;
}

export const useAuditHistory = (): UseDocumentHistoryReturnData => {
  const location = useLocation();
  const { currentUserType, currentOrganisationUuid } = useAuth();

  const [dataIsLoading, setDataIsLoading] = useState(true);

  const [showAuditChangesPanel, setShowAuditChangesPanel] = useState(false);
  const [auditDataUuid, setAuditDataUuid] = useState<string>();
  const [auditDataChange, setAuditDataChange] = useState<string>();

  const [organisationDisplayName, setOrganisationDisplayName] = useState<string>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: DataGridColumnDefinition[] = [
    {
      key: "id",
      name: "Audit ID",
      dataType: "number",
      minWidth: 40,
    },
    {
      name: "Type",
      key: "typeLink",
      dataType: "string",
      formatter: "link",
      filterable: true,
      sortable: true,
      minWidth: 170,
    },
    {
      name: "Project / Group",
      key: "projectLink",
      dataType: "string",
      formatter: "link",
      filterable: true,
      sortable: true,
      minWidth: 100,
    },
    {
      key: "action",
      name: "Detail",
      dataType: "string",
      minWidth: 80,
    },
    {
      key: "changeButton",
      name: "Change",
      dataType: "string",
      formatter: "buttonLink",
      filterable: true,
      sortable: true,
      minWidth: 240,
    },
    {
      key: "userFullName",
      name: "User",
      dataType: "string",
      formatter: "stringWithEllipsis",
      minWidth: 120,
    },
    {
      key: "eventDateTime",
      name: "Date / Time",
      dataType: "Date",
      formatter: "timestamp",
      filterable: false,
      minWidth: 170,
    },
  ];

  const defaultSortingCriteria: SortColumn[] = [{ columnKey: "id", direction: "DESC" }];

  const formatData = useCallback(async (responseData: SearchAuditDataResponse | undefined): Promise<ResultData[]> => {
    const results =
      responseData?.results?.map(async (d) => {
        const result = flattenObject(d);

        result.typeLink = {
          text: d.type,
          to: getAuditObjectLink(
            d.detail,
            d.objectUuid,
            currentUserType,
            Origin.OrganisationAuditPage,
            d.project?.projectUuid
          ),
          state: { origin: { goBackText: "Back to audit", from: location.pathname } as IStateOrigin },
        } as DataGridLinkCellFormatterData;

        if (d.project != null)
          result.projectLink = {
            text: d.project?.projectName,
            to: getProjectDetailsByUuid(d.project.projectUuid, currentUserType, {
              origin: Origin.OrganisationAuditPage,
            }),
            state: { origin: { goBackText: "Back to audit", from: location.pathname } as IStateOrigin },
          } as DataGridLinkCellFormatterData;

        if (d.projectGroup != null && d.projectGroup?.projectGroupUuid)
          result.projectLink = {
            text: d.projectGroup?.projectGroupName,
            to:
              currentUserType === OrganisationTypeConstants.DEVELOPER
                ? getProjectManageProjectGroupRoute(
                    d.projectGroup?.projectGroupUuid,
                    `origin=${Origin.OrganisationAuditPage}`
                  )
                : undefined,
          } as DataGridLinkCellFormatterData;

        if (d.detail !== "Project created" && d.detail !== "Activity created") {
          result.changeButton = {
            text: d.detail,
            action: () => {
              setAuditDataUuid(d.uuid);
              setAuditDataChange(d.detail || undefined);
              setShowAuditChangesPanel(true);
            },
          } as DataGridButtonLinkCellFormatterData;
        } else {
          result.changeButton = {
            text: d.detail,
          } as DataGridButtonLinkCellFormatterData;
        }

        return result;
      }) || [];

    return Promise.all(results);
  }, []);

  const onChange = async ({ filtering, paging, sorting }: CursorChangeProps): Promise<ResultType> => {
    // eslint-disable-next-line prefer-const
    let data: ResultType = {
      resultData: [],
      paging: {
        pageSize: 0,
        totalCount: 0,
        startCursor: "",
        endCursor: "",
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };

    const request: SearchAuditDataRequest & FilterGroups<SearchAuditDataRequest["filter"]> = {
      paging: {
        limit: paging.pageSize,
        beforeCursor: paging.beforeCursor || null,
        afterCursor: paging.afterCursor || null,
      },
      /* eslint-disable @typescript-eslint/no-explicit-any */
      sort: sorting.map((s: { key: any; direction: any }) => ({
        key:
          s.key === "typeLink"
            ? "type"
            : s.key === "projectLink"
            ? "project.projectName"
            : s.key === "changeButton"
            ? "detail"
            : (s.key as any),
        direction: s.direction,
      })),
    };

    const filterCriteria = dataGridMapFilterCriteria(filtering);

    if (filterCriteria.typeLink) {
      filterCriteria.type = filterCriteria.typeLink;
      filterCriteria.typeLink = undefined;
    }

    if (filterCriteria.projectLink) {
      filterCriteria.project = {
        projectName: filterCriteria.projectLink,
      };
      filterCriteria.projectLink = undefined;
    }

    if (filterCriteria.changeButton) {
      filterCriteria.detail = filterCriteria.changeButton;
      filterCriteria.changeButton = undefined;
    }

    delete filterCriteria.typeLink;
    delete filterCriteria.projectLink;
    delete filterCriteria.changeButton;

    request.filter = { results: filterCriteria };

    await searchAuditData(request)
      .then(async (response) => {
        data = {
          resultData: await formatData(response.data),
          paging: {
            startCursor: response.data?.paging?.startCursor || "",
            endCursor: response.data?.paging?.endCursor || "",
            pageSize: paging.pageSize || 10,
            totalCount: response.data?.paging?.total || 0,
            hasNextPage: response.data?.paging?.hasNextPage || false,
            hasPreviousPage: response.data?.paging?.hasPreviousPage || false,
          },
        };
      })
      .finally(() => {
        setDataIsLoading(false);
      });
    return data;
  };

  const getOrganisationDisplayName = useCallback(
    async (organisationUuid: string) => {
      await getOrganisationDetails({ organisationUuid }).then((res) => {
        if (res.status === Status.Success && res.data) {
          setOrganisationDisplayName(res.data.displayName ?? undefined);
        }
      });
    },
    [currentOrganisationUuid]
  );

  useEffect(() => {
    if (currentOrganisationUuid) getOrganisationDisplayName(currentOrganisationUuid);
  }, [currentOrganisationUuid]);

  return {
    columns,
    defaultSortingCriteria,
    dataIsLoading,
    auditDataUuid,
    auditDataChange,
    showAuditChangesPanel,
    exportFileName: `${organisationDisplayName ? `${organisationDisplayName}_` : ""}Audit_History_`.replaceAll(
      " ",
      "_"
    ),
    setShowAuditChangesPanel,
    onChange,
  };
};
