import { Doughnut } from "react-chartjs-2";

import { maybeSetGlobalChartJsConfiguration } from "../utils";
import { useCompletionDoughnutChart } from "./useCompletionDoughnutChart";

maybeSetGlobalChartJsConfiguration();

interface CompletionDoughnutChartProps {
  completed?: number;
  total?: number;
  centerNumber?: number;
  descriptor: string;
  antiDescriptor: string;
}

export const CompletionDoughnutChart = ({
  completed,
  total,
  centerNumber,
  descriptor,
  antiDescriptor,
}: CompletionDoughnutChartProps): JSX.Element => {
  const { initialData, chartData, options, plugins, noDataPlugins } = useCompletionDoughnutChart(
    completed,
    total,
    centerNumber,
    descriptor,
    antiDescriptor
  );

  return (
    <>
      {plugins && <Doughnut data={chartData} options={options} plugins={plugins} />}
      {(initialData === null || initialData === undefined || Object.values(initialData).length === 0) &&
        noDataPlugins && <Doughnut data={chartData} options={options} plugins={noDataPlugins} />}
    </>
  );
};
