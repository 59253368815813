import "../Card/Card.css";
import "./ExpandableCard.css";

import { ReactNode } from "react";

import { RightArrowOrangeIcon } from "../../../assets";
import { Align, Card, Column, Row } from "../..";
import { CardProps } from "../Card/CardProps";
import { ImageButton } from "./components";

interface ExpandableCardProps extends CardProps {
  title: string;
  targetRoute?: string;
  contentAlignment?: Align;
  tooltip?: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state?: any;
}

export const ExpandableCard = ({
  title,
  targetRoute,
  contentAlignment = "start",
  height,
  children,
  tooltip,
  state,
}: ExpandableCardProps): JSX.Element => {
  return (
    <Card height={height}>
      <Row justify="between" className="ExpandableCardHeader">
        <Column span={6}>
          <div>
            <h3>{title}</h3>
            <div>{tooltip}</div>
          </div>
        </Column>
        <Column span={6} className="ExpandableCardHeaderImageButton">
          {targetRoute && (
            <ImageButton icon={<RightArrowOrangeIcon />} route={targetRoute} linkText="View more" state={state} />
          )}
        </Column>
      </Row>
      <Row align={contentAlignment} className="ExpandableCardContent">
        <Column span={12}>{children}</Column>
      </Row>
    </Card>
  );
};
