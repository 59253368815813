import "./MergeProjectsModalForm.css";

import { Dispatch, SetStateAction } from "react";

import { FullRightArrowDark } from "../../../../../assets";
import { ActionButtons, Column, ErrorBox, FormLabel, KanaTooltip, Row, TextInput } from "../../../../../widget";
import { useMergeProjectsModalForm } from "./useMergeProjectsModalForm";

interface MergeProjectsModalFormProps {
  closeModal: () => void;
  setModalTitle: Dispatch<SetStateAction<string>>;
}

export const MergeProjectsModalForm = ({ closeModal, setModalTitle }: MergeProjectsModalFormProps): JSX.Element => {
  const {
    errors,
    projectExternalReference,
    setProjectExternalReference,
    sourceProjectName,
    targetProjectName,
    showConfirmModal,
    isHandleSubmitLoading,
    handleSubmit,
    handleConfirmSubmit,
  } = useMergeProjectsModalForm(closeModal, setModalTitle);

  return (
    <form
      onSubmit={(e) => (showConfirmModal ? handleConfirmSubmit(e) : handleSubmit(e))}
      className="MergeProjectsModalFormContent"
    >
      {errors && errors.length > 0 && (
        <Row spacingV="ml" justify="center">
          <Column span={6}>
            <ErrorBox title="Please solve the below issues before saving" text={errors.map((error) => error.message)} />
          </Column>
        </Row>
      )}
      {showConfirmModal ? (
        <>
          <Row spacingV="ll" justify="center">
            <Column span={10}>
              <p className="body2">
                Matching these projects will merge the marketing assets, description, images and issuance prices from
                this project into the other project. Any documents that have been uploaded will not be merged.
              </p>
            </Column>
          </Row>
          <Row spacingV="ll" justify="center">
            <Column span={6}>
              <div className="MergeProjectsModalFormNames">
                <p>{sourceProjectName}</p>
                <FullRightArrowDark />
                <p>{targetProjectName}</p>
              </div>
            </Column>
          </Row>
          <Row spacingV="2xl" justify="center">
            <Column span={6}>
              <p className="body2">Once you have matched projects together it cannot be undone.</p>
            </Column>
          </Row>
          <Row justify="center">
            <Column span={6}>
              <ActionButtons onCancel={closeModal} fullWidth submitButtonLabel="Match Projects" />
            </Column>
          </Row>
        </>
      ) : (
        <>
          <Row spacingV="ll" justify="center">
            <Column span={10}>
              <p className="body2">
                If this project duplicates another you since created in the registry, you can match and merge them
                together. To match {sourceProjectName} with another project, just enter the registry ID of the project
                you would would like to match with below.
              </p>
            </Column>
          </Row>
          <Row spacingV="ll" justify="around">
            <Column span={6}>
              <FormLabel
                label="Registry"
                tooltipJustify="left"
                tooltip={
                  <KanaTooltip
                    tooltipHeader="What is this?"
                    tooltipText="This is the external registry that your projects are listed on."
                  />
                }
              />
              <p className="body2 MergeProjectsModalFormRegistry">UK Land Carbon Registry</p>
            </Column>
          </Row>
          <Row spacingV="2xl" justify="center">
            <Column span={6}>
              <TextInput
                label="Registry ID"
                name="projectName"
                value={projectExternalReference}
                onChange={(value) => setProjectExternalReference(value)}
                tooltipJustify="left"
                tooltip={
                  <KanaTooltip
                    tooltipHeader="What is the Registry ID?"
                    tooltipText="The registry ID is the unique identifying number for the project you would like to merge with. This can be found in your external registry."
                  />
                }
              />
            </Column>
          </Row>
          <Row justify="center">
            <Column span={6}>
              <ActionButtons onCancel={closeModal} fullWidth isLoading={isHandleSubmitLoading} />
            </Column>
          </Row>
        </>
      )}
    </form>
  );
};
