import { Navigate, Route, Routes } from "react-router-dom";

import { OrganisationPermissionConstants } from "../constants";
import {
  ActivityPageParams,
  ActivityReviewPageParams,
  PrivateActivityDocumentsParams,
  PrivateProjectDocumentsParams,
  PublicVerifierDetailsPreviewParams,
} from "../models";
import { ActivityWizardProvider } from "../route/developer/activities";
import { PublicVerifierDetailsPreviewPage } from "../route/public/verifier";
import { AuditHistoryTab } from "../route/shared";
import {
  ActivityDiscussionProvider,
  ActivityDiscussionTab,
  DocumentsTab as ActivityDocumentsTab,
  VersionHistoryTab,
} from "../route/shared/activities-dashboard";
import { ActivityDocumentHistoryPage } from "../route/shared/activities-dashboard/documents";
import {
  DocumentProvider,
  DocumentsTab,
  IssuancesTab,
  OverviewTab,
  ProjectAuditHistoryPage,
  ProjectDocumentHistoryPage,
  ProjectProvider,
} from "../route/shared/projects";
import { ActivityReviewWizardProvider, ActivityViewPage, ActivityWizardReviewPage } from "../route/verifier/activities";
import {
  ActivityReviewDashboardProvider,
  ActivityReviewPage,
  ReviewsTab as ActivityReviewDashboardReviewsTab,
} from "../route/verifier/activity-review-dashboard";
import {
  AssessmentsTab,
  DashboardPage,
  QueueManagementPage,
  ReviewsTab,
  UnassignedActivitiesTab,
} from "../route/verifier/dashboard";
import { VerifierChangePasswordPage, VerifierChangePersonalDetailsPage } from "../route/verifier/profile";
import { ActivityReviewTab, ProjectPage, ProjectsPage } from "../route/verifier/projects";
import { VerifierSettingsPage } from "../route/verifier/settings";
import { VerifierMarketingAssetsTab } from "../route/verifier/settings/marketing-assets";
import { VerifierOrganisationTab } from "../route/verifier/settings/organisation";
import {
  VerifierUsersEditPage,
  VerifierUsersListPage,
  VerifierUsersSendInvitationPage,
} from "../route/verifier/settings/users";
import { useRouteGuards } from "./guards";

export const VerifierRoutes = (): JSX.Element => {
  const { RequireUserPermission, RequireVerifierAuth } = useRouteGuards();

  return (
    <Routes>
      <Route
        path="dashboard"
        element={
          <RequireVerifierAuth>
            <DashboardPage />
          </RequireVerifierAuth>
        }
      />
      <Route
        path="queue-management/*"
        element={
          <RequireVerifierAuth>
            <QueueManagementPage />
          </RequireVerifierAuth>
        }
      >
        <Route path="assessments" element={<AssessmentsTab />} />
        <Route path="reviews" element={<ReviewsTab />} />
        <Route path="unassigned-activities" element={<UnassignedActivitiesTab />} />
      </Route>
      <Route
        path={`activity-review/:${ActivityReviewPageParams.activityReviewActivityUuid}/document/:${PrivateActivityDocumentsParams.activityDocumentUuid}`}
        element={
          <RequireVerifierAuth>
            <ActivityReviewDashboardProvider>
              <ActivityDocumentHistoryPage />
            </ActivityReviewDashboardProvider>
          </RequireVerifierAuth>
        }
      />

      <Route
        path={`activity-review/:${ActivityReviewPageParams.activityReviewActivityUuid}/*`}
        element={
          <ActivityReviewDashboardProvider>
            <ActivityReviewPage />
          </ActivityReviewDashboardProvider>
        }
      >
        <Route
          path="discussions"
          element={
            <ActivityWizardProvider>
              <RequireVerifierAuth>
                <ActivityDiscussionProvider>
                  <ActivityDiscussionTab />
                </ActivityDiscussionProvider>
              </RequireVerifierAuth>
            </ActivityWizardProvider>
          }
        />
        <Route
          path="documents"
          element={
            <RequireVerifierAuth>
              <ActivityDocumentsTab />
            </RequireVerifierAuth>
          }
        />
        <Route
          path="history"
          element={
            <ActivityWizardProvider>
              <RequireVerifierAuth>
                <VersionHistoryTab />
              </RequireVerifierAuth>
            </ActivityWizardProvider>
          }
        />
        <Route
          path="reviews"
          element={
            <ActivityWizardProvider>
              <RequireVerifierAuth>
                <ActivityReviewDashboardReviewsTab />
              </RequireVerifierAuth>
            </ActivityWizardProvider>
          }
        />
      </Route>
      <Route
        path={`activity-review/:${ActivityReviewPageParams.activityReviewUuid}/review`}
        element={
          <RequireVerifierAuth>
            <ActivityReviewWizardProvider>
              <ActivityWizardReviewPage />
            </ActivityReviewWizardProvider>
          </RequireVerifierAuth>
        }
      />
      <Route
        path={`activity-review/:${ActivityPageParams.activityHistoryUuid}/view`}
        element={
          <RequireVerifierAuth>
            <ActivityWizardProvider>
              <ActivityViewPage />
            </ActivityWizardProvider>
          </RequireVerifierAuth>
        }
      />
      <Route
        path={`activity-review/:${ActivityPageParams.activityHistoryUuid}/view?:location`}
        element={
          <RequireVerifierAuth>
            <ActivityViewPage />
          </RequireVerifierAuth>
        }
      />
      <Route
        path="profile/change-password"
        element={
          <RequireVerifierAuth>
            <VerifierChangePasswordPage />
          </RequireVerifierAuth>
        }
      />
      <Route
        path="profile/change-personal-details"
        element={
          <RequireVerifierAuth>
            <VerifierChangePersonalDetailsPage />
          </RequireVerifierAuth>
        }
      />
      <Route
        path="projects"
        element={
          <RequireVerifierAuth>
            <ProjectsPage />
          </RequireVerifierAuth>
        }
      />
      <Route
        path="projects/:projectUuid/*"
        element={
          <ProjectProvider>
            <ProjectPage />
          </ProjectProvider>
        }
      >
        <Route
          path="overview"
          element={
            <RequireVerifierAuth>
              <OverviewTab />
            </RequireVerifierAuth>
          }
        />
        <Route
          path="issuances"
          element={
            <RequireVerifierAuth>
              <IssuancesTab />
            </RequireVerifierAuth>
          }
        />
        <Route
          path="documents"
          element={
            <RequireVerifierAuth>
              <DocumentsTab />
            </RequireVerifierAuth>
          }
        />
        <Route
          path="activity-review"
          element={
            <RequireVerifierAuth>
              <ActivityReviewTab />
            </RequireVerifierAuth>
          }
        />
      </Route>
      <Route
        path="projects/:projectUuid/audit-history"
        element={
          <RequireVerifierAuth>
            <ProjectProvider>
              <ProjectAuditHistoryPage />
            </ProjectProvider>
          </RequireVerifierAuth>
        }
      />
      <Route
        path={`projects/:${PrivateProjectDocumentsParams.projectUuid}/documents/:${PrivateProjectDocumentsParams.projectDocumentUuid}`}
      >
        <Route
          path=""
          element={
            <RequireVerifierAuth>
              <ProjectProvider>
                <DocumentProvider>
                  <ProjectDocumentHistoryPage />
                </DocumentProvider>
              </ProjectProvider>
            </RequireVerifierAuth>
          }
        />
      </Route>
      <Route
        path="settings/*"
        element={
          <RequireVerifierAuth>
            <VerifierSettingsPage />
          </RequireVerifierAuth>
        }
      >
        <Route
          path="users"
          element={
            <RequireUserPermission
              userPermission={OrganisationPermissionConstants.READ_ORGANISATION_USERS}
              redirectRoute="/v/settings/organisation"
            >
              <VerifierUsersListPage />
            </RequireUserPermission>
          }
        />
        <Route path="organisation" element={<VerifierOrganisationTab />} />
        <Route path="marketing-assets" element={<VerifierMarketingAssetsTab />} />
        <Route path="marketing-assets/:previewUuid" element={<VerifierMarketingAssetsTab />} />
        <Route path="audit-history" element={<AuditHistoryTab />} />
        <Route path="" element={<Navigate to="users" replace />} />
      </Route>
      <Route
        path="settings/users/send-invitation"
        element={
          <RequireVerifierAuth>
            <VerifierUsersSendInvitationPage />
          </RequireVerifierAuth>
        }
      />
      <Route
        path="settings/users/:userUuid/edit"
        element={
          <RequireVerifierAuth>
            <VerifierUsersEditPage />
          </RequireVerifierAuth>
        }
      />
      <Route
        path={`verifier/details/preview/:${PublicVerifierDetailsPreviewParams.previewUuid}`}
        element={
          <RequireVerifierAuth>
            <PublicVerifierDetailsPreviewPage />
          </RequireVerifierAuth>
        }
      />
    </Routes>
  );
};
