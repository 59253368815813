/* Note this page is using imports from /developer - this was done rather than
 * moving the file to shared, as we wanted to keep the git history of the file given the
 * complexity of some of bugs that have occured surrounding the wizard in the past.
 */
import "../../../../../developer/activities/activity/css/ActivityWizard.css";
import "./Review.css";

import { ArrowRoundLeftDarkIcon, ArrowRoundRightLightIcon } from "../../../../../../assets";
import { Button, Column, Row, Stepper, UserHeader, Wizard } from "../../../../../../widget";
import { ActivityWizardContent } from "../../../../../developer/activities/activity/components";
import { ReviewContent } from "../../components";
import { useReview } from "./useReview";

export const Review = (): JSX.Element => {
  const {
    steps,
    l3Steps,
    stepFields,
    stepReviews,
    currentStepName,
    currentStepKeys,
    hasNext,
    hasPrevious,
    activityData,
    isLoading,
    showReviewPage,
    isReviewPage,
    activityDefinition,
    activityDefinitionInfo,
    activityReviewCompletionPercentage,
    activityReviewType,
    activityReviewStatus,
    isAssignedAuditor,
    currentUserType,
    errors,

    isMoveNextLoading,
    isMovePreviousLoading,
    isMoveToLastStepLoading,
    isMoveToReviewLoading,
    isSaveAndCloseLoading,
    // handlers
    movePrevious,
    moveNext,
    moveTo,
    moveToLastStep,
    moveToReview,
    onSaveAndClose,
  } = useReview();

  return isLoading ? (
    <div />
  ) : (
    <div className={`${currentUserType} ActivityWizardContainer ActivityWizardContainer_Review`}>
      <div className="ActivityWizardContainerWizard">
        <Wizard
          projectName={activityData?.activity?.project?.displayName || ""}
          activityName={activityDefinitionInfo?.displayName || ""}
          activityVersion={activityDefinitionInfo?.versionNumber || ""}
          currentUserType={currentUserType}
          steps={steps}
          isReview={isReviewPage}
          completionPercentage={activityReviewCompletionPercentage}
          handleMoveToStep={moveTo}
          handleMoveToReview={showReviewPage ? moveToReview : undefined}
        />
      </div>
      <div className="ActivityWizardContainerContent">
        <Row spacingV="m" className="ActivityWizardContainerContentUserHeader">
          <Column span={12}>
            <UserHeader />
          </Column>
        </Row>
        {showReviewPage && isReviewPage ? (
          <ReviewContent
            activityDefinition={activityDefinition}
            handleMoveToStep={moveTo}
            activityReviewStatus={activityReviewStatus}
            activityReviewType={activityReviewType}
            activityReviewCompletionPercentage={activityReviewCompletionPercentage}
            isAssignedAuditor={isAssignedAuditor}
          />
        ) : (
          <>
            {currentStepName && (
              <Row
                spacingV={l3Steps && l3Steps.length > 0 ? "ml" : "ll"}
                className="ActivityWizardContainerContentStepName"
              >
                <Column span={12}>
                  <h1>{currentStepName}</h1>
                </Column>
              </Row>
            )}
            {l3Steps && l3Steps.length > 0 && (
              <Row spacingV="ll" className="ActivityWizardContainerStepper">
                <Column span={12}>
                  <Stepper
                    steps={l3Steps}
                    currentSubStepKey={currentStepKeys.length === 3 ? currentStepKeys[2] : undefined}
                    onClick={(stepKey) => {
                      const isCurrentStep = currentStepKeys.length === 3 && currentStepKeys[2] === stepKey;
                      const l3StepKeys = [...currentStepKeys.slice(0, -1), stepKey];
                      return !isCurrentStep ? moveTo(l3StepKeys) : undefined;
                    }}
                    currentUserType={currentUserType}
                  />
                </Column>
              </Row>
            )}
            <Row spacingV="ll">
              <Column span={12}>
                <ActivityWizardContent fields={stepFields} errors={errors} />
                {stepReviews !== undefined && <div className="ActivityWizardReviewStepContainer">{stepReviews}</div>}
              </Column>
            </Row>
          </>
        )}
        <Row spacingV="ll">
          <Column span={12}>
            <div className="ActivityWizardContainerContentActions">
              {hasPrevious && (
                <Button
                  text="Previous"
                  onClick={movePrevious}
                  variant="tertiary"
                  icon={<ArrowRoundLeftDarkIcon />}
                  iconPosition="left"
                  isLoading={isMovePreviousLoading}
                />
              )}
              {isReviewPage && (
                <Button
                  text="Previous"
                  onClick={moveToLastStep}
                  variant="tertiary"
                  icon={<ArrowRoundLeftDarkIcon />}
                  iconPosition="left"
                  isLoading={isMoveToLastStepLoading}
                />
              )}
              {hasNext && (
                <Button
                  text="Next"
                  onClick={moveNext}
                  variant="primary"
                  icon={<ArrowRoundRightLightIcon />}
                  isLoading={isMoveNextLoading}
                />
              )}
              {!hasNext && showReviewPage && !isReviewPage && (
                <Button
                  text="Next"
                  onClick={moveToReview}
                  variant="primary"
                  icon={<ArrowRoundRightLightIcon />}
                  isLoading={isMoveToReviewLoading}
                />
              )}
              <Button
                text="Save & Close"
                onClick={() => onSaveAndClose()}
                variant="secondary"
                isLoading={isSaveAndCloseLoading}
              />
            </div>
          </Column>
        </Row>
      </div>
    </div>
  );
};
