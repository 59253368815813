import { useNavigate } from "react-router-dom";

import { OrganisationRoleConstants, OrganisationTypeConstants } from "../../../../constants";
import { VerifierSingleCardWithTabsTemplate } from "../../../../templates";
import { useAuth } from "../../../../useAuth";
import { getDashboardRoute } from "../../../../utils/routes";

const Settings = (): JSX.Element => {
  const navigate = useNavigate();
  const { currentUserRole } = useAuth();

  return (
    <div>
      <VerifierSingleCardWithTabsTemplate
        activeElement="Dashboard"
        pageTitle="Queue Management"
        tabsList={
          currentUserRole === OrganisationRoleConstants.VVB_MANAGER
            ? [
                { route: "/v/queue-management/assessments", label: "Assessments" },
                { route: "/v/queue-management/reviews", label: "Reviews" },
                {
                  route: "/v/queue-management/unassigned-activities",
                  label: "Unassigned Activities",
                },
              ]
            : [
                { route: "/v/queue-management/assessments", label: "Assessments" },
                { route: "/v/queue-management/reviews", label: "Reviews" },
              ]
        }
        backButtonText="Back to dashboard"
        handleBackClick={() => navigate(getDashboardRoute(OrganisationTypeConstants.VERIFIER))}
      />
    </div>
  );
};

export default Settings;
