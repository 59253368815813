import { createContext, Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { ActivityPageParams } from "../../../../../../models";
import {
  getActivityDetails,
  GetActivityDetailsResponse,
  getActivityHistoryDetails,
} from "../../../../../../service/query";
import { Status } from "../../../../../../service/Shared";
import { useAuth } from "../../../../../../useAuth";

interface ActivityWizardProviderProps {
  children?: JSX.Element;
}

interface ActivityWizardContextType {
  activityUuid: string | undefined;
  activityHistoryUuid: string | undefined;
  activityDetails: GetActivityDetailsResponse | undefined;
  currentOrganisationUuid: string | undefined;
  isExternalActivity: boolean;
  activityHistoryRowVersion: number;
  setActivityHistoryRowVersion: Dispatch<SetStateAction<number>>;
  refreshActivityWizard: boolean;
  setRefreshActivityWizard: Dispatch<SetStateAction<boolean>>;
}

export const ActivityWizardContext = createContext<ActivityWizardContextType>({} as ActivityWizardContextType);

export const ActivityWizardProvider = ({ children }: ActivityWizardProviderProps): JSX.Element => {
  const { activityHistoryUuid } = useParams<ActivityPageParams.activityHistoryUuid>();

  const { currentDeveloperUuid } = useAuth();

  const [activityUuid, setActivityUuid] = useState<string | undefined>(undefined);
  const [isExternalActivity, setIsExternalActivity] = useState(true);
  const [currentOrganisationUuid, setCurrentOrganisationUuid] = useState<string | undefined>(undefined);
  const [activityDetails, setActivityDetails] = useState<GetActivityDetailsResponse>();

  const [activityHistoryRowVersion, setActivityHistoryRowVersion] = useState<number>(1);
  const [refreshActivityWizard, setRefreshActivityWizard] = useState(false);

  // Is the user a guest user to the project and the activity (we need this for external discussion checks)
  const fetchIsExternalActivity = useCallback(
    async (uuid: string) => {
      const activityHistoryDetailsResponse = await getActivityHistoryDetails({ activityHistoryUuid: uuid });

      if (activityHistoryDetailsResponse.status === Status.Success && activityHistoryDetailsResponse.data) {
        setActivityUuid(activityHistoryDetailsResponse.data.activity.uuid);

        const activityDetailsResponse = await getActivityDetails({
          activityUuid: activityHistoryDetailsResponse.data.activity.uuid,
        });

        if (activityDetailsResponse.status === Status.Success && activityDetailsResponse.data) {
          setActivityDetails(activityDetailsResponse.data);
          setIsExternalActivity(currentDeveloperUuid !== activityDetailsResponse.data.project.developer.uuid);
          setCurrentOrganisationUuid(activityDetailsResponse.data.project.developer.organisationUuid || undefined);
        }
      }
    },
    [activityHistoryUuid]
  );

  useEffect(() => {
    if (activityHistoryUuid && currentDeveloperUuid) {
      fetchIsExternalActivity(activityHistoryUuid);
    }
  }, [activityHistoryUuid, currentDeveloperUuid]);

  const memoisedValue = useMemo(
    () => ({
      activityUuid,
      activityHistoryUuid,
      activityDetails,
      isExternalActivity,
      currentOrganisationUuid,

      activityHistoryRowVersion,
      setActivityHistoryRowVersion,
      refreshActivityWizard,
      setRefreshActivityWizard,
    }),
    [
      activityHistoryUuid,
      activityDetails,
      currentOrganisationUuid,
      isExternalActivity,
      activityHistoryRowVersion,
      refreshActivityWizard,
    ]
  );

  return <ActivityWizardContext.Provider value={memoisedValue}>{children}</ActivityWizardContext.Provider>;
};
