import "./Grid.css";

import React, { CSSProperties } from "react";

type SpacingV = "xs" | "s" | "sl" | "m" | "ml" | "l" | "ll" | "xl" | "2xl" | "3xl" | "4xl" | "5xl";

export type ColumnSpanOrOffset =
  | 0
  | "0_half"
  | 1
  | "1_half"
  | 2
  | "2_half"
  | 3
  | "3_half"
  | 4
  | "4_half"
  | 5
  | "5_half"
  | 6
  | "6_half"
  | 7
  | "7_half"
  | 8
  | "8_half"
  | 9
  | "9_half"
  | 10
  | "10_half"
  | 11
  | "11_half"
  | 12;
export type Align = "start" | "center" | "end";
type Justify = "start" | "center" | "end" | "around" | "between";

type RowOrColumnProps = {
  className?: string | undefined;
  style?: CSSProperties | undefined;
  justify?: Justify;
  align?: Align;
  children?: React.ReactNode;
};

type ColumnProps = {
  span?: ColumnSpanOrOffset;
  offset?: ColumnSpanOrOffset;
  hasNoPadding?: boolean;
} & RowOrColumnProps;

export const Column = ({
  span = 1,
  offset = undefined,
  justify,
  align,
  children,
  className,
  style,
  hasNoPadding = false,
}: ColumnProps): JSX.Element => {
  let classNames = `Column Column_span_${span}`;
  if (offset !== undefined) classNames += ` Column_offset_${offset}`;
  if (justify !== undefined) classNames += ` Justify_${justify}`;
  if (align !== undefined) classNames += ` Align_${align}`;
  if (className) classNames += ` ${className}`;
  if (hasNoPadding) classNames += " NoPadding";
  return (
    <div className={classNames} style={style}>
      {children}
    </div>
  );
};

type RowProps = {
  spacingV?: SpacingV;
  reversed?: boolean;
  id?: string;
} & RowOrColumnProps;

export const Row: React.FC<RowProps> = ({
  spacingV,
  justify,
  align,
  reversed,
  children,
  className,
  style,
  id,
}): JSX.Element => {
  let classNames = `Row`;
  if (spacingV !== undefined) classNames += ` Row_spacing_${spacingV}`;
  if (justify !== undefined) classNames += ` Justify_${justify}`;
  if (align !== undefined) classNames += ` Align_${align}`;
  if (reversed) classNames += ` Reversed`;
  if (className) classNames += ` ${className}`;
  return (
    <div className={classNames} style={style} id={id}>
      {children}
    </div>
  );
};
