import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { ArrowheadRightDarkIcon } from "../../../assets";
import { CursorChangeProps, ResultType } from "../../../models";
import { IStateOrigin } from "../../../navigators/navigateStateObjects";
import { getActivityAggregate, searchActivities, SearchActivitiesResponse } from "../../../service/query";
import { ResultData, Status } from "../../../service/Shared";
import { useAuth } from "../../../useAuth";
import { flattenObject } from "../../../utils";
import { getActivityDashboardTabRoute, getActivityViewRoute, getProjectDetailsByUuid } from "../../../utils/routes";
import {
  ChartData,
  DataGridButtonLinkCellFormatterData,
  DataGridColumnDefinition,
  DataGridLinkCellFormatterData,
  dataGridMapFilterCriteria,
  Toast,
} from "../../../widget";
import { IconCellFormatterData } from "../../../widget/data/DataGrid/models";

interface UseActivitiesReturnData {
  activityTypeChartData: ChartData;
  activityStatusChartData: ChartData;
  activityCodeChartData: ChartData;
  activityDeveloperChartData: ChartData;
  isLoading: boolean;
  columns: DataGridColumnDefinition[];
  onChange: ({ filtering, paging, sorting }: CursorChangeProps) => Promise<{
    resultData: ResultData[];
    paging: {
      pageSize: number;
      totalCount: number;
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  }>;
}

const PAGE_SIZE = 10;

const columns: DataGridColumnDefinition[] = [
  { key: "developerDisplayName", name: "Developer", dataType: "string" },
  {
    name: "Project / Group",
    key: "projectLink",
    dataType: "string",
    formatter: "link",
    filterable: true,
    sortable: true,
  },
  {
    name: "Activity",
    key: "activityLink",
    dataType: "string",
    formatter: "buttonLink",
    filterable: true,
    sortable: true,
  },
  {
    name: "Variant",
    key: "variant",
    dataType: "string",
    alignment: "center",
    formatter: "align",
    filterable: true,
    sortable: true,
  },
  {
    name: "Status",
    key: "status",
    dataType: "string",
    formatter: "projectActivitiesStatusPill",
    minWidth: 240,
    alignment: "center",
    filterable: true,
    sortable: true,
  },
  {
    name: "Last updated",
    key: "currentVersion.updatedAt",
    dataType: "Date",
    formatter: "dateOnly",
    alignment: "center",
    filterable: false,
    sortable: true,
  },
  {
    name: "Updated by",
    key: "currentVersion.updatedByUser.fullName",
    dataType: "string",
    alignment: "center",
    formatter: "align",
    filterable: true,
    sortable: true,
  },
  {
    name: "More details",
    key: "activityHistoryLink",
    dataType: "string",
    formatter: "icon",
    alignment: "center",
    filterable: false,
    sortable: false,
  },
];

export const useActivities = (): UseActivitiesReturnData => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUserType, currentDevelopmentManagerUuid } = useAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [activityTypeChartData, setActivityTypeChartData] = useState<ChartData>({});
  const [activityStatusChartData, setActivityStatusChartData] = useState<ChartData>({});
  const [activityCodeChartData, setActivityCodeChartData] = useState<ChartData>({});
  const [activityDeveloperChartData, setActivityDeveloperChartData] = useState<ChartData>({});

  const formatData = useCallback(
    (responseData: SearchActivitiesResponse | undefined): ResultData[] =>
      responseData?.results?.map((el) => {
        const result = flattenObject(el);

        result.projectLink = {
          text: el.project ? el.project.displayName : el.group?.displayName,
          to: el.project ? getProjectDetailsByUuid(el.project.uuid, currentUserType) : "",
          state: { origin: { goBackText: "Back to Dashboard", from: location.pathname } as IStateOrigin },
        } as DataGridLinkCellFormatterData;

        result.developerDisplayName = el.project ? el.project.developer.displayName : el.group?.developer?.displayName;

        result.activityLink = {
          text: el.activityDefinition.displayName,
          action: () => {
            if (el.currentVersion) {
              navigate(
                getActivityViewRoute(
                  el.currentVersion.uuid,
                  currentUserType,
                  el.project ? `projectUuid=${el.project.uuid}` : ""
                )
              );
            } else {
              Toast.error({
                message: `This activity has not yet been published. Once ${el.createdByUser.fullName} has published the activity, you can view the activity.`,
              });
            }
          },
        } as DataGridButtonLinkCellFormatterData;

        result.activityHistoryLink = {
          action: () =>
            navigate(
              getActivityDashboardTabRoute(
                el.uuid,
                "documents",
                currentUserType,
                el.project ? `projectUuid=${el.project.uuid}` : ""
              )
            ),
          icon: <ArrowheadRightDarkIcon width={12} height={12} />,
        } as IconCellFormatterData;

        return result;
      }) || [],
    []
  );

  const onChange = async ({ filtering, paging, sorting }: CursorChangeProps): Promise<ResultType> => {
    const filterCriteria = dataGridMapFilterCriteria(filtering);
    if (filterCriteria.projectLink) {
      filterCriteria.project = {
        displayName: filterCriteria.projectLink,
      };
      filterCriteria.projectLink = undefined;
    }
    if (filterCriteria.activityLink) {
      filterCriteria.activityDefinition = {
        displayName: filterCriteria.activityLink,
      };
      filterCriteria.activityLink = undefined;
    }

    let data: ResultType = {
      resultData: [],
      paging: {
        pageSize: PAGE_SIZE,
        totalCount: 0,
        startCursor: "",
        endCursor: "",
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };

    const sortKeyMap: Record<string, string> = {
      projectLink: "project.displayName",
      activityLink: "activityDefinition.displayName",
    };

    await searchActivities({
      paging: {
        beforeCursor: paging.beforeCursor || null,
        afterCursor: paging.afterCursor || null,
        limit: PAGE_SIZE,
      },
      /* eslint-disable @typescript-eslint/no-explicit-any */
      sort: sorting.map((s: { key: any; direction: any }) => ({
        key: sortKeyMap[s.key] || s.key,
        direction: s.direction,
      })),
      /* eslint-enable */
      filter: { results: filterCriteria },
    })
      .then(async (response) => {
        data = {
          resultData: formatData(response.data),
          paging: {
            startCursor: response.data?.paging?.startCursor || "",
            endCursor: response.data?.paging?.endCursor || "",
            pageSize: PAGE_SIZE,
            totalCount: response.data?.paging?.total || 0,
            hasNextPage: response.data?.paging?.hasNextPage || false,
            hasPreviousPage: response.data?.paging?.hasPreviousPage || false,
          },
        };
      })
      .finally(() => {
        setIsLoading(false);
      });

    return data;
  };

  const fetchData = useCallback(async (): Promise<void> => {
    if (currentDevelopmentManagerUuid) {
      const [activityTypeRes, activityStatusRes, activityCodeRes, activityDeveloperRes] = await Promise.all([
        getActivityAggregate({
          groupBy: "activityDefinition.displayName",
          filterBy: "developmentManager",
          filterOperator: "eq",
          aggregate: "activity.id",
          aggregation: "count",
          filterValue: currentDevelopmentManagerUuid,
        }),
        getActivityAggregate({
          groupBy: "activity.status",
          filterBy: "developmentManager",
          filterOperator: "eq",
          aggregate: "activity.id",
          aggregation: "count",
          filterValue: currentDevelopmentManagerUuid,
        }),
        getActivityAggregate({
          groupBy: "activityDefinition.standard.displayName",
          filterBy: "developmentManager",
          filterOperator: "eq",
          aggregate: "activity.id",
          aggregation: "count",
          filterValue: currentDevelopmentManagerUuid,
        }),
        getActivityAggregate({
          groupBy: "developer",
          filterBy: "developmentManager",
          filterOperator: "eq",
          aggregate: "activity.id",
          aggregation: "count",
          filterValue: currentDevelopmentManagerUuid,
        }),
      ]);

      if (activityTypeRes.status === Status.Success && activityTypeRes.data) {
        setActivityTypeChartData(
          activityTypeRes.data.reduce<ChartData>((previous, current) => {
            // eslint-disable-next-line no-param-reassign
            previous[current.key] = +current.value;
            return previous;
          }, {})
        );
      }

      if (activityStatusRes.status === Status.Success && activityStatusRes.data) {
        setActivityStatusChartData(
          activityStatusRes.data.reduce<ChartData>((previous, current) => {
            // eslint-disable-next-line no-param-reassign
            previous[current.key] = +current.value;
            return previous;
          }, {})
        );
      }

      if (activityCodeRes.status === Status.Success && activityCodeRes.data) {
        setActivityCodeChartData(
          activityCodeRes.data.reduce<ChartData>((previous, current) => {
            // eslint-disable-next-line no-param-reassign
            previous[current.key] = +current.value;
            return previous;
          }, {})
        );
      }

      if (activityDeveloperRes.status === Status.Success && activityDeveloperRes.data) {
        setActivityDeveloperChartData(
          activityDeveloperRes.data.reduce<ChartData>((previous, current) => {
            // eslint-disable-next-line no-param-reassign
            previous[current.key] = +current.value;
            return previous;
          }, {})
        );
      }
    }
  }, []);

  useEffect(() => {
    fetchData().finally(() => setIsLoading(false));
  }, [fetchData]);

  return {
    activityTypeChartData,
    activityStatusChartData,
    activityCodeChartData,
    activityDeveloperChartData,
    isLoading,
    columns,
    onChange,
  };
};
