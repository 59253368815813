import { OrganisationTypeConstants } from "../../constants";
import { Origin } from "../../models";
import { logError } from "../../service/error";
import {
  getActivityDashboardTabRoute,
  getActivityReviewDashboardTabRoute,
  getDeveloperSettingsMarketingAssetsRoute,
  getDocumentHistoryRoute,
  getProjectAccessDetailsRoute,
  getProjectDetailsByUuid,
  getProjectIssuancesRoute,
  getProjectManageProjectGroupRoute,
  getProjectMarketingSettingsRoute,
  getUserEditRouteByUuid,
  getUserSettingsOrganisationRoute,
} from "../routes";

export const getAuditObjectLink = (
  detail: string | null,
  objectUuid: string,
  currentUserType: string,
  origin: Origin,
  relatedProjectUuid?: string
): string => {
  switch (detail) {
    case "Project created":
    case "Project details updated":
    case "Project status updated":
    case "Project standard specific data updated":
      return getProjectDetailsByUuid(objectUuid, currentUserType, { origin });
    case "Project marketing updated":
    case "Project marketing created":
      return getProjectMarketingSettingsRoute(objectUuid, `origin=${origin}`);
    case "Issuances price updated":
      return getProjectIssuancesRoute(objectUuid, currentUserType, { origin });
    case "Project access invite created":
    case "Project access invite accepted":
    case "Project access invite rejected":
    case "Project access invite revoked":
    case "Project invite updated":
    case "Project invite resent":
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return getProjectAccessDetailsRoute(objectUuid, { origin });
    case "Organisation user updated":
      return getUserEditRouteByUuid(objectUuid, currentUserType, { origin });
    case "Organisation marketing updated":
    case "Organisation marketing created":
      return getDeveloperSettingsMarketingAssetsRoute({ origin }, objectUuid); // TODO
    case "Organisation details updated":
    case "Organisation address updated":
      return getUserSettingsOrganisationRoute(currentUserType, { origin });
    case "Group description updated":
    case "Group project added":
    case "Group project removed":
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return getProjectManageProjectGroupRoute(
        objectUuid,
        `${relatedProjectUuid ? `projectUuid=${relatedProjectUuid}&` : ""}origin=${origin}`
      );
    case "Activity created":
    case "Activity status updated":
    case "Activity published":
    case "Activity cancelled":
      return currentUserType === OrganisationTypeConstants.VERIFIER
        ? getActivityReviewDashboardTabRoute(objectUuid, "documents", `origin=${origin}`)
        : getActivityDashboardTabRoute(objectUuid, "documents", currentUserType, `origin=${origin}`);
    case "Document uploaded":
    case "Document updated":
    case "Document version updated":
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return getDocumentHistoryRoute(relatedProjectUuid!, objectUuid, currentUserType, { origin });
    case "Document version deleted":
    case "Activity deleted":
    case "Project deleted":
    case "New user invited":
    case "Activity review status updated":
    case "Activity review updated":
      return "";
    default:
      logError({ error: `No matching objectType for ${detail}` });
      return "";
  }
};
