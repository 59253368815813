import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { ProjectActivitiesConstants } from "../../../../../constants";
import {
  getActivityDefinitionVersionDetails,
  getActivityHistoryDetails,
  GetActivityHistoryDetailsResponse,
  getGroupDetails,
  GetGroupDetailsResponse,
  getProjectDetails,
  GetProjectDetailsResponse,
} from "../../../../../service/query";
import { Status } from "../../../../../service/Shared";
import { useAuth } from "../../../../../useAuth";
import { ERROR_ACCESS_DENIED } from "../../../../../utils";
import { getDashboardRoute } from "../../../../../utils/routes";
import { ActivityData, ActivityDefinition, ActivityDefinitionInfo, ResponseDocumentData } from "../types";
import { populateParentLinks } from "../utils";

export const useFetchActivityHistory: (
  setActivityCompletionPercentage: React.Dispatch<React.SetStateAction<number>>,
  setActivityStatus: React.Dispatch<React.SetStateAction<ProjectActivitiesConstants | undefined>>,
  setActivityData: React.Dispatch<React.SetStateAction<GetActivityHistoryDetailsResponse | undefined>>,
  setActivityDefinition: React.Dispatch<React.SetStateAction<ActivityDefinition>>,
  setActivityDefinitionInfo: React.Dispatch<React.SetStateAction<ActivityDefinitionInfo | undefined>>,
  setProjectDetails: React.Dispatch<React.SetStateAction<GetProjectDetailsResponse | undefined>>,
  setGroupDetails: React.Dispatch<React.SetStateAction<GetGroupDetailsResponse | undefined>>,
  activityHistoryUuid?: string
) => () => Promise<{
  activityData: ActivityData;
  activityDefinition: ActivityDefinition;
  projectDetails: GetProjectDetailsResponse | undefined;
  groupDetails: GetGroupDetailsResponse | undefined;
  activityDocuments: ResponseDocumentData[];
  activityUuid: string;
  activityStatus: ProjectActivitiesConstants;
  activityCompletionPercentage: number;
  rowVersion: number;
}> = (
  setActivityCompletionPercentage,
  setActivityStatus,
  setActivityData,
  setActivityDefinition,
  setActivityDefinitionInfo,
  setProjectDetails,
  setGroupDetails,
  activityHistoryUuid
) => {
  const navigate = useNavigate();
  const { currentUserType } = useAuth();

  return useCallback(async () => {
    if (!activityHistoryUuid) throw new Error("Missing 'activityHistoryUuid' parameter");

    const activityHistoryRes = await getActivityHistoryDetails({ activityHistoryUuid });

    if (activityHistoryRes.status !== Status.Success && activityHistoryRes.errors) {
      if (activityHistoryRes.errors?.find((e) => e.message === ERROR_ACCESS_DENIED)) {
        if (window?.history?.state?.idx === 0) {
          navigate(getDashboardRoute(currentUserType), { replace: true });
        } else {
          navigate(-1);
        }

        throw new Error("Not enough permissions to view this activity");
      }
    }

    if (activityHistoryRes.data == null) {
      throw new Error(`"getActivityHistoryDetails" returned no data for uuid ${activityHistoryUuid}`);
    }

    const activityDefinitionRes = await getActivityDefinitionVersionDetails({
      activityDefinitionVersionUuid: activityHistoryRes.data.activity.activityDefinitionVersion.uuid,
      activityHistoryUuid,
    });

    if (activityDefinitionRes.status !== Status.Success || activityDefinitionRes.data == null) {
      throw new Error(
        `"getActivityDefinitionVersionDetails" returned no data for uuid ${activityHistoryRes.data.activity.activityDefinitionVersion.uuid}`
      );
    }

    let projectDetailsData;

    if (activityHistoryRes?.data?.activity?.project?.uuid) {
      const projectDetailsRes = await getProjectDetails({
        projectUuid: activityHistoryRes.data.activity.project.uuid,
        projectType: null,
        projectExternalReference: null,
      });

      if (projectDetailsRes.status === Status.Success && projectDetailsRes.data != null) {
        projectDetailsData = projectDetailsRes.data;
      } else {
        // Throw error; should result in a toast being shown & an error being logged
        throw new Error(
          `"getProjectDetails" returned no data for uuid ${activityHistoryRes.data.activity.project.uuid}`
        );
      }
    }

    let groupDetailsData;

    if (activityHistoryRes?.data?.activity?.group?.uuid) {
      const groupDetailsRes = await getGroupDetails({
        projectGroupUuid: activityHistoryRes.data.activity.group.uuid,
      });

      if (groupDetailsRes.status === Status.Success && groupDetailsRes.data != null) {
        groupDetailsData = groupDetailsRes.data;
      } else {
        // Throw error; should result in a toast being shown & an error being logged
        throw new Error(
          `"getGroupDetails" returned no data for uuid ${activityHistoryRes?.data?.activity?.group?.uuid}`
        );
      }
    }

    const { definition, activityDefinition, versionNumber } = activityDefinitionRes.data;

    populateParentLinks(definition);
    setActivityDefinition(definition);

    setActivityDefinitionInfo({
      displayName: activityDefinition.displayName,
      versionNumber,
    });

    setActivityCompletionPercentage(activityHistoryRes.data.completionPercentage || 0);
    setActivityStatus(activityHistoryRes.data.activity.status);
    setActivityData(activityHistoryRes.data);
    setProjectDetails(projectDetailsData);
    setGroupDetails(groupDetailsData);

    return {
      activityDefinition: definition,
      activityData: activityHistoryRes.data.data,
      projectDetails: projectDetailsData,
      groupDetails: groupDetailsData,
      rowVersion: activityHistoryRes.data.rowVersion,
      activityDocuments: activityHistoryRes.data.documents,
      activityStatus: activityHistoryRes.data.activity.status,
      activityCompletionPercentage: activityHistoryRes.data.completionPercentage || 0,
      activityUuid: activityHistoryRes.data.activity.uuid,
    };
  }, [activityHistoryUuid]);
};
