import { DeveloperProjectCardWithTabsTemplate } from "../../../../templates";
import { useProject } from "./useProject";

const Project = (): JSX.Element => {
  const { projectDetails, handleBackClick, tabsList, backButtonText } = useProject();

  return (
    <DeveloperProjectCardWithTabsTemplate
      activeElement="Projects"
      pageTitle={projectDetails?.displayName}
      projectCode={projectDetails?.standard.displayName}
      projectVerifier={projectDetails?.validator?.displayName}
      projectStatus={projectDetails?.status}
      projectUuid={projectDetails?.uuid}
      chartData={{
        "Pending issuance units (PIUs)": projectDetails?.cachedPiuQuantity ?? 0,
        "Verified carbon units (VCUs)": projectDetails?.cachedVcuQuantity ?? 0,
      }}
      tabsList={tabsList}
      backButtonText={backButtonText}
      handleBackClick={handleBackClick}
    />
  );
};

export default Project;
