import "./DiscussionThreadBody.css";

import { Dispatch, SetStateAction } from "react";

import { InformationIcon } from "../../../../../assets";
import { ErrorBox } from "../../../../cards";
import { Button, TextArea } from "../../../../forms";
import { Divider } from "../../../../general";
import { Column, Row } from "../../../../layout";
import { Message, Thread } from "../../../models";
import { discussionInfoBox } from "../DiscussionInfoBox";
import { DiscussionMessage } from "../DiscussionMessage";
import { NewThreadInformation } from "../NewDiscussion/useNewDiscussion";
import { useDiscussionThreadBody } from "./useDiscussionThreadBody";

interface DiscussionThreadBodyProps {
  threads: Thread[];
  setThreads: Dispatch<SetStateAction<Thread[]>>;
  firstMessagesInEachThread: Message[] | undefined;
  replies: Message[][];
  closeDiscussion: () => void;
  newThreadInformation: NewThreadInformation;
  externalUserType: string;
  internalTeamSelected: boolean;
  isForViewMode?: boolean;
}

export const DiscussionThreadBody = ({
  threads,
  setThreads,
  firstMessagesInEachThread,
  replies,
  closeDiscussion,
  newThreadInformation,
  externalUserType,
  internalTeamSelected,
  isForViewMode = false,
}: DiscussionThreadBodyProps): JSX.Element => {
  const {
    currentUserUuid,
    showNewDiscussionTextArea,
    setShowNewDiscussionTextArea,
    newDiscussionErrors,
    newDiscussion,
    setNewDiscussion,
    handleCloseNewDiscussionContainer,
    handleSendDiscussion,
    replyErrors,
    showReplyTextArea,
    newReply,
    setNewReply,
    setShowReplyTextArea,
    handleCloseReplyContainer,
    handleSendReply,
    isExternalActivity,
    isHandleSendDiscussionLoading,
    isHandleSendReplyLoading,
  } = useDiscussionThreadBody(threads, setThreads, newThreadInformation, internalTeamSelected);

  return (
    <div className="DiscussionThreadBody">
      {((threads[0]?.resolved && !isForViewMode) || threads.length === 0) &&
        // eslint-disable-next-line no-nested-ternary
        (showNewDiscussionTextArea ? (
          <div className="NewDiscussionContainer">
            {newDiscussionErrors && newDiscussionErrors.length > 0 && (
              <Row spacingV="ml" justify="center">
                <Column span={12}>
                  <ErrorBox
                    title="Please solve the below issues before saving"
                    text={newDiscussionErrors.map((error) => error.message)}
                  />
                </Column>
              </Row>
            )}
            <div className="VisibilityInfo">
              <InformationIcon width={16} height={16} />
              <p>
                {internalTeamSelected
                  ? "Visible to internal team only"
                  : `Discussing with the ${externalUserType.toLowerCase()}`}
              </p>
            </div>
            <TextArea
              name="NewDiscussion"
              displayCharacterCount
              maxLength={500}
              value={newDiscussion}
              onChange={setNewDiscussion}
            />
            {discussionInfoBox({ externalUserType, internalTeamSelected })}
            <div className="NewDiscussionMessageActions">
              <Button text="Send discussion" onClick={handleSendDiscussion} isLoading={isHandleSendDiscussionLoading} />
              <Button text="Cancel" variant="secondary" onClick={handleCloseNewDiscussionContainer} />
            </div>
          </div>
        ) : isForViewMode || isExternalActivity ? (
          <p>{internalTeamSelected ? "No internal discussions available" : "No external discussions available"}</p>
        ) : (
          <div>
            <Button text="Start new discussion" onClick={() => setShowNewDiscussionTextArea(true)} />
          </div>
        ))}
      {firstMessagesInEachThread &&
        !!firstMessagesInEachThread.length &&
        firstMessagesInEachThread.map((firstMessageInThread, idx) => (
          <div key={firstMessageInThread.uuid} className={`${threads[idx].resolved ? "ResolvedContainer" : ""}`}>
            {threads[idx].resolved && (
              <div className="ResolvedHeader">
                <p className="body1">Resolved thread</p>
                <Divider type="horizontal" />
              </div>
            )}
            <div className="DiscussionFirstMessageContainer">
              <DiscussionMessage
                threads={threads}
                setThreads={setThreads}
                closeDiscussion={closeDiscussion}
                threadUuid={threads[idx].uuid}
                message={firstMessageInThread}
                showGreenResolveButton={threads[idx].resolved}
                showGreyResolveButton={!threads[idx].resolved}
                createdByCurrentUser={
                  !threads[idx].resolved && currentUserUuid === firstMessageInThread.createdByUserUuid
                }
                isForViewMode={isForViewMode}
                isFirstMessageInThread
                internalTeamSelected={internalTeamSelected}
              />
            </div>
            {!!replies[idx].length && (
              <div className="ReplyContainer">
                <Divider type="vertical" thickness={1} />
                <div className="DiscussionMessageReplies">
                  {replies[idx].map((message) => (
                    <DiscussionMessage
                      threads={threads}
                      setThreads={setThreads}
                      closeDiscussion={closeDiscussion}
                      threadUuid={threads[idx].uuid}
                      message={message}
                      key={message.uuid}
                      createdByCurrentUser={!threads[idx].resolved && currentUserUuid === message.createdByUserUuid}
                      isForViewMode={isForViewMode}
                      internalTeamSelected={internalTeamSelected}
                    />
                  ))}
                </div>
              </div>
            )}
            {idx === 0 &&
              !!threads.length &&
              (showReplyTextArea ? (
                <div className="NewReplyContainer">
                  {replyErrors && replyErrors.length > 0 && (
                    <Row spacingV="ml" justify="center">
                      <Column span={12}>
                        <ErrorBox
                          title="Please solve the below issues before saving"
                          text={replyErrors.map((error) => error.message)}
                        />
                      </Column>
                    </Row>
                  )}
                  <div className="VisibilityInfo">
                    <InformationIcon width={16} height={16} />
                    <p>
                      {internalTeamSelected
                        ? "Visible to internal team only"
                        : `Discussing with the ${externalUserType.toLowerCase()}`}
                    </p>
                  </div>
                  <TextArea
                    name="NewReply"
                    displayCharacterCount
                    maxLength={500}
                    value={newReply}
                    onChange={setNewReply}
                  />
                  {discussionInfoBox({ externalUserType, internalTeamSelected })}
                  <div className="NewReplyMessageActions">
                    <Button
                      text="Send reply"
                      onClick={() => handleSendReply(threads[idx].uuid)}
                      isLoading={isHandleSendReplyLoading}
                    />
                    <Button text="Cancel" variant="secondary" onClick={() => handleCloseReplyContainer()} />
                  </div>
                </div>
              ) : (
                !isForViewMode &&
                (!isExternalActivity || internalTeamSelected) &&
                threads.find((t) => t.resolved === false) && (
                  <div>
                    <Button text="Reply" onClick={() => setShowReplyTextArea(true)} />
                  </div>
                )
              ))}
          </div>
        ))}
    </div>
  );
};
