import { Dispatch, SetStateAction, useContext } from "react";
import { useParams } from "react-router-dom";

import { ActivityPageParams } from "../../../../models";
import { GetActivityDetailsResponse, SearchActivityHistoryResponse } from "../../../../service/query";
import { ActivityReviewDashboardContext } from "../../../verifier/activity-review-dashboard";
import { ActivityDashboardContext } from "../../activities-dashboard";

interface useActivityDashboardContextSwitcherReturnData {
  activityUuid?: string;
  activityDetails?: GetActivityDetailsResponse;
  hasManageProjectActivityPermission?: boolean;
  hasManageProjectActivityReviewPermission?: boolean;
  hasWriteProjectActivityReviewPermission?: boolean;
  canReviewActivity?: boolean;
  showDetailsPanel: boolean;
  isActivityReview: boolean;
  currentActivityReviewUuid?: string;
  currentOrganisationUuid: string | undefined;
  queryParams?: string;
  isExternalActivity: () => boolean;
  setShowVersionConflictModal?: Dispatch<SetStateAction<boolean>>;
  setNewVersionActivityHistory?: Dispatch<SetStateAction<SearchActivityHistoryResponse | undefined>>;
  setDraftActivityHistoryUuid?: Dispatch<SetStateAction<string | undefined>>;
  setShowDetailsPanel: Dispatch<SetStateAction<boolean>>;
  onCloseDetailsPanel: () => void;
  refreshUnreadDiscussionsTabIndicator: () => Promise<void>;
  refreshResolvedDiscussionsKPI: () => Promise<void>;
}
/**
 * Used to reuse the tabs in route / shared / activity-dashboard in the activity-review-dashboard
 * @returns activityUuid & activityDetails either from ActivityDashboardContext or ActivityReviewDashboardContext
 * depending on where the tab is being used
 */
export const useActivityDashboardContextSwitcher = (): useActivityDashboardContextSwitcherReturnData => {
  const { activityUuid: routeActivityUuid } = useParams<ActivityPageParams>(); // Check for activityUuid in URL
  if (routeActivityUuid !== undefined) {
    const {
      activityUuid,
      activityDetails,
      hasManageProjectActivityPermission,
      showDetailsPanel,
      queryParams,
      currentOrganisationUuid,
      isExternalActivity,
      setShowVersionConflictModal,
      setNewVersionActivityHistory,
      setDraftActivityHistoryUuid,
      setShowDetailsPanel,
      onCloseDetailsPanel,
      refreshUnreadDiscussionsTabIndicator,
      refreshResolvedDiscussionsKPI,
    } = useContext(ActivityDashboardContext);
    return {
      activityUuid,
      activityDetails,
      hasManageProjectActivityPermission,
      showDetailsPanel,
      isActivityReview: false,
      queryParams,
      currentOrganisationUuid,
      isExternalActivity,
      setShowVersionConflictModal,
      setNewVersionActivityHistory,
      setDraftActivityHistoryUuid,
      setShowDetailsPanel,
      onCloseDetailsPanel,
      refreshUnreadDiscussionsTabIndicator,
      refreshResolvedDiscussionsKPI,
    };
  }

  const {
    activityReviewActivityUuid,
    activityDetails,
    showDetailsPanel,
    currentActivityReviewUuid,
    queryParams,
    hasManageProjectActivityReviewPermission,
    hasWriteProjectActivityReviewPermission,
    canReviewActivity,
    currentOrganisationUuid,
    setShowDetailsPanel,
    onCloseDetailsPanel,
    refreshUnreadDiscussionsTabIndicator,
    refreshResolvedDiscussionsKPI,
  } = useContext(ActivityReviewDashboardContext);
  return {
    activityUuid: activityReviewActivityUuid,
    activityDetails,
    showDetailsPanel,
    currentActivityReviewUuid,
    queryParams,
    hasManageProjectActivityReviewPermission,
    hasWriteProjectActivityReviewPermission,
    canReviewActivity,
    isActivityReview: true,
    currentOrganisationUuid,
    isExternalActivity: () => false,
    setShowDetailsPanel,
    onCloseDetailsPanel,
    refreshUnreadDiscussionsTabIndicator,
    refreshResolvedDiscussionsKPI,
  };
};
