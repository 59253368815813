import "./CancelReviewModal.css";

import { Button, Column, Modal, Row } from "../../../../widget";
import { useCancelReviewModal } from "./useCancelReviewModal";

interface CancelReviewModalProps {
  show: boolean;
  onClose: () => void;
  activityUuid: string;
  activityStatus: string;
  activityRowVersion: number;
  activityReviewUuid?: string;
  activityReviewStatus?: string;
  activityReviewRowVersion?: number;
  activityAssessmentUuid: string;
  activityAssessmentStatus: string;
  activityAssessmentRowVersion: number;
}

export const CancelReviewModal = ({
  show,
  onClose,
  activityUuid,
  activityStatus,
  activityRowVersion,
  activityReviewUuid,
  activityReviewStatus,
  activityReviewRowVersion,
  activityAssessmentUuid,
  activityAssessmentStatus,
  activityAssessmentRowVersion,
}: CancelReviewModalProps): JSX.Element => {
  const { isLoading, onConfirmClick } = useCancelReviewModal({
    onClose,
    activityUuid,
    activityStatus,
    activityRowVersion,
    activityReviewUuid,
    activityReviewStatus,
    activityReviewRowVersion,
    activityAssessmentUuid,
    activityAssessmentStatus,
    activityAssessmentRowVersion,
  });

  return (
    <Modal show={show} title="Cancel activity review" onClose={onClose}>
      <Row spacingV="ll" justify="center">
        <Column span={10}>
          <span className="body1">
            Are you sure you want to cancel the activity review? This action is permanent and will remove the activity
            from your workflow and return it to the Developer.
            <br />
            <br />
            Do you wish to proceed?
          </span>
        </Column>
      </Row>
      <Row justify="center">
        <Column span={6}>
          <div className="CancelReviewModalButtons">
            <Button text="Continue" onClick={onConfirmClick} isLoading={isLoading} />
            <Button variant="secondary" text="Cancel" onClick={onClose} />
          </div>
        </Column>
      </Row>
    </Modal>
  );
};
