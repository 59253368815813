import "./BasicDetailsSection.css";

import { Dispatch, SetStateAction } from "react";

import { ProjectStatusConstants } from "../../../../../../../constants";
import { BasicDetailsData, SelectData } from "../../../../../../../models";
import { GetProjectDetailsResponse } from "../../../../../../../service/query";
import { getCountryNameByCode } from "../../../../../../../utils";
import { Button, Column, Map, ReadonlyText, Row, Select, TextInput } from "../../../../../../../widget";
import { useBasicDetailsSection } from "./useBasicDetailsSection";

interface BasicDetailsSectionProps {
  projectDetails: GetProjectDetailsResponse;
  basicDetailsData: BasicDetailsData;
  isReadOnly: boolean;
  setBasicDetailsData: Dispatch<SetStateAction<BasicDetailsData>>;
  validatorData: SelectData;
  showVVBDropdown: boolean;
  setShowVVBDropdown: Dispatch<SetStateAction<boolean>>;
}

export const BasicDetailsSection = ({
  projectDetails,
  basicDetailsData,
  isReadOnly,
  setBasicDetailsData,
  validatorData,
  showVVBDropdown,
  setShowVVBDropdown,
}: BasicDetailsSectionProps): JSX.Element => {
  const { mapCoordinates, totalProjectArea } = useBasicDetailsSection(projectDetails);

  return (
    <Row>
      <Column span={8}>
        <Row spacingV="ll">
          <Column span={6}>
            {!isReadOnly ? (
              <TextInput
                label="Display name"
                name="Display name"
                type="text"
                value={basicDetailsData.displayName}
                onChange={(displayNameNewValue) =>
                  setBasicDetailsData({
                    ...basicDetailsData,
                    displayName: displayNameNewValue,
                  })
                }
              />
            ) : (
              <ReadonlyText label="Display name" value={basicDetailsData.displayName} />
            )}
          </Column>
        </Row>
        {/* Note - we shouldn't show information "from the registry" for projects created in Kana */}
        {basicDetailsData.projectStatus !== ProjectStatusConstants.DRAFT &&
        basicDetailsData.projectStatus !== ProjectStatusConstants.PRE_DEVELOPMENT ? (
          <Row spacingV="ll">
            <Column span={6}>
              <ReadonlyText label="Registry name" value={projectDetails.name} />
            </Column>
            <Column span={6}>
              <ReadonlyText label="Registry ID" value={projectDetails?.projectReference?.externalReference} />
            </Column>
          </Row>
        ) : (
          <Row spacingV="ll">
            <Column span={6}>
              <ReadonlyText label="Registry name" value="Not available" noDataAvailable />
            </Column>
            <Column span={6}>
              <ReadonlyText label="Registry ID" value="Not available" noDataAvailable />
            </Column>
          </Row>
        )}
        <Row spacingV="ll">
          <Column span={6}>
            <ReadonlyText label="Project type" value={projectDetails.projectType.displayName} />
          </Column>
          <Column span={6}>
            <ReadonlyText
              label="Total area"
              value={totalProjectArea || "Not available"}
              noDataAvailable={!totalProjectArea}
            />
          </Column>
        </Row>
        <Row spacingV="ll">
          <Column span={6}>
            <ReadonlyText
              label="Grid reference"
              value={projectDetails.locationGridReference || "No grid reference available"}
              noDataAvailable={!projectDetails.locationGridReference}
            />
          </Column>
          <Column span={6}>
            <ReadonlyText
              label="County / Region / State"
              value={projectDetails.addressRegion || "No location available"}
              noDataAvailable={!projectDetails.addressRegion}
            />
          </Column>
        </Row>
        <Row>
          <Column span={6}>
            <ReadonlyText
              label="Country"
              value={getCountryNameByCode(projectDetails.addressCountryCode) || "No location available"}
              noDataAvailable={!projectDetails.addressCountryCode}
            />
          </Column>
          <Column span={6}>
            {!showVVBDropdown && (
              <>
                <Row spacingV="m">
                  <Column span={12}>
                    <h3>Validation & verification body (VVB)</h3>
                  </Column>
                </Row>
                <Row align="center" className="BasicDetailsSection_ValidatorContainer">
                  {projectDetails.validator?.files && projectDetails.validator?.files.length > 0 && (
                    <div className="ImageContainer">
                      <img src={projectDetails?.validator.files[0].file.url} alt="ValidatorLogo" />
                    </div>
                  )}
                  <Column span={8}>
                    <p className="ValidatorNameInDeveloperRoute body1">
                      {projectDetails.validator?.displayName || "No validator selected for this project"}
                      {!isReadOnly && (
                        <Button
                          text="Edit"
                          size="small"
                          variant="secondary"
                          textUnderline
                          hasNoPadding
                          onClick={() => setShowVVBDropdown(true)}
                        />
                      )}
                    </p>
                  </Column>
                </Row>
              </>
            )}
            {showVVBDropdown && !!validatorData.length && (
              <Row>
                <Column span={10}>
                  <Select
                    label="Validation & verification body (VVB)"
                    data={validatorData}
                    value={basicDetailsData.validatorUuid}
                    onChange={(validatorUuidNewValue) =>
                      setBasicDetailsData({
                        ...basicDetailsData,
                        validatorUuid: validatorUuidNewValue,
                      })
                    }
                  />
                </Column>
              </Row>
            )}
          </Column>
        </Row>
      </Column>
      <Column span={3}>
        <Row spacingV="ml">
          <Column span={12}>
            <h3>Project location</h3>
          </Column>
        </Row>
        {mapCoordinates && mapCoordinates.length ? (
          <Map height={320} coordinates={mapCoordinates} />
        ) : (
          <p className="NoData">This project has not specified a location</p>
        )}
      </Column>
    </Row>
  );
};
