import { OrganisationTypeConstants } from "../../../../constants";
import { Column, CursorDataGrid, Row } from "../../../../widget";
import { DetailsPanel } from "./components";
import { useActivityVersionHistoryTab } from "./useVersionHistory";

export const VersionHistory = (): JSX.Element => {
  const {
    columns,
    defaultSortingCriteria,
    dataIsLoading,
    currentActivityReviewUuid,
    onChange,
    selectedActivityHistoryUuid,
    showDetailsPanel,
    currentUserType,
    onCloseDetailsPanel,
    canEdit,
    isActivityReview,
  } = useActivityVersionHistoryTab();

  return (
    <>
      <div className="ProjectTabSection">
        <Row spacingV="ll">
          <Column span={12}>
            <h2>
              {currentUserType !== OrganisationTypeConstants.VERIFIER ? "Version History" : "Submitted version history"}
            </h2>
          </Column>
        </Row>
        <CursorDataGrid
          columns={columns}
          clientSide={false}
          filterable
          onChange={onChange}
          data={[]}
          noDataMessage="No activity history to display"
          dataIsLoading={dataIsLoading}
          defaultSortingCriteria={defaultSortingCriteria}
        />
      </div>
      {selectedActivityHistoryUuid && (
        <DetailsPanel
          show={showDetailsPanel}
          currentUserType={currentUserType}
          currentActivityReviewUuid={currentActivityReviewUuid}
          onClose={onCloseDetailsPanel}
          isActivityReview={isActivityReview}
          activityHistoryUuid={selectedActivityHistoryUuid}
          canEdit={canEdit}
        />
      )}
    </>
  );
};
