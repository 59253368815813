import "./DetailsPanel.css";

import { OrganisationTypeConstants, UserType } from "../../../../../../constants";
import { VersionConflictModal } from "../../../../../../templates/DeveloperProjectCardWithTabsTemplate/components";
import { getFormattedDate } from "../../../../../../utils";
import { Button, Column, CursorDataGrid, Divider, DownloadDropdown, FlyoutPanel, Row } from "../../../../../../widget";
import { DownloadActivityFilesModal } from "../../../../../developer/components";
import { useDetailsPanel } from "./useDetailsPanel";

interface DetailsPanelProps {
  show: boolean;
  activityHistoryUuid: string;
  currentActivityReviewUuid?: string;
  isActivityReview: boolean;
  canEdit: boolean;
  currentUserType: UserType;
  onClose: () => void;
}

export const DetailsPanel = ({
  show,
  onClose,
  currentActivityReviewUuid,
  activityHistoryUuid,
  isActivityReview,
  canEdit,
  currentUserType,
}: DetailsPanelProps): JSX.Element => {
  const {
    activityData,
    onView,
    onEdit,
    columns,
    defaultSortingCriteria,
    dataIsLoading,
    onChange,
    setShowVersionConflictModal,
    showVersionConflictModal,
    showDownloadActivityFilesModal,
    setShowDownloadActivityFilesModal,
    newVersionActivityHistory,
    draftActivityHistoryUuid,
  } = useDetailsPanel(activityHistoryUuid, isActivityReview, currentActivityReviewUuid);

  // Lock activity logic
  const showEditButton = (): JSX.Element | undefined => {
    if (canEdit) {
      // Show edit for dev
      if (
        currentUserType === OrganisationTypeConstants.DEVELOPER &&
        activityData?.isCurrent &&
        !activityData?.isUnderReview
      )
        return <Button text="Edit" variant="tertiary" onClick={onEdit} />;

      // Show edit for vvb (logic for checking edit for vvb is in canEdit from context)
      if (currentUserType === OrganisationTypeConstants.VERIFIER)
        return <Button text="Edit" variant="tertiary" onClick={onEdit} />;
    }
    return undefined;
  };

  return (
    <FlyoutPanel
      show={show}
      onClose={onClose}
      size="Large"
      header={activityData ? `Version ${activityData.versionNumber} ${activityData.isCurrent ? "(Latest)" : ""}` : ""}
      buttonsChildren={
        activityData && (
          <>
            <button className="BtnLink" onClick={onView}>
              View
            </button>
            {showEditButton()}
            <DownloadDropdown
              showDownloadActivityFilesModal={showDownloadActivityFilesModal}
              setShowDownloadActivityFilesModal={setShowDownloadActivityFilesModal}
              activityHistoryUuid={activityData.uuid}
              currentOnly={activityData.isCurrent}
            />
          </>
        )
      }
      contentChildren={
        activityData && (
          <div className="ActivityDetailsPanelContent">
            <div className="ActivityDetailsPanelContentHeader">
              <Row spacingV="ll">
                <Column span={2}>
                  <h3>Author</h3>
                  <p className="body2">{activityData.createdByUser.fullName}</p>
                </Column>
                <Column span={2}>
                  <h3>Date created</h3>
                  <p className="body2">{getFormattedDate(activityData.createdAt)}</p>
                </Column>
                <Column span={2}>
                  <h3>Change</h3>
                  <p className="body2">{activityData.changeSummary}</p>
                </Column>
                <Column span={2}>
                  <h3>Variant</h3>
                  <p className="body2">{activityData.activity.variant ?? "N/A"}</p>
                </Column>
              </Row>
            </div>
            <Divider type="horizontal" />
            <div className="ActivityDetailsPanelContentBody">
              <h3>Activity documents</h3>
              <CursorDataGrid
                columns={columns}
                clientSide={false}
                filterable
                onChange={onChange}
                data={[]}
                noDataMessage="No activity documents to display"
                dataIsLoading={dataIsLoading}
                defaultSortingCriteria={defaultSortingCriteria}
              />
            </div>
            <VersionConflictModal
              showVersionConflictModal={showVersionConflictModal}
              draftActivityHistoryUuid={draftActivityHistoryUuid}
              closeModal={() => setShowVersionConflictModal(false)}
              newVersionActivityHistory={newVersionActivityHistory}
            />

            {showDownloadActivityFilesModal && (
              <DownloadActivityFilesModal
                show={showDownloadActivityFilesModal}
                onClose={() => setShowDownloadActivityFilesModal(false)}
              />
            )}
          </div>
        )
      }
    />
  );
};
