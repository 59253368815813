import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";

import { ColorConstants } from "../../../constants";

let hasChartJsGlobalConfigurationBeenSet = false;

export const maybeSetGlobalChartJsConfiguration = (): void => {
  if (hasChartJsGlobalConfigurationBeenSet) return;

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    PointElement,
    LineElement
  );

  ChartJS.defaults.responsive = true;
  ChartJS.defaults.normalized = true;
  ChartJS.defaults.font.size = 12;
  ChartJS.defaults.font.family = "'Space Grotesk', sans-serif";
  ChartJS.defaults.color = ColorConstants.GREY;
  ChartJS.defaults.backgroundColor = ColorConstants.BLUE;
  ChartJS.defaults.plugins.tooltip.backgroundColor = ColorConstants.WHITE;
  ChartJS.defaults.plugins.tooltip.titleColor = ColorConstants.GREY;
  ChartJS.defaults.plugins.tooltip.bodyColor = ColorConstants.GREY;
  ChartJS.defaults.plugins.tooltip.cornerRadius = 0;
  ChartJS.defaults.plugins.tooltip.borderColor = ColorConstants.GREY_20;
  ChartJS.defaults.plugins.tooltip.borderWidth = 1;
  ChartJS.defaults.plugins.tooltip.padding = {
    top: 16,
    left: 16,
    right: 16,
    bottom: 16,
  };
  ChartJS.defaults.plugins.tooltip.caretSize = 0;
  ChartJS.defaults.plugins.tooltip.xAlign = "center";
  ChartJS.defaults.plugins.tooltip.yAlign = "top";

  hasChartJsGlobalConfigurationBeenSet = true;
};

const getColours = (dataPointCount: number, colours: string[]): string[] => {
  const colourCount = colours.length;
  const ret = Array<string>(dataPointCount);
  for (let i = 0; i < dataPointCount; i++) {
    ret[i] = colours[i % colourCount];
  }
  return ret;
};

const getGradientColours = (dataPointCount: number, colours: string[][]): string[][] => {
  const colourCount = colours.length;
  const ret = Array<string[]>(dataPointCount);
  for (let i = 0; i < dataPointCount; i++) {
    ret[i] = colours[i % colourCount];
  }
  return ret;
};

export const getChartColours = (
  dataPointCount: number,
  colorConstants: string[] = ColorConstants.CHART_COLORS
): string[] => {
  return getColours(dataPointCount, colorConstants);
};

export const getChartHighlightColours = (
  dataPointCount: number,
  colorConstants: string[] = ColorConstants.CHART_COLORS
): string[] => {
  return getColours(dataPointCount, colorConstants);
};

export const getChartGradientColours = (dataPointCount: number): string[][] => {
  return getGradientColours(dataPointCount, ColorConstants.CHART_GRADIENT_COLORS);
};

export const getChartGradientHighlightColours = (dataPointCount: number): string[] => {
  return getColours(dataPointCount, ColorConstants.CHART_GRADIENT_HIGHLIGHT_COLORS);
};
