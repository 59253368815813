import "./ImageButton.css";

import { ReactNode } from "react";
import { Link } from "react-router-dom";

interface ButtonProps {
  icon: ReactNode;
  linkText?: string;
  route?: string;
  onClick?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state?: any;
}

export const ImageButton = ({ icon, linkText, route, onClick, state }: ButtonProps): JSX.Element => {
  if (route != null && onClick == null)
    return (
      <Link className="ImageButton" to={route} state={state}>
        <span>{linkText}</span>
        {icon}
      </Link>
    );
  return (
    <a href={route} onClick={onClick} className="ImageButton">
      {icon}
    </a>
  );
};
