import { Dispatch, SetStateAction, useState } from "react";

import {
  ActivityReviewTypeConstants,
  ProjectActivitiesConstants,
  ProjectActivityReviewsConstants,
} from "../../../../constants";
import { createActivityReview, transitionActivity } from "../../../../service/activity";
import { Status } from "../../../../service/Shared";
import { getErrorMessageFromCode } from "../../../../service/ValidationErrorFormatter";
import { useIsLoadingWrapper } from "../../../../utils";
import { Toast } from "../../../../widget";

/* eslint-disable unused-imports/no-unused-vars */
interface useCompleteAssessmentModalProps {
  activityUuid: string;
  activityStatus: string;
  activityRowVersion: number;
  onClose: () => void;
  activityAssessmentUuid: string;
  activityAssessmentStatus: string;
  activityAssessmentRowVersion: number;
  refreshActivityReviews?: boolean;
  setRefreshActivityReviews?: Dispatch<SetStateAction<boolean>>;
  exitActivityReviewWizard?: () => void;
}
interface useCompleteAssessmentModalReturnData {
  isLoading: boolean;
  onConfirmClick: () => void;
}

export const useCompleteAssessmentModal = ({
  activityUuid,
  activityStatus,
  activityRowVersion,
  onClose,
  activityAssessmentUuid,
  activityAssessmentStatus,
  activityAssessmentRowVersion,
  refreshActivityReviews,
  setRefreshActivityReviews,
  exitActivityReviewWizard,
}: useCompleteAssessmentModalProps): useCompleteAssessmentModalReturnData => {
  const [isLoading, setIsLoading] = useState(false);

  const onConfirmClick = useIsLoadingWrapper(async (): Promise<void> => {
    const transitionActivityRes = await transitionActivity({
      activity: {
        uuid: activityUuid,
        rowVersion: activityRowVersion,
        toStatus: ProjectActivitiesConstants.STATUS_ASSESSMENT_AWAITING_REVIEW,
        fromStatus: activityStatus,
        publishExternalMessages: true,
        publishInternalMessages: false,
      },
      activityReviews: [
        {
          activityReviewUuid: activityAssessmentUuid,
          rowVersion: activityAssessmentRowVersion,
          fromStatus: activityAssessmentStatus,
          toStatus: ProjectActivityReviewsConstants.STATUS_IN_REVIEW,
        },
      ],
    });

    if (transitionActivityRes.status === Status.Success) {
      // Create 2nd review on activity
      const createActivityReviewRes = await createActivityReview({
        activityUuid,
        activityReviewType: ActivityReviewTypeConstants.REVIEW,
        assignedToUserUuids: [],
        deadline: null,
      });

      if (createActivityReviewRes.status === Status.Success) {
        Toast.success({ message: "Activity assessment submitted" });
        // If called from dashboard refresh context
        if (refreshActivityReviews !== undefined && setRefreshActivityReviews !== undefined) {
          setRefreshActivityReviews(!refreshActivityReviews);
        }

        // If called from wizard, call this (navigate)
        if (exitActivityReviewWizard !== undefined) exitActivityReviewWizard();

        onClose();
      } else if (createActivityReviewRes.errors && createActivityReviewRes.errors.length > 0) {
        Toast.error({ message: getErrorMessageFromCode(createActivityReviewRes.errors[0].message) });
      }
    } else if (transitionActivityRes.errors && transitionActivityRes.errors.length > 0) {
      Toast.error({ message: getErrorMessageFromCode(transitionActivityRes.errors[0].message) });
    }
  }, setIsLoading);

  return {
    isLoading,
    onConfirmClick,
  };
};
