import "./InformationBox.css";

import { ReactNode } from "react";

import { AlertIcon, InformationIcon, RocketLargeCoralIcon, SuccessIcon } from "../../../assets";
import { logError } from "../../../service/error";
import { capitalize } from "../../../utils";

type InformationBoxVariant = "success" | "alert" | "info" | "pilotProject";
type InformationBoxDirection = "column" | "row";
type InformationBoxCorners = "rounded" | "default";

export interface InformationBoxProps {
  variant: InformationBoxVariant;
  direction?: InformationBoxDirection;
  corners?: InformationBoxCorners;
  title?: string;
  children?: ReactNode;
  onEdit?: () => void;
}

const getIcon = (variant: InformationBoxVariant): JSX.Element | null => {
  switch (variant) {
    case "success":
      return <SuccessIcon />;
    case "alert":
      return <AlertIcon />;
    case "info":
      return <InformationIcon />;
    case "pilotProject":
      return <RocketLargeCoralIcon />;
    default:
      logError({ error: `No valid InformationBox could be found for variant: ${variant}` });
      return null;
  }
};

export const InformationBox = ({
  variant,
  direction = "column",
  corners = "default",
  title,
  children,
  onEdit,
}: InformationBoxProps): JSX.Element => {
  return (
    <div
      className={`InformationBox InformationBox${capitalize(variant)} InformationBox${capitalize(
        direction
      )} InformationBox${capitalize(corners)}Corners`}
    >
      {title ? (
        <>
          <div className={`InformationBoxHeaderArea ${children ? "" : "InformationBoxNoChildren"}`}>
            <div className="InformationBoxIconContainer">{getIcon(variant)}</div>
            <h3>{title}</h3>
          </div>
          {children && <div className="body2 InformationBoxTextArea">{children}</div>}
        </>
      ) : (
        <div className="InformationBoxWithoutHeaderArea">
          <div className="InformationBoxIconContainer">{getIcon(variant)}</div>
          {children}
        </div>
      )}
      {onEdit !== undefined && (
        <div
          className="EditButton"
          onClick={onEdit}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onEdit();
            }
          }}
          tabIndex={0}
          role="button"
        >
          <p className="body1">Edit</p>
        </div>
      )}
    </div>
  );
};
