import "./ActivityReviewWorkflow.css";

import { Dispatch, SetStateAction } from "react";

import { CheckCircleGreenIcon } from "../../../../../../assets";
import { ProjectActivitiesConstants } from "../../../../../../constants";
import { WorkflowActivityReview } from "../../../../../../models/shared/workflowActivityReviewType";
import { SearchActivityReviewsResponse } from "../../../../../../service/query";
import { StatusCard } from "../../../../../../templates/DeveloperProjectCardWithTabsTemplate/components";
import { ActivityReviewProgressIndicator, ActivityReviewWorkflowButtons } from "./components";

interface ActivityReviewWorkflowProps {
  type?: string;
  activityReviews?: SearchActivityReviewsResponse;
  workflowActivityReviews?: WorkflowActivityReview[];
  canManageActivityReview: boolean;
  refreshActivityReviews: boolean;
  setRefreshActivityReviews: Dispatch<SetStateAction<boolean>>;
}

const completeActivityMessage = (activityStatus: string): JSX.Element | undefined => {
  switch (activityStatus) {
    case ProjectActivitiesConstants.STATUS_SUBMITTED_TO_CODE:
      return (
        <div className="CompleteActivityMessage">
          <CheckCircleGreenIcon />
          <p className="body2">With Code</p>
        </div>
      );
    case ProjectActivitiesConstants.STATUS_SUBMITTED_TO_REGISTRY:
      return (
        <div className="CompleteActivityMessage">
          <CheckCircleGreenIcon />
          <p className="body2">With Registry</p>
        </div>
      );
    case ProjectActivitiesConstants.STATUS_APPROVED:
      return (
        <div className="CompleteActivityMessage">
          <CheckCircleGreenIcon />
          <p className="body2">Complete</p>
        </div>
      );
    default:
      return undefined;
  }
};

export const ActivityReviewWorkflow = ({
  type,
  activityReviews,
  workflowActivityReviews,
  canManageActivityReview,
  refreshActivityReviews,
  setRefreshActivityReviews,
}: ActivityReviewWorkflowProps): JSX.Element => {
  return (
    <div className="ActivityReviewWorkflowWrapper">
      <div className="ActivityReviewWorkflow">
        {workflowActivityReviews?.length && workflowActivityReviews[0]?.activityStatus && (
          <StatusCard status={workflowActivityReviews[0]?.activityStatus} isFixed />
        )}
        {workflowActivityReviews?.length && workflowActivityReviews?.length > 0 && (
          <div className="ActivitiesWrapper">
            <div className="Activities">
              {canManageActivityReview ? (
                workflowActivityReviews
                  .sort((a, b) => a.type.localeCompare(b.type))
                  .map((x) => <ActivityReviewProgressIndicator key={x.uuid} activityReview={x} />)
              ) : (
                <ActivityReviewProgressIndicator
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  activityReview={workflowActivityReviews.find((review) => review.type === type)!}
                />
              )}
            </div>
            {completeActivityMessage(workflowActivityReviews[0].activityStatus)}
            <ActivityReviewWorkflowButtons
              activityReviews={activityReviews}
              workflowActivityReviews={workflowActivityReviews}
              canManageActivityReview={canManageActivityReview}
              type={type}
              refreshActivityReviews={refreshActivityReviews}
              setRefreshActivityReviews={setRefreshActivityReviews}
            />
          </div>
        )}
      </div>
    </div>
  );
};
