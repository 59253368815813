import { Dispatch, SetStateAction, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { OrganisationTypeConstants, ProjectPermissionConstants } from "../../../constants";
import { ProjectContext } from "../../../route/shared/projects/project/ProjectContext";
import { useAuth } from "../../../useAuth";
import { getProjectAuditHistoryRoute } from "../../../utils/routes";
import { ProfileMenuItemType } from "../../../widget";

interface UseVerifierProjectCardWithTabsTemplateReturnData {
  quickActionsMenuOptionsList: ProfileMenuItemType[];
  setShowQuickActionMenu: Dispatch<SetStateAction<boolean>>;
  showQuickActionMenu: boolean;
  isProjectAssociatedWithCode: boolean;
  hasProjectPermission: (permission: string) => boolean;
}

export const useVerifierProjectCardWithTabsTemplate = (
  projectStatus: string | undefined
): UseVerifierProjectCardWithTabsTemplateReturnData => {
  const navigate = useNavigate();

  const { currentUserType } = useAuth();

  const { projectDetails, hasProjectPermission } = useContext(ProjectContext);

  const [showQuickActionMenu, setShowQuickActionMenu] = useState(false);

  const quickActionsMenuOptionsList: ProfileMenuItemType[] = [];

  if (
    currentUserType === OrganisationTypeConstants.VERIFIER &&
    hasProjectPermission(ProjectPermissionConstants.READ_PROJECT_AUDIT)
  ) {
    quickActionsMenuOptionsList.push({
      id: quickActionsMenuOptionsList.length + 1,
      value: "View audit history",
      action: () => navigate(getProjectAuditHistoryRoute(projectDetails?.uuid || "", currentUserType)),
    });
  }

  const isProjectAssociatedWithCode = useMemo(() => {
    return !(projectStatus === "Draft" || projectStatus === "Pre-development");
  }, [projectStatus]);

  return {
    quickActionsMenuOptionsList,
    setShowQuickActionMenu,
    showQuickActionMenu,
    isProjectAssociatedWithCode,
    hasProjectPermission,
  };
};
