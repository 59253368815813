import "./AssignActivityModal.css";

import { Dispatch, SetStateAction } from "react";

import { UserMinusLargeDarkIcon, UserPlusLargeDarkIcon } from "../../../../assets";
import { ServiceError } from "../../../../service/Shared";
import { ActionButtons, Column, DatePicker, ErrorBox, Modal, ReadonlyText, Row, Select } from "../../../../widget";
import { SelectedAssigneeItem, useAssignActivityModal } from "./useAssignActivityModal";

interface AssignActivityModalProps {
  show: boolean;
  closeModal: () => void;
  refreshActivities: boolean;
  setRefreshActivities: Dispatch<SetStateAction<boolean>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  activityReview?: any; // Selected object from searchActivityReviews
  modalTitle: string;
}

export const AssignActivityModal = ({
  show,
  closeModal,
  refreshActivities,
  setRefreshActivities,
  activityReview,
  modalTitle,
}: AssignActivityModalProps): JSX.Element => {
  const {
    relatedUsers,
    activityReviewType,
    deadline,
    setDeadline,
    setSelectedAssignees,
    handleCancel,
    handleSubmit,
    isHandleSubmitLoading,
    errors,
    selectedAssignees,
  } = useAssignActivityModal({
    closeModal,
    refreshActivities,
    setRefreshActivities,
    activityReview,
  });

  const setSelectedAssignee = (selectedItem: SelectedAssigneeItem): void => {
    setSelectedAssignees([...selectedAssignees.filter((x) => x.index !== selectedItem.index), selectedItem]);
  };

  const addAssigneeBox = (): void => {
    setSelectedAssignees([
      ...selectedAssignees,
      { index: selectedAssignees.length, userUuid: undefined, userName: undefined },
    ]);
  };

  const removeAssigneeBox = (currentIndex: number): void => {
    setSelectedAssignees(selectedAssignees.filter((x) => x.index !== currentIndex));
  };
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-empty-function, prettier/prettier
  const noop = () => { };
  return (
    <div className="AssignActivityModal">
      <Modal show={show} title={modalTitle} onClose={handleCancel}>
        {errors && errors.length > 0 && (
          <Row spacingV="ml" justify="center">
            <Column span={6}>
              <ErrorBox
                title="Please solve the below issues before saving"
                text={errors.map((error: ServiceError) => error.message)}
              />
            </Column>
          </Row>
        )}
        <form onSubmit={(e) => handleSubmit(e)}>
          <Row spacingV="ll" justify="center">
            <Column span={6} hasNoPadding>
              <ReadonlyText
                label="Project name"
                value={activityReview?.group?.displayName ?? activityReview?.project?.displayName}
              />
            </Column>
          </Row>
          <Row spacingV="ll" justify="center">
            <Column span={6} hasNoPadding>
              <ReadonlyText label="Activity" value={activityReview?.activity?.activityDefinition?.displayName} />
            </Column>
          </Row>
          <Row spacingV="ll" justify="center">
            <Column span={6} hasNoPadding>
              <ReadonlyText label="Activity status" value={activityReview?.status} />
            </Column>
          </Row>
          <Row spacingV="ll" justify="center">
            <Column span={6} hasNoPadding>
              <ReadonlyText label="Type" value={activityReviewType} />
            </Column>
          </Row>
          {selectedAssignees
            .sort((i) => i.index)
            .map((item) => (
              <Row spacingV="ll" justify="center" key={item.index}>
                <Column span={6} hasNoPadding>
                  <div className="AssigneeDropdownWrapper">
                    <Select
                      label={item.index === 0 ? "Primary Assignee" : "Other Assignee"}
                      data={[
                        ...(relatedUsers?.filter((r) => !selectedAssignees.some((x) => x.userUuid === r.key)) || []),
                        ...(relatedUsers?.filter((r) => item.userUuid === r.key) || []),
                      ]}
                      value={item.userUuid}
                      onChange={(e) =>
                        setSelectedAssignee({
                          userUuid: e,
                          index: item.index,
                          userName: relatedUsers?.find((r) => r.key === e)?.value?.toString(),
                        })
                      }
                    />
                    {selectedAssignees.length < 5 && item.index === 0 && (
                      <div
                        tabIndex={-1}
                        role="button"
                        aria-label="Add Assignee"
                        className="AddAssigneeButton"
                        onClick={() => addAssigneeBox()}
                        onKeyDown={noop}
                      >
                        <UserPlusLargeDarkIcon />
                      </div>
                    )}
                    {item.index > 0 && item.index === selectedAssignees.length - 1 && (
                      <div
                        tabIndex={0}
                        role="button"
                        aria-label="Remove Assignee"
                        className="RemoveAssigneeButton"
                        onClick={() => removeAssigneeBox(item.index)}
                        onKeyDown={noop}
                      >
                        <UserMinusLargeDarkIcon />
                      </div>
                    )}
                  </div>
                </Column>
              </Row>
            ))}
          <Row spacingV="xl" justify="center">
            <Column span={6} hasNoPadding>
              <div className="AssignActivityModalDatePickerWrapper">
                <DatePicker label="Deadline" value={deadline} onChange={(d) => setDeadline(d)} />
              </div>
            </Column>
          </Row>
          <Row justify="center">
            <Column span={6} hasNoPadding>
              <ActionButtons
                submitButtonLabel="Continue"
                isLoading={isHandleSubmitLoading}
                onCancel={handleCancel}
                fullWidth
              />
            </Column>
          </Row>
        </form>
      </Modal>
    </div>
  );
};
