import { Column, CursorDataGrid, Row } from "../../../../../widget";
import { useReviews } from "./useReviews";

const Reviews = (): JSX.Element => {
  const { tableColumnsQueue, defaultSortingCriteria, onReviewQueueDataLoad, isLoading } = useReviews();

  return (
    <div>
      <Row spacingV="ml">
        <Column span={12}>
          <h2>Review</h2>
        </Column>
      </Row>
      <Row>
        <Column span={12}>
          <CursorDataGrid
            columns={tableColumnsQueue}
            clientSide={false}
            filterable
            onChange={onReviewQueueDataLoad}
            data={[]}
            noDataMessage="No reviews to display"
            dataIsLoading={isLoading}
            defaultSortingCriteria={defaultSortingCriteria}
          />
        </Column>
      </Row>
    </div>
  );
};

export default Reviews;
