import "./Stepper.css";

import { Fragment } from "react";

import { CircleFilledIcon, CompletedCircleGreenIcon, ErrorIcon } from "../../../assets";
import { ColorConstants, OrganisationTypeConstants } from "../../../constants";
import { StepItem, StepState } from "../../../models";

interface StepperProps {
  steps: StepItem[];
  currentSubStepKey?: string;
  onClick?: (stepKey: string) => void;
  currentUserType: OrganisationTypeConstants;
}

export const Stepper = ({ steps, currentSubStepKey, onClick, currentUserType }: StepperProps): JSX.Element => {
  return (
    <div className="Stepper">
      {steps.map((step, i) => {
        if (onClick) {
          return (
            <Fragment key={step.stepName}>
              {i > 0 && <div className={`StepDivider ${StepState[step.stepState]} ${currentUserType}`} />}
              <div
                role="button"
                onKeyDown={(e) => e.key === "Enter" && onClick(step.key)}
                onClick={() => onClick(step.key)}
                className={`StepperStep ${StepState[step.stepState]} ${
                  currentSubStepKey === step.key ? "IsCurrent" : ""
                }`}
                tabIndex={0}
              >
                <div className="StepIcon">
                  {step.stepState === StepState.NotStarted && (
                    <>
                      <CircleFilledIcon width="24" height="24" fill={ColorConstants.GREY_20} />
                      <div className="StepNumber">{i + 1}</div>
                    </>
                  )}
                  {step.stepState === StepState.InProgress && (
                    <>
                      <CircleFilledIcon width="24" height="24" fill={ColorConstants.GREEN} />
                      <div className="StepNumber">{i + 1}</div>
                    </>
                  )}
                  {step.stepState === StepState.Invalid &&
                    (currentUserType === OrganisationTypeConstants.VERIFIER ? (
                      <>
                        <CircleFilledIcon width="24" height="24" fill={ColorConstants.YELLOW_DARK_80} />
                        <div className="StepNumber">{i + 1}</div>
                      </>
                    ) : (
                      <ErrorIcon fill={ColorConstants.RED} width={24} height={24} />
                    ))}
                  {step.stepState === StepState.Warning &&
                    (currentUserType === OrganisationTypeConstants.DEVELOPER ? (
                      <>
                        <CircleFilledIcon width="24" height="24" fill={ColorConstants.YELLOW_DARK_80} />
                        <div className="StepNumber">{i + 1}</div>
                      </>
                    ) : (
                      <ErrorIcon fill={ColorConstants.RED} width={24} height={24} />
                    ))}
                  {step.stepState === StepState.Complete && <CompletedCircleGreenIcon />}
                </div>
                <p className="body2">{step.stepName}</p>
              </div>
            </Fragment>
          );
        }

        return (
          <Fragment key={step.stepName}>
            {i > 0 && <div className={`StepDivider ${StepState[step.stepState]} ${currentUserType}`} />}
            <div
              className={`StepperStep ${StepState[step.stepState]} ${
                currentSubStepKey === step.key ? "IsCurrent" : ""
              }`}
            >
              <div className="StepIcon">
                {step.stepState === StepState.NotStarted && (
                  <CircleFilledIcon width="24" height="24" fill={ColorConstants.GREY_20} />
                )}
                {step.stepState === StepState.InProgress && (
                  <CircleFilledIcon width="24" height="24" fill={ColorConstants.GREEN} />
                )}
                {step.stepState === StepState.Complete ? (
                  <CompletedCircleGreenIcon />
                ) : (
                  <div className="StepNumber">{i + 1}</div>
                )}
              </div>
              <p className="body2">{step.stepName}</p>
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};
