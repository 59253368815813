import "../VerifierSingleCardTemplate/VerifierSingleCardTemplate.css";
import "./VerifierSingleCardWithTabsTemplate.css";

import { ArrowFullLeftDarkIcon } from "../../../assets";
import { StandardConstants } from "../../../constants";
import { NavigationActiveElement, TabsList } from "../../../models";
import { WorkflowActivityReview } from "../../../models/shared/workflowActivityReviewType";
import {
  ApproveModal,
  ReturnToAssessorModal,
  SubmitToCodeModal,
  SubmitToRegistryModal,
} from "../../../route/verifier/components";
import { CancelReviewModal } from "../../../route/verifier/components/CancelReviewModal";
import {
  Button,
  Column,
  LeftNavigation,
  Pill,
  ProfileMenu,
  ProfileMenuItem,
  QuickActionsMenu,
  Row,
  Tabs,
  UserHeader,
} from "../../../widget";
import { useVerifierSingleCardWithTabsTemplate } from "./useVerifierSingleCardWithTabsTemplete";

interface VerifierSingleCardWithTabsTemplateProps {
  activeElement: NavigationActiveElement;
  canManageActivityReview?: boolean;
  activityReviews?: WorkflowActivityReview[];
  pageTitle: string | undefined;
  titlePill?: string;
  tabsList: TabsList;
  handleBackClick?: () => void;
  backButtonText?: string;
  isLoading?: boolean;
  kpis?: JSX.Element | JSX.Element[];
}

export const VerifierSingleCardWithTabsTemplate = ({
  activeElement,
  canManageActivityReview,
  activityReviews,
  pageTitle,
  titlePill,
  tabsList,
  handleBackClick,
  backButtonText = "Back",
  isLoading = false,
  kpis,
}: VerifierSingleCardWithTabsTemplateProps): JSX.Element => {
  const {
    showQuickActionMenu,
    setShowQuickActionMenu,
    quickActionsMenuOptionsList,
    showReturnToAssessorModal,
    setShowReturnToAssessorModal,
    showSubmitToRegistryModal,
    setShowSubmitToRegistryModal,
    showSubmitToCodeModal,
    setShowSubmitToCodeModal,
    showApproveModal,
    setShowApproveModal,
    setShowCancelReviewModal,
    showCancelReviewModal,
    activityReview,
    activityAssessment,
    refreshActivityReviews,
    setRefreshActivityReviews,
  } = useVerifierSingleCardWithTabsTemplate({ activityReviews });

  return (
    <div className="VerifierSingleCardTemplate">
      <div className="VerifierSingleCardTemplateNavigation">
        <LeftNavigation activeElement={activeElement} />
      </div>
      {!isLoading && (
        <div className="VerifierSingleCardTemplateContainer">
          {handleBackClick ? (
            <div className="HeaderContainer">
              <Button
                text={backButtonText}
                variant="secondary"
                icon={<ArrowFullLeftDarkIcon />}
                iconPosition="left"
                onClick={handleBackClick}
              />
              <UserHeader />
            </div>
          ) : (
            <UserHeader />
          )}
          <Row spacingV="ll">
            <Column span={12} className="TitleAndPill">
              <h1>{pageTitle}</h1>
              {titlePill && <Pill label={titlePill} variantMap={StandardConstants.CODE_PILL_VARIANT_MAP} />}
              {canManageActivityReview && quickActionsMenuOptionsList.length > 0 && (
                <div className="QuickActions">
                  <QuickActionsMenu onClick={() => setShowQuickActionMenu(true)} />
                  <ProfileMenu
                    show={showQuickActionMenu}
                    onClose={() => setShowQuickActionMenu(false)}
                    position={{ top: "0" }}
                  >
                    {quickActionsMenuOptionsList.map((el) => (
                      <ProfileMenuItem
                        key={el.id}
                        profileMenuItem={el}
                        onClick={() => {
                          if (el.action) {
                            el.action();
                          }
                          setShowQuickActionMenu(false);
                        }}
                      />
                    ))}
                  </ProfileMenu>
                </div>
              )}
            </Column>
          </Row>
          {kpis && (
            <Row spacingV="ll">
              <Column span={12} style={{ paddingRight: 0 }}>
                {kpis}
              </Column>
            </Row>
          )}
          <Tabs className="TemplateTabs" tabsList={tabsList} />
        </div>
      )}
      {activityAssessment !== undefined && activityReview !== undefined && (
        <>
          <SubmitToCodeModal
            show={showSubmitToCodeModal}
            onClose={() => setShowSubmitToCodeModal(false)}
            activityUuid={activityReview.activityUuid}
            activityStatus={activityReview.activityStatus}
            activityRowVersion={activityReview.activityRowVersion}
            refreshActivityReviews={refreshActivityReviews}
            setRefreshActivityReviews={setRefreshActivityReviews}
          />
          <SubmitToRegistryModal
            show={showSubmitToRegistryModal}
            onClose={() => setShowSubmitToRegistryModal(false)}
            activityUuid={activityReview.activityUuid}
            activityStatus={activityReview.activityStatus}
            activityRowVersion={activityReview.activityRowVersion}
            refreshActivityReviews={refreshActivityReviews}
            setRefreshActivityReviews={setRefreshActivityReviews}
          />
          <ApproveModal
            show={showApproveModal}
            onClose={() => setShowApproveModal(false)}
            activityUuid={activityReview.activityUuid}
            activityStatus={activityReview.activityStatus}
            activityRowVersion={activityReview.activityRowVersion}
            activityReviewUuid={activityReview.uuid}
            activityReviewStatus={activityReview.status}
            activityReviewRowVersion={activityReview.rowVersion}
            activityAssessmentUuid={activityAssessment.uuid}
            activityAssessmentStatus={activityAssessment.status}
            activityAssessmentRowVersion={activityAssessment.rowVersion}
            refreshActivityReviews={refreshActivityReviews}
            setRefreshActivityReviews={setRefreshActivityReviews}
          />
          <ReturnToAssessorModal
            show={showReturnToAssessorModal}
            onClose={() => setShowReturnToAssessorModal(false)}
            activityUuid={activityReview.activityUuid}
            activityStatus={activityReview.activityStatus}
            activityRowVersion={activityReview.activityRowVersion}
            activityReviewUuid={activityReview.uuid}
            activityReviewStatus={activityReview.status}
            activityReviewRowVersion={activityReview.rowVersion}
            activityAssessmentUuid={activityAssessment.uuid}
            activityAssessmentStatus={activityAssessment.status}
            activityAssessmentRowVersion={activityAssessment.rowVersion}
            refreshActivityReviews={refreshActivityReviews}
            setRefreshActivityReviews={setRefreshActivityReviews}
          />
        </>
      )}
      {activityAssessment !== undefined && (
        <CancelReviewModal
          show={showCancelReviewModal}
          onClose={() => setShowCancelReviewModal(false)}
          activityUuid={activityAssessment.activityUuid}
          activityStatus={activityAssessment.activityStatus}
          activityRowVersion={activityAssessment.activityRowVersion}
          activityReviewUuid={activityReview?.uuid}
          activityReviewStatus={activityReview?.status}
          activityReviewRowVersion={activityReview?.rowVersion}
          activityAssessmentUuid={activityAssessment.uuid}
          activityAssessmentStatus={activityAssessment.status}
          activityAssessmentRowVersion={activityAssessment.rowVersion}
        />
      )}
    </div>
  );
};
