import { createContext, Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { getProjectDetails, GetProjectDetailsResponse } from "../../../../service/query";
import { Status } from "../../../../service/Shared";
import { useAuth } from "../../../../useAuth";

type ProjectPageRouteParams = "projectUuid";

interface ProjectProviderProps {
  children?: JSX.Element;
}

interface ProjectContextType {
  projectDetails: GetProjectDetailsResponse | undefined;
  setProjectDetails: Dispatch<SetStateAction<GetProjectDetailsResponse | undefined>>;
  isExternalProject: () => boolean;
  hasProjectPermission: (permission: string) => boolean;
  refreshActivities: boolean;
  shouldRefreshActivities: () => void;
  shouldRefreshProjectDetails: () => void;
}

export const ProjectContext = createContext<ProjectContextType>({} as ProjectContextType);

export const ProjectProvider = ({ children }: ProjectProviderProps): JSX.Element => {
  const { currentDeveloperUuid } = useAuth();
  const { projectUuid } = useParams<ProjectPageRouteParams>();
  const [isLoading, setIsLoading] = useState(true);
  const [refreshActivities, setRefreshActivities] = useState(false);
  const [refreshProjectDetails, setRefreshProjectDetails] = useState(false);

  const shouldRefreshActivities = (): void => {
    setRefreshActivities((prev) => !prev);
  };

  const shouldRefreshProjectDetails = (): void => {
    setRefreshProjectDetails((prev) => !prev);
  };

  const [projectDetails, setProjectDetails] = useState<GetProjectDetailsResponse | undefined>(undefined);

  const fetchData = useCallback(async () => {
    if (projectUuid) {
      const res = await getProjectDetails({
        projectUuid,
        projectType: null,
        projectExternalReference: null,
      });

      if (res.status === Status.Success && res.data) {
        setProjectDetails(res.data);
      }
    }
  }, [projectUuid]);

  const isExternalProject = (): boolean => {
    return currentDeveloperUuid !== projectDetails?.developer.uuid;
  };

  const hasProjectPermission = (permission: string): boolean => {
    return projectDetails?.currentUserPermissions?.includes(permission) ?? false;
  };

  useEffect(() => {
    fetchData().finally(() => setIsLoading(false));
  }, [fetchData, projectUuid, refreshProjectDetails]);

  const memoisedValue = useMemo(
    () => ({
      projectDetails,
      refreshActivities,
      shouldRefreshActivities,
      shouldRefreshProjectDetails,
      setProjectDetails,
      isExternalProject,
      hasProjectPermission,
    }),
    [projectDetails, fetchData, refreshActivities]
  );

  return <ProjectContext.Provider value={memoisedValue}>{!isLoading && children}</ProjectContext.Provider>;
};
